import Container from "react-bootstrap/Container";
import { Route, Routes } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import Lists from "./Lists";
import Members from "./Members";

const MailingList = () => {
  const { permissions } = useAuth();

  if (permissions.role_type !== "NIAP") {
    return (
      <Container className="text-center mt-4">
        <h2>Unauthorized</h2>
        <p>You do not have permission to access this area</p>
      </Container>
    );
  }

  return (
    <Routes>
      <Route path="" element={<Lists />} />
      <Route path="members/:id" element={<Members />} />
    </Routes>
  );
};

export default MailingList;
