import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "./store";

interface LoadingState {
  isLoading: boolean;
  loadingText: string;
}

const initialState: LoadingState = {
  isLoading: false,
  loadingText: "",
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: initialState,
  reducers: {
    enableLoadingSpinner(state, action: PayloadAction<string>) {
      const { payload } = action;

      state.isLoading = true;
      state.loadingText = payload;
    },

    disableLoadingSpinner(state) {
      state.isLoading = initialState.isLoading;
      state.loadingText = initialState.loadingText;
    },
  },
});

// Reducer
export default loadingSlice.reducer;

// Actions
export const { enableLoadingSpinner, disableLoadingSpinner } =
  loadingSlice.actions;

// Selectors
export const selectIsLoading = (state: RootState): boolean =>
  state.loading.isLoading;

export const selectLoadingText = (state: RootState): string =>
  state.loading.loadingText;
