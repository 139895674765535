// ResponsiveMaterialTable.js
import MaterialTable, { MTableToolbar } from "@material-table/core";
import TablePagination from "@mui/material/TablePagination";
import { useState, useEffect } from "react";

import { TABLE_ICONS } from "./defaultIcons";
import MobileMaterialTablePagination from "./MobileMaterialTablePagination";

const ResponsiveMaterialTable = (props) => {
  // State to determine if the screen is small
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 600);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Override or set additional options here
  const responsiveOptions = {
    ...props.options,
    search: isSmallScreen ? false : props.options?.search !== false,
    pageSize: isSmallScreen ? 5 : (props.options?.pageSize ?? 20),
  };

  const tableId = props.id;

  const {
    Toolbar: CustomToolbar,
    Pagination: _CustomPagination,
    ...otherComponents
  } = props.components || {};

  const { columns } = props;
  const columnsWithFiltering = columns.map((column) => ({
    ...column,
    filtering: props.options?.filtering || column.filtering || false,
  }));

  return (
    <MaterialTable
      icons={TABLE_ICONS}
      {...props}
      columns={columnsWithFiltering}
      options={responsiveOptions}
      components={{
        ...props.components,
        Toolbar: (ToolbarProps) => {
          const newTitle = `${ToolbarProps.title}: ${totalCount} Matches`;
          const SelectedToolbar = CustomToolbar || MTableToolbar;

          return <SelectedToolbar {...ToolbarProps} title={newTitle} />;
        },
        Pagination: (paginationProps) => {
          useEffect(() => {
            if (paginationProps.count !== undefined) {
              setTotalCount(paginationProps.count);
            }
          }, [paginationProps.count]);

          return isSmallScreen ? (
            <MobileMaterialTablePagination {...paginationProps} />
          ) : (
            <TablePagination
              {...paginationProps}
              labelRowsPerPage={
                <label htmlFor={`rowsPerPage-${tableId}`}>Rows Per Page</label>
              }
              slotProps={{
                select: {
                  inputProps: { id: `rowsPerPage-${tableId}` },
                  MenuProps: {
                    style: {
                      zIndex: 1301,
                    },
                  },
                },
              }}
              sx={{
                ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                  {
                    margin: 0,
                  },
              }}
            />
          );
        },
        ...otherComponents,
      }}
    />
  );
};

export default ResponsiveMaterialTable;
