import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState } from "react";
import { Accordion, Alert, Modal, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

import AddQAUpdateModal from "./AddQAUpdateModal";

export default function QAUpdatesSection({ qaUpdates, qa, refetch }) {
  const [showQAUpdateModal, setShowQaUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedQaUpdate, setSelectedQaUpdate] = useState({});
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { authToken, csrfToken, currentUser } = useAuth();

  const handleDeleteUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/update/${selectedQaUpdate?.upid}/`,
        {
          rid: selectedQaUpdate?.rid__rid,
          shared: selectedQaUpdate?.shared,
          active: false,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setShowDeleteModal(false);
        refetch();
        setAlert({ message: "Update Deleted!", type: "success" });
      })
      .catch((error) => {
        console.log("error sending email: " + error);
        setAlert({ message: "Error Deleting Update.", type: "danger" });
      });
  };

  return (
    <>
      <AddQAUpdateModal
        show={showQAUpdateModal}
        handleClose={() => {
          refetch();
          setShowQaUpdateModal(false);
          setSelectedQaUpdate({});
        }}
        qa={qa}
        editQaUpdate={
          Object.keys(selectedQaUpdate)?.length > 0 ? selectedQaUpdate : null
        }
        setAlert={setAlert}
      />
      <Accordion className="my-4" flush defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h6 className="fw-bold text-primary mt-3">QA UPDATES</h6>
            <span className="border my-2 mx-4 p-2">
              Total: {qaUpdates.length}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            {alert.message !== "" && (
              <Alert
                variant={alert.type}
                className="m-3"
                onClose={() => setAlert({ message: "", type: "" })}
                dismissible
              >
                {alert.message}
              </Alert>
            )}
            <div className="text-end">
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => setShowQaUpdateModal(true)}
              >
                Add Update
              </button>
            </div>
            {showDeleteModal && (
              <Modal
                size="md"
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
              >
                <Modal.Header closeButton className="border-0">
                  <Modal.Title>Delete QA Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4 className="text-center">
                    Are You Sure You Want to Delete this Update By: <br />
                    {selectedQaUpdate?.creator__first_name}{" "}
                    {selectedQaUpdate?.creator__last_name} on: <br />
                    {selectedQaUpdate.entrydate &&
                      moment(selectedQaUpdate.entrydate).format(
                        "YYYY.MM.DD [at] HHmm",
                      )}
                  </h4>
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      variant="primary"
                      className="m-1"
                      onClick={() => {
                        setSelectedQaUpdate({});
                        setShowDeleteModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="danger"
                      className="m-1"
                      onClick={handleDeleteUpdate}
                    >
                      Delete Update
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {qaUpdates.map((update, idx) => (
              <div key={`update-${idx}`}>
                <div className="text-end">
                  {update?.creator__id === currentUser?.id && (
                    <>
                      <button
                        className="btn btn-link d-inline-block"
                        style={{ minWidth: 10 }}
                        onClick={() => {
                          setSelectedQaUpdate(update);
                          setShowQaUpdateModal(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-link d-inline-block"
                        style={{ minWidth: 10 }}
                        onClick={() => {
                          setSelectedQaUpdate(update);
                          setShowDeleteModal(true);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </div>
                <div className="border border-secondary p-4 m-4">
                  {update?.shared && (
                    <p className="d-flex justify-content-end m-0 fw-bold">
                      Shared with Customer
                    </p>
                  )}
                  {update?.moddate ? (
                    <p className="text-end">
                      Update Modified by: {update?.editor__first_name}{" "}
                      {update?.editor__last_name} <br />
                      {update?.moddate &&
                        moment(update.moddate).format("YYYY.MM.DD [at] HHmm")}
                    </p>
                  ) : (
                    <p className="text-end">
                      Update Created by: {update?.creator__first_name}{" "}
                      {update?.creator__last_name} <br />
                      {update?.entrydate &&
                        moment(update.entrydate).format("YYYY.MM.DD [at] HHmm")}
                    </p>
                  )}
                  <div>{update.note1 && parse(update.note1)}</div>
                </div>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
