import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { useState, useCallback, useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import NIAPValidationTeamModal from "../NIAPProductsModule/NIAPValidationTeamModal";
import ManageAMValidationTeamModal from "../ProductsModule/ManageAMValidationTeamModal";
import ManageValidationTeamModal from "../ProductsModule/ManageValidationTeamModal";

export default function AlertCard({ alert, refetch }) {
  const navigate = useNavigate();

  const [projectPersonnel, setProjectPersonnel] = useState({});
  const [ears, setEars] = useState([]);
  const [showNIAPValTeamModal, setShowNIAPValTeamModal] = useState(false);
  const [showValTeamModal, setShowValTeamModal] = useState(false);
  const [showAMValTeamModal, setShowAMValTeamModal] = useState(false);
  const [tdIdentifiers, setTdIdentifiers] = useState({});
  const { authToken, csrfToken, permissions } = useAuth();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/td_ident_by_pk`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setTdIdentifiers(response.data);
      })
      .catch((err) => console.log("unable to get technical query", err));
  }, []);

  const handleView = (type, id, source, text) => {
    if (type === "Product") {
      if (source === "Assurance Maintenance") {
        let maintenance_id = text.match(/\d+/g);
        navigate(
          `/community/products/details/${id}/assurancemaintenance/${maintenance_id}`,
        );
      } else if (source === "AM Validation Team Recommended") {
        let maintenance_id = text.match(/\d+/g);
        fetchValidatorClaim(false, maintenance_id);
        if (permissions?.role_type === "NIAP") {
          setShowNIAPValTeamModal(true);
        } else {
          setShowAMValTeamModal(true);
        }
      } else if (source === "Validation Team Recommended") {
        fetchValidatorClaim(id, false);
        if (permissions?.role_type === "NIAP") {
          setShowNIAPValTeamModal(true);
        } else {
          setShowValTeamModal(true);
        }
      } else {
        navigate(`/community/products/details/${id}`);
      }
    } else if (type === "EAR Review") {
      navigate(`/community/ear-reviews/details/${id}`);
    } else if (type === "PCM") {
      navigate(`/community/pcm/details/${id}`);
    } else if (type === "QA") {
      navigate(`/community/question-answer/details/${id}`);
    } else if (type === "Technical Query") {
      navigate(`/community/technicalqueries/details/${id}`);
    } else if (type === "Technical Decision") {
      navigate(`/community/technical-decisions/details/${tdIdentifiers[id]}`);
    }
  };

  const fetchValidatorClaim = async (product_id, maintenance_id) => {
    let queryString = "";
    if (product_id) {
      queryString = `product_id=${product_id}`;
    } else {
      queryString = `maintenance_id=${maintenance_id}`;
    }
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/validator_claim_by_id/?${queryString}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        let data = response.data;
        if (data.length > 0) {
          setProjectPersonnel(data[0]);
        }
      })
      .catch((error) => console.log(error));
  };

  //update alert to either active or Dismissed
  const handleClick = (active, alert) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/${alert.alert_id}/`,
        { ...alert, alert_to: alert.alert_to.id, active: active },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => refetch())
      .catch((error) => console.log("error updating alert status: " + error));
  };

  const getRelativeTime = (stringDate) => {
    let now = new Date();
    let alert_date = new Date(stringDate);
    let secondsPast = (now.getTime() - alert_date.getTime()) / 1000;
    if (secondsPast < 60) {
      return secondsPast + "s";
    }
    if (secondsPast < 3600) {
      return parseInt(secondsPast / 60) + "min";
    }
    if (secondsPast <= 86400) {
      return parseInt(secondsPast / 3600) + "h";
    }
    if (secondsPast <= 2628000) {
      return parseInt(secondsPast / 86400) + "d";
    }
    if (secondsPast <= 31536000) {
      return parseInt(secondsPast / 2628000) + "mo";
    }
    if (secondsPast > 31536000) {
      return parseInt(secondsPast / 31536000) + "y";
    }
  };

  const getIconColor = (time) => {
    let now = new Date();
    let alert_date = new Date(time);
    let secondsPast =
      (now.getTime() - alert_date.getTime()) / (1000 * 3600 * 24);
    let wholeNumber = Math.trunc(secondsPast);
    if (wholeNumber <= 3) {
      return "var(--bs-green)";
    } else if (wholeNumber > 3 && wholeNumber <= 5) {
      return "var(--bs-yellow)";
    } else {
      return "var(--bs-red)";
    }
  };

  const handleCloseValTeamModal = () => {
    setShowNIAPValTeamModal(false);
  };

  const fetchEars = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => setEars(response.data))
      .catch((error) => console.log(error));
  }, []);

  const refetchEars = () => {
    fetchEars();
  };

  return (
    <>
      <NIAPValidationTeamModal
        show={showNIAPValTeamModal && projectPersonnel?.pp_team_id}
        handleClose={handleCloseValTeamModal}
        productInfo={projectPersonnel}
        ears={ears}
        refetch={refetchEars}
        alertView
      />
      <ManageAMValidationTeamModal
        show={showAMValTeamModal && projectPersonnel?.pp_team_id}
        setShow={() => setShowAMValTeamModal(false)}
        currentProductInEval={projectPersonnel}
        refetch={refetchEars}
        alertView
      />
      <ManageValidationTeamModal
        show={showValTeamModal}
        setShow={setShowValTeamModal}
        currentProductInEval={projectPersonnel}
        refetch={refetchEars}
        alertView
      />
      <Card className="mt-2 mb-2">
        <Card.Body className="p-1">
          <Container>
            <Row>
              <Col className="m-auto" lg={2}>
                <p className="fw-bold mb-0">{alert?.alert_source}</p>
              </Col>
              {alert?.active && (
                <Col className="m-auto" lg={1}>
                  <ErrorIcon
                    sx={{
                      fontSize: 30,
                      color: `${getIconColor(alert.alert_on)}`,
                    }}
                  ></ErrorIcon>
                </Col>
              )}
              <Col>
                <Row>
                  <Col className="m-auto">
                    <p className="fw-bold mb-0">
                      {alert?.alert_to?.first_name} {alert?.alert_to?.last_name}
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <p style={{ color: "DimGray" }}>
                      {getRelativeTime(alert.alert_on)}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <p className="fontAlert overflow-ellipsis">
                    {alert?.alert_text}
                  </p>
                </Row>
              </Col>
              <Col lg={3} className="m-auto">
                <Row className="d-flex justify-content-center">
                  {permissions.role_type !== "Vendor" && (
                    <Button
                      variant="dark"
                      size="sm"
                      className="alertsButton p-1 small-font"
                      onClick={() =>
                        handleView(
                          alert.alert_type,
                          alert.alert_type_id,
                          alert.alert_source,
                          alert.alert_text,
                        )
                      }
                    >
                      View
                    </Button>
                  )}
                </Row>
                <Row className="d-flex justify-content-center">
                  {alert.active ? (
                    <Button
                      variant="dark"
                      size="sm"
                      className="alertsButton p-1 small-font"
                      onClick={() => handleClick(false, alert)}
                    >
                      Dismiss
                    </Button>
                  ) : (
                    <Button
                      variant="dark"
                      size="sm"
                      className="alertsButton p-1 small-font"
                      onClick={() => handleClick(true, alert)}
                    >
                      Restore
                    </Button>
                  )}
                </Row>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </>
  );
}
