import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";
import { convertWeekend } from "utils/timeUtils";

export const DocumentEditModal = ({
  product,
  documentName,
  validationTeam,
  setDocumentHtml,
  setDisplayIframe,
  show,
  handleClose,
}) => {
  const [documentEdits, setDocumentEdits] = useState({});
  const [forLabs, setForLabs] = useState(false);
  const { authToken, csrfToken } = useAuth();

  useEffect(() => {
    setDocumentEdits({});
    //these letters are for labs
    if (
      [
        "Termination Warning Letter",
        "Termination Letter 1 - Package Incomplete",
        "Termination Letter 2 - Package Not Submitted",
        "Withdrawal Letter",
      ].includes(documentName)
    ) {
      setForLabs(true);
    } else {
      setForLabs(false);
    }
  }, [documentName]);

  const handleChange = (e) => {
    setDocumentEdits({ ...documentEdits, [e.target.name]: e.target.value });
  };

  const handleGenerate = async () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/generate_document/?document_name=${documentName}&product_id=${product?.product_id}`,
        {
          ...documentEdits,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        URL.revokeObjectURL(setDocumentHtml);
        let newDoc = "";
        if (response.data.size > 0) {
          newDoc = URL.createObjectURL(response.data);
        }
        setDocumentHtml(newDoc + "#toolbar=0");
        handleClose();
        setDisplayIframe(true);
      })
      .catch(() => {});
  };

  const getLabAddress = () => {
    const lab = product?.assigned_lab;
    if (!lab) return "";
    if (documentEdits?.address_change) return documentEdits?.address_change;
    return `${lab.primary_address_1}
  ${lab.primary_address_2 ? lab.primary_address_2 + "\n " : ""}
  ${lab.primary_city}, ${lab.primary_state} ${lab.primary_zip}`;
  };

  const getVendorAddress = () => {
    const vendor = product?.vendor_id;
    if (!vendor) return "";
    if (documentEdits?.address_change) return documentEdits?.address_change;
    return `${vendor.primary_address_1}
  ${vendor.primary_address_2 ? vendor.primary_address_2 + "\n " : ""}
  ${vendor.primary_city}, ${vendor.primary_state} ${vendor.primary_zip}`;
  };

  const getLabPoc = () => {
    if (documentEdits?.poc) return documentEdits?.poc;
    const lab = product?.assigned_lab;
    if (!lab) return "";
    return lab?.poc;
  };

  const getVendorPoc = () => {
    if (documentEdits?.poc) return documentEdits?.poc;
    const vendor = product?.vendor_poc;
    if (!vendor) return product?.vendor_id?.poc;
    return vendor;
  };

  const getCopyAddressAdditions = () => {
    return `${validationTeam?.map((validator) => validator?.user_id?.first_name + " " + validator?.user_id?.last_name).join(", ")}, CCEVS Validators`;
  };

  const getLabCopyAddresses = () => {
    if (documentEdits?.copy_addressees) return documentEdits?.copy_addressees;
    const vendor = `${getLabPoc()},  ${product?.assigned_lab?.name} \n ${getCopyAddressAdditions()}`;
    return vendor;
  };

  const getVendorCopyAddresses = () => {
    if (documentEdits?.copy_addressees) return documentEdits?.copy_addressees;
    const vendor = `${getVendorPoc()},  ${product?.vendor_id?.name} \n ${getCopyAddressAdditions()}`;
    return vendor;
  };

  const getFinalPackageDueDate = () => {
    let addedDays = moment.utc(product?.kicked_off_date).add(159, "days");
    return convertWeekend(addedDays).format("YYYY-MM-DD");
  };

  const getFinalDeadlineOfPCL = () => {
    let addedDays = moment.utc(product?.kicked_off_date).add(180, "days");
    return convertWeekend(addedDays).format("YYYY-MM-DD");
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          Process Document Generator: Data Entry for{" "}
          <span className="fw-bold">
            {documentName} for VID{product?.v_id}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="issue_date">
                  <Form.Label className="fw-bold">
                    Date of This Letter:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={
                      moment
                        .utc(documentEdits?.issue_date)
                        .format("YYYY-MM-DD") ?? moment().format("YYYY-MM-DD")
                    }
                    name="issue_date"
                    onChange={handleChange}
                  />
                </Form.Group>
                {documentName?.includes("Termination Warning Letter") && (
                  <>
                    <Form.Group className="mb-3" controlId="termination_date">
                      <Form.Label className="fw-bold">
                        Final Checkout Package Due Date:
                      </Form.Label>
                      <Form.Control
                        type="date"
                        defaultValue={
                          documentEdits?.record_closeout_date ??
                          getFinalPackageDueDate()
                        }
                        name="record_closeout_date"
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="record_closeout_date"
                    >
                      <Form.Label className="fw-bold">
                        Final Deadline for the PCL:
                      </Form.Label>
                      <Form.Control
                        type="date"
                        defaultValue={
                          documentEdits?.termination_date ??
                          getFinalDeadlineOfPCL()
                        }
                        name="termination_date"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </>
                )}
                <Form.Group className="mb-3" controlId="poc">
                  <Form.Label className="fw-bold">Addressee:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={forLabs ? getLabPoc() : getVendorPoc()}
                    name="poc"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address_change">
                  <Form.Label className="fw-bold">Address:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength={2000}
                    defaultValue={
                      forLabs ? getLabAddress() : getVendorAddress()
                    }
                    name={"address_change"}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="product_name">
                  <Form.Label className="fw-bold">Product:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={
                      documentEdits?.product_name ?? product?.product_name
                    }
                    name="product_name"
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="copyAddressees">
                  <Form.Label className="fw-bold">Copy Addressees:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength={2000}
                    name="copy_addressees"
                    onChange={handleChange}
                    defaultValue={
                      forLabs ? getVendorCopyAddresses() : getLabCopyAddresses()
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleGenerate}>
          Update Preview
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
