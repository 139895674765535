import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import PolciyLettersTable from "./PolicyLettersTable";

export default function PolicyLettersManager() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<PolciyLettersTable />} />
    </Routes>
  );
}
