import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function GlossaryModal({
  show,
  handleShow,
  definition,
  refetch,
  setAlert,
}) {
  const [validated, setValidated] = useState(false);
  const [editedDefinition, setEditedDefinition] = useState({});
  const { authToken, csrfToken, currentUser } = useAuth();

  useEffect(() => {
    if (definition?.definition_id) {
      setEditedDefinition({ ...definition });
    } else {
      setEditedDefinition({});
    }
    setValidated(false);
  }, [show]);

  const resetForm = () => {
    setValidated(false);
    setEditedDefinition({});
  };

  const handleChange = (e) => {
    setEditedDefinition({
      ...editedDefinition,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/definitions/`,
          {
            creator: currentUser.id,
            editor: currentUser.id,
            entrydate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            moddate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            ...editedDefinition,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          handleShow();
          setAlert({
            message: "New Definition Created!",
            type: "success",
          });
          refetch();
        })
        .catch((error) => {
          setAlert({
            message: "Error Creating New Definition.",
            type: "danger",
          });
          console.log(error);
        });
    }
    setValidated(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/definitions/${definition?.definition_id}/`,
        {
          ...editedDefinition,
          creator: editedDefinition.creator?.id,
          editor: currentUser.id,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        if (refetch) {
          refetch();
        }
        handleShow();
        setAlert({
          message: "Successfully Updated Definition!",
          type: "success",
        });
      })
      .catch((error) => {
        handleShow();
        console.log("error: ", error);
        setAlert({
          message: "Error updating Definition.",
          type: "danger",
        });
      });
  };

  return (
    <Modal show={show} onHide={handleShow} size="lg">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header className="border-0" closeButton>
          <h3 className="text-bright-navy">
            {!definition
              ? "+ NEW TERM OR ACRONYM"
              : `EDIT - ${definition?.term}`}
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="term">
                  <Form.Label>Term or Acronym:*</Form.Label>
                  <Form.Control
                    className="form-indent-readOnly"
                    name="term"
                    onChange={handleChange}
                    value={editedDefinition?.term ?? ""}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="definition">
                  <Form.Label>Definition:*</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={5}
                    name="definition"
                    onChange={handleChange}
                    value={editedDefinition?.definition ?? ""}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Container>
            <Row className="d-flex justify-content-end">
              <Col sm={2}>
                <Button variant="outline-primary" onClick={handleShow}>
                  Cancel
                </Button>
              </Col>
              <Col sm={2}>
                <Button variant="warning" onClick={resetForm}>
                  Reset
                </Button>
              </Col>
              <Col sm={2}>
                {!definition ? (
                  <Button variant="success" type="submit">
                    Add
                  </Button>
                ) : (
                  <Button variant="success" onClick={handleSave}>
                    Update
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
