import { Container, Row, Col } from "react-bootstrap";

import { useGetCCTLProductMetricsQuery } from "api/memberPortal/project/product/product.api";
import { useAuth } from "hooks/useAuth";

interface CCTLMetricsGroupProps {
  handleShowModal: () => void;
  handleShowVendorModal: () => void;
}

export default function CCTLMetricsGroup({
  handleShowModal,
  handleShowVendorModal,
}: CCTLMetricsGroupProps) {
  const { permissions } = useAuth();

  const {
    data: productMetrics = {
      certified_products: 0,
      in_progress_products: 0,
      pre_proposal_products: 0,
    },
  } = useGetCCTLProductMetricsQuery();

  return (
    <Container className="mt-4">
      <Row>
        {permissions?.role_permissions?.find(
          (permission) => permission.area === "Project",
        )?.create &&
          permissions?.role_type === "Lab" && (
            <Col
              className="text-center m-3 rounded-0 shadow clickable"
              onClick={handleShowModal}
              data-cy="cctl-metrics-group-propose-evaluation"
            >
              <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
                +
              </h2>
              <p>Propose an Evaluation</p>
            </Col>
          )}
        <Col
          className="text-center m-3 rounded-0 shadow clickable"
          onClick={handleShowVendorModal}
        >
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            +
          </h2>
          <p>Associate a Vendor</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics.certified_products}
          </h2>
          <p>Certified Products</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics.in_progress_products}
          </h2>
          <p>Products In Progress</p>
        </Col>
        <Col className="text-center m-3 rounded-0 shadow">
          <h2 style={{ color: "var(--bs-gray-500)" }} className="mt-2">
            {productMetrics.pre_proposal_products}
          </h2>
          <p>Products In Pre-Proposal</p>
        </Col>
      </Row>
    </Container>
  );
}
