import axios from "axios";
import { useState, useEffect } from "react";
import { Container, Alert } from "react-bootstrap";

import Subheader from "components/Subheader";
import LetterOfIntentForm from "features/public/Resources/CCTL/components/LetterOfIntentForm";

import "./FormsAndTemplates.css";

export default function FormsAndTemplatesPage() {
  const [showCCTLModal, setShowCCTLModal] = useState(false);
  const [loadCaptcha, setLoadCaptcha] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });

  useEffect(() => {
    document.title = "NIAP - Forms and Templates";
  }, []);

  useEffect(() => {
    //we want to show alert once the user successfully signs the form via docusign and is redirected back to this page
    if (window.location.search === "?event=signing_complete") {
      const loi_id = localStorage.getItem("loi");
      //we are getting the signed copy to send to the user via email
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}cctl/loi/get_and_send_signed_loi/?loi_id=${loi_id}`,
        )
        .then(() => {
          setAlert({
            type: "success",
            message:
              "Letter of Intent submitted successfully. Please check email for downloadable copy.",
          });
          localStorage.removeItem("response_data"); //remove data from local storage
        })
        .catch((error) => {
          console.log("Unable to submit loi: " + error);
          setAlert({
            type: "success",
            message:
              "Letter of Intent submitted successfully. Error sending copy to your email. Please contact NIAP.",
          });
        });
    }
  }, [window.location.search]);

  return (
    <>
      <LetterOfIntentForm
        show={showCCTLModal}
        setShowModal={setShowCCTLModal}
        loadCaptcha={loadCaptcha}
        setAlert={setAlert}
      />
      <Subheader pageName={"Forms & Templates"} />
      <Container className="mb-5 py-3">
        {alert?.message && (
          <div className="p-4">
            <Alert
              variant={alert.type}
              onClose={() => setAlert({ message: "", type: "" })}
              dismissible
            >
              {alert.message}
            </Alert>
          </div>
        )}
        <div className="p-4">
          <h3 className="text-uppercase fw-bold pb-3">
            RESOURCES -{" "}
            <span className="text-primary text-uppercase">
              FORMS AND TEMPLATES
            </span>
          </h3>
          <p>
            The following are NIAP CCEVS e-forms are used to aid in the routine
            conduct of an evaluation/validation. Comments regarding any scheme
            form can be forwarded to the NIAP CCEVS Validation Body using the
            following email address: niap@niap-ccevs.org.
          </p>
        </div>
        <div className="p-4">
          <p>
            Click the form names to preview a sample of the information required
            electronically:
          </p>
          <ul className="clickable text-primary">
            <li className="mb-1">
              <strong>
                <a href="forms/Remote Testing Form F8005 Mar 2025.dotx">
                  Remote Testing Request Form (F8005 - March 2025)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a href="forms/F8002a_20131130.pdf">
                  Evaluation Check-in (F8002a)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a href="forms/F8002b.pdf">
                  Vendor/CCTL Approval to Release Validation Information
                  (F8002b)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a href="forms/F8003_Form-201704.pdf">
                  NIAP Certificate Award/Congratulatory Letter Worksheet, V3.0
                  (F8003 - Apr 2017)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a href="forms/F8004_Form-202001.pdf">
                  NIAP End of Evaluation Survey (F8004 - Jan 2020)
                </a>
              </strong>
            </li>
          </ul>
          <p>
            Interested in participating as an approved laboratory in the CCEVS?
            Submit your Letter of Intent and notify NIAP, here:
          </p>
          <ul>
            <li
              className="mb-1 text-bright-navy clickable"
              onClick={() => {
                setLoadCaptcha(true);
                setShowCCTLModal(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <strong>Letter of Intent (To Become a CCTL)</strong>
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
}
