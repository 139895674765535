import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import FileDownload from "js-file-download";
import { Row, Col, Button } from "react-bootstrap";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

export default function PostingSubheaderDetails({
  product,
  validationReport,
  projpps,
  setShowCertificate,
}) {
  const { authToken } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  return (
    <Row>
      <Col className="mt-3">
        <Row>
          <Col>
            <p>Product Technology Type:</p>
          </Col>
          <Col>
            <p>{product?.tech_type}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Validation Report Number:</p>
          </Col>
          <Col>
            {validationReport && (
              <p className="m-0">
                {validationReport.file_label
                  ? `CCEVS-VR-VID${product?.v_id}-${new Date(validationReport?.uploaded_on).getFullYear()}`
                  : `CCEVS-VR-VID${product?.v_id}-YYYY`}
                <FileDownloadIcon
                  className="clickable"
                  style={{ color: "var(--bs-green)" }}
                  onClick={() => downloadFile(validationReport)}
                />
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Conformance Claim:</p>
          </Col>
          <Col>
            <p>
              {projpps?.length > 0
                ? "Protection Profile Compliant"
                : "Non-Compliant"}
            </p>
          </Col>
        </Row>
      </Col>
      <Col className="mt-3">
        <Row>
          {product?.vendors_cc_draft_cert_verifier ? (
            <>
              <Col xs={12} sm={6} style={{ width: "fit-content" }}>
                <p>Draft CC Certificate:</p>
              </Col>
              <Col lg={1} className="px-2" style={{ width: "fit-content" }}>
                <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
              </Col>
              <Col>
                <p>
                  Approved By{" "}
                  {product?.vendors_cc_draft_cert_verifier?.first_name}{" "}
                  {product?.vendors_cc_draft_cert_verifier?.last_name}{" "}
                </p>
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} sm={6}>
                <p>Draft CC Certificate:</p>
              </Col>
              <Col xs={12} sm={6}>
                <Button
                  variant="warning"
                  className="rounded-pill small-font"
                  onClick={() => setShowCertificate(true)}
                >
                  Review Draft Certificate
                </Button>
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col>
            <p>Web Release:</p>
          </Col>
          <Col>
            <p>
              {product?.publish_status === "Published"
                ? "Approved for Posting"
                : "Declined for Posting"}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
