import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import CcraActiveIcon from "../../assets/CcraActiveIcon.png";
import CcraInactiveIcon from "../../assets/CcraInactiveIcon.png";
import CctlActiveIcon from "../../assets/CctlActiveIcon.png";
import CctlInactiveIcon from "../../assets/CctlInactiveIcon.png";
import PpActiveIcon from "../../assets/PpActiveIcon.png";
import PpInactiveIcon from "../../assets/PpInactiveIcon.png";
import ProductActiveIcon from "../../assets/ProductActiveIcon.png";
import ProductInactiveIcon from "../../assets/ProductInactiveIcon.png";

export default function HomepageCards() {
  const [activeCard, setActiveCard] = useState("");
  const changeActiveCard = (e, card) => setActiveCard(card);
  const removeActiveCard = () => setActiveCard("");

  return (
    <Container fluid>
      <Row className="gx-0 mx-2 my-5">
        <Col md={6} lg={3}>
          <Card
            className="rounded-0 border-primary text-center h-100 homepage-card"
            onMouseEnter={(e) => changeActiveCard(e, "PRODUCTS")}
            onMouseLeave={removeActiveCard}
          >
            <Card.Body className="pt-0">
              <img
                src={
                  activeCard === "PRODUCTS"
                    ? ProductActiveIcon
                    : ProductInactiveIcon
                }
                className="border-bottom border-warning border-4 p-0 m-0"
                width="200px"
                alt="Product Logo"
              />
              <h4 className="fw-bold mt-2">PRODUCTS</h4>
              <Card.Text>
                The certified products are evaluated and granted certificates by
                NIAP or under CCRA partnering schemes, comply with the
                requirements of the NIAP program and where applicable, the
                requirements of the Federal Information Processing Standard
                (FIPS) Cryptographic validation program(s).
              </Card.Text>
            </Card.Body>
            <div className="pb-3">
              <Link to="/products" className="link fw-bold text-uppercase">
                Read more
              </Link>
              <NavigateNextIcon
                className={
                  "ms-2 border rounded-circle " +
                  (activeCard === "PRODUCTS"
                    ? "border-light"
                    : "border-primary")
                }
                color={activeCard === "PRODUCTS" ? "light" : "primary"}
              />
            </div>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card
            className="rounded-0 border-primary text-center h-100 homepage-card"
            onMouseEnter={(e) => changeActiveCard(e, "PP")}
            onMouseLeave={removeActiveCard}
          >
            <Card.Body className="pt-0">
              <img
                src={activeCard === "PP" ? PpActiveIcon : PpInactiveIcon}
                className="border-bottom border-warning border-4 p-0 m-0"
                width="200px"
                alt="Protection Profiles Logo"
              />
              <h4 className="fw-bold mt-2">PROTECTION PROFILES</h4>
              <Card.Text>
                Any product accepted into evaluation under the U.S. CC Scheme
                must claim compliance to an NIAP-approved PP. Protection
                Profiles have been approved for use by vendors for evaluation of
                products under the NIAP Common Criteria Evaluation and
                Validation Scheme and the Common Criteria Recognition
                Arrangement.
              </Card.Text>
            </Card.Body>
            <div className="pb-3">
              <Link
                to="/protectionprofiles"
                className="link fw-bold text-uppercase"
              >
                Read more
              </Link>
              <NavigateNextIcon
                className={
                  "ms-2 border rounded-circle " +
                  (activeCard === "PP" ? "border-light" : "border-primary")
                }
                color={activeCard === "PP" ? "light" : "primary"}
              />
            </div>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card
            className="rounded-0 border-primary text-center h-100 homepage-card"
            onMouseEnter={(e) => changeActiveCard(e, "CCTL")}
            onMouseLeave={removeActiveCard}
          >
            <Card.Body className="pt-0">
              <img
                src={activeCard === "CCTL" ? CctlActiveIcon : CctlInactiveIcon}
                className="border-bottom border-warning border-4 p-0 m-0"
                width="200px"
                alt="CCTL Logo"
              />
              <h4 className="fw-bold mt-2">COMMON CRITERIA TESTING LABS</h4>
              <Card.Text>
                CCTLs are accredited by the NIST National Voluntary Laboratory
                Accreditation Program (NVLAP) and meet CCEVS-specific
                requirements to conduct IT security evaluations for conformance
                to the Common Criteria for Information Technology Security
                Evaluation, International Standard ISO/IEC 15408.
              </Card.Text>
            </Card.Body>
            <div className="pb-3">
              <Link to="/cctl" className="link fw-bold text-uppercase">
                Read more
              </Link>
              <NavigateNextIcon
                className={
                  "ms-2 border rounded-circle " +
                  (activeCard === "CCTL" ? "border-light" : "border-primary")
                }
                color={activeCard === "CCTL" ? "light" : "primary"}
              />
            </div>
          </Card>
        </Col>
        <Col md={6} lg={3}>
          <Card
            className="rounded-0 border-primary text-center h-100 homepage-card"
            onMouseEnter={(e) => changeActiveCard(e, "CCRA")}
            onMouseLeave={removeActiveCard}
          >
            <Card.Body className="pt-0">
              <img
                src={activeCard === "CCRA" ? CcraActiveIcon : CcraInactiveIcon}
                className="border-bottom border-warning border-4 p-0 m-0"
                width="200px"
                alt="CCRA Logo"
              />
              <h4 className="fw-bold mt-2">
                COMMON CRITERIA RECOGNITION AGREEMENT
              </h4>
              <Card.Text>
                The National Information Assurance Partnership (NIAP) serves as
                the U.S. representative to the Common Criteria Recognition
                Arrangement (CCRA), composed of 31 other member nations.
              </Card.Text>
            </Card.Body>
            <div className="pb-3">
              <Link to="/ccra" className="link fw-bold text-uppercase">
                Read more
              </Link>
              <NavigateNextIcon
                className={
                  "ms-2 border rounded-circle " +
                  (activeCard === "CCRA" ? "border-light" : "border-primary")
                }
                color={activeCard === "CCRA" ? "light" : "primary"}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
