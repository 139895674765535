import { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";

import {
  useCheckMaintenanceBannerQuery,
  useUpdateMaintenanceBannerMutation,
} from "api/app/app.api";

const SiteSettingsManager = () => {
  const [alert, setAlert] = useState({
    message: "",
    variant: "",
    enabled: false,
  });

  const { data: bannerEnabled, isLoading } = useCheckMaintenanceBannerQuery();
  const [enableBanner, setEnableBanner] = useState(bannerEnabled);

  const [triggerUpdate] = useUpdateMaintenanceBannerMutation();
  const handleToggle = () => {
    setEnableBanner(!enableBanner);
  };

  useEffect(() => {
    setEnableBanner(bannerEnabled);
  }, [bannerEnabled]);

  const handleSave = async () => {
    triggerUpdate({
      maintenance_banner: enableBanner,
    })
      .unwrap()
      .then(() => {
        setAlert({
          message: "Maintenance banner updated!",
          variant: "success",
          enabled: true,
        });
      })
      .catch((error) => {
        console.log("Error setting site setting", error);
        setAlert({
          message: "Error setting maintenance banner. Please try again.",
          variant: "danger",
          enabled: true,
        });
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Alert
            variant={alert.variant}
            show={alert.enabled}
            dismissible
            onClose={() =>
              setAlert({ message: "", variant: "", enabled: false })
            }
          >
            {alert.message}
          </Alert>

          <h1 className="mb-5 mt-3">Site Settings</h1>
          <Form.Check
            type="switch"
            id="maintenanceBannerToggle"
            label="Show Maintenance Banner?"
            checked={enableBanner}
            onChange={handleToggle}
            disabled={isLoading}
          />

          <Button variant="success" onClick={handleSave} className="mt-5">
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SiteSettingsManager;
