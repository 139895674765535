import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";

import { RootState } from "app/store";

export const useAuth = () => {
  const [cookies, _setCookies, _removeCookies, updateCookies] = useCookies();
  const csrfToken = cookies["csrftoken"];
  const authToken = cookies["auth_token"];
  const tempToken = cookies["temp_token"];
  const oldAuthToken = cookies["old_auth_token"];

  const currentUser = useSelector((state: RootState) => state.user.value);
  const permissions = useSelector((state: RootState) => state.role.value);

  return {
    authToken,
    csrfToken,
    tempToken,
    oldAuthToken,
    currentUser,
    permissions,
    updateCookies,
  };
};
