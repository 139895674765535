import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Tab,
  Tabs,
  OverlayTrigger,
  Popover,
  Button,
  ListGroup,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import QATable from "components/QA/QATable";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import EarReviewsTable from "features/memberPortal/EAR/components/EarReviewsTable/EarReviewsTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import TQTable from "../TRRTTableModule/TQTable";

//Q&A table needs new filtering
const NIAPTabsModule = () => {
  const [tdCount, setTdCount] = useState(0);
  const [qaCount, setQaCount] = useState(0);
  const [earReviews, setEARReviews] = useState([]);
  const [trrtCount, setTrrtCount] = useState(0);
  const [refetchEars, setRefetchEars] = useState(0);
  const tableRef = useRef();
  const trrtTableRef = useRef();
  const tdTableRef = useRef();
  const { authToken } = useAuth();

  const fetchQAs = useCallback(() => {
    const params = {
      deleted: false,
      filter_responses: true,
      status: ["Pending Review", "Assigned", "In Progress", "Planned"],
    };

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/req/?${qs.stringify(
          params,
          {
            arrayFormat: "comma",
          },
        )}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setQaCount(response.data.count);
      })
      .catch((error) => console.log(error));
  }, [authToken]);

  const fetchTRRTCount = useCallback(() => {
    const params = {
      status:
        "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted",
      active: JSON.stringify(true),
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/niap_tq_table_data/?${qs.stringify(params, { arrayFormat: "comma" })}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => setTrrtCount(response.data?.count))
      .catch((error) => console.log(error));
  }, [authToken]);

  const refetchTrrts = () => {
    if (trrtTableRef.current) {
      trrtTableRef.current.onQueryChange();
    }
  };

  const refetchQAs = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const refetchTds = () => {
    if (tdTableRef.current) {
      tdTableRef.current.onQueryChange();
    }
  };

  const getEARReviews = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/?status=Pending Review`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setEARReviews(response.data))
      .catch((error) => console.log(error));
  }, [authToken]);

  useEffect(() => {
    const refetchEar = () => {
      setRefetchEars(refetchEars + 1);
    };

    const loadEAR = async () => {
      getEARReviews();
      refetchEar();
    };
    loadEAR();
    // NOTE: Adding refetchEars to this dependency array will cause infinite fetchProgressPointsData
    // TODO: Need to evaluate how we are triggering refetches. RTKQ may address this for free
    // eslint-disable-next-line
  }, [getEARReviews]);

  useEffect(() => {
    const loadTRRT = async () => {
      fetchTRRTCount();
      refetchTrrts();
      refetchTds();
    };
    loadTRRT();
  }, [fetchTRRTCount]);

  useEffect(() => {
    const loadQAs = async () => {
      fetchQAs();
      refetchQAs();
    };
    loadQAs();
  }, [fetchQAs]);

  useInterval(() => {
    const loadEAR = async () => {
      getEARReviews();
      setRefetchEars(refetchEars + 1);
    };
    loadEAR();

    const loadQAs = async () => {
      fetchQAs();
      refetchQAs();
    };
    loadQAs();
  }, [600000]); //10 min

  useInterval(() => {
    const loadTRRT = async () => {
      fetchTRRTCount();
      refetchTds();
      refetchTrrts();
      refetchTds();
    };
    loadTRRT();
  }, 300000); //5 min

  const tdColumns = [
    {
      title: "Item No.",
      field: "td_id",
      render: (rowData) => (
        <Link
          to={`technical-decisions/details/${rowData.identifier}`}
          className="text-decoration-underline"
        >
          {rowData?.identifier}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    {
      title: "Protection Profiles",
      field: "protection_profile",
      render: (rowData) =>
        rowData?.protection_profile?.length > 0 ? (
          <ListGroup>
            {rowData?.protection_profile?.map((projpp) => (
              <OverlayTrigger
                key={projpp.id}
                placement="top"
                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp.id}`}
                  className="text-decoration-underline"
                >
                  <ListGroup.Item disabled>
                    {projpp.pp_common_name}
                  </ListGroup.Item>
                </Link>
              </OverlayTrigger>
            ))}
          </ListGroup>
        ) : (
          <>No Protection Profiles</>
        ),
    },
    { title: "Status", field: "status" },
    {
      title: "Last Modified",
      render: (rowData) => moment.utc(rowData.modified_on).format("MM/DD/YYYY"),
    },
    {
      title: "Actions",
      headerstyle: { display: "none" },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <Link
                  to={`technical-decisions/details/${rowData.identifier}`}
                  relative="route"
                  className="text-decoration-none"
                >
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <VisibilityIcon color="action" /> View
                  </Button>
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <EditIcon color="action" /> Edit
                  </Button>
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <DeleteIcon color="action" /> Delete
                  </Button>
                </Link>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
            <span className="visually-hidden">Action</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const options = {
    sorting: true,
    columnsButton: true,
    pageSize: 20,
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Tabs defaultActiveKey="TRRTs" className="mb-3">
            <Tab
              eventKey="TRRTs"
              title={
                <div className="d-flex align-items-center">
                  TRRTs
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {trrtCount}
                  </h4>
                </div>
              }
            >
              <TQTable tableRef={trrtTableRef} />
            </Tab>
            <Tab
              eventKey="TDs"
              title={
                <div className="d-flex align-items-center">
                  TDs
                  <h4 className="text-primary ms-2 my-0 fw-bold">{tdCount}</h4>
                </div>
              }
            >
              <ResponsiveMaterialTable
                id={"td-table"}
                title="Technical Decisions"
                options={options}
                tableRef={tdTableRef}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    // Extract the necessary information from the query object
                    const {
                      page,
                      pageSize,
                      search,
                      filters,
                      orderBy,
                      orderDirection,
                    } = query;

                    let newFilters = filters.map((filter) => {
                      let value = "";
                      if (Array.isArray(filter.value)) {
                        value = filter.value;
                      } else {
                        value = filter.value.replace(/['"]+/g, "");
                      }
                      return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                    });
                    const params = {
                      offset: page * pageSize,
                      limit: pageSize,
                      search: search,
                      filters: newFilters,
                      orderBy: orderBy?.field,
                      orderDirection: orderDirection,
                    };

                    params["status"] =
                      "Pending,Integrated,TD Proposed,New,Valid";

                    axios
                      .get(
                        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                        {
                          withCredentials: true,
                          headers: {
                            Authorization: `Token ${authToken}`,
                          },
                        },
                      )
                      .then((response) => {
                        resolve({
                          data: response.data.results,
                          page: page,
                          totalCount: response.data.count,
                        });
                        setTdCount(response.data.count);
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  })
                }
                columns={tdColumns}
              />
            </Tab>
            <Tab
              eventKey="Q&As"
              title={
                <div className="d-flex align-items-center">
                  Q&As
                  <h4 className="text-primary ms-2 my-0 fw-bold">{qaCount}</h4>
                </div>
              }
            >
              <QATable refetchCount={fetchQAs} />
            </Tab>
            <Tab
              eventKey="escalated-ears"
              title={
                <div className="d-flex align-items-center">
                  EAR Reviews
                  <h4 className="text-primary ms-2 my-0 fw-bold">
                    {earReviews?.count}
                  </h4>
                </div>
              }
            >
              <EarReviewsTable refetch={getEARReviews} />
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default NIAPTabsModule;
