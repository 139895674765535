import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { useAuth } from "hooks/useAuth";

const RelabelObject = ({ show, handleClose, reloadDocuments, object }) => {
  const [newLabel, setNewLabel] = useState("");
  const { authToken, csrfToken } = useAuth();

  const handleChange = (event) => {
    setNewLabel(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${object?.file_id}/`,
        { file_label: newLabel },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        reloadDocuments();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReset = () => {
    setNewLabel("");
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Relabel {object?.isFolder ? "Folder" : "Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="file_label">
            <Form.Label>
              Relabel the {object?.isFolder ? "Folder" : "Document"}
            </Form.Label>
            <Form.Control
              type="text"
              name="file_label"
              onChange={handleChange}
              defaultValue={object?.file_label}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
          <Button variant="primary" onClick={handleReset}>
            Reset
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default RelabelObject;
