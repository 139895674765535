import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { Accordion, Button, Stack, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

import CertificateReviewAccordion from "./CertificateReviewAccordion";
import EventLogAccordion from "./EventLogAccordion";
import GenerateDocAccordion from "./GenerateDoc/GenerateDocAccordion";
import ModificationHistoryAccordion from "./ModificationHistoryAccordion";
import SideFileAccordion from "./SideFileAccordion";

export default function PostingSideInfo({
  product,
  files,
  readyToPostChecks,
  handleReadyToPostCheck,
  readOnly,
  validationTeam,
  nistReview,
  setAlert,
}) {
  const navigate = useNavigate();
  const { authToken, permissions } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const toEmailForm = (toEmail) => {
    navigate("/community/email", { state: { email: toEmail } });
  };

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      handleReadyToPostCheck({ [e.target.name]: e.target.checked });
    }
  };

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  return (
    <>
      {permissions?.role_type === "NIAP" && (
        <>
          <GenerateDocAccordion
            product={product}
            validationTeam={validationTeam}
            setAlert={setAlert}
          />
          <EventLogAccordion product={product} />
        </>
      )}
      <ModificationHistoryAccordion product={product} />
      {permissions?.role_type !== "Vendor" && (
        <CertificateReviewAccordion nistReview={nistReview} />
      )}
      {product?.vendor_id && (
        <Accordion flush className="border border-dark mb-3">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">Vendor Information</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              <p className="m-0">{product?.vendor_id?.name}</p>
              <p className="m-0">POC: {product?.vendor_poc}</p>
              <p className="m-0">{product?.vendor_id?.primary_address_1}</p>
              <p className="m-0">{product?.vendor_id?.primary_address_2}</p>
              <p className="m-0">{product?.vendor_id?.primary_state}</p>
              <p className="m-0">{product?.vendor_id?.primary_zip}</p>
              {product.vendor_email && (
                <Button
                  variant="link"
                  className="p-0 text-bright-blue"
                  style={{
                    wordBreak: "break-word",
                  }}
                  onClick={() => toEmailForm(product?.vendor_email)}
                >
                  {product?.vendor_email}
                </Button>
              )}
              <p className="m-0">{product?.vendor_phone}</p>
              <p className="m-0 text-primary">{product?.vendor_id?.website}</p>
              {!readOnly && (
                <div className="mt-1 d-flex justify-content-end">
                  <Form.Check
                    type={"checkbox"}
                    name={`ready-to-post-vendor-info`}
                    id={`ready-to-post-vendor-info`}
                    label="Reviewed"
                    checked={
                      readyToPostChecks.find(
                        (check) => `ready-to-post-vendor-info` in check,
                      )?.[`ready-to-post-vendor-info`]
                    }
                    onChange={handleChange}
                  />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      {product?.assigned_lab && (
        <Accordion flush className="border border-dark mb-3">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">CCTL Information</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              <p className="m-0">{product?.assigned_lab?.name}</p>
              <p className="m-0">POC: {product?.assigned_lab?.poc}</p>
              <p className="m-0">{product?.assigned_lab?.primary_address_1}</p>
              <p className="m-0">{product?.assigned_lab?.primary_address_2}</p>
              <p className="m-0">{product?.assigned_lab?.primary_state}</p>
              <p className="m-0">{product?.assigned_lab?.primary_zip}</p>
              {product.assigned_lab.poc_email && (
                <Button
                  variant="link"
                  className="p-0 text-bright-blue"
                  style={{
                    wordBreak: "break-word",
                  }}
                  onClick={() => toEmailForm(product.assigned_lab.poc_email)}
                >
                  {product.assigned_lab.poc_email}
                </Button>
              )}
              <p className="m-0">{product?.assigned_lab?.primary_phone}</p>
              {!readOnly && (
                <div className="mt-1 d-flex justify-content-end">
                  <Form.Check
                    type={"checkbox"}
                    name={`ready-to-post-cctl-info`}
                    id={`ready-to-post-cctl-info`}
                    label="Reviewed"
                    checked={
                      readyToPostChecks.find(
                        (check) => `ready-to-post-cctl-info` in check,
                      )?.[`ready-to-post-cctl-info`]
                    }
                    onChange={handleChange}
                  />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      {permissions?.role_type !== "Vendor" && (
        <Accordion flush className="border border-dark mb-3">
          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-secondary">
              <h6 className="sidebar-title">Validator Checkout Files</h6>
            </Accordion.Header>
            <Accordion.Body className="bg-light p-1">
              {files
                ?.filter((file) =>
                  file.file_source_type?.includes("Validator Check Out"),
                )
                ?.map((file, idx) => (
                  <Stack
                    direction="horizontal"
                    className="d-flex justify-content-left align-items-start"
                    key={idx}
                  >
                    {file.file_mime_type === "application/pdf" && (
                      <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
                    )}
                    {file.file_mime_type !== "application/pdf" && (
                      <FontAwesomeIcon
                        icon={faFileLines}
                        color="gray"
                        size="xl"
                      />
                    )}
                    <div className="ms-2 text-break">
                      <Button
                        style={{ overflowWrap: "break-word" }}
                        className="text-start m-0 p-0"
                        variant="link"
                        onClick={() => downloadFile(file)}
                      >
                        {file.file_label
                          ? file.file_label
                          : file.file_new_name
                            ? file.file_new_name
                            : file.file_name}
                      </Button>
                      <p className="mb-0 ms-3">
                        {file.file_new_name
                          ? file.file_new_name
                          : file.file_name}
                      </p>
                      <p className="mb-0 ms-3">
                        Attached by {file?.uploaded_by?.first_name}{" "}
                        {file?.uploaded_by?.last_name} on{" "}
                        {file?.uploaded_on &&
                          moment
                            .utc(file?.uploaded_on)
                            .format("YYYY.MM.DD [at] HHmm")}
                      </p>
                    </div>
                  </Stack>
                ))}
              {!readOnly && (
                <div className="mt-1 d-flex justify-content-end">
                  <Form.Check
                    type={"checkbox"}
                    name={`ready-to-post-public-files`}
                    id={`ready-to-post-public-files`}
                    label="Reviewed"
                    checked={
                      readyToPostChecks.find(
                        (check) => `ready-to-post-public-files` in check,
                      )?.[`ready-to-post-public-files`]
                    }
                    onChange={handleChange}
                  />
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      <SideFileAccordion product={product} />
    </>
  );
}
