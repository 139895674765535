import { MTableToolbar } from "@material-table/core";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import qs from "qs";
import { useState, useRef, useEffect } from "react";
import { Popover, OverlayTrigger, Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

import ConfirmModal from "./ConfirmModal";
import UserRolesForm from "./UserRolesForm";

export default function UserRolesTable({
  setRolesSuccess,
  setDeleteSuccess,
  roleSuccess,
  deleteSuccess,
  restoreSuccess,
}) {
  const [show, setShow] = useState(false);
  const [editRole, setEditRole] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteRole, setDeleteRole] = useState({});
  const tableRef = useRef();
  const { authToken } = useAuth();

  useEffect(() => {
    tableRef.current.onQueryChange();
  }, [roleSuccess, deleteSuccess, restoreSuccess]);

  const handleShow = () => {
    setEditRole({});
    setShow(true);
  };

  const handleShowEditModal = (rowData) => {
    setEditRole(rowData);
    setShow(true);
  };

  const handleShowConfirmModal = async (role) => {
    setDeleteRole(role);
    setShowConfirmModal(true);
  };

  const columns = [
    {
      title: "Role Name",
      field: "role_name",
    },
    {
      title: "User Type",
      field: "role_type",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      cellStyle: { textAlign: "center", width: "10%", minWidth: "10%" },
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowEditModal(rowData)}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowConfirmModal(rowData)}
                    >
                      <DeleteIcon color="action" /> Delete
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
                <span className="visually-hidden">Actions</span>
              </Button>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 20,
  };

  return (
    <>
      <ConfirmModal
        title={"Are You Sure You Want to Delete this Role?"}
        buttonText={"Delete"}
        show={showConfirmModal}
        setShow={setShowConfirmModal}
        deleteRole={deleteRole}
        setDeleteSuccess={setDeleteSuccess}
      />
      <UserRolesForm
        show={show}
        setShow={setShow}
        setRolesSuccess={setRolesSuccess}
        editRole={editRole}
        setEditRole={setEditRole}
        refetch={() => tableRef.current.onQueryChange()}
      />
      <ResponsiveMaterialTable
        id={"user-roles-table"}
        title="USER ROLES MANAGER"
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              deleted: false,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}account/roles/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
        components={{
          Toolbar: (props) => (
            <Container>
              <Row>
                <Col>
                  <MTableToolbar {...props} />
                </Col>
                <Col lg={2}>
                  <Button
                    variant="primary"
                    onClick={handleShow}
                    className="m-3"
                  >
                    + Add User Role
                  </Button>
                </Col>
              </Row>
            </Container>
          ),
        }}
      />
    </>
  );
}
