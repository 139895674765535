import { baseApi } from "api/BaseApi";
import { Option } from "api/common/types/common.types";

import { PcmPp } from "./pcmPp.types";

const PCM_PP_BASE_QUERY = "/pcm/pp";

interface OnlyCcid {
  ccid: string;
  pcm?: never;
}
interface OnlyPcm {
  pcm: number;
  ccid?: never;
}

type PcmPpPayload = OnlyCcid | OnlyPcm;

const pcmPpApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPcmPp: build.query<Option[], PcmPpPayload>({
      query: (payload) => {
        const params = payload.pcm
          ? { pcm: payload.pcm }
          : { ccid: payload.ccid };

        return {
          url: `${PCM_PP_BASE_QUERY}/get_pps_by_pcm/`,
          params,
        };
      },
    }),
    getPcmPpOptions: build.query<Option[], PcmPpPayload>({
      query: (payload) => {
        const params = payload.pcm
          ? { pcm: payload.pcm }
          : { ccid: payload.ccid };

        return {
          url: `${PCM_PP_BASE_QUERY}/get_pps_by_pcm/`,
          params,
        };
      },
      transformResponse: (response: PcmPp[]) =>
        response.map((pp) => ({
          label: pp.ppid.pp_short_name,
          value: pp.ppid.pp_id,
        })),
    }),
  }),
});

export const { useGetPcmPpQuery, useGetPcmPpOptionsQuery } = pcmPpApi;
