import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useAuth } from "hooks/useAuth";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const MFAConfirm = ({ mfaMethod, handleCloseModal }) => {
  const [formErrors, setFormErrors] = useState(false);
  const [backupCodes, setBackupCodes] = useState(false);
  const [mfaInfo, setMfaInfo] = useState({});
  const method = mfaMethod;
  const { csrfToken, authToken } = useAuth();

  const handleChange = (e) => {
    setMfaInfo({ ...mfaInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${method}/activate/confirm/`,
        mfaInfo,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setBackupCodes(response.data.backup_codes);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          Check Your {method !== "sms_twilio" ? capitalize(method) : "Phone"}
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="code">
            <Form.Label className="small text-secondary">MFA Code*</Form.Label>
            <Form.Control
              type="text"
              name="code"
              onChange={handleChange}
              required={true}
            />
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          {backupCodes && (
            <div className="backup-codes mb-4">
              <h5>Backup Codes</h5>
              {backupCodes.map((code) => (
                <p key={code}>{code}</p>
              ))}
            </div>
          )}
          <br />
          {!backupCodes && (
            <Button variant="warning" type="submit">
              Confirm
            </Button>
          )}
          {backupCodes && (
            <Button variant="warning" onClick={handleCloseModal}>
              Close
            </Button>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAConfirm;
