import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import FileTable from "../../ProjectFiles";

import PCMDetails from "./PCMDetails";
import PCMTable from "./PCMTable";

export default function PCMManager() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<PCMTable />} />
      <Route path="details/:id" element={<PCMDetails />} />
      <Route path="details/:id/:type/:ccid" element={<FileTable />} />
    </Routes>
  );
}
