import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import Button from "react-bootstrap/Button";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import RequestAssuranceForm from "./RequestAssuranceForm";

const VendorCertifiedProductsTable = ({ setThirtyDayAlert, setAlertInfo }) => {
  const [certfiedProducts, setCertifiedProducts] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [showAMModal, setShowAMModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState({});
  const tableRef = useRef();
  const { authToken } = useAuth();

  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const handleCloseModal = () => {
    setShowAMModal(false);
  };

  const fetchProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/vendor_certified_products_for_alert/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setCertifiedProducts(response.data);
        const twoYearsAgo = moment().subtract(2, "years");
        const thirtyDaysBeforeTwoYearsAgo = moment(twoYearsAgo).subtract(
          30,
          "days",
        );
        let alerts = [];
        response.data.forEach((product) => {
          if (
            moment(product?.assurance_maintenance_date).isBetween(
              thirtyDaysBeforeTwoYearsAgo,
              twoYearsAgo,
            ) &&
            product?.current_assurance_maintenance === null
          ) {
            const countdown = moment(twoYearsAgo).diff(
              moment(product?.assurance_maintenance_date),
              "days",
            );
            alerts.push(
              `Your ability to submit VID ${product?.v_id} for AM will expire in ${countdown} days`,
            );
          }
        });
        setThirtyDayAlert(alerts);
      });
  }, [authToken]);

  const fetchCertificates = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_vendor_product_certificates/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setCertificates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    fetchProducts();
    fetchCertificates();
  }, [fetchCertificates, fetchProducts]);

  useInterval(() => {
    tableRef.current.onQueryChange();
    fetchCertificates();
  }, 600000); //10 min

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "CCTL",
      field: "assigned_lab.name",
    },
    {
      title: "Certification Date",
      field: "certification_date",
      render: (rowData) => {
        return (
          rowData.certification_date &&
          moment.utc(rowData.certification_date).format("YYYY-MM-DD")
        );
      },
    },
    {
      title: "Assurance Maintenance Date",
      field: "assurance_maintenance_date",
      render: (rowData) => {
        if (rowData.assurance_maintenance_date) {
          if (
            rowData.assurance_maintenance_date === rowData?.certification_date
          ) {
            return <>No Date</>;
          } else {
            return moment
              .utc(rowData.assurance_maintenance_date)
              .format("YYYY-MM-DD");
          }
        } else {
          return <>No Date</>;
        }
      },
    },
    {
      title: "Downloadable CC Certificates",
      render: (rowData) =>
        certificates &&
        certificates[rowData?.product_id]?.map((file) => (
          <Button
            key={file.file_id}
            variant="link"
            onClick={() => downloadFile(file)}
          >
            <FontAwesomeIcon icon={faFilePdf} color="red" size="xl" />
            <span className="visually-hidden">{`${rowData.product_name} certficate`}</span>
          </Button>
        )),
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      <RequestAssuranceForm
        show={showAMModal}
        handleCloseModal={handleCloseModal}
        refetch={fetchProducts}
        product={currentProduct}
        setAlertInfo={setAlertInfo}
      />
      <ResponsiveMaterialTable
        columns={columns}
        title="Certified Products"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/vendor_certified_products/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </>
  );
};

export default VendorCertifiedProductsTable;
