import "./ProjectDetailsAccordion.css";
import "../../Products.css";
import axios from "axios";
import parse from "html-react-parser";
import { useState, useEffect, useCallback } from "react";
import { Alert, Button, Accordion, Container, Row, Col } from "react-bootstrap";

import ECRCommentsTable from "components/ECR/ECRCommentsTable";
import TechnicalQueriesTable from "components/TRRTS/TRRTSManager/TechnicalQueriesTable";
import HTMLEditor from "components/UI/HTMLEditor";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import AMHistoryTable from "./AMHistoryTable";
import EARDetailsAccordion from "./EARDetailsAccordion";
import EcrCommentsCount from "./EcrCommentsCount.js";
import ProjectConfirmSaveModal from "./ProjectConfirmSaveModal.js";

export default function ProjectDetailsAccordion({
  id,
  project,
  refetch,
  assignedOrg,
}) {
  const [activeKey, setActiveKey] = useState([]);
  const [editedHtmlFields, setEditedHtmlFields] = useState({});
  const [showAlert, setShowAlert] = useState("");
  const [ecrCounts, setEcrCounts] = useState({});
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const { authToken, csrfToken, permissions } = useAuth();

  const expandAll = () => {
    const allKeys = [];
    projectDescriptionFields.forEach((pd, idx) => {
      allKeys.push(idx);
    });
    setActiveKey(allKeys);
  };

  const collapseAll = () => {
    setActiveKey([]);
  };

  const handleItemClick = (idx) => {
    let newKeys = [...activeKey];
    if (newKeys.includes(idx)) {
      newKeys = newKeys.filter((key) => key !== idx);
    } else {
      newKeys.push(idx);
    }
    setActiveKey(newKeys);
  };

  const handleChange = (e) => {
    setEditedHtmlFields({
      ...editedHtmlFields,
      [e.target.name]: e.target.value,
    });
  };

  const fetchEcrCounts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/count_comments?vid=${project.v_id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setEcrCounts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [project]);

  useEffect(() => {
    fetchEcrCounts();
  }, [fetchEcrCounts]);

  useInterval(() => {
    fetchEcrCounts();
  }, 180000); //3 min

  const handleSaveAll = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${project.product_id}/`,
        editedHtmlFields,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        setEditedHtmlFields({});
        setShowAlert("success");
        setShowConfirmSave(false);
        refetch();
      })
      .catch(() => {
        setShowAlert("fail");
        setShowConfirmSave(false);
      });
  };

  const projectDescriptionFields = [
    {
      title: "Product Description",
      name: "product_description",
      text: project?.product_description,
    },
    {
      title: "Security Evaluation Summary",
      name: "security_evaluation_summary",
      text: project?.security_evaluation_summary,
    },
    {
      title: "Environmental Strengths",
      name: "environmental_strengths",
      text: project?.environmental_strengths,
    },
    {
      title: "Evaluation Configuration",
      name: "evaluation_configuration",
      text: project?.evaluation_configuration,
    },
    { title: "EAR Review" },
    { title: "Assurance Maintenance History" },
    { title: "Technical Queries" },
    { title: "ECR Comments" },
  ];

  return (
    <Container className="padding-xs-0">
      <ProjectConfirmSaveModal
        show={showConfirmSave}
        handleClose={() => setShowConfirmSave(false)}
        handleSave={handleSaveAll}
        vid={project.v_id}
        editedHTMLChanges={editedHtmlFields}
      />
      <Row>
        <Col>
          {Object.keys(editedHtmlFields).length > 0 && showAlert === "" && (
            <Alert variant="danger">Please Save Changes!</Alert>
          )}
          {showAlert === "success" && (
            <Alert
              variant="success"
              dismissible
              onClose={() => setShowAlert("")}
            >
              Changes successfully saved!
            </Alert>
          )}
          {showAlert === "fail" && (
            <Alert
              variant="danger"
              dismissible
              onClose={() => setShowAlert("")}
            >
              Changes failed to save!
            </Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {activeKey.length !== projectDescriptionFields.length && (
            <Button
              variant="link"
              onClick={expandAll}
              className="text-primary ml-auto"
            >
              Expand All
            </Button>
          )}
          {activeKey.length === projectDescriptionFields.length && (
            <Button
              variant="link"
              onClick={collapseAll}
              className="text-primary ml-auto"
            >
              Collapse All
            </Button>
          )}
        </Col>
        <Col className="d-flex justify-content-end mb-2">
          {Object.keys(editedHtmlFields).length > 0 && (
            <Button
              variant="success"
              onClick={() =>
                project.status === "Certified" ||
                project.status === "In Assurance Maintenance"
                  ? setShowConfirmSave(true)
                  : handleSaveAll()
              }
            >
              Save All
            </Button>
          )}
        </Col>
      </Row>
      <Accordion activeKey={activeKey} alwaysOpen flush>
        {projectDescriptionFields.map((pd, idx) => (
          <Accordion.Item
            eventKey={idx}
            key={idx}
            className={pd.title !== "ECR Comments" ? "border-bottom-dark" : ""}
          >
            <Accordion.Header onClick={() => handleItemClick(idx)}>
              <Container fluid>
                <Row>
                  <Col xs={12} sm={3}>
                    <h6 className="mb-2 mb-lg-0">{pd.title}</h6>
                  </Col>
                  {pd.title === "ECR Comments" && (
                    <Col xs={12} sm={9} className="mt-xs-2">
                      <div className="d-flex flex-wrap justify-content-start">
                        {permissions?.role_type !== "Lab" && (
                          <EcrCommentsCount ecrCounts={ecrCounts} />
                        )}
                        {/* <span className='border p-2 me-2 mb-2'>Total: {ecrCounts?.total}</span> */}
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </Accordion.Header>
            <Accordion.Body className="padding-xs-0">
              <>
                {pd.title === "ECR Comments" && project.v_id && (
                  <ECRCommentsTable
                    vidNumber={project.v_id}
                    fetchEcrCounts={fetchEcrCounts}
                  />
                )}
                {pd.title === "EAR Review" && <EARDetailsAccordion id={id} />}
                {pd.title === "Assurance Maintenance History" && (
                  <AMHistoryTable
                    id={id}
                    product={project}
                    fetchProject={refetch}
                  />
                )}
                {pd.title === "Technical Queries" ? (
                  <TechnicalQueriesTable id={id} />
                ) : (
                  <>
                    {(permissions?.role_type === "NIAP" &&
                      (project.status === "Certified" ||
                        project.status === "In Assurance Maintenance")) ||
                    (project.status !== "Certified" &&
                      project.status !== "In Assurance Maintenance" &&
                      assignedOrg &&
                      ![
                        "ECR Comments",
                        "EAR Review",
                        "Assurance Maintenance History",
                        "Technical Queries",
                      ]?.includes(pd.title)) ? (
                      <HTMLEditor
                        name={pd.name}
                        handleChange={handleChange}
                        setIsValid={() => {}}
                        isValid={true}
                        defaultValue={pd.text}
                      />
                    ) : (
                      pd.text && parse(pd.text)
                    )}
                  </>
                )}
              </>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
}
