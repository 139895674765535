import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useRef, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import RequestAssuranceForm from "components/Dashboard/VendorModules/RequestAssuranceForm";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

export default function AMHistoryTable({ id, product, fetchProject }) {
  const [showModal, setShowModal] = useState(false);
  const tableRef = useRef();
  const { authToken } = useAuth();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [id]);

  const refetch = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
    fetchProject();
  };

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 180000); //3 min

  const columns = [
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Start Date",
      field: "entrydate",
      render: (rowData) =>
        rowData?.entrydate ? (
          moment.utc(rowData?.entrydate).format("MM/DD/YYYY")
        ) : (
          <>TBD</>
        ),
    },
    {
      title: "Completion Date",
      field: "completion_date",
      render: (rowData) =>
        rowData?.completion_date ? (
          moment.utc(rowData?.completion_date).format("MM/DD/YYYY")
        ) : (
          <>TBD</>
        ),
    },
    {
      title: "Status",
      field: "status",
    },
    {
      title: "",
      cellStyle: { width: "10%", minWidth: "10%" },
      render: (rowData) => (
        <Button
          variant="link"
          className="text-bright-navy"
          href={`${id}/assurancemaintenance/${rowData?.maintenance_id}`}
          size="sm"
        >
          View Details
        </Button>
      ),
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <Container>
      <RequestAssuranceForm
        show={showModal}
        handleCloseModal={() => setShowModal(false)}
        product={product}
        fromProductDetails={true}
        refetch={refetch}
        manual={true}
      />
      <ResponsiveMaterialTable
        columns={columns}
        title={`AM History for VID${id}`}
        tableRef={tableRef}
        actions={[
          {
            icon: () => (
              <>
                <AddIcon />
                <span style={{ fontSize: 16 }}>Add New</span>
              </>
            ),
            tooltip: "New Assurance Maintenance Request",
            onClick: () => {
              setShowModal(true);
            },
            isFreeAction: true,
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              product_id: id,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/maintenance/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </Container>
  );
}
