import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

const SerialNumberModal = ({
  show,
  handleClose,
  serialNumberToEdit,
  setAlert,
}) => {
  const [editedSerialNumber, setEditedSerialNumber] = useState({});
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const { authToken, csrfToken } = useAuth();

  const letterTypes = [
    { type: "Congratulations Letter", pscode: "CL1", abbrev: "Congrats Ltr" },
    {
      type: "Termination Warning Letter",
      pscode: "TWL",
      abbrev: "Warning Ltr",
    },
    { type: "Termination Letter 1", pscode: "TL", abbrev: "Term Ltr 1" },
    { type: "Termination Letter 2", pscode: "TL2", abbrev: "Term Ltr 2" },
    { type: "Withdrawal Letter", pscode: "WDL", abbrev: "Withdrawal Ltr" },
    { type: "IAR Concurrence Letter", pscode: "UAL", abbrev: "IAR Concur Ltr" },
    { type: "IAR Denial Letter", pscode: "URL", abbrev: "IAR Denial Ltr" },
  ];

  useEffect(() => {
    if (show && serialNumberToEdit?.snid) {
      setEditedSerialNumber({
        ...serialNumberToEdit,
        pscode: serialNumberToEdit?.pcode,
      });
    }
  }, [show, serialNumberToEdit]);

  const handleChange = (e) => {
    if (e.target.name === "pcode") {
      let selectedLetterType = letterTypes.find(
        (letterType) => letterType.type === e.target.value,
      );
      setEditedSerialNumber({
        ...editedSerialNumber,
        pscode: selectedLetterType.pscode,
        type: selectedLetterType.type,
        abbrev: selectedLetterType.abbrev,
      });
    } else {
      setEditedSerialNumber({
        ...editedSerialNumber,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      let data = { ...editedSerialNumber };
      if (!data.dtd) {
        data.dtd = new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        );
      }
      // if existing snid, do put
      if (data?.snid) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}org/doc/${data.snid}/`,
            {
              ...data,
              creator: data?.creator?.id,
              editor: data?.editor?.id,
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            setAlert({
              message: "Successfully Updated Document Serial Number!",
              type: "success",
            });
            setValidated(false);
            setEditedSerialNumber({});
            handleClose();
          })
          .catch((error) => {
            setError("Error Submitting Form. Please Try Again.");
            console.log(error);
          });
      } else {
        data["entrydate"] = new Date(
          new Date().setMinutes(
            new Date().getMinutes() - new Date().getTimezoneOffset(),
          ),
        );
        axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/doc/`, data, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then((response) => {
            setAlert({
              message: "Successfully Created Document Serial Number!",
              type: "success",
            });
            setValidated(false);
            setEditedSerialNumber({});
            handleClose();
          })
          .catch((error) => {
            setError("Error Submitting Form. Please Try Again.");
          });
      }
    }
    setValidated(true);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setEditedSerialNumber({});
      }}
    >
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="document-serial-number-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>+ ADD New Document Serial Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                {error !== "" && (
                  <Alert variant="danger" className="mt-2" dismissible>
                    {error}
                  </Alert>
                )}
                <Row className="mb-2">
                  <Form.Group controlId="fullsn">
                    <Form.Label>Full Document Serial Number*</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullsn"
                      onChange={handleChange}
                      value={
                        editedSerialNumber?.fullsn ??
                        `CCEVS-****-${moment().format("YY")}`
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="docsn">
                    <Form.Label>
                      Serial Number (Please Input Number)*
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="docsn"
                      onChange={handleChange}
                      required
                      value={editedSerialNumber?.docsn}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="dtd">
                    <Form.Label>Date of this Letter*</Form.Label>
                    <Form.Control
                      type="date"
                      name="dtd"
                      onChange={handleChange}
                      value={
                        moment
                          .utc(editedSerialNumber?.dtd)
                          .format("YYYY-MM-DD") ?? moment().format("YYYY-MM-DD")
                      }
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="pid">
                    <Form.Label>VID (Please Input Number)*</Form.Label>
                    <Form.Control
                      type="number"
                      name="pid"
                      onChange={handleChange}
                      required
                      value={editedSerialNumber?.pid}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group controlId="pcode">
                    <Form.Label>Document Type*</Form.Label>
                    <Form.Select
                      name="pcode"
                      onChange={handleChange}
                      required
                      value={
                        letterTypes.find(
                          (letterType) =>
                            letterType.pscode === editedSerialNumber?.pscode,
                        )?.type
                      }
                    >
                      <option value="">Select Document Type</option>
                      {letterTypes.map((letterType, idx) => (
                        <option key={idx} value={letterType.type}>
                          {letterType.type}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container fluid className="d-flex justify-content-end">
            <Row>
              <Col sm={12} md={6}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleClose();
                    setEditedSerialNumber({});
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={12} md={6}>
                <Button variant="primary" type="submit">
                  {editedSerialNumber?.snid ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default SerialNumberModal;
