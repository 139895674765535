import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

const ResendCode = () => {
  const [formErrors, setFormErrors] = useState({});
  const [user, setUser] = useState({});
  const [method, setMethod] = useState({});
  const [activeMethods, setActiveMethods] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ephemeralToken = searchParams.get("ephemeral-token");
  const { authToken, csrfToken } = useAuth();

  const handleChange = (e) => {
    setMethod({ ...method, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mfaMethod = method.method;
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/code/request/`,
        method,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        navigate(
          `/login/mfa-confirm?mfa-method=${mfaMethod}&ephemeral-token=${ephemeralToken}`,
        );
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/mfa/user-active-methods/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setActiveMethods(response.data.results);
        setMethod({
          ...method,
          method: response.data.results.filter((m) => m.is_primary)[0].name,
        });
      })
      .catch((error) => {
        console.log("Error");
        console.log(error.response.data);
      });
  }, []);

  useEffect(() => {
    const loadUser = () => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    };
    loadUser();
  }, []);

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Choose Method to Receive Code</Card.Title>
        <Card.Subtitle as="p">
          Select an MFA Method to receive a code
        </Card.Subtitle>
        <Form className="centered-form" onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="method">
            {activeMethods?.map((method, idx) => (
              <div key={idx}>
                {method.name === "email" && (
                  <>
                    <Form.Check
                      key={idx}
                      type="radio"
                      label="Email"
                      name="method"
                      value="email"
                      onChange={handleChange}
                      defaultChecked={method.is_primary}
                      id="method1"
                    />
                    <p className="small">{user.email}</p>
                  </>
                )}
                {method.name === "sms_twilio" && (
                  <>
                    <Form.Check
                      type="radio"
                      label="SMS"
                      name="method"
                      value="sms_twilio"
                      onChange={handleChange}
                      defaultChecked={method.is_primary}
                      id="method2"
                    />
                    <p className="small">{user.phone_number}</p>
                  </>
                )}
                {method.name === "app" && (
                  <>
                    <Form.Check
                      type="radio"
                      label="Application"
                      name="method"
                      value="app"
                      onChange={handleChange}
                      defaultChecked={method.is_primary}
                      id="method3"
                    />
                    <p className="small">
                      Such as Authy or Google Authenticator
                    </p>
                  </>
                )}
              </div>
            ))}
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <p className="text-secondary small">
            Once you select your method above and click continue, please be
            patient. You will receive your code shortly.
          </p>
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ResendCode;
