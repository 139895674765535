import { Box, Typography } from "@mui/material";
import {
  gridRowCountSelector,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import CustomGridToolbarColumnsButton from "./components/CustomGridToolbarColumnsButton";
import CustomGridToolbarRefetchButton from "./components/CustomGridToolbarRefetchButton";
import CustomGridToolbarResetButton from "./components/CustomGridToolbarResetButton";

interface CustomDataGridToolbarProps {
  title?: string;
  refetch?: () => void;
}

const CustomDataGridToolbar = ({
  title,
  refetch,
}: CustomDataGridToolbarProps) => {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const displayTitle = `${title ? `${title}: ` : ""} ${rowCount} Matches`;

  return (
    <GridToolbarContainer>
      <Typography variant="h6" sx={{ padding: ".75em" }}>
        {displayTitle}
      </Typography>

      <GridToolbarFilterButton />
      <Box
        flex={1}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "1em",
        }}
      >
        <GridToolbarQuickFilter />
        <CustomGridToolbarColumnsButton />
        <CustomGridToolbarRefetchButton refetch={refetch} />
        <CustomGridToolbarResetButton />
      </Box>
    </GridToolbarContainer>
  );
};
export default CustomDataGridToolbar;
