import { Row, Col } from "react-bootstrap";

import { useGetProductMetricsQuery } from "api/memberPortal/project/product/product.api";

const NIAPMetricsGroup = () => {
  const {
    data: productMetrics = {
      new_products: 0,
      new_trrts: 0,
      pending_ecrs: 0,
      pending_tds: 0,
      in_final_products: 0,
      in_progress_products: 0,
    },
  } = useGetProductMetricsQuery();

  return (
    <Row className="mt-3 mb-3">
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className=" mt-2 text-gray-400">{productMetrics.new_products}</h2>
        <p className="m-0 mb-2">New Projects</p>
      </Col>
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className=" mt-2 text-gray-400">{productMetrics.new_trrts}</h2>
        <p className="m-0 mb-2">New TRRTs</p>
      </Col>
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className=" mt-2 text-gray-400">{productMetrics.pending_ecrs}</h2>
        <p className="m-0 mb-2">Pending ECRs</p>
      </Col>
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className=" mt-2 text-gray-400">{productMetrics.pending_tds}</h2>
        <p className="m-0 mb-2">Pending Technical Decisions</p>
      </Col>
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className=" mt-2 text-gray-400">
          {productMetrics.in_final_products}
        </h2>
        <p className="m-0 mb-2">Products in Final Review</p>
      </Col>
      <Col variant="light" className="text-center m-3 rounded-0 shadow">
        <h2 className="mt-2 text-gray-400">
          {productMetrics.in_progress_products}
        </h2>
        <p className="m-0 mb-2">Products in Evaluation</p>
      </Col>
    </Row>
  );
};

export default NIAPMetricsGroup;
