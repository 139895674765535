import axios from "axios";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { Modal, Form, Button, Row, Col, Alert } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import ReactSelect from "components/ReactSelect";
import { useAuth } from "hooks/useAuth";

export default function BecomeAUsTcForm({ show, setShowModal, testing }) {
  const [communities, setCommunities] = useState([]);
  const [tc, setTc] = useState({});
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [tcValid, setTcValid] = useState(true);
  const siteKey = "6Lf29aAjAAAAAIagG9XUSNUz_nXKnwxrK_fDcGf5";
  const recaptchaRef = useRef(null);
  const [errors, setErrors] = useState({});
  const { csrfToken } = useAuth();

  const handleChange = (e) => {
    if (e.target.name === "phone") {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setTc({ ...tc, [e.target.name]: e.target.value });
  };

  const testSelects = () => {
    if ("tc_communities" in tc) {
      return true;
    }
    if (!("tc_communities" in tc)) {
      setTcValid(false);
    }
    return false;
  };

  const checkPhones = () => {
    if (
      tc?.phone &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        tc.phone,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    testSelects();
    if (form.checkValidity() === false || testSelects() === false) {
      e.stopPropagation();
    } else if (!testing && window.grecaptcha.getResponse() === "") {
      e.stopPropagation();
    } else {
      const token = e.target["g-recaptcha-response"]?.value;
      const htmlMessage = `<div><ul><li>Name: ${tc?.name}</li><li>Affiliation: ${tc?.affiliation}</li><li>Phone Number: ${tc?.phone}</li><li>TC Communities: ${tc?.tc_communities}</li><li>Statement: ${tc?.statement}</li></ul></div>`;
      if (window.location.href?.includes("https://www.niap-ccevs.org/")) {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/`,
            {
              subject: `Become a Technical Community Participant - ${tc?.name}`,
              recipients: tc?.tc_communities,
              cc_recipients: tc?.email,
              message: htmlMessage,
              external_sender: tc?.email,
              recaptcha_token: token,
            },
            { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
          )
          .then(() => {
            handleClose();
            setTcValid(true);
            recaptchaRef.current?.reset();
          })
          .catch((error) => {
            console.log(
              "Unable to submit become a technical community participant request: " +
                error,
            );
            setError(true);
          });
      }
    }
    setValidated(true);
  };

  const handleClose = () => setShowModal(false);

  const showCaptcha = () => {
    if (!testing) {
      try {
        window.grecaptcha.render("form-recaptcha", {
          sitekey: siteKey,
          ref: recaptchaRef,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const params = {
    is_international: "false",
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/get_all?${qs.stringify(params, { arrayFormat: "comma" })}`,
      )
      .then((response) => {
        setCommunities(
          response.data.map((comm) => ({
            value: comm.contact_email,
            label: comm.community_name,
          })),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setTc({});
    setError(false);
    setValidated(false);
    setInvalidToken(false);
    setTcValid(true);
  }, [show]);

  return (
    <Modal size="lg" show={show} onEntered={showCaptcha} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">
          <h3>BECOME A TECHNICAL COMMUNITY PARTICIPANT</h3>
        </Modal.Title>
      </Modal.Header>
      <p className="mx-4 mt-3">
        Calls for participants for each Technical Community (TC) are sent to
        industry, government, end users, academic institutions, and labs as
        announcements posted on the NIAP website. All interested parties wanting
        to participate in any TC should provide the following information below:
      </p>
      <Form validated={validated} onSubmit={handleSubmit} noValidate>
        <Modal.Body>
          <Row>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name: *</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  placeholder="Name"
                  autoFocus
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="affiliation">
                <Form.Label>Affiliation: *</Form.Label>
                <Form.Control
                  type="text"
                  name="affiliation"
                  onChange={handleChange}
                  placeholder="Affiliation"
                  required
                />
                <Form.Text className="text-muted">
                  Vendor, CCTL, Academic Institution, Scheme, Other
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>E-mail Address: *</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Telephone Number: *</Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  onChange={handleChange}
                  placeholder="+1 (111)111-1111"
                  isInvalid={errors.phone}
                  required
                />
                <Form.Text className="text-muted">
                  Specify country code, DSN, comm., etc., as applicable
                </Form.Text>
              </Form.Group>
              {!testing && (
                <Form.Group className="mb-3" controlId="token">
                  <ReCAPTCHA
                    id="form-recaptcha"
                    sitekey={siteKey}
                    ref={recaptchaRef}
                  />
                  {invalidToken ? (
                    <p className="text-danger">Please Confirm.</p>
                  ) : (
                    <Form.Text className="text-muted">
                      Required to submit any information via this form.
                    </Form.Text>
                  )}
                </Form.Group>
              )}
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="tc_communities">
                <Form.Label>Select TC Communities: *</Form.Label>
                <ReactSelect
                  id={"tc_communities"}
                  options={communities}
                  newObj={tc}
                  handleChange={handleChange}
                  name={"tc_communities"}
                  isValid={tcValid}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="statement">
                <Form.Label>
                  A brief statement of the qualifications for participation in
                  the TC: *
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  name="statement"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="warning" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Form>
      {error && (
        <Alert variant="danger" className="mt-3">
          Failed to send Message
        </Alert>
      )}
    </Modal>
  );
}
