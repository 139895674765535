import { PRODUCT_STATUSES } from "api/memberPortal/project/product/productStatuses.type";

import ArchivedDateCell from "./ArchivedDate.cell";
import CctlCell from "./Cctl.cell";
import DaysInProgressCell from "./DaysInProgress.cell";
import KickedOffCell from "./KickedOff.cell";
import PpClaimCell from "./PpClaim.cell";
import ProductStatusCell from "./ProductStatus.cell";
import ProductTableActionsCell from "./ProductTableActions.cell";
import ResubmissionCell from "./Resubmission.cell";
import VidCell from "./Vid.cell";

const buildProductColumns = ({
  sidebarFilters,
  setProduct,
  setShowDeleteModal,
  setShowAMValTeamModal,
  setShowValTeamModal,
  projpps,
}) => {
  return [
    {
      title: "Resubmission",
      field: "is_resubmission",
      lookup: { true: "True", false: "False" },
      render: (rowData) => <ResubmissionCell project={rowData} />,
    },
    {
      title: "VID",
      field: "v_id",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
      render: (rowData) => <VidCell project={rowData} />,
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
    },
    { title: "Product", field: "product_name" },
    {
      title: "CCTL",
      field: "assigned_lab.name",
      cellStyle: {
        width: "40%",
        minWidth: "40%",
      },
      render: (rowData) => <CctlCell project={rowData} />,
    },
    {
      title: "Kicked Off",
      field: "kicked_off_date",
      type: "date",
      render: (rowData) => <KickedOffCell project={rowData} />,
    },
    {
      title: "Product Status",
      field: "status",
      lookup: Object.values(PRODUCT_STATUSES).reduce((acc, status) => {
        if (status === PRODUCT_STATUSES.CERTIFIED) {
          acc[status] = "Completed";
        } else {
          acc[status] = status;
        }
        return acc;
      }, {}),
      render: (rowData) => <ProductStatusCell project={rowData} />,
    },
    {
      title: "Archived Date",
      field: "sunset_date",
      hidden: sidebarFilters.status?.indexOf("Archived") < 0,
      type: "date",
      render: (rowData) => <ArchivedDateCell project={rowData} />,
    },
    {
      title: "PP Claim",
      render: (rowData) => <PpClaimCell project={rowData} projpps={projpps} />,
    },
    {
      title: "Days in Progress",
      field: "days_in_progress",
      render: (rowData) => <DaysInProgressCell project={rowData} />,
    },
    {
      title: "Actions",
      field: "actions",
      headerStyle: { background: "white", position: "sticky", right: 0 },
      cellStyle: { background: "white", position: "sticky", right: 0 },
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <ProductTableActionsCell
          project={rowData}
          setProduct={setProduct}
          setShowDeleteModal={setShowDeleteModal}
          setShowAMValTeamModal={setShowAMValTeamModal}
          setShowValTeamModal={setShowValTeamModal}
        />
      ),
    },
  ];
};
export default buildProductColumns;
