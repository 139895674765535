import { Modal, Container, Row, Col } from "react-bootstrap";

import CICOPdf from "features/public/Resources/Publications/PublicationPDFs/CCEVSGuidance/CICO.pdf";

import stepThreeImage from "../../assets/step-three.png";

export default function StepThreeModal({ show, setShowStepThreeModal }) {
  const handleClose = () => {
    setShowStepThreeModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={stepThreeImage}
                      alt="step three image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Step 3
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5 className="fw-bold">Evaluation/Check-Out Proceedings</h5>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">
                  a. Lab evaluates product and provides all relevant
                  documentation to NIAP
                </h5>
              </Row>
              <Row>
                <ul className="remove-dots mx-3">
                  <li>
                    i. Sync sessions may be held between Lab/Validators to
                    answer questions & resolve issues
                  </li>
                  <li>
                    ii. Including NIAP Forms: F8002b (Approval for Posting),
                    F8003 (Certificate Worksheet), & F8004 (Evaluation Survey)
                  </li>
                </ul>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">
                  b. <a href={CICOPdf}>Evaluation Checkout</a>
                </h5>
              </Row>
              <Row>
                <ul className="remove-dots mx-3">
                  <li>
                    i. NIAP Validators review all delivered relevant
                    documentation
                  </li>
                  <p className="mx-5 mb-0">
                    1. Possibly return to lab for corrections to documentation
                    or clarification
                  </p>
                  <li>
                    ii. NIAP Submits DRAFT CC Certificate for approval to
                    Lab/Vendor
                  </li>
                  <li>iii. Timeline: 3 weeks (Estimated)</li>
                </ul>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">
                  c. NIAP Validators submit final package approval with
                  finalized:
                </h5>
              </Row>
              <Row>
                <ul className="remove-dots mx-3">
                  <li>i. Security Target</li>
                  <li>ii. Administrative Guidance</li>
                  <li>iii. Assurance Activities Report</li>
                  <li>iv. Validation Report</li>
                  <li>v. Certificate</li>
                  <li>vi. Product Compliant List (PCL) Entry Form</li>
                </ul>
              </Row>
              <Row className="mt-4">
                <h5 className="fw-bold">Timeline:</h5>
              </Row>
              <Row>
                <ul className="remove-dots mx-3">
                  <li>Target: 90 Days</li>
                  <li>Max: 6 Months</li>
                </ul>
              </Row>
              <Row>
                <h5 className="fw-bold text-bright-navy mt-4">
                  What is Administrative Guidance
                </h5>
              </Row>
              <Row>
                <p>
                  Instructions to users on how to configure the product so that
                  it is consistent with the evaluated configuration.
                </p>
              </Row>
              <Row>
                <h5 className="fw-bold text-bright-navy mt-4">
                  What is Assurance Activities Report?
                </h5>
              </Row>
              <Row>
                <p>
                  Summarized assurance activities of the product evaluation.
                </p>
              </Row>
              <Row>
                <h5 className="fw-bold text-bright-navy mt-4">
                  What is a Validation Report?
                </h5>
              </Row>
              <Row className="mb-4">
                <p>
                  A publicly available document issued by the NIAP validation
                  body that summarizes the evaluation results and confirms the
                  overall results are acceptable.
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
