import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import Select from "react-select";

import { handleAlertAndMailer } from "components/Products/Helper/functions";
import HTMLEditor from "components/UI/HTMLEditor";
import { useAuth } from "hooks/useAuth";

export default function ECRCommentForm({
  show,
  handleClose,
  vidNumber,
  maintenance,
  refetch,
  project_id,
  ecr,
  setEcr,
  isEditing,
  fetchEcrCounts,
}) {
  const [pps, setPPs] = useState([]);
  const [tds, setTDs] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedTDs, setSelectedTDs] = useState([]);
  const [existingTDs, setExistingTDs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [existingDocs, setExistingDocs] = useState([]);
  const [fileLabel, setFileLabel] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showValid, setShowValid] = useState(false);
  const [ecrStatusOptions, setEcrStatusOptions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [relatedProductId, setRelatedProductId] = useState();
  const [currProduct, setCurrProduct] = useState();
  const [commentValid, setCommentValid] = useState(true);
  const [existingComment, setExistingComment] = useState({});
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);
  const { authToken, csrfToken, currentUser, permissions } = useAuth();

  useEffect(() => {
    if (show) {
      setExistingComment(ecr);
    }
  }, [show]);

  const ecrDocOptions = [
    {
      label: "Administrative Guide (AGD)",
      value: "Administrative Guide (AGD)",
    },
    {
      label: "Assurance Vulnerability Assessment (AVA)",
      value: "Assurance Vulnerability Assessment (AVA)",
    },
    {
      label: "Entropy Assessment Report (EAR)",
      value: "Entropy Assessment Report (EAR)",
    },
    {
      label: "Key Management Description",
      value: "Key Management Description",
    },
    { label: "Validation Report", value: "Validation Report" },
    {
      label: "Assurance Activity Report (AAR)",
      value: "Assurance Activity Report (AAR)",
    },
    { label: "Check-in Package", value: "Check-in Package" },
    {
      label: "Evaluation Technical Report (ETR)",
      value: "Evaluation Technical Report (ETR)",
    },
    { label: "Security Target", value: "Security Target" },
    { label: "Other", value: "Other" },
    {
      label: "Assurance Continuity Maintenance Report (ACMR)",
      value: "Assurance Continuity Maintenance Report (ACMR)",
    },
    { label: "Check-out Package", value: "Check-out Package" },
    {
      label: "Impact Analysis Report (IAR)",
      value: "Impact Analysis Report (IAR)",
    },
    { label: "Test Reports", value: "Test Reports" },
  ];

  //set status to show based on role_type
  useEffect(() => {
    if (
      (permissions?.role_type === "Validator" &&
        (permissions?.is_senior || permissions?.is_lead)) ||
      permissions?.role_type === "NIAP"
    ) {
      setEcrStatusOptions([
        { label: "Draft", value: "Draft" },
        { label: "Proposed", value: "Proposed" },
        { label: "On Hold", value: "On Hold" },
        { label: "Concealed", value: "Concealed" },
        { label: "Response to Lab", value: "Response to Lab" },
        { label: "Pending Final Review", value: "Pending Final Review" },
        { label: "Reviewed", value: "Reviewed" },
        { label: "Cancelled", value: "Cancelled" },
        { label: "Resolved", value: "Resolved" },
        { label: "Released", value: "Released" },
      ]);
    } else {
      setEcrStatusOptions([
        { label: "Draft", value: "Draft" },
        { label: "Proposed", value: "Proposed" },
        { label: "Concealed", value: "Concealed" },
      ]);
    }
  }, []);

  const ecrTypeOptions = [
    { label: "Administrative", value: "Administrative" },
    { label: "Informative", value: "Informative" },
    { label: "Substantive", value: "Substantive" },
    { label: "Critical", value: "Critical" },
  ];

  const getEcrDocTypes = (ecrid) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/all_doc_types_for_ecr/?ecrid=${ecrid}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const options = response.data.map((doc) => ({
          xid: doc.xid,
          label: doc.dtid.doctype,
          value: doc.dtid.dtid,
        }));
        setSelectedDocs(options);
        setExistingDocs(options);
      })
      .catch((error) => console.log(error));
  };

  const fetchECRCommentFiles = (ecrid) => {
    if (!maintenance) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=ecr-comment&file_type_id=${ecrid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setUploadedFiles(response.data?.filter((f) => f.active === true));
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_file_source_type_and_type_id/?file_type=ecr-comment&file_source_type=Assurance Maintenance&file_type_id=${ecrid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setUploadedFiles(response.data?.filter((f) => f.active === true));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fetchECRTDs = (ecrid) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical_decisions_by_ecrid/?ecrid=${ecrid}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        const options = response.data.map((td) => ({
          label: td.identifier ? td.identifier : td.title,
          value: td.td_id,
        }));
        setExistingTDs(response.data);
        setSelectedTDs(options);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (show) {
      setShowValid(false);
      setSelectedDocs([]);
      setUploadedFiles([]);
      setSelectedTDs([]);
      setExistingTDs([]);
      setSelectedProfile([]);
      if (isEditing) {
        fetchECRCommentFiles(ecr?.ecrid);
        fetchECRTDs(ecr?.ecrid);
        getEcrDocTypes(ecr?.ecrid);
        setSelectedProfile(ecr?.pp_id?.pp_id);
      }
    }
  }, [show, isEditing]);

  const loadPPByID = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id?product_id=${currProduct?.product_id}`,
      )
      .then((response) => {
        const options = response.data.map((pp) => ({
          label: pp.pp__pp_short_name ? pp.pp__pp_short_name : pp.pp__pp_name,
          value: pp.pp__pp_id,
        }));
        setPPs(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currProduct]);

  const fetchProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const options = response.data.map((p) => ({
          label: `${p.product_name} (VID${p.v_id})`,
          value: p.product_id,
        }));
        setProjects(options);
      })
      .catch((error) => console.log(error));
  };

  const fetchValidatorProducts = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/all_my_validations/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        const options = response.data.map((p) => ({
          label: `${p.product_name} (VID Number ${p.v_id})`,
          value: p.product_id,
        }));
        setProjects(options);
      })
      .catch((error) =>
        console.log("Unable to get current products: " + error),
      );
  };

  const handleChange = (e) => {
    if (e.target.name === "file") {
      const newFiles = uploadedFiles ? uploadedFiles : [];
      newFiles.push({
        file_type: "ecr-comment",
        file_source_type: maintenance ? "Assurance Maintenance" : null,
        file_display_name: ecr.file_type,
        file_name: e.target.files[0].name,
        file_label: ecr.file_label,
        active: true,
        file: e.target.files[0],
        private: ecr.file_public_status === "Public" ? false : true,
      });
      setUploadedFiles([...newFiles]);
    } else if (
      e.target.name === "file_type" ||
      e.target.name === "file_label"
    ) {
      setFileLabel(e.target.value);
      setEcr({
        ...ecr,
        [e.target.name]: e.target.value,
        file_label: e.target.value,
      });
    } else if (e.target.name === "private") {
      setEcr({ ...ecr, [e.target.name]: e.target.checked });
    } else if (e.target.name === "pp_id") {
      setSelectedProfile(e.target.value);
      setSelectedTDs([]);
      setEcr({ ...ecr, [e.target.name]: e.target.value });
    } else {
      setEcr({ ...ecr, [e.target.name]: e.target.value });
    }

    if (e.target.name === "pid") {
      setRelatedProductId(e.target.value);
      setSelectedProfile(null);
      setEcr({ ...ecr, ["pp_id"]: null });
      setSelectedTDs([]);
    }
  };

  const testSelects = () => {
    if (permissions.role_type !== "Lab") {
      return ecr.type && ecr.status && selectedDocs.length > 0 ? true : false;
    } else {
      return ecr.status && selectedDocs.length > 0 ? true : false;
    }
  };

  const handleUploadFiles = (ecrid) => {
    if (uploadedFiles.length > 0) {
      for (let f in uploadedFiles) {
        const submittedData = new FormData();
        if (!uploadedFiles[f].file_mime_type) {
          submittedData.append("file", uploadedFiles[f].file);
          submittedData.append("file_type_id", ecrid);
          submittedData.append("file_type", "ecr-comment");
          submittedData.append("active", JSON.stringify(true));
          if (uploadedFiles[f].file_source_type !== null) {
            submittedData.append(
              "file_source_type",
              uploadedFiles[f].file_source_type,
            );
          }
          submittedData.append(
            "file_display_name",
            uploadedFiles[f].file_display_name,
          );
          submittedData.append("file_name", uploadedFiles[f].file_name);
          submittedData.append("file_label", uploadedFiles[f].file_label);
          submittedData.append("private", uploadedFiles[f].private);
          submittedData.append(
            "uploaded_on",
            new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ).toISOString(),
          );
        }
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}file/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            console.log("file uploaded");
          })
          .catch((error) => {
            console.log("Error");
          });
      }
    }
  };

  const handleAddECR = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() || !testSelects() || !ecr?.cmnt) {
      setShowValid(true);
      e.stopPropagation();
      if (!ecr?.cmnt) {
        setCommentValid(false);
      } else {
        setCommentValid(true);
      }
    } else {
      if (isEditing) {
        let data = {
          ...ecr,
          aid: ecr?.aid?.aid,
          creator: ecr?.creator?.id,
          editor: currentUser.id,
          moddate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          pp_id:
            typeof ecr?.pp_id === "object" && ecr?.pp_id !== null
              ? ecr?.pp_id?.pp_id
              : ecr?.pp_id,
          fid: ecr?.fid ? ecr?.fid?.fid : ecr?.fid,
          responseauthor: ecr?.responseauthor
            ? ecr?.responseauthor?.id
            : ecr?.responseauthor,
          dtid: ecr?.dtid?.xid,
        };

        if (maintenance) {
          data = {
            ...data,
            maintenance_id: ecr?.maintenance_id?.maintenance_id,
            process_location: "Assurance Maintenance",
          };
        } else {
          data = { ...data, pid: ecr?.pid?.product_id };
        }
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/${ecr?.ecrid}/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            if (response.data?.status === "Released") {
              handleSendAlertAndMail();
            }
            handleUploadFiles(response.data?.ecrid);
            selectedTDs.forEach((td) => {
              if (!existingTDs?.find((obj) => obj?.td_id === td?.value)) {
                const ecrTD = {
                  entrydate: new Date(
                    new Date().setMinutes(
                      new Date().getMinutes() - new Date().getTimezoneOffset(),
                    ),
                  ),
                  ecrid: response.data.ecrid,
                  td_id: td.value,
                  creator: currentUser.id,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/`,
                    ecrTD,
                    {
                      withCredentials: true,
                      headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .catch((error) => console.log(error));
              }
            });
            selectedDocs.forEach((doc) => {
              if (!existingDocs?.find((obj) => obj?.value === doc?.value)) {
                const ecrDoc = {
                  entrydate: new Date(
                    new Date().setMinutes(
                      new Date().getMinutes() - new Date().getTimezoneOffset(),
                    ),
                  ),
                  ecrid: response.data.ecrid,
                  doc_type: doc.value,
                  creator: currentUser.id,
                };
                axios
                  .post(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/`,
                    ecrDoc,
                    {
                      withCredentials: true,
                      headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then(() => refetch())
                  .catch((error) => console.log(error));
              }
            });
            existingDocs.forEach((doc) => {
              if (!selectedDocs.find((obj) => obj.value === doc?.value)) {
                axios
                  .delete(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/${doc?.xid}`,
                    {
                      withCredentials: true,
                      headers: {
                        "X-CSRFToken": csrfToken,
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then(() => refetch())
                  .catch((error) => console.log(error));
              }
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            handleClose();
            refetch();
          });
      } else {
        let data = {
          ...ecr,
          active: true,
          creator: currentUser.id,
          entrydate: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          state: "Proposed",
        };

        if (maintenance) {
          data = { ...data, maintenance_id: maintenance?.maintenance_id };
        } else {
          data = { ...data, pid: project_id ? project_id : relatedProductId };
        }
        axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/`, data, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then((response) => {
            if (response.data?.status === "Released") {
              handleSendAlertAndMail();
            }
            if (project_id) {
              fetchEcrCounts();
            }
            handleUploadFiles(response.data?.ecrid);
            selectedTDs.forEach((td) => {
              const ecrTD = {
                entrydate: new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() - new Date().getTimezoneOffset(),
                  ),
                ),
                ecrid: response.data.ecrid,
                td_id: td.value,
                creator: currentUser.id,
              };
              axios.post(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/`,
                ecrTD,
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              );
            });

            selectedDocs.forEach((doc) => {
              const ecrDoc = {
                entrydate: new Date(
                  new Date().setMinutes(
                    new Date().getMinutes() - new Date().getTimezoneOffset(),
                  ),
                ),
                ecrid: response.data.ecrid,
                doc_type: doc.value,
                creator: currentUser.id,
              };
              axios
                .post(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/doc/`,
                  ecrDoc,
                  {
                    withCredentials: true,
                    headers: {
                      "X-CSRFToken": csrfToken,
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then(() => refetch());
            });
          })
          .catch((error) => console.log(error))
          .finally(() => {
            handleClose();
            refetch();
          });
      }
    }
  };

  const removeFile = async (file, idx) => {
    if (file.file_id) {
      await axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${file.file_id}/`,
          {
            active: false,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => fetchECRCommentFiles(ecr?.ecrid))
        .catch((error) => console.log("Unable to update file", error));
    } else {
      setUploadedFiles(uploadedFiles.slice(idx + 1));
    }
  };

  useEffect(() => {
    if (show && currProduct) {
      loadPPByID();
    }
  }, [loadPPByID, currProduct, show]);

  const loadTechDec = useCallback(() => {
    if (selectedProfile !== null) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_all_tds_by_id/?profile=${selectedProfile}`,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          const options = response.data.map((td) => ({
            label: td.identifier ? td.identifier : td.title,
            value: td.td_id,
          }));
          setTDs(options);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedProfile]);

  useEffect(() => {
    if (show && selectedProfile !== null) {
      loadTechDec();
    }
  }, [loadTechDec, selectedProfile, show]);

  useEffect(() => {
    if (!vidNumber && show) {
      if (permissions?.role_type === "Validator") {
        fetchValidatorProducts();
      } else {
        fetchProducts();
      }
    }
  }, [vidNumber, show]);

  const getTitle = () => {
    if (isEditing) {
      return <>Edit ECR Comment {ecr?.ecrid}</>;
    } else if (vidNumber !== "none" && vidNumber) {
      return <>Add ECR Comment for VID {vidNumber}</>;
    } else if (maintenance) {
      return <>Add ECR Comment for AM {maintenance?.title}</>;
    } else {
      return (
        <>
          Add ECR Comment for *
          <Select
            required
            options={projects}
            onChange={(e) =>
              handleChange({ target: { name: "pid", value: e.value } })
            }
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                borderColor: showValid && !ecr.pid ? "red" : "#cccccc",
              }),
            }}
          />
        </>
      );
    }
  };

  const handleSendAlertAndMail = () => {
    handleAlertAndMailer(csrfToken, authToken, {
      alert_type_id: project_id,
      alert_type: "Product",
      alert_source: "ECR",
      subject: `ECR Comments have been released for Product VID${vidNumber}`,
      recipients: { to: ["Lab"], cc: ["Validators"] },
      alert_text: `ECR Comments have been released for Product VID${vidNumber}`,
    });
  };

  const removeTD = async (td, idx) => {
    let removedTD = existingTDs?.find((obj) => obj?.td_id === td?.value);
    if (removedTD) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/get_ecr_td_by_ecrid_and_tdid/?ecrid=${ecr?.ecrid}&tdid=${removedTD?.td_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (response.data?.length > 0) {
            axios
              .delete(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/technical-decision/${response.data[0]?.xid}`,
                {
                  withCredentials: true,
                  headers: {
                    "X-CSRFToken": csrfToken,
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                fetchECRTDs();
              })
              .catch((error) =>
                console.log("error removing technical decision", error),
              );
          } else {
            const newTDs = selectedTDs.filter(
              (selectedTD) => selectedTD?.value !== td?.value,
            );
            setSelectedTDs(newTDs);
          }
        })
        .catch((error) => console.log(error));
    } else {
      const newTDs = selectedTDs.filter(
        (selectedTD) => selectedTD?.value !== td?.value,
      );
      setSelectedTDs(newTDs);
    }
  };

  const fetchProduct = (productId) => {
    if (productId) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${productId}/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setCurrProduct(response.data);
          // Automating setting process location for product with status Pre-Evaluation and In-Progress"
          if (response.data.status === "Pre-Evaluation") {
            setEcr({ ...ecr, process_location: "Check In" });
          } else if (response.data.status === "In Assurance Maintenance") {
            setEcr({ ...ecr, process_location: "Assurance Maintenance" });
          } else {
            setEcr({ ...ecr, process_location: "Check Out" });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (project_id === undefined && relatedProductId) {
      fetchProduct(relatedProductId);
    } else if (maintenance) {
      fetchProduct(maintenance?.product?.product_id);
    } else if (project_id) {
      fetchProduct(project_id);
    }
  }, [project_id, relatedProductId, show]);

  const getProcessLocation = () => {
    if (!maintenance) {
      if (currProduct?.status === "Pre-Evaluation") {
        return "Check In";
      } else if (currProduct?.status === "In Progress") {
        return "Check Out";
      }
    } else {
      return "Assurance Maintenance";
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      enforceFocus={enforceFocusModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {getTitle()}
          {["Pre-Evaluation", "In Progress"].includes(currProduct?.status) && (
            <Row className="ms-1">Process Location: {getProcessLocation()}</Row>
          )}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleAddECR}>
        <Container className="mt-2">
          <Modal.Body>
            <Row className="mb-3">
              <Col>
                <Form.Label htmlFor="title">Comment Title</Form.Label>
                <Form.Control
                  id="title"
                  name="gist"
                  onChange={handleChange}
                  defaultValue={ecr?.gist}
                />
              </Col>
            </Row>

            {[
              "Pre-Evaluation",
              "In Progress",
              "In Assurance Maintenance",
            ].includes(currProduct?.status) ? (
              ""
            ) : (
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="location">
                    <Form.Label>Process Location*</Form.Label>
                    <Form.Select
                      value={ecr?.process_location}
                      required
                      onChange={handleChange}
                      name="process_location"
                    >
                      <option
                        value=""
                        disabled
                        hidden
                        selected={ecr?.process_location}
                      >
                        Select Check In or Check Out
                      </option>
                      <option value="Check In">Check In</option>
                      <option value="Check Out">Check Out</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row className="mb-3">
              {permissions.role_type !== "Lab" && (
                <Col xs={12} md={6} className="mb-xs-3">
                  <Form.Label htmlFor="type">Type*</Form.Label>
                  <Select
                    id="type"
                    required
                    options={ecrTypeOptions}
                    onChange={(e) =>
                      handleChange({ target: { name: "type", value: e.value } })
                    }
                    value={ecrTypeOptions?.find(
                      (type) => type.value === ecr?.type,
                    )}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor: showValid && !ecr.type ? "red" : "#cccccc",
                      }),
                    }}
                  />
                </Col>
              )}
              <Col xs={12} md={6} className="mb-xs-3">
                <Form.Label htmlFor="status">Status*</Form.Label>
                {!isEditing ||
                ecrStatusOptions?.find((s) => s?.value === ecr.status) ? (
                  <Select
                    id="status"
                    required
                    placeholder="Select a Status"
                    options={ecrStatusOptions}
                    onChange={(e) =>
                      handleChange({
                        target: { name: "status", value: e.value },
                      })
                    }
                    value={ecrStatusOptions?.find(
                      (status) => status.value === ecr?.status,
                    )}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        borderColor:
                          showValid && !ecr.status ? "red" : "#cccccc",
                      }),
                    }}
                  />
                ) : (
                  <p>{ecr?.status}</p>
                )}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={12} md={6} className="mb-xs-3">
                <Form.Label htmlFor="docType">Document Types*</Form.Label>
                <Select
                  id="docType"
                  isMulti
                  required
                  placeholder="Select All Document Types"
                  options={ecrDocOptions}
                  onChange={(e) => setSelectedDocs(e)}
                  value={selectedDocs}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor:
                        showValid && !selectedDocs.length > 0
                          ? "red"
                          : "#cccccc",
                    }),
                  }}
                />
              </Col>
              <Col xs={12} md={6} className="mb-xs-3">
                <Form.Label htmlFor="cite">Cite (Page & Paragraph)*</Form.Label>
                <Form.Control
                  id="cite"
                  required
                  name="cite"
                  onChange={handleChange}
                  defaultValue={ecr?.cite}
                />
              </Col>
            </Row>
            <Row>
              <Form.Label className="pt-3">Attach Files</Form.Label>
            </Row>
            <Row className="mb-2">
              <Row className="pt-2">
                <Col xl={3} sm={12}>
                  <Form.Group className="mb-4 text-start" controlId="file_type">
                    <Form.Label className="small text-secondary">
                      Type
                    </Form.Label>
                    <Form.Select
                      name="file_type"
                      value={ecr?.file_type ?? ""}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="EAR Review">EAR Review</option>
                      <option value="Security Target">Security Target</option>
                      <option value="Product Information">
                        Product Information
                      </option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xl={3} sm={12}>
                  <Form.Group
                    className="mb-4 text-start"
                    controlId="file_public_status"
                  >
                    <Form.Label className="small text-secondary">
                      Document Visibility
                    </Form.Label>
                    <Form.Select
                      name="file_public_status"
                      value={ecr?.file_public_status ?? ""}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      <option value="Public">Public</option>
                      <option value="Proprietary">Proprietary</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xl={6} sm={12}>
                  <Form.Group controlId="file_label">
                    <Form.Label className="small text-secondary">
                      File Label
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="file_label"
                      onChange={handleChange}
                      value={fileLabel}
                    />
                  </Form.Group>
                </Col>
                <Col xl={6} sm={12}>
                  <Form.Group controlId="file">
                    <Form.Label className="small text-secondary">
                      Attach Files
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="file"
                      onChange={handleChange}
                      disabled={
                        !ecr.file_type ||
                        !ecr.file_public_status ||
                        !ecr.file_label
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              {uploadedFiles.length > 0 && (
                <Row xs={1} md={2} lg={3}>
                  {uploadedFiles.map((file, i) => (
                    <Col key={i} className="mt-2">
                      <Card style={{ width: "15rem" }}>
                        <Card.Body>
                          <Card.Title>{file.file_label}</Card.Title>
                          <Card.Subtitle>{file?.file?.name}</Card.Subtitle>
                          <p>{file?.file_type}</p>
                          <p>{file?.file_public_status}</p>
                        </Card.Body>
                        <Card.Footer>
                          <Button
                            variant="outline-warning"
                            type="button"
                            onClick={() => removeFile(file, i)}
                          >
                            Remove
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </Row>
            <Form.Group className="mb-4 mt-4">
              <Form.Label htmlFor="cmnt">Comment*</Form.Label>
              <HTMLEditor
                name="cmnt"
                handleChange={handleChange}
                isValid={commentValid}
                setIsValid={setCommentValid}
                defaultValue={existingComment?.cmnt}
                setEnforceFocusModal={setEnforceFocusModal}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="rationale">Rationale</Form.Label>
              <HTMLEditor
                name="rationale"
                handleChange={handleChange}
                isValid={true}
                setIsValid={() => {}}
                defaultValue={existingComment?.rationale}
                setEnforceFocusModal={setEnforceFocusModal}
              />
            </Form.Group>
            <Row className="mb-3">
              <Col xs={12} md={6} className="mb-xs-3">
                <Form.Label htmlFor="protectionProfile">
                  Protection Profile
                </Form.Label>
                <Select
                  id="protectionProfile"
                  placeholder="Select a Protection Profile"
                  options={pps}
                  onChange={(e) =>
                    handleChange({ target: { name: "pp_id", value: e.value } })
                  }
                  value={
                    selectedProfile
                      ? pps?.find((pp) => pp.value === selectedProfile)
                      : null
                  }
                />
              </Col>
              <Col>
                <Form.Label htmlFor="requirement">Requirement</Form.Label>
                <Form.Control
                  id="requirement"
                  name="req"
                  onChange={handleChange}
                  defaultValue={ecr?.req}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Label htmlFor="technicalDecisions">
                  Technical Decisions
                </Form.Label>
                <Select
                  id="technicalDecisions"
                  placeholder="Select All Technical Decisions"
                  options={tds}
                  onChange={(e) => setSelectedTDs([...selectedTDs, e])}
                />
                {selectedTDs?.map((td, idx) => (
                  <Row className="m-3 border-bottom text-center" key={idx}>
                    <Col sm={6}>{td.label}</Col>
                    <Col sm={6}>
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => removeTD(td, idx)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col xs={12} md={2}>
              <Button
                type="button"
                className="mb-xs-3 w-100"
                onClick={handleClose}
                variant="warning"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12} md={2}>
              <Button type="submit" variant="success" className="mb-xs-3 w-100">
                {isEditing ? "Update" : "Submit"}
              </Button>
            </Col>
          </Modal.Footer>
        </Container>
      </Form>
    </Modal>
  );
}
