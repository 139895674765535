import axios from "axios";

import { Maintenance } from "api/memberPortal/project/maintenance/maintenance.types";

function latestApprovedMaintenance(maintenances: Maintenance[]) {
  const latestApprovedMaintenance = maintenances
    ?.filter((maintenance) => maintenance.status === "Approved")
    ?.sort((a, b) =>
      new Date(a.completion_date).getTime() <
      new Date(b.completion_date).getTime()
        ? 1
        : -1,
    )[0];
  return latestApprovedMaintenance;
}

export { latestApprovedMaintenance };

interface MailerData {
  subject: string;
  recipients: {
    to: string[]; // TODO: MAKE CONSTS
    cc: string[]; // TODO: MAKE CONSTS
  };
  alert_type?: string; // TODO: MAKE CONSTS
  alert_text?: string;
  alert_type_id?: string | number;
}

export const handleAlertAndMailer = async (
  csrfToken: string,
  authToken: string,
  data: MailerData,
) => {
  let headers = {
    "X-CSRFToken": csrfToken,
  };
  if (authToken) {
    // @ts-ignore - We wont need this once we switch to RTKQ so im not going to bother typing it
    headers["Authorization"] = `Token ${authToken}`;
  }
  await axios
    .post(
      `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/send_alert_and_mail/`,
      data,
      {
        withCredentials: true,
        headers: headers,
      },
    )
    .then(() => {
      console.log("Alert and email sent successfully.");
    })
    .catch((error) => console.log("Unable to create alert and email.", error));
};
