import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Subheader from "components/Subheader";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useInterval } from "hooks/useInterval";
import "components/Products/Products.css";

export default function PCMDetailsPage() {
  const { id } = useParams();
  const [ccProduct, setCCProduct] = useState({});
  const [pcmPps, setPcmPps] = useState([]);
  const [files, setFiles] = useState([]);
  const [listOfAms, setListOfAms] = useState([]);
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  useEffect(() => {
    document.title = "NIAP - CC Product Details";
  }, []);

  const getCCProduct = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/get_ccp_by_ccid/?ccid=${id}`,
      )
      .then((response) => {
        setCCProduct(response.data);
      })
      .catch((error) => {
        console.log("error fetching cc product", error);
      });
  }, []);

  useEffect(() => {
    getCCProduct();
  }, [id]);

  const getCCFiles = (rid) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_pcl_files_by_ccid/?ccid=${id}`,
      )
      .then((response) => {
        setFiles(response.data.filter((f) => f.active === true));
      })
      .catch((error) => setFiles([]));
  };

  useEffect(() => {
    getCCFiles(ccProduct?.ccid);
  }, [ccProduct]);

  const getAMs = () => {
    if (id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/maintenance/get_maintenances_by_ccid/?ccid=${id}`,
        )
        .then((response) => {
          setListOfAms(response.data);
        })
        .catch((error) => {
          setListOfAms([]);
        });
    }
  };

  useEffect(() => {
    getAMs();
  }, [id]);

  const getPcmPps = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/pp/get_pps_by_ccid/?ccid=${id}`,
      )
      .then((response) => {
        setPcmPps(response.data);
      })
      .catch((error) => setPcmPps([]));
  }, []);

  useEffect(() => {
    getPcmPps();
  }, []);

  useInterval(() => {
    getCCFiles(ccProduct?.ccid);
  }, 300000); //5 min

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_public_file/?file_id=${file.file_id}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addHttpsToVendorWebsite = (website) => {
    if (
      website &&
      !website.includes("https://") &&
      !website.includes("http://")
    ) {
      return "https://" + website;
    }
    return website;
  };

  return (
    <>
      <Subheader pageName={"Products"} />
      <Container className="p-5" fluid>
        <Row>
          <Col className="d-flex">
            <h3 className="fw-bold">
              Compliant Product -{" "}
              <span className="text-primary">{ccProduct?.name}</span>
            </h3>
          </Col>
        </Row>
        <Row className="gx-2">
          <Col sm={12} md={3} lg={2} className="bg-primary p-4 text-white">
            <Stack className="border-bottom pb-4">
              <Container className="p-0">
                {files?.map((file, idx) => (
                  <Row className="mt-3" key={idx}>
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        onClick={() => downloadFile(file)}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        {file?.file_display_name}
                      </Button>
                    </Stack>
                  </Row>
                ))}
              </Container>
              <Container className="p-0">
                {ccProduct?.pdf_cert && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${ccProduct?.pdf_cert}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        Validation Report
                      </Button>
                    </Stack>
                  </Row>
                )}
                {ccProduct?.pdf_st && (
                  <Row className="mt-3">
                    <Stack direction="horizontal">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="yellow"
                        size="xl"
                        className="px-1"
                      />
                      <Button
                        variant="link"
                        target="_blank"
                        href={`https://www.commoncriteriaportal.org/nfs/ccpfiles/files/epfiles/${ccProduct?.pdf_st}`}
                        className="text-white d-flex justify-content-start p-0 m-0 small-font"
                      >
                        Security Target
                      </Button>
                    </Stack>
                  </Row>
                )}
              </Container>
            </Stack>
            {ccProduct?.certified && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Certification Date</small>
                <p>{moment.utc(ccProduct?.certified).format("MM/DD/YYYY")}</p>
              </Stack>
            )}
            {ccProduct?.am_date && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Assurance Maintenance Date</small>
                <p>{moment.utc(ccProduct?.am_date).format("MM/DD/YYYY")}</p>
              </Stack>
            )}
            {listOfAms?.length > 0 && (
              <Stack className="border-bottom pt-4">
                <small className="pb-2">Maintenance Release</small>
                {listOfAms?.map((am, idx) => (
                  <Button
                    key={idx}
                    variant="link"
                    href={`/products/international-product/${id}/assurancemaintenance/${am?.maintenance_id}`}
                    className="text-decoration-underline text-white mb-3"
                  >
                    {am?.name}{" "}
                    {am?.am_date_completed &&
                      moment.utc(am?.am_date_completed).format("MM/DD/YYYY")}
                  </Button>
                ))}
              </Stack>
            )}
          </Col>
          <Col sm={12} md={9} lg={10} className="px-4">
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">Product Type:</h6>
                <p>{ccProduct?.categories}</p>
              </Stack>
            </Row>
            <Row className="pt-4">
              <h6 className="fw-bold">Conformance Claim:</h6>
              {pcmPps?.length > 0 && (
                <div className="d-flex align-items-center py-2">
                  <p>Protection Profile Compliant</p>
                </div>
              )}
            </Row>
            <Row className="pt-4">
              <h6 className="fw-bold">PP Identifier:</h6>
              {pcmPps?.map((pp, idx) => (
                <div key={idx} className="d-flex align-items-center py-2">
                  <p>{pp?.pp_name}</p>
                </div>
              ))}
            </Row>
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">CC Testing Lab:</h6>
                <p>{ccProduct?.assigned_lab_name}</p>
              </Stack>
            </Row>
            <Row className="pt-4">
              <Stack>
                <h6 className="fw-bold">Vendor Information:</h6>
                <p>{ccProduct?.vendor?.name}</p>
                {ccProduct?.vendor?.website && (
                  <a
                    href={addHttpsToVendorWebsite(ccProduct?.vendor?.website)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {addHttpsToVendorWebsite(ccProduct?.vendor?.website)}
                  </a>
                )}
              </Stack>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
