import { baseApi } from "api/BaseApi";

import {
  Product,
  ProductShort,
  ProductMetrics,
  CCTLProductMetrics,
} from "./product.types";

const PRODUCT_BASE_QUERY = "/project/product";

const productApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<Product, number>({
      query: (id) => `${PRODUCT_BASE_QUERY}/${id}/`,
    }),

    getProductByVid: build.query<Product, number | string>({
      query: (vid) => ({
        url: `${PRODUCT_BASE_QUERY}/get_product_by_vid/`,
        params: { vid },
      }),
    }),

    getAllProducts: build.query<ProductShort[], void>({
      query: () => `${PRODUCT_BASE_QUERY}/get_all_products/`,
    }),

    getValidatorProducts: build.query<ProductShort[], void>({
      query: () => `${PRODUCT_BASE_QUERY}/all_my_validations/`,
    }),

    getProductMetrics: build.query<ProductMetrics, void>({
      query: () => `${PRODUCT_BASE_QUERY}/product_metrics/`,
    }),

    getCCTLProductMetrics: build.query<CCTLProductMetrics, void>({
      query: () => `${PRODUCT_BASE_QUERY}/cctl_product_metrics/`,
    }),

    getCCTLProductsAvailableForAM: build.query<Product[], void>({
      query: () => `${PRODUCT_BASE_QUERY}/my_available_am_products/`,
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllProductsQuery,
  useGetProductQuery,
  useGetValidatorProductsQuery,
  useLazyGetProductByVidQuery,
  useGetProductMetricsQuery,
  useGetCCTLProductMetricsQuery,
  useGetCCTLProductsAvailableForAMQuery,
} = productApi;
