import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect } from "react";
import { Button, Accordion, Modal } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import NotesForm from "./NotesForm";

export default function Notes({ item, note_type, id, refetchNotes, refetch }) {
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [currentNote, setCurrentNote] = useState({});
  const [inResponseTo, setInResponseTo] = useState();
  const [toDeleteNote, setToDeleteNote] = useState();
  const [totalNotes, setTotalNotes] = useState();
  const [expandAll, setExpandAll] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [unfilteredNotesList, setUnfilteredNotes] = useState([]);
  const { authToken, csrfToken, currentUser, permissions } = useAuth();

  const openNoteModal = (replyTo = null, note = null) => {
    if (note?.in_response_to) {
      setInResponseTo(note?.in_response_to.note_id);
    } else {
      setInResponseTo(replyTo);
    }
    setCurrentNote(note);
    setShowNoteModal(true);
  };

  const closeNoteModal = () => {
    setShowNoteModal(false);
    fetchNotes();
  };

  const openDeleteModal = (note) => {
    setToDeleteNote(note);
    setShowDeleteModal(true);
  };

  const deleteNote = (note_id) => {
    axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${note_id}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        fetchNotes();
        setShowDeleteModal(false);
      })
      .catch((error) => alert("Unable to delete note: " + error));
  };
  const notesUrl = () => {
    if (note_type === "technical_query") {
      return `get_tq_notes_by_tq_id/?tq_id=${id}`;
    } else {
      return `get_notes_by_type_and_type_id/?note_type=${note_type}&note_type_id=${id}`;
    }
  };

  const fetchNotes = async () => {
    await axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${notesUrl()}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        const unfilteredNotes = response.data;
        setUnfilteredNotes(unfilteredNotes);
        setTotalNotes(unfilteredNotes.length);
        const filteredNotes = [];
        const responses = {};
        unfilteredNotes.forEach((note) => {
          if (!note.in_response_to) {
            filteredNotes.push(note);
          } else {
            if (!responses[note.in_response_to.note_id]) {
              responses[note.in_response_to.note_id] = [];
            }
            responses[note.in_response_to.note_id].push(note);
          }
        });
        for (let key in responses) {
          const children = responses[key];
          children.forEach((note, idx) => {
            if (responses[note.note_id]) {
              note["responses"] = responses[note.note_id];
              responses[key][idx] = note;
            }
          });
        }
        filteredNotes.forEach((note) => {
          if (responses[note.note_id]) {
            responses[note.note_id]?.sort((a, b) =>
              new Date(a.submitted_on).getTime() <
              new Date(b.submitted_on).getTime()
                ? 1
                : -1,
            );
            note["responses"] = responses[note.note_id];
          }
        });
        filteredNotes.sort((a, b) =>
          new Date(a.submitted_on).getTime() <
          new Date(b.submitted_on).getTime()
            ? 1
            : -1,
        );
        setNotes(filteredNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchNotes();
  }, [refetchNotes, id]);

  useInterval(() => {
    fetchNotes();
  }, 180000); //3 min

  const visibilityText = (note) => {
    const visibilityTypes = [];
    if (note.internal_only) {
      visibilityTypes.push("Private");
    }

    if (note.visible_to_labs) {
      visibilityTypes.push("Visible to Lab");
    }

    if (note.visible_to_validators) {
      visibilityTypes.push("Visible to Validators");
    }

    if (note.visible_to_smes) {
      visibilityTypes.push("Visible to SME's");
    }
    return visibilityTypes.length > 0 ? visibilityTypes.join(", ") : "Public";
  };

  const childNotes = (note) => {
    return (
      <>
        <div className="text-end">
          {note_type !== "technical_query" && (
            <button
              className="btn btn-link d-inline-block"
              style={{ minWidth: 10 }}
              onClick={() => openNoteModal(note)}
            >
              Reply
            </button>
          )}
          {(note?.submitted_by?.id === currentUser?.id ||
            permissions?.role_type === "NIAP") && (
            <>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 ms-3">
          <p className="text-end mb-0">
            Note by: {note.submitted_by?.first_name}{" "}
            {note.submitted_by?.last_name} <br />
            {note.submitted_on &&
              moment.utc(note.submitted_on).format("YYYY.MM.DD [at] HHmm")}
          </p>
          <p
            className="text-end"
            data-cy={`note-visibility-${note_type}-${note.note_id}`}
          >
            Visibility: {visibilityText(note)}
          </p>
          <p className="fw-bold">{note.title}</p>
          <div>{note.note && parse(note.note)}</div>
          {note.responses && (
            <Accordion
              defaultActiveKey={note.note_id}
              activeKey={selectedNotes}
              onSelect={() => {
                const uniqueNotes = new Set(selectedNotes);
                if (uniqueNotes.has(note.note_id)) {
                  uniqueNotes.delete(note.note_id);
                } else {
                  uniqueNotes.add(note.note_id);
                }
                setSelectedNotes([...uniqueNotes]);
              }}
            >
              <Accordion.Item
                eventKey={
                  selectedNotes.includes(note.note_id) ? note.note_id : null
                }
              >
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.note_id}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const displayNotes = () => {
    return notes.map((note) => (
      <div key={note.note_id}>
        <div className="text-end mx-3">
          {note_type !== "technical_query" && (
            <button
              className="btn btn-link d-inline-block py-1"
              style={{ minWidth: 10 }}
              onClick={() => openNoteModal(note)}
            >
              Reply
            </button>
          )}

          {(note?.submitted_by?.id === currentUser?.id ||
            permissions?.role_type === "NIAP") && (
            <>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 m-4 mt-0">
          <p className="text-end mb-0">
            Note by: {note.submitted_by?.first_name}{" "}
            {note.submitted_by?.last_name} <br />
            {note.submitted_on &&
              moment.utc(note.submitted_on).format("YYYY.MM.DD [at] HHmm")}{" "}
          </p>
          <p
            className="text-end"
            data-cy={`note-visibility-${note_type}-${note.note_id}`}
          >
            Visibility: {visibilityText(note)}
          </p>
          <p className="fw-bold">{note.title}</p>
          <div>{note.note && parse(note.note)}</div>
          {note.responses && (
            <Accordion
              defaultActiveKey={note.note_id}
              activeKey={selectedNotes}
              onSelect={() => {
                const uniqueNotes = new Set(selectedNotes);
                if (uniqueNotes.has(note.note_id)) {
                  uniqueNotes.delete(note.note_id);
                } else {
                  uniqueNotes.add(note.note_id);
                }
                setSelectedNotes([...uniqueNotes]);
              }}
            >
              <Accordion.Item
                eventKey={
                  selectedNotes.includes(note.note_id) ? note.note_id : null
                }
              >
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.note_id}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    ));
  };

  const accordionTitle = () => {
    if (note_type === "product") {
      return "Project Notes";
    } else if (note_type === "ecr-comments") {
      return "RESPONSES";
    } else {
      return "INTERNAL NOTES";
    }
  };

  return (
    <Accordion className="my-4" flush defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h6 className={note_type !== "product" ? "fw-bold text-dark" : ""}>
            {accordionTitle()}
          </h6>
          {/* <span className='border my-2 mx-4 p-2'>Total: {totalNotes}</span> */}
          {note_type === "ecr-comments" &&
            ["Released", "Response to Validator", "Response to Lab"]?.includes(
              item?.status,
            ) &&
            (permissions?.is_senior ||
              permissions?.is_lead ||
              permissions?.role_type === "Lab") && (
              <Button
                variant="warning"
                className="rounded-pill"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal()}
              >
                {permissions?.role_type === "Lab"
                  ? "Respond to Validator"
                  : "Response to Lab"}
              </Button>
            )}
        </Accordion.Header>
        <Accordion.Body>
          {notes?.length > 0 &&
            (!expandAll ? (
              <div>
                <button
                  className="btn btn-primary d-inline-block"
                  style={{ minWidth: 10 }}
                  onClick={() => {
                    setExpandAll(true);
                    setSelectedNotes(
                      unfilteredNotesList.map((note) => note.note_id),
                    );
                  }}
                >
                  Expand All
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn btn-primary d-inline-block"
                  style={{ minWidth: 10 }}
                  onClick={() => {
                    setExpandAll(false);
                    setSelectedNotes([]);
                  }}
                >
                  Collapse All
                </button>
              </div>
            ))}
          <div className="text-end">
            <button
              className="btn btn-link d-inline-block"
              style={{ minWidth: 10 }}
              onClick={() => openNoteModal()}
            >
              Add
            </button>
          </div>
          {notes?.length > 0 && displayNotes()}
          {showNoteModal && (
            <NotesForm
              show={showNoteModal}
              note_type={note_type}
              currentNote={currentNote}
              item={item}
              handleCloseNotesForm={closeNoteModal}
              replyTo={inResponseTo}
              id={id}
            />
          )}
          {showDeleteModal && (
            <Modal
              size="md"
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
            >
              <Modal.Header closeButton className="border-0">
                <Modal.Title>Delete Note</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4 className="text-center">
                  Are You Sure You Want to Delete this By: <br />
                  {toDeleteNote.submitted_by?.first_name}{" "}
                  {toDeleteNote.submitted_by?.last_name} on: <br />
                  {toDeleteNote.submitted_on &&
                    moment
                      .utc(toDeleteNote.submitted_on)
                      .format("YYYY.MM.DD [at] HHmm")}
                </h4>
                <div className="d-flex justify-content-center mt-4">
                  <Button
                    variant="primary"
                    className="m-1"
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={() => deleteNote(toDeleteNote.note_id)}
                  >
                    Delete
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
