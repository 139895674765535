import { Row, Col, Container } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

import AlertsModule from "../AlertsModule";
import NIAPMetricsGroup from "../Metrics/NIAPMetricsGroup";
import NIAPProductsModule from "../NIAPProductsModule/NIAPProductsModule";
import NIAPTabsModule from "../NIAPTabsModule/NIAPTabsModule";

export default function NIAPDashboard() {
  const { permissions } = useAuth();
  return (
    <Container size="md" className="my-4" fluid>
      <NIAPMetricsGroup />
      <Col className="mx-2">
        <Row className="mb-4">
          <NIAPTabsModule />
        </Row>
        <Row className="mb-4">
          {permissions.role_name === "Database Manager" && (
            <NIAPProductsModule />
          )}
        </Row>
        <Row className="mb-4">
          <AlertsModule />
        </Row>
      </Col>
    </Container>
  );
}
