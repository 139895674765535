import axios from "axios";
import qs from "qs";
import { useState, useEffect, useCallback } from "react";
import { Card, Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import PaginationComponent from "components/UI/Pagination/PaginationComponent";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import AlertCard from "./AlertCard";

import "./AlertsModule.css";

export default function AlertsModule() {
  const [activeAlerts, setActiveAlerts] = useState([]);
  const [inactiveAlerts, setInactiveAlerts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState(true);
  const [activeOffset, setActiveOffset] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveOffset, setInactiveOffset] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const { authToken } = useAuth();

  const alertsPerPage = 3;

  const fetchActiveAlerts = useCallback(() => {
    const params = {
      limit: alertsPerPage,
      offset: activeOffset,
      active: "True",
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/current_user_alerts/?${qs.stringify(params)}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setActiveAlerts(response.data.results);
        setActiveCount(response.data.count);
      })
      .catch((error) => console.log("error: " + error));
  }, [activeOffset]);

  const fetchInactiveAlerts = useCallback(() => {
    const params = {
      limit: alertsPerPage,
      offset: inactiveOffset,
      active: "False",
    };
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}alerts/current_user_alerts/?${qs.stringify(params)}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setInactiveAlerts(response.data.results);
        setInactiveCount(response.data.count);
      })
      .catch((error) => console.log("error: " + error));
  }, [inactiveOffset]);

  useEffect(() => {
    const fetchAlerts = async () => {
      fetchActiveAlerts();
      fetchInactiveAlerts();
    };
    fetchAlerts();
  }, [fetchActiveAlerts, fetchInactiveAlerts]);

  useInterval(() => {
    const fetchAlerts = async () => {
      fetchActiveAlerts();
      fetchInactiveAlerts();
    };
    fetchAlerts();
  }, 300000); //5 min
  const handleSelect = (key) => {
    setCurrentPage(1);
    if (key === "active") {
      setView(true);
    } else {
      setView(false);
    }
  };

  //get current alerts
  const indexOfLastAlert = currentPage * alertsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - alertsPerPage;

  //set current page
  const paginate = (pageNumber, pageNumberLength) => {
    if (pageNumber < 1 || pageNumber > pageNumberLength) {
      setCurrentPage(1);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    const offsetPage = currentPage - 1;
    if (view) {
      setActiveOffset(offsetPage * alertsPerPage);
    }
    if (!view) {
      setInactiveOffset(offsetPage * alertsPerPage);
    }
  }, [currentPage]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Container>
            <Row>
              <Col>Alerts and Notifications</Col>
              <Col lg={3}>
                <div
                  className="p-2 m-auto"
                  style={{ backgroundColor: "var(--bs-bright-navy)" }}
                >
                  <p className="mb-0 text-white text-center">
                    My Mail - {activeAlerts.length}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card.Title>
        <Tabs
          defaultActiveKey="active"
          className="mb-3"
          onSelect={handleSelect}
        >
          <Tab eventKey="active" title="Active">
            {view && (
              <>
                {activeAlerts.map((activeAlert, index) => {
                  return (
                    <AlertCard
                      alert={activeAlert}
                      key={index}
                      refetch={fetchActiveAlerts}
                    />
                  );
                })}
                <PaginationComponent
                  tabAlertsLength={activeCount}
                  alertsPerPage={alertsPerPage}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </>
            )}
          </Tab>
          <Tab eventKey="dismissed" title="Dismissed">
            {!view && (
              <>
                {inactiveAlerts.map((inactiveAlert, index) => {
                  return (
                    <AlertCard
                      alert={inactiveAlert}
                      key={index}
                      refetch={fetchInactiveAlerts}
                    />
                  );
                })}
                <PaginationComponent
                  tabAlertsLength={inactiveCount}
                  alertsPerPage={alertsPerPage}
                  currentPage={currentPage}
                  paginate={paginate}
                />
              </>
            )}
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
}
