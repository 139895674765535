import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import qs from "qs";
import { useState, useRef, useEffect, useMemo } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import UnauthorizedView from "components/UI/UnauthorizedView";
import ContactTRRTModal from "features/public/TRRTs/components/ContactTRRTModal";
import { useAuth } from "hooks/useAuth";

import ColorList from "./ColorList";
import { buildTqColumns } from "./TechnicalQueriesTable/TechnicalQueries.columns.jsx";
import Filter from "./TRRTTableFilter";

export default function TechnicalQueriesTable({ id }) {
  const [showColorKey, setShowColorKey] = useState(false);
  const [showTRRTQueryModal, setShowTRRTQueryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTQ, setDeleteTQ] = useState({});
  const [filterOpen, setFilterOpen] = useState(id ? false : true);
  const [isLoading, setIsLoading] = useState(true);

  //setting sidebarFilters to show 'active' statuses by default
  const [sidebarFilters, setSidebarFilters] = useState({
    status:
      "Proposed,TD Required,Resolution Proposed,In Progress,TD Proposed,Accepted",
    team: [],
  });

  const [refreshKey, setRefreshKey] = useState(0);
  const [usersTeams, setUsersTeams] = useState();

  const [groupedTds, setGroupedTds] = useState([]);
  const tableRef = useRef();
  const { authToken, permissions } = useAuth();

  useEffect(() => {
    if (window.innerWidth < 768) {
      setFilterOpen(false);
    }
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [sidebarFilters, id]);

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/users_teams`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((res) => {
        setSidebarFilters((prev) => ({
          ...prev,
          team: res.data,
        }));
        setUsersTeams(res.data);
        tableRef.current?.onQueryChange();
      })
      .catch((err) => console.error(err));
  }, [authToken, tableRef]);

  const handleDelete = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-query/${deleteTQ?.tq_id}/`,
        {
          active: false,
          recaptcha_token: "member_portal",
          modified_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
        },
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((res) => {
        setRefreshKey(refreshKey + 1);
        setDeleteTQ({});
        setShowDeleteModal(false);
      })
      .catch((err) => console.log("unable to get technical queries", err));
  };

  const fetchTQUrl = () => {
    if (permissions?.role_type === "NIAP") {
      return "technical-query/niap_tq_table_data/";
    } else if (permissions?.role_type === "Lab") {
      return "technical-query/lab_tq_table_data/";
    } else if (permissions?.role_type === "Validator") {
      return "technical-query/niap_tq_table_data/";
    } else if (permissions?.role_type === "SME") {
      return "technical-query/niap_tq_table_data/";
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/get_grouped_tds_by_tq/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((res) => {
        setGroupedTds(res.data);
      })
      .catch((err) => console.log("unable to get technical queries", err));
  }, [authToken]);

  const canReadTqs = permissions?.role_permissions?.find(
    (permission) => permission.area === "Technical Questions",
  )?.read;

  const columns = useMemo(
    () =>
      buildTqColumns({
        id,
        setDeleteTQ,
        setShowDeleteModal,
        groupedTds,
      }),
    [groupedTds, id],
  );

  if (!canReadTqs) return <UnauthorizedView />;

  const options = {
    columnsButton: true,
    search: true,
    filtering: true,
    pageSize: 20,
    emptyRowsWhenPaging: false,
  };

  return (
    <>
      {showDeleteModal && (
        <Modal
          size="md"
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
        >
          <Modal.Header closeButton className="border-0">
            <Modal.Title>Delete Technical Query</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className="text-center">
              Are You Sure You Want to Technical Query ID: {deleteTQ?.tq_id}?
            </h4>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="primary"
                className="m-1"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </Button>
              <Button variant="danger" className="m-1" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <ContactTRRTModal
        show={showTRRTQueryModal}
        setShow={() => setShowTRRTQueryModal(false)}
        memberPortal={true}
        refetch={() => setRefreshKey(refreshKey + 1)}
      />
      <Container fluid>
        <Row className="py-4">
          <Col className="text-end">
            {!id && (
              <ButtonGroup>
                <Button
                  variant="primary"
                  className="me-3 rounded-0"
                  onClick={() => setShowColorKey(!showColorKey)}
                >
                  Color Key
                </Button>
                <Button
                  className="me-3 rounded-0"
                  variant="warning"
                  onClick={() => setShowTRRTQueryModal(true)}
                >
                  SUBMIT A TRRT QUERY
                </Button>
              </ButtonGroup>
            )}
          </Col>
          {showColorKey && (
            <Row>
              <Col className="text-start">
                <ColorList />
              </Col>
            </Row>
          )}
        </Row>
        {!id && (
          <Row className="mt-3">
            <Col md={9}></Col>
            <Col
              sm={12}
              md={3}
              className="d-flex justify-content-end align-content-center mb-2"
            >
              <Button
                variant="link rounded-pill"
                style={{ backgroundColor: "#dedede", maxHeight: 40 }}
                onClick={changeFilter}
              >
                {filterOpen && (
                  <>
                    <KeyboardArrowRightIcon />
                    <KeyboardArrowRightIcon />
                    &nbsp; Close Filter &nbsp;
                    <KeyboardArrowRightIcon />
                    <KeyboardArrowRightIcon />
                  </>
                )}
                {!filterOpen && (
                  <>
                    <KeyboardArrowLeftIcon />
                    <KeyboardArrowLeftIcon />
                    &nbsp; Open Filter &nbsp;
                    <KeyboardArrowLeftIcon />
                    <KeyboardArrowLeftIcon />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
            <ResponsiveMaterialTable
              title={"Technical Queries"}
              key={refreshKey}
              tableRef={tableRef}
              columns={columns}
              options={options}
              isLoading={isLoading}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current &&
                    tableRef.current.dataManager.columns.forEach((item) => {
                      tableRef.current.onFilterChange(item.tableData.id, "");
                    }),
                },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // This ensures we are not making repeated calls before the initial filters are set
                  // This mainly prevents a race condition that would lead to unreliable initial renders
                  if (!usersTeams) {
                    resolve({
                      data: [],
                      page: 0,
                      totalCount: 0,
                    });
                  }
                  setIsLoading(true);

                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters
                    .map((filter) => {
                      let value = filter.value.replace(/['"]+/g, "");

                      if (Array.isArray(filter.value)) {
                        value = filter.value;
                      }

                      if (
                        [
                          "deadline",
                          "submitted_on",
                          "modified_on",
                          "response_on",
                          "date_sent",
                        ].includes(filter.column.field)
                      ) {
                        const dateObj = new Date(filter.value);
                        const isoStr = dateObj.toISOString();
                        const formattedStr = isoStr.split("T")[0];
                        value = formattedStr;
                      }

                      if (filter.column.field === "status") {
                        if (value?.length > 0) {
                          return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                        } else {
                          return null;
                        }
                      } else {
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      }
                    })
                    .filter((filter) => filter !== null);

                  let params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };

                  if (id) {
                    params["product_id"] = id;
                  } else {
                    params = {
                      ...sidebarFilters,
                      ...params,
                    };
                  }

                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}${fetchTQUrl()}?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      setIsLoading(false);
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      setIsLoading(false);
                      reject(error);
                    });
                })
              }
            />
          </Col>

          {!id && filterOpen && (
            <Col xs={10} md={3}>
              <Filter filters={sidebarFilters} setFilters={setSidebarFilters} />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}
