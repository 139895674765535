import axios from "axios";
import { Modal, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

const ResetPasswordModal = ({ user, show, handleClose, setAlert }) => {
  const { authToken, csrfToken } = useAuth();

  const handleResetPassword = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}password-reset/`,
        { email: user?.email },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        handleClose();
        setAlert({ message: "Password reset email sent.", type: "success" });
      })
      .catch(() => {
        handleClose();
        setAlert({
          message: "Error sending password reset email. Please try again.",
          type: "danger",
        });
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Reset Password for {user?.first_name} {user?.last_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to reset the password for this user?</p>
        <p>
          This action will create and send a temporary password to the email
          account associated with the user with a link to allow them to reset
          their password.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={handleResetPassword}>
          Reset Password
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPasswordModal;
