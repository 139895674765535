import { faFileLines, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import FileDownload from "js-file-download";
import moment from "moment";
import { Button, Row, Col } from "react-bootstrap";

import {
  useGetFileMutation,
  useRemoveFileMutation,
} from "api/memberPortal/files/files.api";
import { UserUploadedFile } from "api/memberPortal/files/files.types";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

interface FileInfoButtonProps {
  file: UserUploadedFile;
  ERTMember: boolean;
}

const FileInfoButton = ({ file, ERTMember }: FileInfoButtonProps) => {
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();
  const { permissions } = useAuth();

  const [triggerGetFile] = useGetFileMutation();

  const downloadFile = async (fileId: number) => {
    showLoadingSpinner("Downloading");

    await triggerGetFile(fileId);

    hideLoadingSpinner();
  };

  const [triggerRemoveFile] = useRemoveFileMutation();

  const isPdf = file.file_mime_type === "application/pdf";

  const canDelete =
    ERTMember ||
    permissions.role_type === "NIAP" ||
    permissions.role_type === "Validator";

  return (
    <Row
      className="d-flex justify-content-left align-items-start"
      key={file?.file_id}
    >
      <Col>
        <FontAwesomeIcon
          icon={isPdf ? faFilePdf : faFileLines}
          color={isPdf ? "red" : "gray"}
          size="xl"
        />
      </Col>

      <Col md={8}>
        <Button
          style={{ overflowWrap: "break-word" }}
          className="text-start m-0 p-0"
          variant="link"
          onClick={() => downloadFile(file.file_id)}
        >
          {`${file.file_display_name ?? file.file_label ?? file.file_new_name ?? file.file_name}`}
        </Button>

        <p className="mb-0 ms-3">
          {file.file_new_name ? file.file_new_name : file.file_name}
        </p>

        <p className="mb-0 ms-3">
          Attached by {file?.uploaded_by?.first_name}{" "}
          {file?.uploaded_by?.last_name} on{" "}
          {file?.uploaded_on &&
            moment.utc(file?.uploaded_on).format("YYYY.MM.DD [at] HHmm")}
        </p>
      </Col>

      {canDelete && (
        <Col>
          <span
            style={{ float: "right", cursor: "pointer" }}
            className="mx-2"
            onClick={() => triggerRemoveFile(file.file_id)}
          >
            X
          </span>
        </Col>
      )}
    </Row>
  );
};
export default FileInfoButton;
