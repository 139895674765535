import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import qs from "qs";
import { useRef, useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

import TechnicalCommunitiesForm from "../TechnicalCommunitiesForm";

const TechnicalCommunitiesTable = () => {
  const tableRef = useRef();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTC, setSelectedTC] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { authToken } = useAuth();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const handleOpenEditModal = (event, tc) => {
    setSelectedTC(tc);
    setShowEditModal(true);
  };

  const handleCloseEditModal = (event) => {
    setSelectedTC({});
    setShowEditModal(false);
  };

  const columns = [
    { title: "Name", field: "community_name" },
    { title: "Alias", field: "contact_email" },
    { title: "Website", field: "url" },
    {
      title: "ITC",
      field: "is_international",
      render: (rowData) => (rowData?.is_international ? "Yes" : "No"),
      defaultSort: "desc",
    },
    {
      title: "Actions",
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          key="bottom"
          rootClose
          overlay={
            <Popover>
              <Popover.Body>
                <Button
                  variant="link"
                  onClick={(e) => handleOpenEditModal(e, rowData)}
                >
                  Edit
                </Button>
                {/* <br />
                    <Button variant='link'>Delete</Button> */}
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
            <span className="visually-hidden">Actions</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const options = {
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <TechnicalCommunitiesForm
        show={showEditModal}
        onHide={handleCloseEditModal}
        tc={selectedTC}
        refetch={() => setRefresh(!refresh)}
      />
      <ResponsiveMaterialTable
        title={"Technical Communities"}
        actions={[
          {
            icon: () => (
              <>
                <AddIcon />
                <span style={{ fontSize: 16 }}>Add New</span>
              </>
            ),
            tooltip: "New Technical Community",
            onClick: (e) => {
              handleOpenEditModal(e, {});
            },
            isFreeAction: true,
          },
        ]}
        columns={columns}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}tech/communities/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        tableRef={tableRef}
        options={options}
      />
    </Container>
  );
};

export default TechnicalCommunitiesTable;
