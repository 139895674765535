import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import CreateFolder from "../CreateFolder";
import DocumentTreeTable from "../DocumentTreeTable";
import TreeFilter from "../Filter";
import UploadFile from "../UploadFile";

const DocumentTree = ({ page }) => {
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [update, setUpdate] = useState(0);
  const [filterOpen, setFilterOpen] = useState(true);
  const [filters, setFilters] = useState({});
  const [alert, setAlert] = useState({});

  useEffect(() => {
    if (window.innerWidth < 768) {
      setFilterOpen(false);
    }
  }, []);

  const handleOpenFileModal = () => {
    setShowFileModal(true);
  };

  const handleCloseFileModal = () => {
    setShowFileModal(false);
  };

  const handleOpenFolderModal = () => {
    setShowFolderModal(true);
  };

  const handleCloseFolderModal = () => {
    setShowFolderModal(false);
  };

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <Container fluid className="border border-secondary mt-3 pt-2">
      <Row className=" mt-2 mb-2">
        <Col xs={12} md={8} lg={9} className="d-flex align-items-center">
          <h2>Community File Area</h2>
        </Col>
        <Col
          xs={12}
          md={4}
          lg={3}
          className="mt-3 mt-md-0 d-md-flex justify-content-md-end"
        >
          <div className="d-grid gap-2 d-md-block">
            <Button
              variant="secondary"
              className="me-md-2"
              onClick={handleOpenFileModal}
            >
              <AddIcon />
              &nbsp; File Upload
            </Button>
            <Button variant="secondary" onClick={handleOpenFolderModal}>
              <AddIcon />
              &nbsp; Create Folder(s)
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          {alert.message && (
            <Alert variant={alert.variant} dismissible>
              {alert.message}
            </Alert>
          )}
        </Col>
        <Col
          sm={12}
          md={3}
          className="d-flex justify-content-end align-content-center"
        >
          <Button
            variant="link rounded-pill"
            style={{ backgroundColor: "#dedede", maxHeight: 40 }}
            onClick={changeFilter}
          >
            {filterOpen && (
              <>
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
                &nbsp; Close Filter &nbsp;
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
              </>
            )}
            {!filterOpen && (
              <>
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
                &nbsp; Open Filter &nbsp;
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
          <DocumentTreeTable
            currentPage={page}
            updateFolder={setCurrentFolder}
            currentFolder={currentFolder}
            update={update}
            sideFilters={filters}
          />
        </Col>
        {filterOpen && (
          <Col xs={10} md={3}>
            <TreeFilter
              filters={filters}
              setFilters={setFilters}
              currentPage={page}
            />
          </Col>
        )}
      </Row>
      <CreateFolder
        show={showFolderModal}
        handleClose={handleCloseFolderModal}
        currentFolder={currentFolder}
        setUpdate={setUpdate}
        currentPage={page}
        setAlert={setAlert}
      />
      <UploadFile
        show={showFileModal}
        handleClose={handleCloseFileModal}
        currentFolder={currentFolder}
        type={page}
        setUpdate={setUpdate}
        setAlert={setAlert}
      />
    </Container>
  );
};

export default DocumentTree;
