import { Paper } from "@mui/material";
import { DataGrid, type DataGridProps } from "@mui/x-data-grid";

import CustomDataGridToolbar from "./Toolbar/CustomDataGridToolbar";
// This is a shallow wrapper of the Mui DataGrid
// to allow consistent default styling throughout the app

interface DefaultDataGridProps extends DataGridProps {
  title?: string;
  showToolbar?: boolean;
  refetch?: () => void;
}

const DefaultDataGrid = ({
  rows,
  columns,
  title,
  showToolbar = true,
  sx,
  refetch,
  ...props
}: DefaultDataGridProps) => {
  return (
    <Paper
      elevation={1}
      sx={{
        overflowX: "auto",
        margin: "0 auto",
        padding: 0,
      }}
    >
      <DataGrid
        disableColumnMenu
        disableDensitySelector
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: () =>
            showToolbar && (
              <CustomDataGridToolbar title={title} refetch={refetch} />
            ),
        }}
        slotProps={{
          toolbar: {
            title,
          },
        }}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "auto",
          ".MuiDataGrid-cell": {
            padding: "1em",
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          ".MuiDataGrid-iconButtonContainer > .MuiIconButton-root:hover:hover":
            {
              backgroundColor: "inherit",
            },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#f0f0f0", // Light gray for odd rows
          },
          ".MuiTablePagination-toolbar > p": {
            marginBottom: "initial",
          },
          ".MuiDataGrid-columnHeaderTitle": {
            lineHeight: "1.25em",
            padding: ".5em",
          },
          ...sx,
        }}
        {...props}
      />
    </Paper>
  );
};
export default DefaultDataGrid;
