import { ThemeProvider, createTheme } from "@mui/material";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-dom";
import { Outlet, useLocation } from "react-router-dom";

import { useCheckMaintenanceBannerQuery } from "api/app/app.api";
import LoadingSpinner from "components/UI/LoadingSpinner/LoadingSpinner";
import { useAuth } from "hooks/useAuth";

import AppLogout from "./components/AppLogout";
import DailyDeployBanner from "./components/DailyDeployBanner";
import Footer from "./components/Footer/Footer.screen";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import ScrollToTop from "./components/ScrollToTop";

import "./App.css";

const searchClient = algoliasearch(
  "31P4SHL05P",
  "3e9d823d46542e1e805f9f12cce0ade1",
);

const defaultReactTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(var(--bs-primary-rgb))",
    },
    secondary: {
      main: "rgb(var(--bs-secondary-rgb))",
    },
    success: {
      main: "rgb(var(--bs-success-rgb))",
    },
    info: {
      main: "rgb(var(--bs-info-rgb))",
    },
    warning: {
      main: "rgb(var(--bs-warning-rgb))",
    },
    // TODO: look more into this https://mui.com/material-ui/customization/theming/#custom-variables
    // @ts-ignore
    danger: {
      main: "rgb(var(--bs-danger-rgb))",
    },
    light: {
      main: "rgb(var(--bs-light-rgb))",
    },
    dark: {
      main: "rgb(var(--bs-dark-rgb))",
    },
  },
});

function App() {
  const { oldAuthToken } = useAuth();

  const location = useLocation();
  const onLoginScreen = location.pathname.includes("login");
  const { data: bannerEnabled = false } = useCheckMaintenanceBannerQuery();
  const shouldShowBanner = bannerEnabled && !onLoginScreen;

  return (
    <div className={oldAuthToken ? "border border-5 border-danger" : ""}>
      <InstantSearch searchClient={searchClient} indexName="test_HBD">
        <ThemeProvider theme={defaultReactTheme}>
          <LoadingSpinner />

          <AppLogout>
            {shouldShowBanner && <DailyDeployBanner />}

            <ScrollToTop />

            <div className="header">
              <NavigationBar />
            </div>

            <Outlet />

            <Footer />
          </AppLogout>
        </ThemeProvider>
      </InstantSearch>
    </div>
  );
}

export default App;
