import { Route, Routes } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import UnauthorizedView from "../UI/UnauthorizedView";

import ECRCommentsTable from "./ECRCommentsTable";
import ECRDetails from "./ECRDetails";

export default function ECRManager() {
  const { permissions } = useAuth();

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "ECR Comments",
    )?.read
  ) {
    <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<ECRCommentsTable />} />
      <Route path="/:vid" element={<ECRCommentsTable />} />
      <Route path="details/:id" element={<ECRDetails />} />
    </Routes>
  );
}
