import { useEffect } from "react";
import { Container } from "react-bootstrap";

import Subheader from "components/Subheader";

import AssuranceActivityReport from "./PublicationPDFs/CCEVSGuidance/AssuranceActivityReportingGuidance.pdf";
import CCInTheCloud from "./PublicationPDFs/CCEVSGuidance/CCInTheCloud.pdf";
import CICOPdf from "./PublicationPDFs/CCEVSGuidance/CICO.pdf";
import EntropyDocumentation_V1 from "./PublicationPDFs/CCEVSGuidance/EntropyDocumentation_v1.pdf";
import EntropyDocumentation_V2 from "./PublicationPDFs/CCEVSGuidance/EntropyDocumentation_v2.pdf";
import ESMPositionStatement from "./PublicationPDFs/CCEVSGuidance/ESMPositionStatement.pdf";
import HCDEval from "./PublicationPDFs/CCEVSGuidance/HCDEval.pdf";
import MobileDeviceFundamentals from "./PublicationPDFs/CCEVSGuidance/MobileDeviceFundamentals.pdf";
import NoPPExists from "./PublicationPDFs/CCEVSGuidance/NoPPExists.pdf";
import PPReqs from "./PublicationPDFs/CCEVSGuidance/PPReqs.pdf";
import TheRelaOfNIAP from "./PublicationPDFs/CCEVSGuidance/TheRelaOfNIAP.pdf";
import LabDocument from "./PublicationPDFs/SchemePublications/LabDocument.pdf";
import NISTCCTesting from "./PublicationPDFs/SchemePublications/NISTCCTesting.pdf";
import NISTProcedures from "./PublicationPDFs/SchemePublications/NISTProcedures.pdf";
import SchemePub1 from "./PublicationPDFs/SchemePublications/scheme-pub-1.pdf";
import SchemePub2 from "./PublicationPDFs/SchemePublications/scheme-pub-2.pdf";
import SchemePub3 from "./PublicationPDFs/SchemePublications/scheme-pub-3.pdf";
import SchemePub4 from "./PublicationPDFs/SchemePublications/scheme-pub-4.pdf";
import SchemePub5 from "./PublicationPDFs/SchemePublications/scheme-pub-5.pdf";
import SchemePub6 from "./PublicationPDFs/SchemePublications/scheme-pub-6.pdf";

export default function PublicationsPage() {
  useEffect(() => {
    document.title = "NIAP - Publications";
  }, []);

  return (
    <>
      <Subheader pageName={"Publications"} />
      <Container className="mb-5 py-3">
        <div className="p-4 border-bottom border-dark">
          <h4 className="text-uppercase fw-bold pb-3">
            RESOURCES -{" "}
            <span className="text-primary text-uppercase">PUBLICATIONS</span>
          </h4>
          <h5 className="text-uppercase fw-bold pb-3">
            <span className="text-primary text-uppercase">
              SCHEME PUBLICATIONS
            </span>
          </h5>
          <p>
            The CCEVS will communicate to sponsors of evaluations, testing
            laboratories, government agencies, and the general public through a
            series of technical and administrative publications. The flagship
            documents in the series are Publication #1; Organization, Management
            and Concept of Operations; and Publication #2; Quality Manual and
            Standard Operating Procedures
          </p>
          <p>
            Other publications provide guidance to validators, guidance to CCEVS
            approved Common Criteria Testing Laboratories, guidance to sponsors,
            and assurance continuity: guidance for maintenance and
            re-evaluation. Comments on any scheme publication can be forwarded
            to the CCEVS using the following email address: niap@niap-ccevs.org.
          </p>
          <ul className="clickable text-primary mx-3">
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub1}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #1 Common Criteria Evaluation and Validation
                  Scheme - Organization, Management and Concept of Operations,
                  Version 5.0
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub2}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #2 Common Criteria Evaluation and Validation
                  Scheme - Quality Manual and Standard Operating Procedures,
                  Version 5.0
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub3}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #3 Common Criteria Evaluation and Validation
                  Scheme - Guidance to Validators, Version 4.0
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub4}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #4 Common Criteria Evaluation and Validation
                  Scheme - Guidance to CCEVS Approved Common Criteria Testing
                  Laboratories, Version 4.0
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub5}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #5 Common Criteria Evaluation and Validation
                  Scheme - Guidance to Sponsors, Version 4.0
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={SchemePub6}
                  target="_blank"
                  rel="noreferrer"
                >
                  Publication #6 Common Criteria Evaluation and Validation
                  Scheme - Assurance Continuity: Guidance for Maintenance and
                  Re-evaluation, Version 3.0
                </a>
              </strong>
            </li>
          </ul>
          <p>NIST Publications:</p>
          <ul className="clickable text-primary mx-3">
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={NISTProcedures}
                  target="_blank"
                  rel="noreferrer"
                >
                  NIST Publications NIST Handbook 150, Procedures and General
                  Requirements
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={NISTCCTesting}
                  target="_blank"
                  rel="noreferrer"
                >
                  NIST Handbook 150-20, Information Technology Security
                  Testing-Common Criteria
                </a>
              </strong>
            </li>
          </ul>
          <ul className="clickable text-primary mx-3">
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={LabDocument}
                  target="_blank"
                  rel="noreferrer"
                >
                  NVLAP LAB BULLETIN NUMBER: LB-82-2014: Revision of the NVLAP
                  Common Criteria Testing Laboratory Accreditation Program
                  Handbook, NIST Handbook 150-20
                </a>
              </strong>
            </li>
          </ul>
        </div>
        <div className="p-4 border-bottom border-dark">
          <h5 className="text-primary text-uppercase fw-bold pb-3">
            CCEVS GUIDANCE DOCUMENTS
          </h5>
          <p>The CCEVS has issued the following guidance:</p>
          <ul className="clickable text-primary mx-3">
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={CCInTheCloud}
                  target="_blank"
                  rel="noreferrer"
                >
                  Position Statement regarding the CC in the Cloud Working Group
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={HCDEval}
                  target="_blank"
                  rel="noreferrer"
                >
                  Interim Guidance for Evaluation of Self-Encrypting Drives for
                  the Hard Copy Device Protection Profile
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={PPReqs}
                  target="_blank"
                  rel="noreferrer"
                >
                  Guidance on Protection Profile Requirements: Objective to
                  Mandatory
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={NoPPExists}
                  target="_blank"
                  rel="noreferrer"
                >
                  Guidelines for When No PP Exists
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={EntropyDocumentation_V1}
                  target="_blank"
                  rel="noreferrer"
                >
                  Entropy Documentation and Assessment Clarification (Release 1)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={EntropyDocumentation_V2}
                  target="_blank"
                  rel="noreferrer"
                >
                  Entropy Documentation and Assessment Clarification (Release 2)
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={AssuranceActivityReport}
                  target="_blank"
                  rel="noreferrer"
                >
                  Assurance Activity Reporting Guidance
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={ESMPositionStatement}
                  target="_blank"
                  rel="noreferrer"
                >
                  Position Statement regarding the CC evaluation of Enterprise
                  Security Management Products
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={TheRelaOfNIAP}
                  target="_blank"
                  rel="noreferrer"
                >
                  Relationship of NIAP Archived Products to CNSSP-11
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={MobileDeviceFundamentals}
                  target="_blank"
                  rel="noreferrer"
                >
                  Mobile Device Fundamentals PP Equivalency Considerations
                </a>
              </strong>
            </li>
            <li className="mb-1">
              <strong>
                <a
                  className="remove-underline"
                  href={CICOPdf}
                  target="_blank"
                  rel="noreferrer"
                >
                  Check-In Check-Out (CICO) Guidance
                </a>
              </strong>
            </li>
          </ul>
        </div>
        <div className="p-4">
          <h5 className="text-primary text-uppercase fw-bold pb-3">
            TECHNICAL DECISIONS REGARDING PROTECTION PROFILES
          </h5>
          <p>The CCEVS has issued the following guidance:</p>
          <ul className="clickable text-primary mx-3">
            <li className="mb-1">
              <strong>
                <a className="remove-underline" href="./technical-decisions">
                  Technical Decisions regarding Protection Profiles
                </a>
              </strong>
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
}
