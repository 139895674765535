import { baseApi } from "api/BaseApi";

import { PublicTechnicalDecision } from "./technicalDecisions.types";

const PUBLIC_TD_BASE_QUERY = "technical-decision";

const publicTechnicalDecisionApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicTd: build.query<PublicTechnicalDecision, string>({
      query: (id) => `${PUBLIC_TD_BASE_QUERY}/frontend_by_identifier/${id}/`,
    }),
  }),

  overrideExisting: false,
});

export const { useGetPublicTdQuery } = publicTechnicalDecisionApi;
