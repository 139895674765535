import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import LabgramDetails from "./LabgramDetails";
import LabgramsTable from "./LabgramsTable";

export default function LabgramsManager() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<LabgramsTable />} />
      <Route path="details/:id" element={<LabgramDetails />} />
    </Routes>
  );
}
