import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Row, Col, Button, Alert } from "react-bootstrap";
import { Outlet, useNavigate } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

export default function CheckPermEARManager() {
  const [ertMember, setERTMember] = useState();

  const { authToken, currentUser, permissions } = useAuth();

  useEffect(() => {
    if (currentUser) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${currentUser.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setERTMember(response.data.is_member);
        })
        .catch((err) =>
          console.log("unable to get member's virtual teams", err),
        );
    }
  }, [currentUser]);

  if (permissions?.role_type === "NIAP") {
    return <EARManager />;
  } else if (
    permissions?.role_type === "Validator" &&
    permissions?.role_permissions?.find(
      (permission) => permission.area === "EAR Reviews",
    )?.read
  ) {
    return <EARManager />;
  } else if (permissions?.role_type === "SME" && ertMember === true) {
    return <EARManager />;
  } else if (
    permissions?.role_type === "Lab" ||
    (permissions?.role_type !== "NIAP" &&
      permissions?.role_type !== "Validator" &&
      !permissions?.role_permissions?.find(
        (permission) => permission.area === "EAR Reviews",
      )?.read &&
      permissions?.role_type !== "SME" &&
      ertMember === false)
  ) {
    return <UnauthorizedView />;
  }

  return <div></div>;
}

function EARManager() {
  const [jumpEar, setJumpEar] = useState({});
  const [alert, setAlert] = useState({});
  const navigate = useNavigate();
  const { authToken } = useAuth();

  const handleChange = (e) => {
    setJumpEar({ ...jumpEar, [e.target.name]: e.target.value });
  };

  const jump = (e) => {
    e.preventDefault();
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${jumpEar.earid}`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => {
        setAlert({});
        navigate(`/community/ear-reviews/details/${response.data.earid}`);
      })
      .catch((error) => {
        console.log(error.response.data.detail);
        setAlert({
          message: `Error completing Request: ${error.response.data.detail}`,
          variant: "warning",
        });
      });
  };

  return (
    <>
      {alert?.message && (
        <Alert
          variant={alert?.variant}
          dismissible
          onClose={() => setAlert({})}
        >
          {alert?.message}
        </Alert>
      )}
      <Form onSubmit={jump}>
        <Row className="justify-content-end mb-2">
          <Col xs={12} md={3}>
            <Form.Group controlId="jumpbox">
              <Form.Label className="visually-hidden">Jump to EAR</Form.Label>
              <Form.Control
                name="earid"
                onChange={handleChange}
                placeholder="Jump to EAR"
              />
            </Form.Group>
          </Col>
          <Col xs={12} md="auto">
            <Button type="submit" className="w-100 w-md-auto mt-2 mt-md-0">
              Jump
            </Button>
          </Col>
        </Row>
      </Form>

      <Outlet />
    </>
  );
}
