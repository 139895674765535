import { Tabs, Tab, Container } from "react-bootstrap";

import Subheader from "components/Subheader";

import IndexedTabContent from "./components/IndexedTabContentSite";
import siteData from "./data/sites.json";

export default function SiteMap() {
  return (
    <>
      <Subheader pageName={"Site Map"} />
      <Container className="mb-5 py-3">
        <div className="p-4 border-bottom border-dark">
          <h4 className="text-uppercase fw-bold pb-3">
            Home -{" "}
            <span className="text-uppercase fw-bold text-primary">
              Site Index
            </span>
          </h4>
        </div>
        <div className="p-4">
          <h4 className="text-uppercase fw-bold pb-3">Browse the A-Z Index</h4>
          <Tabs defaultActiveKey="A" id="defintions-tabs" className="m-3">
            <Tab className="pb-5 text-primary" eventKey="A" title="A">
              <IndexedTabContent index="A" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="B" title="B">
              <IndexedTabContent index="B" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="C" title="C">
              <IndexedTabContent index="C" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="D" title="D">
              <IndexedTabContent index="D" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="E" title="E">
              <IndexedTabContent index="E" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="F" title="F">
              <IndexedTabContent index="F" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="G" title="G">
              <IndexedTabContent index="G" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="H" title="H">
              <IndexedTabContent index="H" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="I" title="I">
              <IndexedTabContent index="I" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="J" title="J">
              <IndexedTabContent index="J" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="K" title="K">
              <IndexedTabContent index="K" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="L" title="L">
              <IndexedTabContent index="L" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="M" title="M">
              <IndexedTabContent index="M" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="N" title="N">
              <IndexedTabContent index="N" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="O" title="O">
              <IndexedTabContent index="O" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="P" title="P">
              <IndexedTabContent index="P" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="Q" title="Q">
              <IndexedTabContent index="Q" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="R" title="R">
              <IndexedTabContent index="R" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="S" title="S">
              <IndexedTabContent index="S" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="T" title="T">
              <IndexedTabContent index="T" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="U" title="U">
              <IndexedTabContent index="U" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="V" title="V">
              <IndexedTabContent index="V" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="W" title="W">
              <IndexedTabContent index="W" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="X" title="X">
              <IndexedTabContent index="X" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="Y" title="Y">
              <IndexedTabContent index="Y" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="Z" title="Z">
              <IndexedTabContent index="Z" data={siteData} />
            </Tab>
            <Tab className="pb-5" eventKey="All" title="All">
              <IndexedTabContent index="All" data={siteData} />
            </Tab>
          </Tabs>
        </div>
      </Container>
    </>
  );
}
