import { type GridColDef } from "@mui/x-data-grid";

import { ProjectPp } from "api/memberPortal/project/protectionProfiles/protectionProfile.types";

import VidLink from "./VidLink";

export const associatedEvaluationsColumns: GridColDef<ProjectPp>[] = [
  {
    field: "product__v_id",
    headerName: "VID",
    flex: 1,
    renderCell: ({ row }) => (
      <VidLink vid={row.product__v_id} productId={row.product__product_id} />
    ),
    cellClassName: "p-0 d-flex justify-content-center align-items-center",
  },
  { field: "product__assigned_lab__name", headerName: "CCTL", flex: 2 },
  { field: "product__status", headerName: "Status", flex: 2 },
  { field: "product__product_name", headerName: "Evaluation Project", flex: 4 },
];
