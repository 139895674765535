import axios from "axios";
import moment from "moment";
import { useEffect, useState, useRef, useCallback } from "react";
import { Modal, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { useCookies } from "react-cookie";

import { PRODUCT_STATUSES } from "api/memberPortal/project/product/productStatuses.type";
import { updateProductProgressPoint } from "components/UI/Functions/progressFunctions";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

import CAVPCertificateFormSection from "./CAVPCertificateFormSection";
import EarFormSection from "./EarFormSection";
import EvaluationInfoFormSection from "./EvaluationInfoFormSection";
import EvaluationTeamFormSection from "./EvaluationTeamFormSection";
import FileFormSection from "./FileFormSection";
import NiapCCEVSListingFormSection from "./NiapCCEVSListingFormSection";
import ProductTypeFormSection from "./ProductTypeFormSection";
import ProjectedDatesFormSection from "./ProjectedDatesFormSection";
import RejectionRationaleSection from "./RejectionRationaleSection";
import VendorLabInfoFormSection from "./VendorLabInfoFormSection";

import "./ProductForm.css";

export default function ProductForm({
  show,
  handleCloseModal,
  product,
  switchForms,
  draft,
  reload,
}) {
  const [cookies] = useCookies(); // NOTE: this is used in the form below. Evaluate for removal
  const [editedProduct, setEditedProduct] = useState({ ...product });

  const [validated, setValidated] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [prodDescValid, setProdDescValid] = useState(true);
  const [vendorValid, setVendorValid] = useState(true);
  const [ppValid, setPpValid] = useState(true);
  const [justificationValid, setJustificationValid] = useState(true);
  const [files, setFiles] = useState([]);
  const [editedFileLabel, setEditedFileLabel] = useState("");
  const [ears, setEars] = useState([{ id: 1, files: [], approved: false }]);
  const [evalTeam, setEvalTeam] = useState([]);
  const [alert, setAlert] = useState({ show: false });
  const [isResubmission, setIsResubmission] = useState(false);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [productPps, setProductPps] = useState([]);

  const isSwitch = useRef(false);
  const saveDraft = useRef(false);

  const { authToken, csrfToken, currentUser } = useAuth();

  const fetchExistingEarsAndFiles = () => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/ears_and_files_by_product_id/?product_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setEars(response.data);
        })
        .catch((error) => console.log(error));
    }
  };

  const loadCSFCComponents = () => {
    if (product?.csfc_eligible) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}cs/proj/get_cscomponentproj_by_pid/?pid=${product.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setSelectedComponents(
            response.data?.map((component) => {
              return { ...component, active: true };
            }),
          );
        })
        .catch((error) => console.log(error));
    }
  };

  const fetchProductProtectionProfiles = useCallback(() => {
    setEditedProduct({ product_name: product.product_name, ...editedProduct });
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_all_projpp_by_product_id?product_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setProductPps(
            response.data?.map((pp) => ({
              value: pp?.pp__pp_id,
              label: pp?.pp_shared_name,
            })),
          );
        })
        .catch((error) => console.log(error));
    }
  }, [product]);

  useEffect(() => {
    fetchProductProtectionProfiles();
  }, [fetchProductProtectionProfiles, reload]);

  useEffect(() => {
    setValidated(false);
    setProdDescValid(true);
    setVendorValid(true);
    setPpValid(true);
    setJustificationValid(true);
    setNameValid(false);
    setFiles([]);

    if (!draft) {
      setEditedProduct({});
      setSelectedComponents([]);
    } else {
      loadCSFCComponents();
    }
    setAlert({ show: false });
    fetchExistingEarsAndFiles();
  }, [show]);

  const handleChange = async (e) => {
    //from ProductTypeFormSection
    if (e.target.name === "is_resubmission") {
      if (e.target.value === "true" || e.target.value === true) {
        setIsResubmission(true);
      } else {
        setIsResubmission(false);
      }
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: JSON.parse(e.target.value),
      });
      //from CAVPCertificateFormSection
    } else if (e.target.name === "nist_required") {
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: e.target.checked,
      });
      // from EarFormSection
    } else if (e.target.name === "ear_required") {
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: e.target.checked,
      });
      // from EarFormSection
    } else if (e.target.name === "ear_previously_submitted") {
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: e.target.checked,
      });
    } else if (
      e.target.name === "file_display_name" ||
      e.target.name === "file_label"
    ) {
      setEditedFileLabel(e.target.value);
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: e.target.value,
        file_label: e.target.value,
      });
    } else if (e.target.name === "file") {
      const newFiles = files ? files : [];
      newFiles.push({
        file_type: "product",
        file_source_type: "Check In",
        file_display_name: editedProduct.file_display_name,
        uploaded_on: getISOCurrentDate(),
        file_name: e.target.files[0].name,
        file_label: editedProduct.file_label,
        active: true,
        file: e.target.files[0],
        private: editedProduct.file_public_status === "Public" ? false : true,
      });
      setFiles([...newFiles]);
    } else if (e.target.name === "csfc_components") {
      const value = e.target.value;
      if (e.target.checked) {
        setSelectedComponents([...selectedComponents, { value, active: true }]);
      } else {
        setSelectedComponents(
          selectedComponents.map((option) => {
            if (option?.component === value || option?.value === value) {
              return { ...option, active: false };
            }
            return option;
          }),
        );
      }
    } else if (e.target.name === "tech_type") {
      let values = [];
      for (let i = 0; i < e.target.selectedOptions.length; i++) {
        values.push(e.target.selectedOptions[i].value);
      }
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: values?.join(","),
      });
    } else {
      setEditedProduct({
        ...editedProduct,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddEar = async (productID) => {
    let earData = new FormData();
    if (ears.length > 0) {
      for (let ear in ears) {
        earData = new FormData();
        for (let f in ears[ear].files) {
          let file = ears[ear].files[f];
          if (!file.file_id) {
            // added this because we don't want a file that already exists to be added again
            earData.append("attachments_file", file?.file);
            earData.append("attachments_source_type", file?.file_source_type);
            earData.append("attachments_uploaded", file?.uploaded_on);
            earData.append("file_label", file?.file_label);
            earData.append("file_display_name", file?.file_display_name);
          }
        }

        earData.append("pid", productID);
        earData.append("creator", `${currentUser?.id}`);
        earData.append("editor", `${currentUser?.id}`);
        earData.append("entrydate", getISOCurrentDate());
        earData.append("status", "Draft");
        earData.append("apriori", ears[ear].apriori ?? `${false}`);
        if (product.ear_required) {
          earData.append("reqd", `${true}`);
        }

        if (!product.ear_required) {
          earData.append("just", product.no_ear_justification);
          earData.append("reqd", `${false}`);
        }

        if (ears[ear]?.earid) {
          // if ear already exists, update it
          await axios
            .put(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${ears[ear].earid}/`,
              earData,
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                  "Content-Type": "multipart/form-data",
                },
              },
            )
            .then(() => {
              console.log("ear updated");
            })
            .catch((error) =>
              console.log("Unable to submit new product: " + error),
            );
        } else {
          await axios
            .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/`, earData, {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              console.log("new ear added");
            })
            .catch((error) =>
              console.log("Unable to submit new product: " + error),
            );
        }
      }
    }
  };

  const getValue = (key) => {
    if (editedProduct[key] !== undefined && editedProduct[key] !== "") {
      return editedProduct[key];
    } else if (key === "protection_profile") {
      return productPps;
    }
    return product[key];
  };

  const testFieldsRenderAlerts = () => {
    let fieldsValid = true;

    const isOneLeadEvaluator = evalTeam.some(
      (member) => member.role === "Lead Evaluator",
    );
    if (!isOneLeadEvaluator) {
      setAlert({
        show: true,
        text: "Please Assign a Lead Evaluator to Evaluation Team.",
      });
      fieldsValid = false;
    }

    const hasSecurityTarget = files?.some(
      (f) => f.file_display_name === "Security Target",
    );
    if (!hasSecurityTarget) {
      setAlert({
        show: true,
        text: "Please Attach a Security Target File.",
      });
      fieldsValid = false;
    }

    const earRequired = getValue("ear_required");
    const noEarJustification = getValue("no_ear_justification");
    if (!earRequired && !noEarJustification) {
      setJustificationValid(false);
      fieldsValid = false;
    }

    if (
      (earRequired === "false" || earRequired === false) &&
      !noEarJustification
    ) {
      setJustificationValid(false);
      fieldsValid = false;
    }

    const productDescription = getValue("product_description");
    if (!productDescription) {
      setProdDescValid(false);
      fieldsValid = false;
    }

    const vendorId = getValue("vendor_id");
    if (!vendorId) {
      setVendorValid(false);
      fieldsValid = false;
    }

    const protectionProfile = getValue("protection_profile");
    if (protectionProfile?.length === 0) {
      setPpValid(false);
      fieldsValid = false;
    }

    const productName = getValue("product_name");

    if (!productName) {
      setNameValid(false);
      fieldsValid = false;
    }

    if (!fieldsValid) {
      return false;
    } else {
      setJustificationValid(true);
      setProdDescValid(true);
      setVendorValid(true);
      setPpValid(true);
      setNameValid(true);
      return true;
    }
  };

  const handleChangeForm = (e, type) => {
    saveDraft.draft = true;
    isSwitch.switch = true;
    handleSubmit(e, type);
  };

  const handleSubmit = async (e, formType) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!saveDraft.draft) {
      testFieldsRenderAlerts();
    }
    if (
      !saveDraft.draft &&
      (form.checkValidity() === false || testFieldsRenderAlerts() === false)
    ) {
      e.stopPropagation();
    } else {
      const submittedData = new FormData();

      if (files.length > 0) {
        for (let f in files) {
          if (!files[f].file_mime_type) {
            submittedData.append("attachments_file", files[f].file);
            submittedData.append(
              "attachments_source_type",
              files[f].file_source_type,
            );
            submittedData.append(
              "attachments_type",
              files[f].file_display_name,
            );
            submittedData.append("attachments_uploaded", files[f].uploaded_on);
            submittedData.append("file_label", files[f].file_label);
            submittedData.append("private", files[f].private);
          }
        }
      }

      //handling django default fields
      submittedData.append(
        "is_resubmission",
        editedProduct.is_resubmission || "",
      );
      if (editedProduct.is_resubmission === true) {
        submittedData.append("publish_status", "Unpublished");
      }
      submittedData.append("ear_required", editedProduct.ear_required || "");
      submittedData.append(
        "ear_previously_submitted",
        editedProduct.ear_previously_submitted || "",
      );
      submittedData.append("nist_required", editedProduct.nist_required || "");
      submittedData.append("sync_required", editedProduct.sync_required || "");
      submittedData.append(
        "post_certificate",
        editedProduct.post_certificate || "",
      );

      for (let key in editedProduct) {
        if (
          key === "file" ||
          key === "file_type" ||
          key === "kicked_off_date"
        ) {
          delete editedProduct[key];
        } else if (key === "nist_completion") {
          submittedData.append(
            "nist_completion",
            new Date(editedProduct[key]).toJSON(),
          );
        } else if (key === "st_evaluation_date") {
          submittedData.append(
            "st_evaluation_date",
            new Date(editedProduct[key]).toJSON(),
          );
        } else if (key === "test_ready_date") {
          submittedData.append(
            "test_ready_date",
            new Date(editedProduct[key]).toJSON(),
          );
        } else if (key === "testing_complete_date") {
          submittedData.append(
            "testing_complete_date",
            new Date(editedProduct[key]).toJSON(),
          );
        } else if (key === "evaluator_checkout_date") {
          submittedData.append(
            "evaluator_checkout_date",
            new Date(editedProduct[key]).toJSON(),
          );
        } else if (key === "previous_vid") {
          editedProduct[key].forEach((vid) => {
            submittedData.append("previous_vid", vid);
          });
        } else if (key === "protection_profile") {
          editedProduct[key].forEach((pp) => {
            submittedData.append("protection_profile", pp);
          });
        } else {
          if (editedProduct[key]) {
            submittedData.append(key, editedProduct[key]);
          }
        }
      }
      if (selectedComponents?.length > 0) {
        selectedComponents.forEach((component) => {
          if (!component.cscid && component?.active === true) {
            submittedData.append("csfc_components", component.value);
          } else if (component.cscid && component?.active === false) {
            submittedData.append("csfc_components", component.component);
          }
        });
      }

      if (
        !editedProduct.nist_completion &&
        editedProduct?.nist_required === true
      ) {
        submittedData.append(
          "nist_completion",
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ).toISOString(),
        );
      }
      if (!product.product_id) {
        if (!editedProduct.st_evaluation_date) {
          submittedData.append(
            "st_evaluation_date",
            moment().add(7, "days").utc().startOf("day").toISOString(),
          );
        }
        if (!editedProduct.test_ready_date) {
          submittedData.append(
            "test_ready_date",
            moment().add(14, "days").utc().startOf("day").toISOString(),
          );
        }
        if (!editedProduct.testing_complete_date) {
          submittedData.append(
            "testing_complete_date",
            moment().add(16, "days").utc().startOf("day").toISOString(),
          );
        }
        if (!editedProduct.evaluator_checkout_date) {
          submittedData.append(
            "evaluator_checkout_date",
            moment().add(21, "days").utc().startOf("day").toISOString(),
          );
        }
        submittedData.append("submission_type", "Evaluation");
      }

      if (saveDraft.draft) {
        submittedData.append("status", PRODUCT_STATUSES.DRAFT);
        submittedData.append(
          "draft_date",
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ).toISOString(),
        );
      } else {
        submittedData.append("status", PRODUCT_STATUSES.PRE_PROPOSAL);
        submittedData.append("pre_eval_status", "Proposed");
        submittedData.append(
          "check_in_date",
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ).toISOString(),
        );
      }

      if (!product.product_id) {
        await axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            if (response.data?.status === PRODUCT_STATUSES.DRAFT) {
              let message = `Product VID${response.data.v_id} has been saved and exists as a Draft.`;
              handleCloseModal(response.data, message, "success");
            }
            if (ears?.length > 0) {
              handleAddEar(response.data.product_id);
            }
            if (isSwitch.switch) {
              isSwitch.switch = false;
              saveDraft.draft = false;
              switchForms(response.data, formType);
            } else {
              saveDraft.draft = false;
              setValidated(false);
              handleCloseModal();
            }
          })
          .catch((error) =>
            console.log("Unable to submit new product: ", error),
          );
      } else {
        await axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product.product_id}/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then(async (response) => {
            if (isSwitch.switch) {
              isSwitch.switch = false;
              saveDraft.draft = false;
              switchForms(response.data, formType);
            } else {
              saveDraft.draft = false;
              setValidated(false);
              if (ears?.length > 0) {
                handleAddEar(response.data.product_id);
              }
              let message = "";
              if (response.data?.status === PRODUCT_STATUSES.PRE_PROPOSAL) {
                await updateProductProgressPoint(
                  response.data.product_id,
                  1,
                  "In Progress",
                  cookies,
                );
                message = `New Product VID${response.data.v_id} sent to Vendor for Review!`;
              } else {
                message = `New Draft Product VID${response.data.v_id} has been created.`;
              }
              handleCloseModal(response.data, message, "success");
            }
          })
          .catch((error) =>
            console.log("Unable to submit edited product: " + error),
          );
      }
    }
    setValidated(true);
  };

  const productMissingName =
    !editedProduct.product_name && !product.product_name;

  const nameHasBeenCleared = false; // temporarily forcing to allow drafts
  // product.product_name && !editedProduct.product_name;

  const productMissingVendor = !product.vendor_id && !editedProduct.vendor_id;

  const shouldPreventSubmit =
    productMissingName || nameHasBeenCleared || productMissingVendor;

  return (
    <Modal size="lg" show={show} onHide={() => handleCloseModal()}>
      <Modal.Header closeButton className="border-0">
        {product.v_id && (
          <Modal.Title>
            EDIT EVALUATION PROJECT:{" "}
            <span className="text-primary">VID {product.v_id}</span>
          </Modal.Title>
        )}
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <h5 className="fw-bold text-center">NIAP Check-In Form</h5>
            <h5 className="fw-bold text-center">
              Product Evaluations Claiming Exact Compliance with a NIAP Approved
              PP {product?.product_id ? "VID " + product.v_id : ""}
            </h5>
            <Col className="mt-4">
              <Row>
                {!product?.product_id && (
                  <>
                    <p className="fst-italic mb-3">
                      PLEASE FILL OUT ALL INFORMATION BELOW
                    </p>
                    <p className="mb-3">
                      Select if this product is a new product submission, or if
                      this product is a resubmission from a previously
                      terminated/withdrawn product.
                    </p>
                  </>
                )}
              </Row>
              <RejectionRationaleSection product={product} />
              <ProductTypeFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                reload={reload}
              />
              <EvaluationInfoFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                setProdDescValid={setProdDescValid}
                nameValid={nameValid}
                prodDescValid={prodDescValid}
                ppValid={ppValid}
                productPps={productPps}
              />
              <NiapCCEVSListingFormSection
                isResubmission={isResubmission}
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
              />
              <FileFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                reload={reload}
                setFiles={setFiles}
                files={files}
                editedFileLabel={editedFileLabel}
              />
              <ProjectedDatesFormSection
                product={product}
                handleChange={handleChange}
              />
              <EarFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                reload={fetchExistingEarsAndFiles}
                ears={ears}
                setEars={setEars}
                setJustificationValid={setJustificationValid}
                justificationValid={justificationValid}
              />
              <VendorLabInfoFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
                handleChangeForm={handleChangeForm}
                vendorValid={vendorValid}
                selectedComponents={selectedComponents}
              />
              <EvaluationTeamFormSection
                product={product}
                handleChangeForm={handleChangeForm}
                setEvalTeam={setEvalTeam}
                reload={reload}
                disableButton={shouldPreventSubmit}
              />
              <CAVPCertificateFormSection
                product={product}
                editedProduct={editedProduct}
                handleChange={handleChange}
              />
            </Col>
          </Row>
          <Alert
            show={alert.show}
            variant="danger"
            dismissible
            onClose={() => setAlert({ show: false })}
          >
            {alert.text}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-end">
            <Col sm={12} md="auto" className="mb-2 mb-md-0">
              <Button
                variant="outline-primary"
                onClick={() => handleCloseModal()}
                className="w-100" // Full width on small screens
              >
                Cancel
              </Button>
            </Col>

            <Col sm={12} md="auto" className="mb-2 mb-md-0">
              <Button
                variant="warning"
                type="submit"
                onClick={() => {
                  saveDraft.draft = true;
                }}
                disabled={shouldPreventSubmit}
                className="w-100" // Full width on small screens
              >
                {product.product_id ? "Update Draft" : "Save as Draft"}
              </Button>
            </Col>

            <Col sm={12} md="auto">
              <Button
                variant="success"
                type="submit"
                className="w-100" // Ensures button spans the full width on small screens
                disabled={shouldPreventSubmit}
              >
                {product.product_id && !draft
                  ? "Update"
                  : "Submit to Vendor for Signing"}
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
      <small className="fw-bold p-3" align="right">
        NIAP CCEVS Form F8002a, Version 1.0 (Nov 13)
      </small>
    </Modal>
  );
}
