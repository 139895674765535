import { baseApi } from "api/BaseApi";

import { ProgressPoint } from "./progressPoints.types";

const PROGRESS_POINTS_BASE_URL = "project/progress";

const progressPointsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProgressPointsByPid: build.query<ProgressPoint[], string>({
      query: (pid) => ({
        url: `${PROGRESS_POINTS_BASE_URL}/progress_points_by_pid/`,
        params: { pid },
      }),
      transformResponse: (response: ProgressPoint[]) => {
        return response.sort((a, b) => {
          return a.progress_point > b.progress_point ? 1 : -1;
        });
      },
    }),
  }),

  overrideExisting: false,
});

export const { useGetProgressPointsByPidQuery } = progressPointsApi;
