import { createBrowserRouter } from "react-router-dom";

import App from "App";
import AuthenticatedRoute from "routing/AuthenticatedRoute";

import authenticatedRoutes from "./authenticated/authenticated.routes";
import publicRoutes from "./public/public.routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      ...publicRoutes,
      {
        path: "/community",
        element: <AuthenticatedRoute />,
        children: authenticatedRoutes,
      },
    ],
  },
]);

export default router;
