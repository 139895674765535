import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import axios from "axios";
import FileDownload from "js-file-download";
import moment from "moment";
import { useState } from "react";
import { Alert, Container, Row, Col, Button } from "react-bootstrap";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

import CctlCheckOutModal from "./CctlCheckOutModal";
import CertificateModal from "./CertificateModal";

export default function PCLReviewDetails({
  product,
  labSignedCheckout,
  validationReport,
  certificate,
  refetch,
}) {
  const [showModal, setShowModal] = useState(false);
  const [showCertificate, setShowCertificate] = useState(false);
  const [alert, setAlert] = useState({});
  const { authToken } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch(() => {
        console.log("Error");
      });
  };

  return (
    <Container className="mt-4 mb-4">
      <Row>
        {alert.message && (
          <Alert
            variant={alert.variant}
            onClose={() => setAlert({})}
            dismissible
          >
            {alert.message}
          </Alert>
        )}
        <Col>
          <Row className="mb-3">
            <Col>
              <h5 className="text-primary fw-bold">PCL REVIEW DETAILS</h5>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="m-auto" sm={12} md={6}>
              <p className="m-0">
                Validation Report Number:{" "}
                {validationReport.file_label
                  ? `CCEVS-VR-VID${product?.v_id}-${new Date(validationReport?.uploaded_on).getFullYear()}`
                  : `CCEVS-VR-VID${product?.v_id}-YYYY`}
                <FileDownloadIcon
                  className="mx-2 clickable"
                  style={{ color: "var(--bs-green)" }}
                  onClick={() => downloadFile(validationReport)}
                />
              </p>
            </Col>
            <Col className="m-2">
              <Row>
                {Object.keys(labSignedCheckout).length > 0 ? (
                  <>
                    <Col
                      xs={12}
                      sm={6}
                      md={2}
                      className="mt-1"
                      style={{ width: "fit-content" }}
                    >
                      <p className="m-0">Release of Information:</p>
                    </Col>
                    <Col
                      lg={1}
                      className="p-2 m-auto clickable"
                      style={{ width: "fit-content" }}
                    >
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        color="red"
                        size="xl"
                        onClick={() => {
                          downloadFile(labSignedCheckout);
                        }}
                      />
                    </Col>
                    <Col
                      lg={1}
                      className="p-2 m-auto"
                      style={{ width: "fit-content" }}
                    >
                      <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
                    </Col>
                    <Col className="m-auto p-0" style={{ textAlign: "center" }}>
                      <p className="m-0 small-font">
                        Approved By {labSignedCheckout?.uploaded_by?.first_name}{" "}
                        {labSignedCheckout?.uploaded_by?.last_name} <br />
                        {moment(labSignedCheckout?.uploaded_on).format(
                          "MM/DD/YYYY",
                        )}
                      </p>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      xs={12}
                      sm={6}
                      md={2}
                      className="mt-1"
                      style={{ width: "fit-content" }}
                    >
                      <p className="m-0">Release of Information:</p>
                    </Col>
                    <Col xs={12} sm={6} md={4}>
                      <Button
                        variant="warning"
                        className="rounded-pill small-font"
                        onClick={() => setShowModal(true)}
                      >
                        Review and Sign
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
          <Row className="mx-3">
            <Col className="m-2">
              <Row>
                {product?.labs_cc_draft_cert_verifier !== null ? (
                  <>
                    <Col
                      xs={12}
                      sm={6}
                      md={2}
                      className="mt-1"
                      style={{ width: "fit-content" }}
                    >
                      <p className="m-0">Draft CC Certificate:</p>
                    </Col>
                    <Col
                      lg={1}
                      className="px-2 m-auto"
                      style={{ width: "fit-content" }}
                    >
                      <CheckCircleIcon style={{ color: "var(--bs-green)" }} />
                    </Col>
                    <Col className="p-0 d-flex" style={{ textAlign: "center" }}>
                      <p className="m-0 small-font">
                        Approved By{" "}
                        {product?.labs_cc_draft_cert_verifier?.first_name}{" "}
                        {product?.labs_cc_draft_cert_verifier?.last_name} <br />
                        {moment(product?.labs_cc_draft_cert_date).format(
                          "MM/DD/YYYY",
                        )}
                      </p>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col
                      xs={12}
                      sm={6}
                      className="mt-2"
                      style={{ width: "fit-content" }}
                    >
                      <p className="m-0">Draft CC Certificate:</p>
                    </Col>
                    <Col xs={12} sm={6}>
                      <Button
                        variant="warning"
                        className="rounded-pill small-font"
                        onClick={() => setShowCertificate(true)}
                      >
                        Review Draft Certificate
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <CctlCheckOutModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        product={product}
        validation_report={validationReport}
        certificate={certificate?.certificate}
        setAlert={setAlert}
      />
      <CertificateModal
        show={showCertificate}
        handleClose={() => setShowCertificate(false)}
        product={product}
        refetch={refetch}
      />
    </Container>
  );
}
