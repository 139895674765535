import { baseApi } from "api/BaseApi";
import { Option } from "api/common/types/common.types";

import {
  AssociatedProductPayload,
  GroupedPp,
  ProjectPp,
  ProtectionProfile,
  SetAssociationResponse,
} from "./protectionProfile.types";

const PROJECT_PPS_BASE_URL = "project/protection-profiles";
const PROTECTION_PROFILES_BASE_URL = "protection-profile";

const protectionProfilesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllProjectPp: build.query<ProjectPp[], number | string>({
      query: (ppId) => ({
        url: `${PROJECT_PPS_BASE_URL}/get_all_project_pp/`,
        params: {
          pp: ppId,
        },
      }),
      providesTags: ["ASSOCIATED_PRODUCTS"],
    }),

    getAllProjectPpByProductId: build.query<ProjectPp[], number | string>({
      query: (product_id) => ({
        url: `${PROJECT_PPS_BASE_URL}/get_all_projpp_by_product_id/`,
        params: {
          product_id,
        },
      }),
      // providesTags: ["ASSOCIATED_PRODUCTS"], // not sure if we need tags here...
    }),

    getProtectionProfile: build.query<ProtectionProfile, number | string>({
      query: (ppId) => `${PROTECTION_PROFILES_BASE_URL}/${ppId}`,
    }),

    associateProduct: build.mutation<
      SetAssociationResponse[],
      AssociatedProductPayload
    >({
      query: (body) => ({
        url: `${PROJECT_PPS_BASE_URL}/set_association/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ASSOCIATED_PRODUCTS"],
    }),

    getGroupedPp: build.query<GroupedPp[], void>({
      query: () => `${PROJECT_PPS_BASE_URL}/get_grouped_pp/`,
    }),

    ppDropdownOptions: build.query<Option[], void>({
      query: () => `${PROTECTION_PROFILES_BASE_URL}/get_pp_for_dropdown/`,
    }),
  }),

  overrideExisting: false,
});

export const {
  useAssociateProductMutation,
  useGetAllProjectPpQuery,
  useGetAllProjectPpByProductIdQuery,
  useGetProtectionProfileQuery,
  useGetGroupedPpQuery,
  usePpDropdownOptionsQuery,
} = protectionProfilesApi;
