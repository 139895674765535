import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import ReactSelect from "components/ReactSelect";
import { useAuth } from "hooks/useAuth";

export default function AccountForm({
  show,
  handleCloseModal,
  account,
  roles,
  companies,
  reload,
}) {
  const [editedAccount, setEditedAccount] = useState({});
  const [errors, setErrors] = useState({});
  const [validateAlert, setValidateAlert] = useState({ message: "", type: "" });
  const [schemes, setSchemes] = useState([]);
  const [affils, setAffils] = useState([]);
  const [validated, setValidated] = useState(false);
  const [userRoleValid, setUserRoleValid] = useState(true);
  const [companyValid, setCompanyValid] = useState(true);
  const [schemeValid, setSchemeValid] = useState(true);
  const [affiliationValid, setAffiliationValid] = useState(true);
  const { authToken, csrfToken, currentUser } = useAuth();

  useEffect(() => {
    setEditedAccount({
      ...account,
      user_role: account?.user_role?.role_id,
      company: account?.company?.org_id,
      scheme: account?.scheme?.sid,
      account_group: account?.account_group?.group_id,
      affiliation: account?.affiliation?.afid,
    });
  }, [account]);

  useEffect(() => {
    if (show) {
      setValidated(false);
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/scheme/get_all_schemes/`,
        )
        .then((response) => {
          let schemes = response.data?.map((scheme) => {
            return {
              label: scheme?.cid__name,
              value: scheme?.sid,
            };
          });
          setSchemes(schemes);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_active_affils/`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          let affils = response.data?.map((affil) => {
            return {
              label: `${affil?.svc} (${affil?.abbrev})`,
              value: affil?.afid,
            };
          });
          setAffils(affils);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [show]);

  const roleOptions = roles?.map((role) => {
    return { value: role.role_id, label: role.role_name };
  });

  const companyOptions = companies?.map((company) => {
    return { value: company.org_id, label: company.name };
  });

  const handleChange = (e) => {
    if (
      e.target.name === "phone_number" ||
      e.target.name === "fax" ||
      e.target.name === "alt_phone"
    ) {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setEditedAccount({ ...editedAccount, [e.target.name]: e.target.value });
  };

  const checkPhones = () => {
    if (
      editedAccount?.phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        editedAccount?.phone_number,
      )
    ) {
      return false;
    } else if (
      editedAccount?.fax_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        editedAccount?.fax,
      )
    ) {
      return false;
    } else if (
      editedAccount?.alt_phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        editedAccount?.alt_phone,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkReactSelects = () => {
    let isValid = true;
    if (!editedAccount?.user_role) {
      setUserRoleValid(false);
      isValid = false;
    } else {
      setUserRoleValid(true);
    }
    if (!editedAccount?.company) {
      setCompanyValid(false);
      isValid = false;
    } else {
      setCompanyValid(true);
    }
    if (!editedAccount?.scheme) {
      setSchemeValid(false);
      isValid = false;
    } else {
      setSchemeValid(true);
    }
    if (!editedAccount?.affiliation) {
      setAffiliationValid(false);
      isValid = false;
    } else {
      setAffiliationValid(true);
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    setValidateAlert({ message: "", type: "" });
    checkReactSelects();
    if (
      form.checkValidity() === false ||
      checkPhones() === false ||
      checkReactSelects() === false
    ) {
      e.stopPropagation();
    } else {
      if (!editedAccount.id) {
        let data = {
          ...editedAccount,
          creator: currentUser?.id,
        };
        if (!editedAccount.username) {
          data["username"] = `${editedAccount?.first_name
            ?.charAt(0)
            ?.toLowerCase()}${editedAccount?.last_name?.toLowerCase()}`;
        }
        await axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/`,
            data,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            reload(response.data.id);
            handleCloseModal();
            setValidated(false);
          })
          .catch((error) => {
            if (
              error.response.data?.non_field_errors?.includes(
                "Username has already been used",
              )
            ) {
              setValidateAlert({
                message: "Username has already been used.",
                type: "danger",
              });
            } else if (
              error.response.data?.non_field_errors?.includes(
                "Email has already been used",
              )
            ) {
              setValidateAlert({
                message: "Email has already been used.",
                type: "danger",
              });
            }
          });
      } else if (editedAccount.id) {
        await axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${editedAccount.id}/`,
            {
              ...editedAccount,
              creator: editedAccount.creator?.id,
              editor: currentUser?.id,
            },
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then((response) => {
            reload(response.data.id);
            handleCloseModal();
            setValidated(false);
          })
          .catch((error) => console.log("Unable to update account: " + error));
      }
    }
    setValidated(true);
  };

  const getDefaultUsername = () => {
    if (editedAccount?.username) return editedAccount.username;
    else if (account?.username) return account.username;
    else if (
      editedAccount?.first_name &&
      editedAccount?.last_name &&
      !editedAccount?.username
    ) {
      return `${editedAccount?.first_name
        ?.charAt(0)
        ?.toLowerCase()}${editedAccount?.last_name?.toLowerCase()}`;
    } else {
      return "";
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleCloseModal}>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="create-account-form"
      >
        <Modal.Header closeButton>
          <Modal.Title>{account?.id ? "Edit" : "Create"} Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {!account?.id && (
            <Form.Group controlId="password">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                required
                type="password"
                name="password"
                defaultValue={account.pass3word}
                onChange={handleChange}
                placeholder="Password"
                className="mb-2"
              />
              <PasswordStrengthBar password={editedAccount.password} minLength="8" />
            </Form.Group>
          )} */}
          <Form.Group className="mb-3" controlId="first_name">
            <Form.Label>First Name*</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              defaultValue={account.first_name}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="last_name">
            <Form.Label>Last Name*</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              defaultValue={account.last_name}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>
              Username*{" "}
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id="button-tooltip">
                    <Container>
                      <Row className="mt-2">
                        <p className="fw-bold">
                          The name used to log into this application.
                        </p>
                      </Row>
                      <Row>
                        <p>
                          It is recommended that you use the SID when available
                          and follow and alternative format for all others.
                        </p>
                        <ul>
                          <li>SID, if available.</li>
                          <p>--OR--</p>
                          <li>
                            Initial letter of the first name plus the full last
                            name, serializing the username to avoid any
                            duplicates (If name is Jane Doe, examples of
                            usernames could be jdoe, jdoe1, jdoe2, etc.).
                          </li>
                        </ul>
                        <p>
                          The system will help you create a username and will
                          notify you of any existing duplicates.
                        </p>
                      </Row>
                    </Container>
                  </Tooltip>
                }
              >
                <InfoIcon className="text-bright-navy" />
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="username"
              value={getDefaultUsername()}
              onChange={handleChange}
              placeholder="Username"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="user_role">
            <Form.Label>User Role*</Form.Label>
            <ReactSelect
              handleChange={handleChange}
              options={roleOptions}
              newObj={editedAccount}
              name="user_role"
              isValid={userRoleValid}
              editComponent={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="affiliation">
            <Form.Label>Affiliation*</Form.Label>
            <div data-testid="affiliation-select">
              <ReactSelect
                handleChange={handleChange}
                options={affils}
                newObj={editedAccount}
                name="affiliation"
                isValid={affiliationValid}
                editComponent={true}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="company">
            <Form.Label>Company*</Form.Label>
            <ReactSelect
              handleChange={handleChange}
              options={companyOptions}
              newObj={editedAccount}
              name="company"
              isValid={companyValid}
              editComponent={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="scheme">
            <Form.Label>Scheme*</Form.Label>
            <ReactSelect
              handleChange={handleChange}
              options={schemes}
              newObj={editedAccount}
              name="scheme"
              isValid={schemeValid}
              editComponent={true}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              name="email"
              defaultValue={account.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="alt_email">
            <Form.Label>Alternate Email</Form.Label>
            <Form.Control
              type="email"
              name="alt_email"
              defaultValue={account.alt_email}
              onChange={handleChange}
              placeholder="Alternate Email"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone_number">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              name="phone_number"
              defaultValue={account.phone_number}
              onChange={handleChange}
              placeholder="111-111-1111"
              isInvalid={errors.phone_number}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="fax">
            <Form.Label>Fax Phone</Form.Label>
            <Form.Control
              type="tel"
              name="fax"
              defaultValue={account.fax}
              onChange={handleChange}
              placeholder="111-111-1111"
              isInvalid={errors.fax}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="alt_phone">
            <Form.Label>Alternate Phone</Form.Label>
            <Form.Control
              type="tel"
              name="alt_phone"
              defaultValue={account.alt_phone}
              onChange={handleChange}
              placeholder="111-111-1111"
              isInvalid={errors.alt_phone}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            {validateAlert.message && (
              <Row>
                <Col>
                  <Alert
                    variant={validateAlert.type}
                    dismissible
                    onClose={() => setValidateAlert({ message: "", type: "" })}
                  >
                    {validateAlert.message}
                  </Alert>
                </Col>
              </Row>
            )}
            <Row className="d-flex justify-content-end">
              <Col sm={2}>
                <Button
                  variant="light"
                  className="w-auto"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={2}>
                <Button variant="warning" className="w-auto" type="submit">
                  {account?.id ? "Update" : "Submit"}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
