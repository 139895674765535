import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

import AccountFilterOrgs from "./AccountFilterOrgs";

import "./AccountManager.css";

export default function AccountFilter({
  show,
  handleCloseFiltering,
  filters,
  setFilters,
}) {
  const [roles, setRoles] = useState([
    { name: "NIAP", isChecked: false },
    { name: "Vendor", isChecked: false },
    { name: "Validator", isChecked: false },
    { name: "Lab", isChecked: false },
  ]);
  const [labs, setLabs] = useState([]);
  const { authToken, csrfToken, permissions } = useAuth();

  const handleClearFilters = () => {
    setFilters({});
  };

  const handleChange = (e) => {
    const newFilters = filters;
    if (e.target.type === "button") {
      if (!newFilters.org_id) {
        newFilters["org_id"] = [];
      }
      const selectedLabs = newFilters.org_id;
      const exists = selectedLabs.indexOf(e.target.textContent);
      if (exists > -1) {
        selectedLabs.splice(exists, 1);
      } else {
        selectedLabs.push(e.target.textContent);
      }
      newFilters["org_id"] = selectedLabs;
    } else if (e.target.type === "checkbox") {
      if (!newFilters.role_id) {
        newFilters["role_id"] = [];
      }
      const selectedRoles = newFilters.role_id;
      const exists = selectedRoles.indexOf(e.target.id);
      if (exists > -1) {
        selectedRoles.splice(exists, 1);
      } else {
        selectedRoles.push(e.target.id);
      }
      newFilters["role_id"] = selectedRoles;
    }
    setFilters({ ...newFilters });
  };
  const handleLabs = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        const convertOrgs = response.data.results.map((org) => ({
          name: org.name,
          isChecked: false,
          orgType: org.org_type,
        }));
        setLabs(convertOrgs);
      })
      .catch((error) => console.log("Unable to get Organizations for", error));
  };
  useEffect(() => {
    handleLabs();
  }, []);

  return (
    <Modal size="md" show={show} onHide={handleCloseFiltering}>
      <Modal.Header closeButton>
        <Modal.Title className="me-2">Edit Filter</Modal.Title>
        <Button
          variant="link"
          onClick={handleClearFilters}
          className="text-primary text-decoration-underline"
        >
          Clear all Filters
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {permissions?.role_permissions?.find(
            (permission) => permission.area === "Accounts",
          )?.read && (
            <Form.Group className="mb-3 p-2">
              <Form.Label htmlFor="role">
                <strong>Role Checkbox Selector</strong>
              </Form.Label>
              <div className="container">
                {roles.map((role, key) => {
                  return (
                    <Form.Check
                      key={key}
                      type="checkbox"
                      id={role.name}
                      className="role-checkbox"
                      checked={filters.role_id?.indexOf(role.name) > -1}
                      onChange={handleChange}
                      label={role.name}
                    />
                  );
                })}
              </div>
            </Form.Group>
          )}
          {roles
            .filter((role) => filters.role_id?.indexOf(role.name) > -1)
            .map((role) => {
              return (
                <AccountFilterOrgs
                  role={role.name}
                  labs={labs}
                  filters={filters}
                  handleChange={handleChange}
                />
              );
            })}
        </Form>
      </Modal.Body>
    </Modal>
  );
}
