import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import {
  Tab,
  Tabs,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Button,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import EarReviewsTable from "features/memberPortal/EAR/components/EarReviewsTable/EarReviewsTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import TQTable from "../TRRTTableModule/TQTable";

export default function SMETabs() {
  const [earCount, setEarCount] = useState(0);
  const [earRefetch, setEarRefetch] = useState(false);
  const [trrtCount, setTrrtCount] = useState(0);
  const [tds, setTDs] = useState([]);
  const [TRRTMember, setTRRTMember] = useState(false);
  const [ERTMember, setERTMember] = useState(false);
  const trrtRef = useRef();
  const { authToken, currentUser, permissions } = useAuth();

  const refetchEars = () => {
    setEarRefetch(!earRefetch);
  };

  const getTRRTs = () => {
    trrtRef.current.onQueryChange();
  };

  const fetchTDs = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => setTDs(response.data))
      .catch((error) => console.log(error));
  };

  const getUserTeamInfo = useCallback(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member/?user=${currentUser.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setERTMember(true);
          refetchEars();
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));

    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_trrt_team_member/?user=${currentUser.id}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        if (response?.data?.is_member === true) {
          setTRRTMember(true);
          getTRRTs();
        }
      })
      .catch((err) => console.log("unable to get member's virtual teams", err));
  }, []);

  useEffect(() => {
    const loadTeamInfo = async () => {
      getUserTeamInfo();
    };
    loadTeamInfo();
    if (
      permissions?.role_permissions?.find(
        (permission) => permission.area === "Technical Decisions",
      )?.read
    ) {
      fetchTDs();
    }
  }, []);

  useInterval(() => {
    if (
      permissions?.role_permissions?.find(
        (permission) => permission.area === "Technical Decisions",
      )?.read
    ) {
      fetchTDs();
    }
    refetchEars();
    getTRRTs();
    trrtRef.current.onQueryChange();
  }, 300000); //5 min

  const tdColumns = [
    {
      title: "Item No.",
      field: "td_id",
      render: (rowData) => (
        <Link
          to={`technical-decisions/details/${rowData.identifier}`}
          className="text-decoration-underline"
        >
          {rowData?.identifier}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    {
      title: "Protection Profiles",
      field: "protection_profile",
      render: (rowData) =>
        rowData?.protection_profile?.length > 0 ? (
          <ListGroup>
            {rowData?.protection_profile?.map((projpp, idx) => (
              <OverlayTrigger
                key={idx}
                placement="top"
                overlay={<Tooltip>{projpp.pp_name}</Tooltip>}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp.id}`}
                  className="text-decoration-underline"
                >
                  <ListGroup.Item disabled>
                    {projpp.pp_common_name}
                  </ListGroup.Item>
                </Link>
              </OverlayTrigger>
            ))}
          </ListGroup>
        ) : (
          <>No Protection Profiles</>
        ),
    },
    { title: "Status", field: "status" },
    {
      title: "Last Modified",
      render: (rowData) => moment.utc(rowData.modified_on).format("MM/DD/YYYY"),
    },
    {
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <Link
                  to={`technical-decisions/details/${rowData.identifier}`}
                  relative="route"
                  className="text-decoration-none"
                >
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <VisibilityIcon color="action" /> View
                  </Button>
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <EditIcon color="action" /> Edit
                  </Button>
                  <Button variant="link" className="m-1 p-0 btn-link">
                    <DeleteIcon color="action" /> Delete
                  </Button>
                </Link>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const options = {
    sorting: true,
    columnsButton: true,
    pageSize: 20,
  };

  return (
    <Card>
      {TRRTMember || ERTMember ? (
        <Card.Body>
          <Tabs
            defaultActiveKey={ERTMember ? "escalated-ears" : "TRRTs"}
            className="mb-3"
          >
            {ERTMember && (
              <Tab
                eventKey="escalated-ears"
                title={
                  <div className="d-flex align-items-center">
                    Escalated Ears
                    <h4 className="text-primary ms-2 my-0 fw-bold">
                      {earCount}
                    </h4>
                  </div>
                }
              >
                <EarReviewsTable
                  refetch={refetchEars}
                  setEarCount={setEarCount}
                />
              </Tab>
            )}
            {permissions?.role_permissions?.find(
              (permission) => permission.area === "Technical Decisions",
            )?.read && (
              <Tab
                eventKey="TDs"
                title={
                  <div className="d-flex align-items-center">
                    TD's
                    <h4 className="text-primary ms-2 my-0 fw-bold">
                      {tds?.count}
                    </h4>
                  </div>
                }
              >
                <ResponsiveMaterialTable
                  title="Technical Decisions"
                  options={options}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      // Extract the necessary information from the query object
                      const {
                        page,
                        pageSize,
                        search,
                        filters,
                        orderBy,
                        orderDirection,
                      } = query;

                      let newFilters = filters.map((filter) => {
                        let value = "";
                        if (Array.isArray(filter.value)) {
                          value = filter.value;
                        } else {
                          value = filter.value.replace(/['"]+/g, "");
                        }
                        return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                      });
                      const params = {
                        offset: page * pageSize,
                        limit: pageSize,
                        search: search,
                        filters: newFilters,
                        orderBy: orderBy?.field,
                        orderDirection: orderDirection,
                      };
                      axios
                        .get(
                          `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                          {
                            withCredentials: true,
                            headers: {
                              Authorization: `Token ${authToken}`,
                            },
                          },
                        )
                        .then((response) => {
                          resolve({
                            data: response.data.results,
                            page: page,
                            totalCount: response.data.count,
                          });
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    })
                  }
                  columns={tdColumns}
                />
              </Tab>
            )}
            {TRRTMember && (
              <Tab
                eventKey="TRRTs"
                title={
                  <div className="d-flex align-items-center">
                    TRRTs
                    <h4 className="text-primary ms-2 my-0 fw-bold">
                      {trrtCount}
                    </h4>
                  </div>
                }
              >
                <TQTable
                  tableRef={trrtRef}
                  refetch={getTRRTs}
                  setTrrtCount={setTrrtCount}
                />
              </Tab>
            )}
          </Tabs>
        </Card.Body>
      ) : (
        <Card.Body>
          <p>Not a Member of a TRRT or ERT Team</p>
        </Card.Body>
      )}
    </Card>
  );
}
