// @ts-nocheck - we need to type the rest of these functions
import { TDateISO } from "api/common/types/datetime.types";

const moment = require("moment");
// Due to server timezone being in utc, we need to convert the dates to utc
const momentConvertUTC = (date) => moment.utc(date).startOf("day");

// Function is used to calculate the  days from start to end date
// NOTE: THIS FUNCTION IS ONLY USED IN ONE PLACE (ACMRTable) - evaluate if needed
//     or if we can convert to the one below
export const calculateDaysInProgress = (start, end) => {
  if (!start || !end) return 0;
  const startDate = momentConvertUTC(start);
  const endDate = momentConvertUTC(end);
  return endDate.diff(startDate, "days");
};

// Calculate days from project kickoff to today for projects that are active
// If project is no longer active, use the date of the project end based on status
export const calculateActiveDaysInProgress = (product) => {
  const { status, kicked_off_date } = product;

  if (!kicked_off_date) return 0;

  const endDateLookup = {
    Certified: product.validator_posting_date,
    Withdrawn: product.withdrawal_date,
    Terminated: product.termination_date,
    Archived: product.sunset_date,
    Finalizing: product.validator_posting_date,
  };

  const endDate = endDateLookup[status] || moment.utc();

  return calculateDaysInProgress(kicked_off_date, endDate);
};

// Function is used to calculate the Current date with the time offset needed
// Note: Due to our server being in UTC, time is converted 4 hours behind the EST to be post/put as UTC
// Example: 10:30am EST -> 6:30am EST to Server -> 10:30am UTC
export const getCurrentDate = () => {
  // Convert the current date to fake EST time (For UTC server)
  const now = new Date();
  const offset = now.getTimezoneOffset();
  now.setMinutes(now.getMinutes() - offset);
  return now;
};

// Function is used to get the Current date in ISO format
//   This is identical to the following snippet. Pasting here for searchability.
//   > new Date(new Date().setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString();
export const getISOCurrentDate = (): TDateISO => {
  const now = getCurrentDate();
  return now.toISOString() as TDateISO;
};

// Function is used to check if date is a weekend and if so, convert it to the next Monday
export const convertWeekend = (inputDate) => {
  let date, isMomentObj;

  // Check if the input is a Moment.js object
  if (moment.isMoment(inputDate)) {
    date = inputDate.clone().utc(); // Clone to avoid mutating the original Moment object
    isMomentObj = true;
  }
  // Check if the input is a native Date object
  else if (inputDate instanceof Date) {
    date = moment.utc(inputDate); // Convert Date to Moment in UTC
    isMomentObj = false;
  }
  // If the input is neither, throw an error
  else {
    return inputDate; // Return the input as is if it's not a valid date
  }

  const day = date.day(); // Get the day of the week (0 - Sunday, 6 - Saturday)

  // Adjust the date if it's a weekend
  if (day === 0) {
    // Sunday
    date.add(1, "days"); // Move to Monday
  } else if (day === 6) {
    // Saturday
    date.subtract(1, "days"); // Move to Friday
  }

  // Return the date in the same format as the input
  return isMomentObj ? date : date.toDate();
};

// function will return the current date in UTC without any time
export const getCurrentUTCDate = () => {
  return momentConvertUTC(moment()).format("YYYY-MM-DD");
};
