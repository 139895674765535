import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import ProjectFiles from "../../ProjectFiles";

import PPDetails from "./PPDetails/PPDetails";
import PPTable from "./PPTable";

export default function PPManager() {
  const { permissions } = useAuth();
  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Protection Profile",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<PPTable />} />
      <Route path="details/:id" element={<PPDetails />} />
      <Route path="details/:id/:type/files" element={<ProjectFiles />} />
    </Routes>
  );
}
