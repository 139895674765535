import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import PaginationComponent from "components/UI/Pagination/PaginationComponent";

export default function AccordionBody({ announcements, setOffset, count }) {
  const [currentPage, setCurrentPage] = useState(1);
  const announcementsPerPage = 5;
  const paginate = (pageNumber, pageNumberLength) =>
    pageNumber < 1 || pageNumber > pageNumberLength
      ? setCurrentPage(1)
      : setCurrentPage(pageNumber);

  useEffect(() => {
    const offsetPage = currentPage - 1;
    setOffset(offsetPage * announcementsPerPage);
  }, [currentPage]);

  return (
    <>
      {announcements.map((announcement, index) => (
        <div key={index} className="mx-3 border-bottom border-gray-700">
          <h6 className="mt-3">
            <strong>
              {announcement?.title} (
              {moment.utc(announcement.posted).format("DD MMMM YYYY")})
            </strong>
          </h6>
          <div className="mx-3 mt-3 mb-4">
            {announcement?.announcement && parse(announcement?.announcement)}
          </div>
        </div>
      ))}
      <Row>
        <Col className="d-flex justify-content-end mt-5">
          <PaginationComponent
            tabAlertsLength={count}
            alertsPerPage={announcementsPerPage}
            currentPage={currentPage}
            paginate={paginate}
          />
        </Col>
      </Row>
    </>
  );
}
