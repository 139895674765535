// NOTE: This file is a very lightly modified version of the default MUI GridToolbarColumnsButton
// The only functional change is removing the button label to match the MaterialTable styling
// Source code copied from below
// https://github.com/mui/mui-x/blob/v7.27.3/packages/x-data-grid/src/components/toolbar/GridToolbarColumnsButton.tsx

import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { type ButtonProps } from "@mui/material/Button";
import { type TooltipProps } from "@mui/material/Tooltip";
import { unstable_useId as useId } from "@mui/material/utils";
import {
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
  gridPreferencePanelStateSelector,
  GridPreferencePanelsValue,
} from "@mui/x-data-grid";
import { forwardRef } from "@mui/x-internals/forwardRef";
import * as React from "react";

interface CustomGridToolbarColumnsButtonProps {
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps?: {
    button?: Partial<ButtonProps>;
    tooltip?: Partial<TooltipProps>;
  };
}

const CustomGridToolbarColumnsButton = forwardRef<
  HTMLButtonElement,
  CustomGridToolbarColumnsButtonProps
>(function GridToolbarColumnsButton(props, ref) {
  const { slotProps = {} } = props;
  const buttonProps = slotProps.button || {};
  const tooltipProps = slotProps.tooltip || {};
  const columnButtonId = useId();
  const columnPanelId = useId();

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const preferencePanel = useGridSelector(
    apiRef,
    gridPreferencePanelStateSelector,
  );

  const showColumns = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (
      preferencePanel.open &&
      preferencePanel.openedPanelValue === GridPreferencePanelsValue.columns
    ) {
      apiRef.current.hidePreferences();
    } else {
      apiRef.current.showPreferences(
        GridPreferencePanelsValue.columns,
        columnPanelId,
        columnButtonId,
      );
    }

    buttonProps.onClick?.(event);
  };

  // Disable the button if the corresponding is disabled
  if (rootProps.disableColumnSelector) {
    return null;
  }

  const isOpen =
    preferencePanel.open && preferencePanel.panelId === columnPanelId;

  return (
    <rootProps.slots.baseTooltip
      title="Show Columns"
      enterDelay={1000}
      {...rootProps.slotProps?.baseTooltip}
      {...tooltipProps}
    >
      <rootProps.slots.baseIconButton
        id={columnButtonId}
        size="small"
        aria-label="Show Columns"
        aria-haspopup="menu"
        aria-expanded={isOpen}
        aria-controls={isOpen ? columnPanelId : undefined}
        {...rootProps.slotProps?.baseButton}
        {...buttonProps}
        onPointerUp={(event) => {
          if (preferencePanel.open) {
            event.stopPropagation();
          }
          buttonProps.onPointerUp?.(event);
        }}
        onClick={showColumns}
        ref={ref}
      >
        <ViewColumnIcon />
      </rootProps.slots.baseIconButton>
    </rootProps.slots.baseTooltip>
  );
});

export default CustomGridToolbarColumnsButton;
