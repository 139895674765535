import { Route, Routes } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import UnauthorizedView from "../../UI/UnauthorizedView";

import TechnicalQueriesTable from "./TechnicalQueriesTable";
import TRRTDetailsPage from "./TRRTDetailsPage";

export default function TechnicalQueryManager() {
  const { permissions } = useAuth();
  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Questions",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<TechnicalQueriesTable />} />
      <Route path="details/:id" element={<TRRTDetailsPage />} />
    </Routes>
  );
}
