import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Subheader from "components/Subheader";
import processImg from "images/NIAPValProcess.png";

import EvaluationSteps from "./components/EvaluationSteps";

export default function EvaluationProcessPage() {
  useEffect(() => {
    document.title = "NIAP - Evaluation Process";
  }, []);

  return (
    <>
      <Subheader pageName={"Evaluation Process"} />
      <Container fluid>
        <Row className="px-5">
          <Col>
            <Row className="mt-3">
              <h4 className="text-black fw-bold text-uppercase mb-3">
                About Us -{" "}
                <span className="text-bright-navy fw-bold text-uppercase">
                  Evaluation Process
                </span>
              </h4>
            </Row>
            <Row>
              <p>
                To begin the evaluation process, a product vendor chooses an
                approved Common Criteria Testing Lab (CCTL) to conduct the
                product evaluation against an applicable NIAP-approved{" "}
                <a href="/protectionprofiles">Protection Profile.</a>
                The vendor, with possible assistance from the lab, drafts the
                Security Target then provides the associated Target of
                Evaluation to the CCTL. The CCTL then proposes a new evaluation
                to NIAP. Once entered into evaluation, the CCTL evaluates the
                product with oversight, validation, and ultimate approval from
                NIAP. The information below provides detail of the
                inputs/outputs of the process. Upon successful completion, the
                product is posted to the NIAP{" "}
                <a href="/products">Product Compliant List</a> and the{" "}
                <a href="https://www.commoncriteriaportal.org/">
                  Common Criteria Portal
                </a>
                . The evaluation process can be completed in just 90 days but
                may take up to six months.
              </p>
            </Row>
            <Row className="mt-5 mb-5">
              <div className="d-flex justify-content-center">
                <img src={processImg} alt="process" width="70%" />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <EvaluationSteps />
    </>
  );
}
