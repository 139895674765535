import { Modal, Container, Row, Col } from "react-bootstrap";

import candidateCCTLImg from "images/cctl_page/candidateCCTL.png";

export default function CandidateCCTLBody({ show, setShowCandidateModal }) {
  const handleClose = () => {
    setShowCandidateModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={candidateCCTLImg}
                      alt="approved cctl image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Candidate (CCTLs)
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <p>
                  Candidate Common Criteria Testing Laboratories are IT security
                  testing laboratories that are currently in the process of
                  obtaining an accreditation by the NIST National Voluntary
                  Laboratory Accreditation Program (NVLAP). Additionally,
                  Candidate Common Criteria Testing Laboratories must
                  demonstrate their Common Criteria proficiency by performing an
                  actual common criteria evaluation of a product, under the
                  close supervision of the NIAP CCEVS. If your organization
                  wishes to become a CCTL please send a letter of intent to
                  pursue accreditation to niap@niap-ccevs.org.
                </p>
              </Row>
              <Row>
                <p>
                  The following laboratories are currently eligible to submit a
                  sponsor’s product for evaluation against the Common Criteria
                  for Information Technology Security Evaluation, International
                  Standard ISO/IEC 15408 to the NIAP CCEVS. Potential sponsors
                  are encouraged to meet with the Director NIAP CCEVS prior to
                  signing contractual documents with a Candidate Common Criteria
                  Testing Laboratory so that risk mitigation options can be
                  discussed and planned in the event that a Candidate Common
                  Criteria Testing Laboratory falls short of demonstrating their
                  proficiency. You may contact the NIAP CCEVS Office on
                  410-854-4458 or by email, niap@niap-ccevs.org.
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
