import axios from "axios";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import ReactSelect from "../ReactSelect";
import "./emailForm.css";

const EmailForm = () => {
  const [mailingLists, setMailingLists] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const { authToken, csrfToken, currentUser } = useAuth();

  const location = useLocation();

  useEffect(() => {
    getMailingLists();
    if (location.state && location.state.email) {
      setEmailData({ recipients: location.state.email, send_to_list: "No" });
      setShowBackButton(true);
    }
  }, [location.state]);

  const getMailingLists = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/mailing-list/get_all_mailing_list`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setMailingLists(
          response.data
            .filter((list) => list.active)
            ?.map((list) => {
              return { value: list.id, label: list.name };
            }),
        );
      })
      .catch(() => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (window.location.href?.includes("https://www.niap-ccevs.org/")) {
      const submittedData = new FormData();
      submittedData.append("sender", currentUser.id);
      for (let key in emailData) {
        if (key === "file" || key === "file_type") {
          delete emailData[key];
        } else if (key === "attachments") {
          emailData[key].forEach((attachment) => {
            for (let k in attachment) {
              submittedData.append(`${key}_${k}`, attachment[k]);
            }
          });
        } else if (key === "mailing_list") {
          emailData[key].forEach((item) => {
            submittedData.append(key, item);
          });
        } else {
          submittedData.append(key, emailData[key]);
        }
      }
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}mailer/`,
          submittedData,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          },
        )
        .then(() => {
          setEmailData({});
          setMailSent(true);
        })
        .catch((error) => {
          console.log("Error");
          console.log(error.response.data);
          setError(true);
        });
    } else {
      setError(true);
    }
  };

  const handleChange = (e) => {
    setMailSent(false);
    if (e.target.name === "file") {
      const attachments = emailData.attachments ? emailData.attachments : [];
      attachments.push({ type: emailData.file_type, file: e.target.files[0] });
      setEmailData({
        ...emailData,
        attachments: attachments,
        file: undefined,
        file_type: undefined,
      });
    } else {
      setEmailData({ ...emailData, [e.target.name]: e.target.value });
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setEmailData({ sender: currentUser.id, attachments: [] });
  };

  const removeAttachment = (idx) => {
    const attachments = emailData.attachments ? emailData.attachments : [];
    attachments.splice(idx, 1);
    setEmailData({ ...emailData, attachments: attachments });
  };

  const MailingLists = () => {
    return (
      <ReactSelect
        handleChange={handleChange}
        defaultValue={mailingLists.filter((list) =>
          emailData.mailing_list?.includes(list.value),
        )}
        options={mailingLists}
        newObj={emailData}
        name={"mailing_list"}
        isMulti={true}
      />
    );
  };

  return (
    <>
      <h1 className="mt-3">Send Message</h1>
      {showBackButton && (
        <Button
          variant="outline-primary"
          className="m-3"
          onClick={() => window.history.back()}
        >
          Back
        </Button>
      )}
      <Form onSubmit={handleSubmit} onReset={handleReset}>
        <Container fluid>
          <Row className="pt-2">
            <Col xl={12}>
              <Form.Group className="mb-4" controlId="from-email">
                <Form.Label className="small text-secondary">From*</Form.Label>
                <Form.Control
                  type="text"
                  name="from_email"
                  value={`${currentUser?.last_name}, ${currentUser?.first_name}: ${currentUser?.company?.name}`}
                  required={true}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xl={12}>
              <Form.Group className="mb-4 text-start" controlId="send-to-list">
                <Form.Label className="small text-secondary">
                  Send to List?*
                </Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="Yes"
                    name="send_to_list"
                    value="Yes"
                    required={true}
                    onChange={handleChange}
                    inline={true}
                    checked={emailData?.send_to_list === "Yes"}
                    id="send_to_list1"
                  />
                  <Form.Check
                    type="radio"
                    label="No"
                    name="send_to_list"
                    value="No"
                    required={true}
                    onChange={handleChange}
                    inline={true}
                    checked={emailData?.send_to_list === "No"}
                    id="send_to_list2"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          {emailData?.send_to_list === "No" && (
            <Row className="pt-2">
              <Col xl={12}>
                <Form.Group className="mb-4 text-start" controlId="to-emails">
                  <Form.Label className="small text-secondary">To*</Form.Label>
                  <br />
                  <small>List of emails, separated by ";"</small>
                  <Form.Control
                    type="text"
                    name="recipients"
                    value={emailData?.recipients ?? ""}
                    required={true}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
          {emailData?.send_to_list === "Yes" && (
            <Row className="pt-2">
              <Col xl={12}>
                <Form.Group className="mb-4 text-start" controlId="email-list">
                  <Form.Label className="small text-secondary">
                    {" "}
                    Mailing List*
                  </Form.Label>
                  <Form.Control as={MailingLists} name="mailing_list" />
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="pt-2">
            <Col xl={12}>
              <Form.Group className="mb-4 text-start" controlId="cc-emails">
                <Form.Label className="small text-secondary">CC</Form.Label>
                <br />
                <small>List of emails, separated by ";"</small>
                <Form.Control
                  type="text"
                  name="cc_recipients"
                  value={emailData?.cc_recipients ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xl={12}>
              <Form.Group className="mb-4 text-start" controlId="email-topic">
                <Form.Label className="small text-secondary">Topic*</Form.Label>
                <Form.Select
                  name="subject"
                  value={emailData?.subject ?? ""}
                  required={true}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="default">Default</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col xl={4} sm={12}>
              <Form.Group className="mb-4 text-start" controlId="file_type">
                <Form.Label className="small text-secondary">Type</Form.Label>
                <Form.Select
                  name="file_type"
                  value={emailData?.file_type ?? ""}
                  onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="ear_review">EAR Review</option>
                  <option value="security_target">Security Target</option>
                  <option value="product_information">
                    Product Information
                  </option>
                  {/* <option value="user_guide">User Guide</option> */}
                  <option value="other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xl={8} sm={12}>
              <Form.Group controlId="attach-file">
                <Form.Label className="small text-secondary">
                  Attach Files
                </Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  onChange={handleChange}
                  disabled={!emailData.file_type}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-2">
            {emailData?.attachments && emailData?.attachments?.length > 0 && (
              <Col md={8}>
                <div className="border p-3">
                  {emailData?.attachments?.map((attachment, idx) => (
                    <Row key={idx}>
                      <Col sm={4}>{attachment.type}</Col>
                      <Col sm={6}>{attachment.file.name}</Col>
                      <Col sm={2}>
                        <Button
                          variant="outline-primary"
                          className="attachment-remove"
                          onClick={() => removeAttachment(idx)}
                        >
                          X
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            )}
          </Row>
          <Row className="pt-2">
            <Col xl={12}>
              <Form.Group controlId="file">
                <Form.Label className="small text-secondary">
                  Message
                </Form.Label>
                <Form.Control
                  name="message"
                  as="textarea"
                  value={emailData?.message ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="pt-4 justify-content-end">
            <Col xs={12} md={2}>
              <Button variant="primary" type="submit" className="w-100 mb-xs-3">
                Send
              </Button>
            </Col>
            <Col xs={12} md={2}>
              <Button variant="outline-primary" type="reset" className="w-100">
                Discard
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
      {mailSent && (
        <Alert className="mt-4" variant="success">
          Mail Sent!
        </Alert>
      )}
      {error && (
        <Alert className="mt-4" variant="danger">
          Mail Failed to Send
        </Alert>
      )}
    </>
  );
};

export default EmailForm;
