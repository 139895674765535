import { useMemo, useState, FormEvent } from "react";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import { normalizeErrorMessages } from "api/common/utils/rtkq.utils";
import { useLazyGetProductByVidQuery } from "api/memberPortal/project/product/product.api";

function JumpVid() {
  const navigate = useNavigate();
  const [vidId, setVidId] = useState("");

  const [showAlert, setShowAlert] = useState(false);
  const [triggerGetProductsByVid, { error }] = useLazyGetProductByVidQuery();

  const handleProductJump = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setShowAlert(false);
      const result = await triggerGetProductsByVid(vidId).unwrap();
      navigate(`/community/products/details/${result.product_id}`);
    } catch {
      setShowAlert(true);
    }
  };

  const errorMessage = useMemo(() => {
    if (error) {
      return `[${vidId}]: ${normalizeErrorMessages(error)}`;
    }

    // we actually only want to disable react-hooks/exhaustive-deps below but something is weird with eslint and that rule rn
    // eslint-disable-next-line
  }, [error]); // we dont actually want vidId here as that will change as the user types
  //           // we just want the value at the time the error was generated

  return (
    <Form
      onSubmit={handleProductJump}
      className="row justify-content-end align-items-center d-flex"
    >
      <Col style={{ minHeight: "58px" }}>
        <Alert
          show={showAlert}
          variant="warning"
          dismissible
          onClose={() => setShowAlert(false)}
          style={{ flex: 1, margin: 0, marginRight: "1em" }}
        >
          {errorMessage}
        </Alert>
      </Col>

      <Col xs={12} md={3}>
        <Form.Group controlId="jumpbox">
          <Form.Label className="visually-hidden">Jump to VID</Form.Label>
          <Form.Control
            value={vidId}
            name="vid"
            onChange={(e) => setVidId(e.target.value)}
            placeholder="Jump to VID"
          />
        </Form.Group>
      </Col>

      <Col xs={12} md="auto">
        <Button
          type="submit"
          onClick={handleProductJump}
          className="w-100 w-md-auto mt-1"
        >
          Jump
        </Button>
      </Col>
    </Form>
  );
}

export default JumpVid;
