import { valueof } from "api/common/utils/type.utils";

export const PUBLISH_STATUSES = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  PUBLISHED: "Published",
  SUBMITTED: "Submitted",
  UNPUBLISHED: "Unpublished",
} as const;
export type PublishStatus = valueof<typeof PUBLISH_STATUSES>;

export const PRODUCT_STATUSES = {
  DRAFT: "In Draft",
  PRE_PROPOSAL: "Pre-Proposal",
  PROPOSED: "Proposed",
  PRE_EVALUATION: "Pre-Evaluation",
  IN_PROGRESS: "In Progress",
  FINALIZING: "Finalizing",
  CERTIFIED: "Certified",
  TERMINATED: "Terminated",
  WITHDRAWN: "Withdrawn",
  ARCHIVED: "Archived",
  IN_ASSURANCE_MAINTENANCE: "In Assurance Maintenance",
  OTHER: "Other",
} as const;
export type ProductStatus = valueof<typeof PRODUCT_STATUSES>;

export const CERTIFICATION_STATUSES = {
  CERTIFIED: "Certified",
  NOT_CERTIFIED: "Not Certified",
} as const;
export type CertificationStatus = valueof<typeof CERTIFICATION_STATUSES>;

export const PRODUCT_KICKOFF_STATUSES = {
  READY_FOR_RECOMMENDATION: "Ready for Recommendation",
  RECOMMENDED_FOR_KICKOFF: "Recommended for Kickoff",
  KICKED_OFF: "Kicked Off",
} as const;
export type ProductKickoffStatus = valueof<typeof PRODUCT_KICKOFF_STATUSES>;

export const PRE_EVAL_STATUSES = {
  PROPOSED: "Proposed",
  SIGNED: "Signed",
  READY_FOR_ASSIGNMENT: "Ready for Assignment",
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
} as const;
export type PreEvalStatus = valueof<typeof PRE_EVAL_STATUSES>;

export const FIPS_STATUSES = {
  TBD: -1,
  NO_DEPENDENCY_EXISTS: 0,
  UNSATISFIED_DEPENDENCY_EXISTS: 1,
  DEPENDENCY_SATISFIED: 2,
} as const;
export type FipsStatus = valueof<typeof FIPS_STATUSES>;

// Lookup for FIPS labels
export const FIPS_STATUS_LOOKUP: Record<FipsStatus, string> = {
  [-1]: "TBD",
  [0]: "No Dependency Exists",
  [1]: "Unsatisfied Dependency Exists",
  [2]: "Dependency Satisfied",
};

export const PRODUCT_SUBMISSION_TYPES = {
  EVALUATION: "Evaluation",
  ASSURANCE_MAINTENANCE: "Assurance Maintenance",
} as const;
export type ProductSubmissionType = valueof<typeof PRODUCT_SUBMISSION_TYPES>;

export const CSFC_ELIGIBLE_OPTIONS = {
  YES: "Yes",
  NO: "No",
  MAYBE: "Maybe",
  UNKNOWN: "Unknown",
};
export type CsfcEligibleOption = valueof<typeof CSFC_ELIGIBLE_OPTIONS>;

export const VALIDATION_TEAM_APPROVAL_STATUSES = {
  SUBMITTED: "Submitted",
  APPROVED: "Approved",
  REJECTED: "Rejected",
} as const;

export type ValidationTeamApprovalStatus = valueof<
  typeof VALIDATION_TEAM_APPROVAL_STATUSES
>;
