import axios from "axios";
import { useState, useEffect } from "react";
import { Accordion, Container } from "react-bootstrap";

import Subheader from "components/Subheader";
import { useInterval } from "hooks/useInterval";

import PolicyLettersTable from "./components/PolicyLettersTable";

export default function PoliciesPage() {
  const [activePolicies, setActivePolicies] = useState(0);
  const [inactivePolicies, setInactivePolicies] = useState(0);

  const loadPolicies = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/policies/get_public_policies_counts/`,
      )
      .then((response) => {
        setActivePolicies(response.data?.active);
        setInactivePolicies(response.data?.inactive);
      })
      .catch((error) => {
        console.log("error getting policy counts: ", error);
      });
  };

  useEffect(() => {
    loadPolicies();
  }, []);

  useInterval(() => {
    loadPolicies();
  }, 600000); //10 min

  useEffect(() => {
    document.title = "NIAP - Policies";
  }, []);

  return (
    <>
      <Subheader pageName={"Policies"} />
      <Container className="mb-5 py-3">
        <div className="p-4">
          <h4 className="text-uppercase fw-bold pb-3">
            RESOURCES -{" "}
            <span className="text-primary text-uppercase">
              SCHEME POLICY LETTERS
            </span>
          </h4>
          <p>
            The following CCEVS guidance documents are currently available.
            Comments on any scheme publication can be forwarded to
            niap@niap-ccevs.org.
          </p>
        </div>
        <div className="p-4">
          <Accordion flush defaultActiveKey="0" alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h6 className="text-primary fw-bold">
                  {activePolicies} CURRENT POLICY LETTERS
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <PolicyLettersTable archived={false} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h6 className="text-primary fw-bold">
                  {inactivePolicies} SUPERSEDED OR OUTDATED POLICY LETTERS
                </h6>
              </Accordion.Header>
              <Accordion.Body>
                <PolicyLettersTable archived={true} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </>
  );
}
