import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import NISTDetails from "./NISTDetails";
import NISTTable from "./NISTTable";

const CheckPermNISTManager = () => {
  // TODO: see why this is here and probably delete
  const [ncertMember, setNcertMember] = useState();

  const { permissions } = useAuth();

  if (
    permissions?.role_type === "NIAP" ||
    permissions?.role_type === "Lab" ||
    permissions?.role_type === "Validator"
  ) {
    return <NISTManager />;
  } else if (permissions?.role_type !== "NIAP" && ncertMember === false) {
    return <UnauthorizedView />;
  }

  return <div></div>;
};

const NISTManager = () => {
  return (
    <Routes>
      <Route path="" element={<NISTTable />} />
      <Route path="details/:id" element={<NISTDetails />} />
    </Routes>
  );
};

export default CheckPermNISTManager;
