import { type RouteObject } from "react-router-dom";

import PCMAssuranceMaintenancePage from "components/NIAPAdmin/PCM/PCMAssuranceMaintenancePage";
import AssuranceMaintenancePage from "components/Products/AssuranceMaintenancePage/AssuranceMaintenancePage";
import CCRA from "features/public/AboutUs/CCRA/CCRA.screen";
import EvaluationProcess from "features/public/AboutUs/EvaluationProcess/EvaluationProcess.screen";
import NiapCcevs from "features/public/AboutUs/NiapCcevs/NiapCcevs.screen";
import TermsAcronyms from "features/public/AboutUs/TermsAcronyms/TermsAcronyms.screen";
import FAQs from "features/public/FAQs/FAQ.screen";
import Homepage from "features/public/Homepage/Homepage.screen";
import PCMDetailsPage from "features/public/Products/PcmDetails/PcmDetails.screen";
import ProductDetails from "features/public/Products/ProductDetails/ProductDetails.screen";
import Products from "features/public/Products/PublicProducts.screen";
import Profile from "features/public/Profile/Profile.screen";
import ProtectionProfileDetails from "features/public/ProtectionProfiles/components/ProtectionProfileDetails";
import ProtectionProfiles from "features/public/ProtectionProfiles/PublicProtectionProfiles.screen";
import QAFollowUpResponsePage from "features/public/QA/QAFollowUpResponsePage";
import EventsPage from "features/public/Resources/Announcements/EventsPage/Events.screen";
import NewsPage from "features/public/Resources/Announcements/NewsPage/News.screen";
import CCTL from "features/public/Resources/CCTL/CCTL.screen";
import FormsAndTemplatesPage from "features/public/Resources/FormsAndTemplates/FormsAndTemplates.screen";
import LabgramDetails from "features/public/Resources/Labgrams/LabgramDetails.screen";
import LabgramsPage from "features/public/Resources/Labgrams/Labgrams.screen";
import PoliciesPage from "features/public/Resources/Policies/Policies.screen";
import PolicyLetterView from "features/public/Resources/Policies/PolicyLetterView.screen";
import PublicationsPage from "features/public/Resources/Publications/Publications.screen";
import Search from "features/public/Search/Search.screen";
import SiteMap from "features/public/SiteMap/SiteMap.screen";
import TechCommunities from "features/public/TechCommunities/TechnicalCommunities.screen";
import TechnicalDecisionDetails from "features/public/TechnicalDecisions/TechDecisionDetails.screen";
import TechnicalDecisions from "features/public/TechnicalDecisions/TechDecisions.screen";
import TRRTSPage from "features/public/TRRTs/TRRT.screen";
import Logout from "routing/Logout";

import loginRoutes from "./login.routes";

const publicRoutes: RouteObject[] = [
  {
    index: true,
    element: <Homepage />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/niap-ccevs",
    element: <NiapCcevs />,
  },
  {
    path: "/ccra",
    element: <CCRA />,
  },
  {
    path: "/evaluation-process",
    element: <EvaluationProcess />,
  },
  {
    path: "/cctl",
    element: <CCTL />,
  },
  {
    path: "/terms-acronyms",
    element: <TermsAcronyms />,
  },
  {
    path: "/faqs",
    element: <FAQs />,
  },
  {
    path: "/products",
    children: [
      {
        index: true,
        element: <Products />,
      },
      { path: ":id", element: <ProductDetails /> },
      {
        path: ":product_id/assurancemaintenance/:id",
        element: <AssuranceMaintenancePage />,
      },
      {
        path: "international-product",
        children: [
          { path: ":id", element: <PCMDetailsPage /> },
          {
            path: ":ccid/assurancemaintenance/:id",
            element: <PCMAssuranceMaintenancePage />,
          },
        ],
      },
    ],
  },
  {
    path: "/protectionprofiles",
    children: [
      {
        index: true,
        element: <ProtectionProfiles />,
      },
      {
        path: ":id",
        element: <ProtectionProfileDetails />,
      },
    ],
  },
  {
    path: "trrt",
    element: <TRRTSPage />,
  },
  {
    path: "/question-answer/response/:id",
    element: <QAFollowUpResponsePage testing={undefined} />,
  },
  {
    path: "/policies",
    children: [
      {
        index: true,
        element: <PoliciesPage />,
      },
      {
        path: ":id",
        element: <PolicyLetterView />,
      },
    ],
  },
  {
    path: "/formsandtemplates",
    element: <FormsAndTemplatesPage />,
  },
  {
    path: "/labgrams",
    children: [
      {
        index: true,
        element: <LabgramsPage />,
      },
      {
        path: ":id",
        element: <LabgramDetails />,
      },
    ],
  },
  { path: "/events", element: <EventsPage /> },
  { path: "/announcements", element: <NewsPage /> },
  { path: "/publications", element: <PublicationsPage /> },
  { path: "/technical-communities", element: <TechCommunities /> },
  {
    path: "/technical-decisions",
    children: [
      {
        index: true,
        element: <TechnicalDecisions />,
      },
      {
        path: ":id",
        element: <TechnicalDecisionDetails />,
      },
    ],
  },
  {
    path: "/sitemap",
    element: <SiteMap />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  ...loginRoutes,
  {
    path: "/profile",
    element: <Profile />,
  },
];

export default publicRoutes;
