import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  OverlayTrigger,
  Button,
  Popover,
} from "react-bootstrap";

import DeleteModal from "components/UI/DeleteModal";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

import GlossaryModal from "./GlossaryModal";

export default function GlossaryTable() {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [filtering, setFiltering] = useState(false);
  const [showGlossaryModal, setShowGlossaryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [definition, setDefinition] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const { authToken, csrfToken } = useAuth();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const handleDeleteDefinition = () => {
    axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/definitions/${definition?.definition_id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        setAlert({
          message: "Successfully Deleted Definition",
          type: "success",
        });
        setRefresh(!refresh);
        setDefinition({});
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.log("error getting events: ", error);
        setAlert({
          message: "Unable to Delete Definition",
          type: "danger",
        });
      });
  };

  const handleShowGlossaryModal = (rowData) => {
    if (rowData) {
      setDefinition(rowData);
    } else {
      setDefinition({});
    }
    setShowGlossaryModal(!showGlossaryModal);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const columns = [
    {
      title: "ID",
      field: "definition_id",
    },
    {
      title: "Term or Acronym",
      field: "term",
    },
    {
      title: "Definition",
      field: "definition",
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowGlossaryModal(rowData)}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => {
                        setDefinition(rowData);
                        setShowDeleteModal(true);
                      }}
                    >
                      <DeleteIcon color="action" /> Delete
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
                <span className="visually-hidden">Actions</span>
              </Button>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const options = {
    showTitle: false,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: filtering,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <GlossaryModal
        show={showGlossaryModal}
        handleShow={handleShowGlossaryModal}
        refetch={() => {
          setRefresh(!refresh);
        }}
        setAlert={setAlert}
        definition={Object.keys(definition)?.length > 0 ? definition : null}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        type={`Definition - ${definition.term}`}
        deleteFunction={handleDeleteDefinition}
      />
      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={7} className="mb-xs-3">
              <h3 className="text-bright-navy">
                GLOSSARY OF TERMS AND ACRONYMS
              </h3>
            </Col>
            <Col sm={12} md={3} className="d-flex justify-content-end mb-xs-3">
              <Button
                variant="primary"
                className="rounded-0 w-100"
                onClick={() => setShowGlossaryModal(true)}
              >
                + New Term or Acronym
              </Button>
            </Col>
            <Col sm={12} md={2} className="d-flex justify-content-end mb-xs-3">
              <Button
                className="account-btn w-100"
                variant={filtering ? "primary" : "light"}
                onClick={(e) => setFiltering(!filtering)}
              >
                Filter
              </Button>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              columns={columns}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/definitions/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              tableRef={tableRef}
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
