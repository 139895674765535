import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { selectIsLoading, selectLoadingText } from "app/loading.slice";

const LoadingSpinner = () => {
  const open = useSelector(selectIsLoading);
  const loadingText = useSelector(selectLoadingText);

  return (
    <Backdrop
      open={open}
      sx={{
        flexDirection: "column",
        zIndex: 1400,
        backgroundColor: "rgba(0, 0, 0, 0.65)",
        backdropFilter: "blur(1px)",
      }}
    >
      <CircularProgress sx={{ color: "white" }} size={60} />
      <Typography variant="h5" color="white" sx={{ margin: "1.5em" }}>
        {loadingText}
      </Typography>
    </Backdrop>
  );
};

export default LoadingSpinner;
