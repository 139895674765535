import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import QRCode from "react-qr-code";

const MFADisplayQR = ({ setStep, qrCode }) => {
  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          Scan this Code with your Authentication Application
        </Card.Title>
        <QRCode value={qrCode} />
        <Button
          className="my-4"
          variant="warning"
          onClick={() => setStep("confirm")}
        >
          Next
        </Button>
      </Card.Body>
    </Card>
  );
};

export default MFADisplayQR;
