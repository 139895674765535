import axios from "axios";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useAuth } from "hooks/useAuth";

const MFAselect = ({ setStep, setMfaMethod, setQrCode }) => {
  const [formErrors, setFormErrors] = useState({});
  const [method, setMethod] = useState({});
  const { authToken, csrfToken, currentUser } = useAuth();

  const handleChange = (e) => {
    setMethod({ ...method, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mfaMethod = method.method;
    setMfaMethod(mfaMethod);
    if (mfaMethod === "email" && !currentUser.email) {
      setStep("add-detail");
    } else if (mfaMethod === "sms_twilio" && !currentUser.phone_number) {
      setStep("add-detail");
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${mfaMethod}/activate/`,
          method,
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          if (mfaMethod === "app") {
            const qrCode = response.data.details;
            setStep("qr");
            setQrCode(qrCode);
          } else {
            setStep("confirm");
          }
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    }
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Choose Verification Method</Card.Title>
        <Card.Subtitle as="p">
          For additional security NIAP requires you select a method to receive
          one time codes
        </Card.Subtitle>
        <Form className="centered-form" onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="method">
            <Form.Check
              type="radio"
              label="Email"
              name="method"
              value="email"
              onChange={handleChange}
              id="method1"
            />
            <p className="small">Your Email: {currentUser.email}</p>
            <Form.Check
              type="radio"
              label="SMS"
              name="method"
              value="sms_twilio"
              onChange={handleChange}
              id="method2"
            />
            <span className="small">
              By selecting to receive MFA codes by SMS you consent to receive
              text messages from us. Message and data rates may apply. Messages
              will be sent on each login.
            </span>
            <p className="small">
              Your Phone Number: {currentUser.phone_number}
            </p>
            <Form.Check
              type="radio"
              label="Application"
              name="method"
              value="app"
              onChange={handleChange}
              id="method3"
            />
            <p className="small">Such as Authy or Google Authenticator</p>
          </Form.Group>
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAselect;
