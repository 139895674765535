// examples lifted from docs at
// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#type-safe-error-handling

import { type FetchBaseQueryError } from "@reduxjs/toolkit/query";

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (
  error: unknown,
): error is FetchBaseQueryError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "status" in error &&
    "data" in error
  );
};

/**
 * Type predicate to narrow an unknown error to a native Js error
 */
export const isNativeError = (error: unknown): error is Error =>
  error instanceof Error;

/**
 * Type predicate to narrow an unknown error to an object with a named string property
 */
export const hasStringProp = (object: unknown, prop: string): boolean => {
  return (
    typeof object === "object" &&
    object != null &&
    prop in object &&
    typeof (object as Record<string, string>)[prop] === "string"
  );
};

export const extractStringProp = (object: unknown, prop: string) => {
  if (hasStringProp(object, prop)) {
    return (object as Record<string, string>)[prop];
  }
  return undefined;
};

/**
 * Type predicate to narrow an unknown error to an array with a named string property
 */
export const hasArrayProp = (object: unknown, prop: string): boolean => {
  return (
    typeof object === "object" &&
    object !== null &&
    prop in object &&
    Array.isArray((object as any)[prop])
  );
};

/**
 * Type predicate to narrow an unknown error to an object with a string 'details' property
 */
export const isErrorWithDetails = (
  error: unknown,
): error is { details: string } => {
  return hasStringProp(error, "details");
};

/**
 * Type predicate to narrow an unknown error to an object with a string `errors` property
 */
export const isErrorWithError = (
  error: unknown,
): error is { data: { error: string } } => {
  return hasStringProp((error as { data: unknown }).data, "error");
};

export const extractBaseQueryError = (error: FetchBaseQueryError): string => {
  return (
    extractStringProp(error.data, "error") ??
    extractStringProp(error.data, "detail") ??
    "An unknown error occurred"
  );
};

export const normalizeErrorMessages = (
  error: unknown,
  formatBaseQueryError?: (_error: FetchBaseQueryError) => string,
) => {
  if (isFetchBaseQueryError(error)) {
    if (formatBaseQueryError) {
      return formatBaseQueryError(error);
    }
    return extractBaseQueryError(error);
  }

  if (isErrorWithDetails(error)) {
    return error.details;
  }

  if (isNativeError(error)) {
    return error.message;
  }

  if (isErrorWithError(error)) {
    return error.data.error;
  }

  return "An unknown error occurred";
};
