import { Outlet } from "react-router-dom";

import {
  AUTH_ROLES,
  type AccessLevel,
  type AuthRole,
  type PermissionArea,
} from "api/common/types/auth.types";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { checkPermissions } from "utils/permissions";

interface RequiredPermissions {
  area?: PermissionArea;
  role?: AuthRole;
  access?: AccessLevel;
}

// TODO: FULLY FLESH THIS OUT FOR AUTHENTICATION
export const AuthenticatedRoute = ({
  requiredPermissions,
}: {
  requiredPermissions?: RequiredPermissions;
}) => {
  const { permissions, authToken } = useAuth();
  const { area, access } = requiredPermissions || {};

  let isUnauthorized =
    !permissions.role_type ||
    permissions.role_type === AUTH_ROLES.EXTERNAL ||
    !authToken;

  let authorizedToViewArea = false;

  if (area && access) {
    authorizedToViewArea = checkPermissions({ permissions, area, access });
  } else {
    authorizedToViewArea = true;
  }

  if (isUnauthorized || !authorizedToViewArea) return <UnauthorizedView />;

  return <Outlet />;
};

export default AuthenticatedRoute;
