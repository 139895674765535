import Typography from "@mui/material/Typography";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import ppProcess from "images/pp-process.svg";
import "../PPProcessModal.css";
import "components/Products/Products.css";

const phaseColors = {
  "Phase 1": "text-warning",
  "Phase 2": "text-primary",
  "Phase 3": "text-orange",
  "Phase 4": "text-green",
};

const phaseStatus = {
  Initiation: "Phase 1",
  Planning: "Phase 2",
  Development: "Phase 3",
  Publishing: "Phase 4",
};

const columns = [
  { title: "Profile Name", field: "pp_name" },
  { title: "Technology Type", field: "tech_type" },
  {
    title: "Phase",
    field: "phase",
    render: (rowData) => (
      <p className={phaseColors[phaseStatus[rowData.status]]}>
        {phaseStatus[rowData.status]}
      </p>
    ),
  },
];

const options = {
  headerStyle: {
    fontWeight: "bold",
    paddingBottom: "0",
  },
  cellStyle: {
    fontWeight: "bold",
  },
  search: false,
  pageSize: 20,
};

export default function PPProcessModal({ show, handleCloseModal }) {
  const [inDevPps, setInDevPps] = useState([]);

  const loadProtectionProfile = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}protection-profile/get_in_development_pps/`,
      )
      .then((response) => {
        setInDevPps(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (show) {
      loadProtectionProfile();
    }
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">
          PROTECTION PROFILES DEVELOPMENT PROCESS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m_table">
        <img
          src={ppProcess}
          className="modal-img mb-5"
          alt="Protection Profile Process"
        />
        <Typography
          variant="h5"
          gutterBottom
          className="mb-2"
          data-testid="pp_process_header"
        >
          Protection Profiles Status as of{" "}
          {new Date().toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <ResponsiveMaterialTable
          title="Showing"
          columns={columns}
          data={inDevPps}
          options={options}
        />
      </Modal.Body>
    </Modal>
  );
}
