import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Col, Row } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function HeaderEditOrSaveSections({
  title,
  value,
  editField,
  handleEdit,
  fieldName,
  isEditable,
  renderEditComponent,
  openAudit,
}) {
  const { permissions } = useAuth();

  const EditButton = ({ onClick, editField, field }) =>
    permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Decisions",
    )?.update &&
    editField === "" && (
      <Col className="d-flex justify-content-end me-3">
        <EditIcon
          className="clickable"
          onClick={() => onClick(field)}
          data-testid={`edit-icon-${field}`}
        />
      </Col>
    );

  const SaveButton = ({ onClick, editField, field }) =>
    editField === field && (
      <Col className="d-flex justify-content-end me-3">
        <SaveIcon
          className="clickable"
          onClick={onClick}
          data-testid={`save-icon-${field}`}
        />
      </Col>
    );

  return (
    <Col>
      <Row>
        <Col>
          <h5>{title}:</h5>
        </Col>
        {isEditable && (
          <>
            <EditButton
              onClick={handleEdit}
              editField={editField}
              field={fieldName}
            />
            <SaveButton
              onClick={() => openAudit()}
              editField={editField}
              field={fieldName}
            />
          </>
        )}
      </Row>
      {editField === fieldName ? renderEditComponent() : <div>{value}</div>}
    </Col>
  );
}
