import { Route, Routes } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import UnauthorizedView from "../UI/UnauthorizedView";

import MemberVirtTeamsTable from "./MemberVirtTeamsTable";
import VirtualTeamInfoTable from "./VirtualTeamInfoTable";
import VirtualTeams from "./VirtualTeams";

const VirtTeams = () => {
  const { permissions } = useAuth();

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Virtual Teams",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="" element={<VirtualTeams />} />
      <Route path=":id" element={<VirtualTeamInfoTable />} />
      <Route path=":id/:member_id" element={<MemberVirtTeamsTable />} />
    </Routes>
  );
};

export default VirtTeams;
