import axios from "axios";
import qs from "qs";
import { useState, useRef } from "react";
import { Button } from "react-bootstrap";

import ConfirmModal from "components/AccountManager/UserRolesManager/ConfirmModal";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

import CreateNewContactModal from "./CreateNewConactModal";

const VendorContacts = () => {
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [currentContact, setCurrentContact] = useState({});
  const [contactInfo, setContactInfo] = useState({});
  const tableRef = useRef();
  const { authToken } = useAuth();

  const refetchContacts = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const handleShowConfirmModal = (rowData) => {
    setContactInfo(rowData);
    setShow(true);
  };

  const removeContacts = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/contacts/${contactInfo.contact_id}/`,
        { ...contactInfo, org_id: contactInfo.org_id.org_id, active: false },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setShow(false);
        refetchContacts();
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const columns = [
    {
      title: "First Name",
      field: "first_name",
    },

    {
      title: "Last Name",
      field: "last_name",
    },
    {
      title: "Position",
      field: "position",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Phone",
      field: "phone_number",
    },

    {
      title: "Actions",
      render: (rowData) => {
        return (
          <>
            <Button
              variant="primary"
              className="m-1 p-0"
              onClick={() => openModal(rowData)}
            >
              Edit
            </Button>
            <Button
              variant="danger"
              className="m-1 p-0"
              onClick={() => {
                handleShowConfirmModal(rowData);
              }}
            >
              Remove
            </Button>
          </>
        );
      },
    },
  ];

  const options = {
    paging: true,
    search: false,
    sorting: true,
    showEmptyDataSourceMessage: true,
    pageSize: 20,
  };

  const openModal = (data) => {
    setCurrentContact(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setCurrentContact({});
    setShowModal(false);
  };

  return (
    <div className="my-3">
      <ResponsiveMaterialTable
        title="Vendor Contacts"
        columns={columns}
        options={options}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}org/contacts/my_org_contacts/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        components={{
          Actions: (props) => (
            <div>
              <Button
                variant="primary"
                style={{ marginLeft: 40, marginRight: 10 }}
                onClick={() => openModal({})}
                className="account-btn"
              >
                + Add Contacts
              </Button>
            </div>
          ),
        }}
      />
      <CreateNewContactModal
        showModal={showModal}
        closeModal={closeModal}
        tableRef={tableRef}
        currentContact={currentContact}
      />
      <ConfirmModal
        title={"Are You Sure You Want to Remove this Contact?"}
        buttonText={"Remove"}
        show={show}
        setShow={setShow}
        removeContacts={removeContacts}
      />
    </div>
  );
};

export default VendorContacts;
