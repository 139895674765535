import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import TechnicalCommunitiesTable from "./TechnicalCommunitiesTable";

const TechnicalCommunitiesManager = () => {
  const { permissions } = useAuth();
  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<TechnicalCommunitiesTable />} />
    </Routes>
  );
};
export default TechnicalCommunitiesManager;
