import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import CommunityTechDecisionDetails from "./CommunityTechDecisionDetails";
import CommunityTechDecisionTable from "./CommunityTechDecisionTable";

export default function TechDecisionManager({}) {
  const { permissions } = useAuth();

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Technical Decisions",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<CommunityTechDecisionTable />} />
      <Route path="details/:id" element={<CommunityTechDecisionDetails />} />
    </Routes>
  );
}
