import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useAuth } from "hooks/useAuth";

const AddDetails = ({ setStep, mfaMethod }) => {
  const [userInfo, setUserInfo] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const method = mfaMethod;
  const [errors, setErrors] = useState({});
  const { csrfToken, authToken, currentUser } = useAuth();

  const handleChange = (e) => {
    if (e.target.name === "phone_number") {
      setErrors({
        ...errors,
        [e.target.name]:
          !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            e.target.value,
          ),
      });
    }
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const checkPhones = () => {
    if (
      userInfo?.phone_number &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        userInfo?.phone_number,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() || !checkPhones()) {
      e.stopPropogation();
    } else {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/${userInfo.id}/`,
          userInfo,
          { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
        )
        .then((response) => {
          axios
            .post(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}auth/${method}/activate/`,
              { method: method },
              {
                withCredentials: true,
                headers: {
                  "X-CSRFToken": csrfToken,
                  Authorization: `Token ${authToken}`,
                },
              },
            )
            .then((response) => {
              setStep("confirm");
            })
            .catch((error) => {
              setFormErrors(error.response.data);
            });
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
    }
  };

  useEffect(() => {
    let collapsedUserData = {};
    collapsedUserData.id = currentUser?.id;
    collapsedUserData.username = currentUser?.username;
    collapsedUserData.password = currentUser?.password;
    collapsedUserData.user_role = currentUser?.user_role?.role_id;
    collapsedUserData.account_group = currentUser?.account_group?.group_id;
    collapsedUserData.scheme = currentUser?.scheme?.sid;
    collapsedUserData.company = currentUser?.company?.org_id;
    collapsedUserData.creator = currentUser?.creator?.id;
    collapsedUserData.editor = currentUser?.editor?.id;

    setUserInfo(collapsedUserData);
  }, []);

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">
          You do not have a {method !== "sms_twilio" ? method : "phone"}{" "}
          asocciated with your account. Please enter one now
        </Card.Title>
        <Form onSubmit={handleSubmit}>
          {method === "email" && (
            <Form.Group className="mb-4 text-start" controlId="username">
              <Form.Label className="small text-secondary">
                Email Address*
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                onChange={handleChange}
                required={true}
              />
            </Form.Group>
          )}
          {method === "sms_twilio" && (
            <Form.Group className="mb-4 text-start" controlId="username">
              <Form.Label className="small text-secondary">
                Phone Number*
              </Form.Label>
              <Form.Control
                type="tel"
                name="phone_number"
                onChange={handleChange}
                isInvalid={errors.phone_number}
                required={true}
              />
            </Form.Group>
          )}
          {formErrors && <p className="mb-4 text-danger">{formErrors.error}</p>}
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AddDetails;
