import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

const NIAReassignAgentModal = ({ show, handleCloseModal, id }) => {
  const [agents, setAgents] = useState([]);
  const [newSelectedAgent, setNewSelectedAgent] = useState({});
  const [currentAgent, setCurrentAgent] = useState("");
  const { authToken, csrfToken } = useAuth();

  const getOnDutyAgent = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_on_duty_agent/`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setCurrentAgent(response.data);
        setNewSelectedAgent(response.data?.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}question-answer/${id}/`,
        {
          account_id: newSelectedAgent,
          recaptcha_token: "member_portal",
        },
        {
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
          withCredentials: true,
        },
      )
      .then((response) => {
        console.log("QA object updated successfully");
      })
      .catch((error) => {
        console.error("Failed to update QA object:", error);
      });
    handleCloseModal();
  };

  const fetchAgents = () => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}account/users`, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
        withCredentials: true,
      })
      .then((response) => {
        const responseAgents = response.data.results.filter(
          (role) => role?.user_role?.role_type === "NIAP",
        );
        setAgents([...responseAgents]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAgents();
    getOnDutyAgent();
  }, []);

  return (
    <>
      <Modal size="sm" show={show} onHide={handleCloseModal}>
        <Form>
          <Modal.Header closeButton className=" align-items-start">
            <Modal.Title className="mt-2 "> REASSIGN TICKET ADMIN </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group controlId="agent">
              <Form.Label>NIAP AGENT</Form.Label>
              <Form.Select
                className="mb-4"
                name="vendor"
                defaultValue={currentAgent.id}
                onChange={(e) => setNewSelectedAgent(e.target.value)}
              >
                .<option value="">Select an Agent</option>
                {agents.map((agent, index) => (
                  <option key={index} value={agent.id}>
                    {agent?.last_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col>
                <Button onClick={handleCloseModal} variant="outline-primary">
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button onClick={handleSubmit}>Reassign</Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default NIAReassignAgentModal;
