import axios from "axios";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import { Link, useLocation } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

const CheckEmail = () => {
  const [passwordResent, setPasswordResent] = useState(false);
  const { state } = useLocation();
  const { csrfToken } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}password-reset/`,
        { email: state?.email },
        { withCredentials: true, headers: { "X-CSRFToken": csrfToken } },
      )
      .then(() => {
        setPasswordResent(true);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Check Your Email</Card.Title>
        <p className="m-4 text-secondary">
          We sent a password reset link to {state?.email}
        </p>
        <p className="m-4 small text-secondary">
          Didn’t receive the email?{" "}
          <Link onClick={handleSubmit}>Click to resend</Link>
        </p>
        {passwordResent && (
          <p className="m-4 small text-success">Password resent</p>
        )}
        <p className="m-4 small text-secondary">
          <Link to="/login">&#8592; Back to login</Link>
        </p>
      </Card.Body>
    </Card>
  );
};

export default CheckEmail;
