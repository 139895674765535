import moment from "moment";
import { useState } from "react";
import { Accordion, Button, Container, Row } from "react-bootstrap";

import { useGetFilesByTypeAndIdQuery } from "api/memberPortal/files/files.api";
import { useGetProgressPointsByPidQuery } from "api/memberPortal/project/progressPoints/progressPoints.api";
import FileUploadModal from "components/Products/FileUpload";
import { useAuth } from "hooks/useAuth";

import FileInfoButton from "./components/FileInfo.button";

export default function EarSideInfo({ review, id, ERTMember }) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  // TODO: properly handle undefined
  const productId = review?.pid?.v_id;

  const { permissions } = useAuth();

  const { data: files = [] } = useGetFilesByTypeAndIdQuery(
    {
      file_type: "ear-review",
      file_type_id: id,
    },
    {
      selectFromResult: (result) => {
        if (!result.data) return result;

        const activeFiles = result.data
          .filter((file) => file.active)
          .sort((a, b) => moment(b.uploaded_on).diff(moment(a.uploaded_on)));
        return { ...result, data: activeFiles };
      },
    },
  );

  const { data: progressPoints = [] } = useGetProgressPointsByPidQuery(
    productId,
    { skip: !productId },
  );

  const updateFiles = () => {
    setShowUploadModal(false);
  };

  const completionDate = progressPoints[0]?.submission_dates[0]?.estimated_date;

  const submissionDate = completionDate
    ? moment.utc(completionDate).format("MM/DD/YYYY")
    : undefined;

  const recordCreationDate = completionDate
    ? moment.utc(completionDate).format("YYYY.MM.DD [at] HHmm")
    : undefined;

  const creator = review.creator;

  return (
    <div>
      {showUploadModal && (
        <FileUploadModal
          show={showUploadModal}
          updateFiles={updateFiles}
          type="ear-review"
          id={id}
        />
      )}

      <Accordion flush className="border border-dark mb-3" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">IMPORTANT DATES</h6>
          </Accordion.Header>

          <Accordion.Body className="bg-light p-1">
            <Container className="">
              <Row>
                <p>Submission Date: {submissionDate || "---"}</p>
              </Row>

              <Row>
                <p>
                  Escalation Date:{" "}
                  {review?.release_date
                    ? moment.utc(review?.release_date).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>

              <Row>
                <p>
                  Deadline Date:{" "}
                  {review?.due
                    ? moment.utc(review?.due).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>

              <Row>
                <p>
                  Completion or Termination Date:{" "}
                  {review?.enddate
                    ? moment.utc(review?.enddate).format("MM/DD/YYYY")
                    : "---"}
                </p>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion flush className="border border-dark mb-3" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTACHED DOCUMENTS</h6>
          </Accordion.Header>

          <Accordion.Body className="bg-light p-3">
            {files.map((file) => (
              <FileInfoButton
                file={file}
                ERTMember={ERTMember}
                key={file.file_id}
              />
            ))}

            <div className="text-center">
              {permissions?.role_permissions?.find(
                (permission) => permission.area === "EAR Reviews",
              )?.update && (
                <Button
                  variant="outline-primary rounded-pill mb-2 mt-2"
                  onClick={() => setShowUploadModal(true)}
                >
                  Upload Files
                </Button>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion flush className="border border-dark mb-3" defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="bg-secondary">
            <h6 className="text-white">ATTRIBUTION INFORMATION</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container className="">
              <Row className="border-dark border-bottom">
                <p>
                  {recordCreationDate &&
                    `Record Created: ${recordCreationDate} by ${creator.first_name} ${creator.last_name}`}
                </p>
              </Row>
              <Row className="border-dark border-bottom">
                <p>
                  Last Modified:{" "}
                  {review?.moddate
                    ? `${moment.utc(review?.moddate).format("YYYY.MM.DD [at] HH:mm")} by
                  ${review?.editor?.first_name} ${review?.editor?.last_name}`
                    : ""}
                </p>
              </Row>
              <Row>
                <p>
                  Official Response Sent to Lab:{" "}
                  {review?.response && review?.response?.creator !== null
                    ? `${moment(review?.response?.entrydate).format(
                        "YYYY.MM.DD [at] HHmm",
                      )} by ${
                        review?.response?.creator?.first_name || ""
                      } ${review?.response?.creator?.last_name || ""}`
                    : ""}
                </p>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
