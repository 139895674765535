import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useCallback, useRef } from "react";
import { Popover, OverlayTrigger, ListGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

const VendorArchivedProductsTable = () => {
  const [projpps, setProjpps] = useState({});
  const tableRef = useRef();
  const { authToken } = useAuth();

  const fetchProducts = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const fetchPPs = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [authToken]);

  useEffect(() => {
    fetchPPs();
  }, [fetchPPs]);

  useInterval(() => {
    fetchPPs();
    fetchProducts();
  }, 600000); //10 min

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
    },
    {
      title: "Product",
      field: "product_name",
    },
    {
      title: "CCTL",
      field: "assigned_lab.name",
    },
    {
      title: "Conformance Claim",
      render: (rowData) => (
        <OverlayTrigger
          trigger={["hover", "focus"]}
          overlay={
            <Popover>
              <Popover.Body>
                <ListGroup>
                  {projpps &&
                    projpps[rowData?.product_id]?.map((projpp, idx) => (
                      <ListGroup.Item key={idx} disabled>
                        {projpp.name}
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="outline-dark" size="sm">
            Claims
          </Button>
        </OverlayTrigger>
      ),
    },
    {
      title: "Certification Date",
      field: "certification_date",
      render: (rowData) =>
        moment.utc(rowData.certification_date).format("YYYY-MM-DD"),
    },
    {
      title: "Scheme",
      field: "submitting_country_id.code2_iso",
      render: (rowData) =>
        rowData?.submitting_country_id && (
          <img
            src={require(
              `../../../images/flags2/${rowData?.submitting_country_id?.code2_iso?.toLowerCase()}.png`,
            )}
            alt={`${rowData?.submitting_country_id?.code2_iso} Flag`}
          />
        ),
    },
  ];

  const options = {
    sorting: true,
    search: false,
    columnsButton: true,
    padding: "dense",
    pageSize: 20,
  };

  return (
    <>
      <ResponsiveMaterialTable
        columns={columns}
        title="Archived Products"
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/vendor_archived_products/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </>
  );
};

export default VendorArchivedProductsTable;
