import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";

import { useAuth } from "hooks/useAuth";

export default function MembersTable({
  members,
  reload,
  virtualTeamID,
  levels,
  handleClose,
  refetch,
}) {
  const [users, setUsers] = useState([]);
  const [memberInfo, setMemberInfo] = useState([]);
  const [newMemberIDs, setNewMemberIDs] = useState([]);
  const [newMembers, setNewMembers] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedLevel, setSelectedLevel] = useState({});
  const [rowIndex, setRowIndex] = useState(1);
  const [invalidInput, setInvalidInput] = useState(false);
  const { authToken, csrfToken, currentUser } = useAuth();

  //filtering if members already on team or have been added to newMembers
  const userOptions = users
    .filter(
      (user) => !(user.id in memberInfo) && !newMemberIDs.includes(user.id),
    )
    .map((user) => {
      return { value: user.id, label: user.first_name + " " + user.last_name };
    });

  const levelOptions = levels?.map((level) => {
    return { value: level.vtlid, label: level.gist + " " + level.perm };
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/get_filter_users/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log("error getting users", error);
      });

    let membersInfo = {};
    for (let member in members) {
      membersInfo[members[member].account_id.id] =
        members[member].lvl.lvl + " - " + members[member].lvl.gist;
    }
    setMemberInfo(membersInfo);
  }, []);

  //get newMember's IDs for filter
  useEffect(() => {
    let idArr = [];
    for (let member in newMembers) {
      idArr.push(newMembers[member].user.id.value);
    }
    setNewMemberIDs(idArr);
  }, [newMembers]);

  const handleUserChange = (e) => {
    setSelectedUser({ user: { ...e.target } });
  };

  const handleLevelChange = (e) => {
    setSelectedLevel({ level: { ...e.target } });
  };

  const addNewMember = () => {
    if (
      Object.keys(selectedUser).length === 0 ||
      Object.keys(selectedLevel).length === 0
    ) {
      setInvalidInput(true);
      return;
    }
    setInvalidInput(false);
    setSelectedUser([]);
    setSelectedLevel([]);
    setRowIndex(rowIndex + 1);
    let combinedData = { ...selectedUser, ...selectedLevel };
    setNewMembers({ ...newMembers, [rowIndex]: combinedData });
  };

  const removeNewMember = (member) => {
    setNewMembers((current) => {
      const copy = { ...current };
      delete copy[member];
      return copy;
    });
  };

  const submitMembers = async () => {
    let allMembersArr = [];
    handleClose();
    for (let member in newMembers) {
      let memberObj = {
        account_id: newMembers[member].user.id.value,
        vtid: virtualTeamID,
        lvl: newMembers[member].level.id.value,
        creator: currentUser.id,
        editor: currentUser.id,
      };
      allMembersArr.push(memberObj);
    }
    await axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/set_members/`,
        {
          members: [...allMembersArr],
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((res) => {
        refetch();
      })
      .catch((error) => console.log("Unable to update new members: " + error));
    await axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/?vt=${virtualTeamID}`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((res) => {
        reload();
      })
      .catch((err) => console.log("Unable to get Virtual Team Members", err));
  };

  const UserSelect = () => {
    return (
      <Select
        onChange={(e) =>
          handleUserChange({
            target: {
              id: { name: "id", value: e.value },
              info: { name: "info", value: e.label },
            },
          })
        }
        isSearchable={true}
        value={userOptions.find(
          (user) => user.value === selectedUser?.user?.id?.value,
        )}
        options={userOptions}
      />
    );
  };

  const LevelSelect = () => {
    return (
      <Select
        onChange={(e) =>
          handleLevelChange({
            target: {
              id: { name: "id", value: e.value },
              info: { name: "info", value: e.label },
            },
          })
        }
        value={levelOptions?.find(
          (level) => level.value === selectedLevel?.level?.id?.value,
        )}
        options={levelOptions}
      />
    );
  };

  return (
    <Container>
      <Form>
        {Object.keys(newMembers).map((member, index) => {
          return (
            <Row key={index}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicUser">
                  <Form.Control
                    name="account_id"
                    placeholder={newMembers[member].user.info.value}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicLevel">
                  <Form.Control
                    name="lvl"
                    placeholder={newMembers[member].level.info.value}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col lg={1}>
                <IconButton
                  color="primary"
                  aria-label="dee"
                  component="label"
                  onClick={() => removeNewMember(member)}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Col>
            </Row>
          );
        })}
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Control name="account_id" as={UserSelect} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="formBasicLevel">
              <Form.Control name="lvl" as={LevelSelect} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {invalidInput ? (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span style={{ color: "var(--bs-red)" }}>
                Please input a User and Level to add a Member.
              </span>
            </Col>
          ) : null}
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="primary" onClick={addNewMember}>
              + Add Member
            </Button>
          </Col>
        </Row>
        {Object.keys(newMembers).length > 0 && (
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "5%",
              }}
            >
              <Button variant="warning" onClick={submitMembers}>
                Submit {Object.keys(newMembers).length} Member(s)
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </Container>
  );
}
