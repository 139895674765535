import axios from "axios";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { useAuth } from "hooks/useAuth";

const EditMailContact = ({ show, member, onHide, getMembers, setAlert }) => {
  const [editedMember, setEditedMember] = useState({});
  const { authToken, csrfToken, currentUser, permissions } = useAuth();

  const handleReset = () => {
    setEditedMember({ ...member });
  };

  const handleChange = (event) => {
    setEditedMember({
      ...editedMember,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setEditedMember({ ...member });
  }, [member]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      let alias = member.alias?.replace("?", "@");
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}dlmanager/members/edit_contact/${alias}/`,
          {
            name: editedMember.name,
            primarySmtpAddress: editedMember.primarySmtpAddress,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
              "X-CSRFToken": csrfToken,
            },
          },
        )
        .then(() => {
          getMembers();
          onHide();
          setAlert({
            variant: "success",
            message: "Member updated successfully!",
          });
        })
        .catch((error) => {
          console.log(error);
          setAlert({
            variant: "danger",
            message: "Error updating Member. Please try again.",
          });
        });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Mailing List Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              defaultValue={editedMember.name}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="primarySmtpAddress"
              defaultValue={editedMember.primarySmtpAddress}
              onChange={handleChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onHide}>
            Cancel
          </Button>
          <Button type="reset" onClick={handleReset} variant="warning">
            Reset
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="success">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditMailContact;
