import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Doc3DTable from "../Doc3DTable";
import Doc3DFilter from "../Filter";

const Doc3DTabs = () => {
  const sectionTabs = {
    all: {
      name: "All Pages or Sections",
      type: "all",
      tabs: [
        {
          name: "CC Certificates",
          type: ["Certificate", "Unsigned Certificate", "CC Certificate"],
        },
        { name: "Security Targets", type: ["Security Target"] },
        {
          name: "Validation Reports",
          type: ["Validation Report", "Draft Validation Report"],
        },
        {
          name: "Assurance Activity Reports (AAR)",
          type: ["Assurance Activity Report (AAR)"],
        },
        {
          name: "Administrative Guides (AGD)",
          type: ["Administrative Guide (AGD)"],
        },
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
        {
          name: "Check-in Packages",
          type: [
            "Vendor Signed Check In Package",
            "Check In Package",
            "Signed Check In Package",
            "Check-in Package",
          ],
        },
        {
          name: "Check-out Packages (Final Pkg)",
          type: [
            "Vendor Signed Check Out Package",
            "CCTL Signed Check Out Package",
            "Signed Check Out Package",
            "Check-out Package",
          ],
        },
        {
          name: "Entropy Assessment Reports (EAR)",
          type: ["Entropy Assessment Report (EAR)"],
        },
        {
          name: "Sync Meeting Notes",
          type: ["Synch Meeting Note", "Sync Meeting Note"],
        },
        { name: "Test Reports", type: ["Test Report", "Test Reports"] },
        { name: "Vendor Logos", type: ["Vendor Logo"] },
        {
          name: "Assurance Continuity Maintenance Report (ACMR)",
          type: ["Assurance Continuity Maintenance Report (ACMR)"],
        },
        {
          name: "Impact Analysis Reports (IAR)",
          type: ["Impact Analysis Report (IAR)"],
        },
        {
          name: "Protection Profiles (HTML)",
          type: ["Protection Profile (HTML)"],
        },
        {
          name: "Protection Profiles",
          type: ["Protection Profile", "Protection Profiles"],
        },
        { name: "Tabular Forms", type: ["Tabular Form"] },
        { name: "Supporting Documents", type: ["Supporting Document"] },
        { name: "PP Configuration", type: ["PP Configuration"] },
        { name: "Addenda", type: ["Addenda"] },
        { name: "DoD Annexes", type: ["DoD Annex"] },
        { name: "Control Mapping", type: ["Control Mapping"] },
        {
          name: "Mobile Application Vetting",
          type: ["Mobile Application Vetting"],
        },
        { name: "Usage Guidance", type: ["Usage Guidance"] },
        { name: "Guidance Documents", type: ["Guidance Document"] },
      ],
    },
    pclPrimary: {
      name: "PCL – Primary Documents",
      type: "product",
      tabs: [
        {
          name: "CC Certificates",
          type: ["Certificate", "Unsigned Certificate", "CC Certificate"],
        },
        { name: "Security Targets", type: ["Security Target"] },
        {
          name: "Validation Reports",
          type: ["Validation Report", "Draft Validation Report"],
        },
      ],
    },
    pclSecondary: {
      name: "PCL – Secondary Documents",
      type: "product",
      tabs: [
        {
          name: "Assurance Activity Reports (AAR)",
          type: ["Assurance Activity Report (AAR)"],
        },
        {
          name: "Administrative Guides (AGD)",
          type: ["Administrative Guide (AGD)"],
        },
      ],
    },
    pclInternal: {
      name: "PCL – International Evaluations",
      type: "ccp",
      tabs: [
        {
          name: "Assurance Activity Reports (AAR)",
          type: ["Assurance Activity Report (AAR)"],
        },
        {
          name: "Administrative Guides (AGD)",
          type: ["Administrative Guide (AGD)"],
        },
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
      ],
    },
    pclInternational: {
      name: "PCL – International Updates",
      type: "ccp",
      tabs: [
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
      ],
    },
    project: {
      name: "Project File Area (PFA) Only",
      type: "product",
      tabs: [
        {
          name: "Check-in Packages",
          type: [
            "Vendor Signed Check In Package",
            "Check In Package",
            "Signed Check In Package",
            "Check-in Package",
          ],
        },
        {
          name: "Check-out Packages (Final Pkg)",
          type: [
            "Vendor Signed Check Out Package",
            "CCTL Signed Check Out Package",
            "Signed Check Out Package",
            "Check-out Package",
          ],
        },
        {
          name: "Entropy Assessment Reports (EAR)",
          type: ["Entropy Assessment Report (EAR)"],
        },
        {
          name: "Sync Meeting Notes",
          type: ["Synch Meeting Note", "Sync Meeting Note"],
        },
        { name: "Test Reports", type: ["Test Report", "Test Reports"] },
        { name: "Vendor Logos", type: ["Vendor Logo"] },
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
      ],
    },
    assurancePrimary: {
      name: "Assurance Continuity – Primary Documents",
      type: "assurance-maintenance",
      tabs: [{ name: "Security Targets", type: ["Security Target"] }],
    },
    assuranceSeconary: {
      name: "Assurance Continuity – Secondary Documents",
      type: "assurance-maintenance",
      tabs: [
        {
          name: "Assurance Continuity Maintenance Report (ACMR)",
          type: ["Assurance Continuity Maintenance Report (ACMR)"],
        },
        {
          name: "Administrative Guides (AGD)",
          type: ["Administrative Guide (AGD)"],
        },
      ],
    },
    assuranceInternal: {
      name: "Assurance Continuity – Internal Documents",
      type: "assurance-maintenance",
      tabs: [
        {
          name: "Impact Analysis Reports (IAR)",
          type: ["Impact Analysis Report (IAR)"],
        },
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
      ],
    },
    ppPriamry: {
      name: "Protection Profiles – Primary Documents",
      type: "protection-profile",
      tabs: [
        {
          name: "Protection Profiles (HTML)",
          type: ["Protection Profile (HTML)"],
        },
        {
          name: "Protection Profiles",
          type: ["Protection Profile", "Protection Profiles"],
        },
        { name: "Tabular Forms", type: ["Tabular Form"] },
        { name: "Supporting Documents", type: ["Supporting Document"] },
        { name: "PP Configuration", type: ["PP Configuration"] },
        {
          name: "Validation Reports",
          type: ["Validation Report", "Draft Validation Report"],
        },
        {
          name: "CC Certificates",
          type: ["Certificate", "Unsigned Certificate", "CC Certificate"],
        },
        { name: "Addenda", type: ["Addenda"] },
        { name: "DoD Annexes", type: ["DoD Annex"] },
        { name: "Control Mapping", type: ["Control Mapping"] },
      ],
    },
    ppSecondary: {
      name: "Protection Profiles – Secondary Documents",
      type: "protection-profile",
      tabs: [
        {
          name: "Mobile Application Vetting",
          type: ["Mobile Application Vetting"],
        },
        { name: "Usage Guidance", type: ["Usage Guidance"] },
        {
          name: "Other Documents",
          type: ["Other Document", "Other Documents"],
        },
      ],
    },
    guidance: {
      name: "Guidance Documents",
      type: "general",
      tabs: [{ name: "Guidance Documents", type: ["Guidance Document"] }],
    },
  };

  const [selectedSection, setSelectedSection] = useState("");
  const [currentSection, setCurrentSection] = useState("all");
  const [currentTabs, setCurrentTabs] = useState(sectionTabs["all"].tabs);
  const [filterOpen, setFilterOpen] = useState(true);
  const [filters, setFilters] = useState({});
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setFilterOpen(false);
    }
  }, []);

  const handleChange = (event) => {
    setSelectedSection(event.target.value);
  };

  const handleChangeTabs = (tabId) => {
    setActiveKey(tabId);
    setFilters({});
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedSection === "") {
      return;
    }
    setCurrentSection(selectedSection);
    setCurrentTabs(sectionTabs[selectedSection].tabs);
    setActiveKey(0);
  };

  const changeFilter = () => {
    setFilterOpen(!filterOpen);
  };

  return (
    <Container fluid className="border border-secondary mt-3 pt-2">
      <h2>Dynamic Document Distribution</h2>
      <hr />
      <Row>
        <Col sm={12} md={9}>
          <Form onSubmit={handleSubmit} className="d-flex my-3">
            <Form.Group controlId="tab-select">
              <Form.Label className="visually-hidden">
                Page or Section
              </Form.Label>
              <Form.Select onChange={handleChange}>
                {Object.keys(sectionTabs).map((key, idx) => (
                  <option key={idx} value={key}>
                    {sectionTabs[key].name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Button className="ms-5" type="submit" onClick={handleSubmit}>
              Apply
            </Button>
          </Form>
        </Col>
        <Col
          sm={12}
          md={3}
          className="d-flex justify-content-end align-content-center mb-2"
        >
          <Button
            variant="link rounded-pill"
            style={{ backgroundColor: "#dedede", maxHeight: 40 }}
            onClick={changeFilter}
          >
            {filterOpen && (
              <>
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
                &nbsp; Close Filter &nbsp;
                <KeyboardArrowRightIcon />
                <KeyboardArrowRightIcon />
              </>
            )}
            {!filterOpen && (
              <>
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
                &nbsp; Open Filter &nbsp;
                <KeyboardArrowLeftIcon />
                <KeyboardArrowLeftIcon />
              </>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={filterOpen ? 2 : 12} md={filterOpen ? 9 : 12}>
          <Tabs
            activeKey={activeKey}
            mountOnEnter
            animation="true"
            onSelect={handleChangeTabs}
          >
            {currentTabs.map((tab, idx) => (
              <Tab eventKey={idx} key={idx} title={tab.name}>
                <Doc3DTable
                  title={tab.name}
                  docType={tab.type}
                  tableType={sectionTabs[currentSection].type}
                  sideFilters={filters}
                />
              </Tab>
            ))}
          </Tabs>
        </Col>
        {filterOpen && (
          <Col xs={10} md={3}>
            <Doc3DFilter filters={filters} setFilters={setFilters} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Doc3DTabs;
