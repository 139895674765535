import axios from "axios";
import { useState, useEffect } from "react";
import { Accordion, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import AddEventLogModal from "./AddEventLogModal";

export default function EventLogAccordion({ product }) {
  const [eventsCount, setEventsCount] = useState({});
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const { authToken } = useAuth();

  const fetchEvents = async () => {
    if (product?.product_id)
      await axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}event/log/get_event_counts_by_vid/?v_id=${product?.v_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setEventsCount(response.data);
        })
        .catch((error) => console.log(error));
  };

  useInterval(() => {
    const fetch = async () => {
      fetchEvents();
    };
    fetch();
  }, 60000); //1 min

  useEffect(() => {
    fetchEvents();
  }, [product?.product_id, showAddEventModal]);

  return (
    <>
      <AddEventLogModal
        show={showAddEventModal}
        onHide={() => setShowAddEventModal(false)}
        product={product}
      />
      <Accordion flush className="border border-dark mb-3" defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header className="bg-secondary">
            <h6 className="sidebar-title">Events for VID {product?.v_id}</h6>
          </Accordion.Header>
          <Accordion.Body className="bg-light p-1">
            <Container>
              <Row>
                <Col>
                  <Row className="text-center">
                    <Button
                      as={Link}
                      to={`eventlog/${product?.v_id}`}
                      className="text-decoration-underline"
                      variant="link"
                    >
                      There are {eventsCount?.total} events logged about this
                      project.
                    </Button>
                  </Row>
                  <Row className="text-center">
                    <p>
                      {eventsCount?.direct} events for this project directly.
                    </p>
                  </Row>
                  <Row className="d-flex justify-content-center mb-2">
                    <Button
                      variant="primary"
                      style={{ width: "fit-content" }}
                      onClick={() => setShowAddEventModal(true)}
                    >
                      Add Ad-hoc Event
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
