import { createSlice } from "@reduxjs/toolkit";

import { AUTH_ROLES } from "api/common/types/auth.types";

import { AppPermission } from "./role.types";

const initialState: { value: AppPermission } = {
  value: {
    role_id: -1,
    role_name: AUTH_ROLES.UNAUTHORIZED,
    role_type: AUTH_ROLES.UNAUTHORIZED,
    deleted: false,
    is_lead: false,
    is_senior: false,
    role_permissions: [],
  },
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    addRole: (state, role) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = role.payload;
    },
    removeRole: (state) => {
      state.value = initialState.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addRole, removeRole } = roleSlice.actions;

export default roleSlice.reducer;
