import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState } from "react";
import { Button, Accordion, ButtonGroup, Card } from "react-bootstrap";

import "moment-timezone";

import { useAuth } from "hooks/useAuth";

import EarNoteModal from "../modals/EarNoteModal/EarNote.modal";

export default function EarNotes({ review, fetchNotes, notes }) {
  const [showNotes, setShowNotes] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const { authToken, csrfToken, currentUser } = useAuth();

  const editNote = async (note) => {
    setCurrentNote({ ...note, isEdit: true });
    setShowNotes(true);
  };

  const deleteNote = (note_id) => {
    axios
      .delete(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/${note_id}/`, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
        },
      })
      .then(() => {
        fetchNotes();
      })
      .catch((error) => console.log(error));
  };

  return (
    <Accordion className="my-4" flush>
      <Accordion.Item>
        <Accordion.Header>
          <h6 className="fw-bold text-dark">NOTES</h6>
        </Accordion.Header>
        <Button
          variant="secondary rounded-pill mb-1"
          onClick={() => setShowNotes(true)}
        >
          Add a Note
        </Button>
        <Accordion.Body>
          {notes?.map((note) => {
            return (
              <Card key={note?.note_id} className="mb-3">
                <Card.Body>
                  <Card.Title></Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    By {note?.submitted_by?.first_name}{" "}
                    {note?.submitted_by?.last_name} on&nbsp;
                    {moment
                      .utc(note?.submitted_on)
                      .format("YYYY.MM.DD [at] HHmm")}
                  </Card.Subtitle>
                  <Card.Text style={{ whiteSpace: "pre-wrap" }}>
                    {note?.note && parse(note?.note)}
                  </Card.Text>
                </Card.Body>
                {note?.submitted_by?.company ===
                  currentUser?.company?.org_id && (
                  <Card.Footer>
                    <ButtonGroup size="sm" className="ms-2">
                      <Button
                        variant="outline-dark"
                        onClick={() => editNote(note)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outline-dark"
                        onClick={() => deleteNote(note?.note_id)}
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Card.Footer>
                )}
              </Card>
            );
          })}
          <EarNoteModal
            show={showNotes}
            handleClose={() => {
              setCurrentNote({});
              setShowNotes(false);
            }}
            fetchNotes={fetchNotes}
            currentNote={currentNote}
            review={review}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
