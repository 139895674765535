import { type RouteObject } from "react-router-dom";

import Community from "components/Community/Community.screen";
import EarReviewsTable from "features/memberPortal/EAR/components/EarReviewsTable/EarReviewsTable";
import EarManager from "features/memberPortal/EAR/Ear.screen";
import EarDetails from "features/memberPortal/EAR/EarDetails/EarDetails.screen";

const authenticatedRoutes: RouteObject[] = [
  {
    element: <Community />,
    children: [
      // This probably isnt the best way to do this, but the Community screen doesn't render without it¯\_(ツ)_/¯

      { index: true, element: <></> },
      {
        path: "ear-reviews/",
        element: <EarManager />,
        children: [
          {
            index: true,
            element: (
              // @ts-ignore - need to properly type underlying table
              <EarReviewsTable filter={true} showAddEarButton={true} />
            ),
          },
          { path: "details/:id", element: <EarDetails /> },
        ],
      },
    ],
  },

  // TODO: replace the * with the actual routes and pull out of community.screen
  { path: "*", element: <Community /> },
];

export default authenticatedRoutes;
