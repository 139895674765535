import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Route, Routes, useLocation, Link } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

import UnauthorizedView from "../UI/UnauthorizedView";

import Doc3DTabs from "./Doc3DTabs";
import DocumentTree from "./DocumentTree";
import FileDetails from "./FileDetails";

const DocumentManager = () => {
  const location = useLocation();

  const { permissions } = useAuth();
  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Document Manager",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  const ValidatorRoutes = () => {
    return (
      <Routes>
        <Route path="" element={<DocumentTree page="Validator" />} />
      </Routes>
    );
  };

  const ValidatorButtons = () => {
    return (
      <Row>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Validator
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  const CctlRoutes = () => {
    return (
      <Routes>
        <Route path="" element={<DocumentTree page="Lab" />} />
      </Routes>
    );
  };

  const CctlButtons = () => {
    return (
      <Row>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Lab
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  const TechRoutes = () => {
    return (
      <Routes>
        <Route path="" element={<DocumentTree page="Tech Community" />} />
      </Routes>
    );
  };

  const TechButtons = () => {
    return (
      <Row>
        <Col xl={2} md={2} sm={3}>
          <Link to="/community/documents">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Tech Community
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  const NiapRoutes = () => {
    return (
      <Routes>
        <Route path="niap" element={<DocumentTree page="NIAP" />} />
        <Route path="validator" element={<DocumentTree page="Validator" />} />
        <Route path="lab" element={<DocumentTree page="Lab" />} />
        <Route
          path="tech-community"
          element={<DocumentTree page="Tech Community" />}
        />
        <Route path="details/:id" element={<FileDetails />} />
        <Route path="" element={<Doc3DTabs />} />
      </Routes>
    );
  };

  const NiapButtons = () => {
    return (
      <Row>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents"
                  ? "primary"
                  : "outline-dark"
              }
            >
              3D Files
            </Button>
          </Link>
        </Col>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents/niap">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents/niap"
                  ? "primary"
                  : "outline-dark"
              }
            >
              NIAP
            </Button>
          </Link>
        </Col>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents/validator">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents/validator"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Validator
            </Button>
          </Link>
        </Col>
        <Col xl={1} md={2} sm={3}>
          <Link to="/community/documents/lab">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents/lab"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Lab
            </Button>
          </Link>
        </Col>
        <Col xl={2} md={2} sm={3}>
          <Link to="/community/documents/tech-community">
            <Button
              className="px-3"
              variant={
                location.pathname === "/community/documents/tech-community"
                  ? "primary"
                  : "outline-dark"
              }
            >
              Tech Community
            </Button>
          </Link>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid className="p-3">
      <h2>File Area</h2>
      {permissions.role_type === "NIAP" && <NiapButtons />}
      {permissions.role_type === "Lab" && <CctlButtons />}
      {permissions.role_type === "Validator" && <ValidatorButtons />}
      {permissions.role_type === "Tech Community" && <TechButtons />}
      {permissions.role_type === "NIAP" && <NiapRoutes />}
      {permissions.role_type === "Lab" && <CctlRoutes />}
      {permissions.role_type === "Validator" && <ValidatorRoutes />}
      {permissions.role_type === "Tech Community" && <TechRoutes />}
    </Container>
  );
};

export default DocumentManager;
