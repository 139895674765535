import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  OverlayTrigger,
  Button,
  Popover,
} from "react-bootstrap";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import EventModal from "./EventModal";

export default function EventsTable() {
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [filtering, setFiltering] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [event, setEvent] = useState({});
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const { authToken } = useAuth();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  useInterval(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, 300000); //5 min

  const handleShowEventModal = (rowData) => {
    if (rowData) {
      setEvent(rowData);
    } else {
      setEvent({});
    }
    setShowEventModal(!showEventModal);
  };

  const getDateStatus = (startDate, endDate) => {
    const currentDateObj = new Date();
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (currentDateObj < startDateObj) {
      return "Future";
    } else if (currentDateObj >= startDateObj && currentDateObj <= endDateObj) {
      return "Current";
    } else {
      return "Past";
    }
  };

  const statusFilter = (rowData) => {
    const status = getDateStatus(rowData.startDate, rowData.endDate);
    return status.toLowerCase();
  };

  const columns = [
    {
      title: "Status",
      render: (rowData) => getDateStatus(rowData.begins, rowData.expires),
      customFilterAndSearch: (rowData) => statusFilter(rowData),
      filtering: false,
    },
    {
      title: "Type",
      field: "subject",
    },
    {
      title: "Title",
      field: "title",
    },
    {
      title: "Beginning Date",
      field: "begins",
      render: (rowData) => {
        return rowData?.begins !== null
          ? moment.utc(rowData.begins).format("MM/DD/YYYY")
          : "";
      },
      type: "date",
    },
    {
      title: "Ending Date",
      field: "expires",
      render: (rowData) => {
        return rowData?.expires !== null
          ? moment.utc(rowData.expires).format("MM/DD/YYYY")
          : "";
      },
      type: "date",
    },
    { title: "Event Venue", field: "venue" },
    { title: "Location", field: "location" },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              key="bottom"
              overlay={
                <Popover>
                  <Popover.Body>
                    <Button
                      variant="link"
                      className="m-1 p-0 btn-link"
                      onClick={() => handleShowEventModal(rowData)}
                    >
                      <EditIcon color="action" /> Edit
                    </Button>
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="link">
                <MoreVertIcon className="float-end" />
                <span className="visually-hidden">Actions</span>
              </Button>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  const options = {
    showTitle: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: filtering,
    pageSize: 20,
  };

  return (
    <Container fluid>
      <EventModal
        show={showEventModal}
        handleShow={handleShowEventModal}
        refetch={() => {
          setRefresh(!refresh);
        }}
        setAlert={setAlert}
        event={Object.keys(event)?.length > 0 ? event : null}
      />
      <Row>
        {alert.message !== "" && (
          <Row className="d-flex justify-content-center mt-3">
            <Col sm={8}>
              <Alert
                variant={alert.type}
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            </Col>
          </Row>
        )}
        <Col>
          <Row className="mt-5 mb-3">
            <Col sm={12} md={9}>
              <h3 className="text-bright-navy">EVENTS MANAGER</h3>
            </Col>
            <Col sm={12} md={3} className="d-flex justify-content-end">
              <div>
                <Button
                  variant="primary"
                  className="me-3 rounded-0"
                  onClick={() => setShowEventModal(true)}
                >
                  + Add Event
                </Button>
                <Button
                  className="account-btn"
                  variant={filtering ? "primary" : "light"}
                  onClick={(e) => setFiltering(!filtering)}
                >
                  Filter
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <ResponsiveMaterialTable
              title={"EVENTS"}
              columns={columns}
              tableRef={tableRef}
              data={(query) =>
                new Promise((resolve, reject) => {
                  // Extract the necessary information from the query object
                  const {
                    page,
                    pageSize,
                    search,
                    filters,
                    orderBy,
                    orderDirection,
                  } = query;

                  let newFilters = filters.map((filter) => {
                    let value = "";
                    if (Array.isArray(filter.value)) {
                      value = filter.value;
                    } else if (
                      ["begins", "expires"]?.includes(filter?.column?.field)
                    ) {
                      const dateObj = new Date(filter?.value);
                      const isoStr = dateObj.toISOString();
                      const formattedStr = isoStr.split("T")[0];
                      value = formattedStr;
                    } else {
                      value = filter.value.replace(/['"]+/g, "");
                    }
                    return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                  });
                  const params = {
                    offset: page * pageSize,
                    limit: pageSize,
                    search: search,
                    filters: newFilters,
                    orderBy: orderBy?.field,
                    orderDirection: orderDirection,
                  };
                  axios
                    .get(
                      `${process.env.REACT_APP_DJANGO_ENDPOINT}publish/announcements/get_events/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                      {
                        withCredentials: true,
                        headers: {
                          Authorization: `Token ${authToken}`,
                        },
                      },
                    )
                    .then((response) => {
                      resolve({
                        data: response.data.results,
                        page: page,
                        totalCount: response.data.count,
                      });
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              }
              options={options}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
