import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import QADetails from "./QADetails";
import QATable from "./QATable";

export default function QA() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<QATable manager={true} />} />
      <Route path="details/:id" element={<QADetails />} />
    </Routes>
  );
}
