import Container from "react-bootstrap/Container";

import { useAuth } from "hooks/useAuth";

import CctlDashboard from "./DashboardTypes/CctlDashboard";
import NIAPDashboard from "./DashboardTypes/NIAPDashboard";
import SMEDashboard from "./DashboardTypes/SMEDashboard";
import ValidatorDashboard from "./DashboardTypes/ValidatorDashboard";
import VendorDashboard from "./DashboardTypes/VendorDashboard";

export default function Dashboard({ selectedDashBoard }) {
  const { permissions } = useAuth();

  return (
    <Container fluid>
      {(permissions.role_type === "Lab" || selectedDashBoard === "Lab") && (
        <CctlDashboard />
      )}
      {permissions.role_type === "NIAP" &&
        (selectedDashBoard === "NIAP" || selectedDashBoard === "") && (
          <NIAPDashboard />
        )}
      {(permissions.role_type === "Validator" ||
        selectedDashBoard === "Validator") && <ValidatorDashboard />}
      {(permissions.role_type === "Vendor" ||
        selectedDashBoard === "Vendor") && <VendorDashboard />}
      {(permissions.role_type === "SME" || selectedDashBoard === "SME") && (
        <SMEDashboard />
      )}
    </Container>
  );
}
