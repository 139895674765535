import axios from "axios";
import qs from "qs";
import { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Form,
  Alert,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";

import ReactSelect from "components/ReactSelect";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

export default function ManageEvalTeamModal({
  show,
  setShow,
  currentProductInEval,
  switchForms,
  isSwitch,
  setReloadFormData,
}) {
  const [availableLabMembers, setAvailableLabMembers] = useState([]);
  const [selectedLabMembers, setSelectedLabMembers] = useState([]);
  const [productsInEval, setProductsInEval] = useState([]);
  const [alert, setAlert] = useState({});
  const [myOrg, setMyOrg] = useState({});
  const [evaluations, setEvaluations] = useState([]);
  const [newLabMember, setNewLabMember] = useState({});
  const [fullMembersInfo, setFullMembersInfo] = useState([]);
  const { authToken, csrfToken, currentUser } = useAuth();

  //get currentUser's Org
  const getCurrentUser = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/my_org/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setMyOrg(response.data);
      })
      .catch((error) => {
        console.log("error getting user org", error);
      });
  }, []);

  useEffect(() => {
    if (show) {
      getCurrentUser();
    }
  }, [authToken, getCurrentUser]);

  const handleClose = () => {
    setShow(false);
    setAlert({});
  };

  //get all productsInEval from project/personnel
  const fetchEvalProducts = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?submission_type=Evaluation`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProductsInEval(response.data.results);
      })
      .catch((error) => {
        console.log("error getting products", error);
      });
  }, [authToken]);

  //get eval team from project/personnel for product
  const fetchSelectedPersonnel = useCallback(() => {
    if (currentProductInEval?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?team=Evaluation&product=${currentProductInEval?.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setSelectedLabMembers(response.data.results);
        })
        .catch((error) => {
          console.log("error getting selected personnel", error);
        });
    }
  }, [authToken, currentProductInEval?.product_id]);

  //get orgUsers from org/personnel/myOrgUsers
  const fetchAvailableEvaluators = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/available_evaluators/?product=${currentProductInEval?.product_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setFullMembersInfo(response.data);
        let availableMemberOptions = response.data.map((member) => ({
          value: member?.user_id?.id,
          label: member?.user_id?.first_name + " " + member?.user_id?.last_name,
        }));
        setAvailableLabMembers(availableMemberOptions);
      })
      .catch((error) => {
        console.log("error getting lab members", error);
      });
  }, [currentProductInEval?.product_id, productsInEval]);

  const fetchEvaluations = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/filter_evaluations/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setEvaluations(response.data);
      })
      .catch((error) => console.log(error));
  }, [authToken]);

  useEffect(() => {
    if (show) {
      fetchEvalProducts();
    }
  }, [fetchEvalProducts, show]);

  useEffect(() => {
    if (show) {
      fetchSelectedPersonnel();
    }
  }, [fetchSelectedPersonnel]);

  useEffect(() => {
    if (show) {
      fetchAvailableEvaluators();
    }
  }, [fetchAvailableEvaluators, show]);

  useEffect(() => {
    if (show) {
      fetchEvaluations();
    }
  }, [fetchEvaluations, show]);

  const refetchEvalInfo = () => {
    fetchEvalProducts();
    fetchAvailableEvaluators();
    fetchSelectedPersonnel();
  };

  const handleChange = (e) => {
    setNewLabMember({ ...newLabMember, [e.target.name]: e.target.value });
  };

  const handleRemoveMember = async (rowData) => {
    await axios
      .delete(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/${rowData.id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        setAlert({ type: "Successfully Removed Member!", variant: "success" });
        refetchEvalInfo();
      })
      .catch(() =>
        setAlert({ type: "Error Removing Member.", variant: "danger" }),
      );
  };

  const handleAddMember = async () => {
    if (newLabMember["role"] && newLabMember["evaluator"]) {
      await axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/`,
          {
            pp_team_id: myOrg.org_id,
            assigned_on: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            role: newLabMember["role"],
            product_id: currentProductInEval?.product_id,
            user_id: newLabMember["evaluator"],
            assigned_by: currentUser?.id,
            team_type: "Evaluation",
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          setAlert({
            type: "Successfully Added New Member!",
            variant: "success",
          });
          refetchEvalInfo();
        })
        .catch(() =>
          setAlert({ type: "Error Adding Member.", variant: "danger" }),
        );
    } else {
      setAlert({
        type: "Please select both a name and a role to add member.",
        variant: "danger",
      });
    }
  };

  const getEvaluationsByUser = (user_id) => {
    let validationLength = evaluations.filter(
      (evals) => evals.user_id === user_id,
    ).length;
    return validationLength;
  };

  const findPreRoleOfMember = (user_id) => {
    let membersRole = fullMembersInfo.find(
      (member) => member?.user_id?.id === user_id,
    )?.role;
    if (membersRole) {
      return membersRole;
    } else {
      return "No Pre-Role";
    }
  };

  let filteredAvailableLabMembers = availableLabMembers.filter(
    (evaluator) =>
      !selectedLabMembers.some(
        (member) => member?.user_id?.id === evaluator.value,
      ),
  );

  const columns = [
    {
      title: "Name",
      field: "user_id",
      render: (rowData) =>
        rowData.user_id &&
        rowData.user_id.first_name + " " + rowData.user_id.last_name,
      editComponent: () => (
        <ReactSelect
          options={filteredAvailableLabMembers}
          newObj={newLabMember}
          handleChange={handleChange}
          name={"evaluator"}
          isValid={true}
          editComponent={true}
          id="eval_team_select"
        />
      ),
    },
    {
      title: "Active Evaluations",
      field: "user_id",
      render: (rowData) => <>{getEvaluationsByUser(rowData?.user_id?.id)}</>,
      customSort: (a, b) => getEvaluationsByUser(a) - getEvaluationsByUser(b),
      customFilterAndSearch: (input, rowData) => {
        let matcher = new RegExp(input, "i");
        if (matcher.test(getEvaluationsByUser(rowData?.user_id?.id))) {
          return true;
        }
      },
      editComponent: () => {
        if (newLabMember["evaluator"]) {
          return (
            <p className="m-0">
              {getEvaluationsByUser(newLabMember["evaluator"])}
            </p>
          );
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Pre-Roles",
      field: "role",
      editComponent: () => {
        if (newLabMember["evaluator"]) {
          return (
            <p className="m-0">
              {findPreRoleOfMember(newLabMember["evaluator"])}
            </p>
          );
        } else {
          return <p className="m-0">Select Name...</p>;
        }
      },
    },
    {
      title: "Assigned",
      field: "role",
      editComponent: () => (
        <>
          <Form.Select
            name="role"
            onChange={handleChange}
            value={newLabMember?.role ?? ""}
            id="eval_team_role_assignment"
          >
            <option value="">Select</option>
            <option value="Trainee">Trainee</option>
            <option value="Evaluator">Evaluator</option>
            <option value="Lead Evaluator">Lead Evaluator</option>
            <option value="Sr. Evaluator">Sr. Evaluator</option>
            <option value="Staff Liaison">Staff Liaison</option>
          </Form.Select>
        </>
      ),
    },
  ];

  const options = {
    sorting: true,
    pageSize: 10,
  };

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header
        closeButton
        onClose={() => setShow(false)}
        className="border-0"
      >
        {isSwitch ? (
          <Container>
            <Row>
              <Col xs={12} sm={4}>
                <Button
                  className="primary"
                  onClick={() => {
                    handleClose();
                    switchForms();
                    setReloadFormData(selectedLabMembers.length);
                  }}
                >
                  BACK TO EVALUATION PROJECT
                </Button>
              </Col>
              <Col className="text-uppercase m-0" xs={12} sm={8}>
                <h4>Manage and Assign Evaluation Team</h4>
              </Col>
            </Row>
            <Row className="mt-4 m-auto">
              <Col sm={12} className="d-flex justify-content-center">
                <p className="m-0 fw-bold">
                  Lead Evaluator Required for Evaluation Team Assignment to
                  Submit Check-In Package
                </p>
              </Col>
            </Row>
          </Container>
        ) : (
          <Modal.Title className="text-uppercase me-2">
            Manage and Assign Evaluation Team
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {alert.type && (
          <Alert
            variant={alert.variant}
            className="m-3"
            onClose={() => setAlert({})}
            dismissible
          >
            {alert.type}
          </Alert>
        )}
        <ResponsiveMaterialTable
          title="Evaluation Team"
          options={options}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });
              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
                team: "Evaluation",
                product: currentProductInEval?.product_id,
              };
              axios
                .get(
                  `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/?${qs.stringify(
                    params,
                    { arrayFormat: "comma" },
                  )}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count || 0,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          columns={columns}
          editable={{
            onRowAdd: () => handleAddMember(),
            onRowDelete: (rowData) => handleRemoveMember(rowData),
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
