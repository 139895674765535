import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import MethodDetails from "./AddMethodDetails/AddMethodDetails.screen";
import MFAConfirm from "./LoginMFAConfirm/LoginMfaConfirm.screen";
import MFADisplayQR from "./LoginMFADisplayQR/LoginMfaDisplayQr.screen";
import MFASelect from "./LoginMFASelect/LoginMfaSelect.screen";

const AddMfa = ({ show, handleCloseModal, userInfo, setUserInfo }) => {
  const [step, setStep] = useState("select");
  const [mfaMethod, setMfaMethod] = useState("");
  const [qrCode, setQrCode] = useState("");

  return (
    <Modal size="md" show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add MFA Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === "select" && (
          <MFASelect
            setStep={setStep}
            setMfaMethod={setMfaMethod}
            setQrCode={setQrCode}
          />
        )}
        {step === "add-detail" && (
          <MethodDetails setStep={setStep} mfaMethod={mfaMethod} />
        )}
        {step === "qr" && <MFADisplayQR setStep={setStep} qrCode={qrCode} />}
        {step === "confirm" && (
          <MFAConfirm
            mfaMethod={mfaMethod}
            handleCloseModal={handleCloseModal}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddMfa;
