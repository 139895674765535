import axios from "axios";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";

import "scss/custom-radio-button.css";
import { isErrorWithDetails } from "api/common/utils/rtkq.utils";
import { useMfaActivateMutation } from "api/public/auth/auth.api";
import { MfaMethod } from "api/public/auth/auth.types";
import { useAuth } from "hooks/useAuth";

const MFAselect = () => {
  const { tempToken } = useAuth();
  const navigate = useNavigate();

  const [user, setUser] = useState({ phone_number: "", email: "" });
  const [method, setMethod] = useState<{ method: MfaMethod }>({
    method: "email",
  });

  const [triggerActivate, activateResponse] = useMfaActivateMutation();
  const { error, isError } = activateResponse;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMethod({ ...method, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const mfaMethod = method.method;

    if (mfaMethod === "email" && !user.email) {
      navigate("/login/add-details?method=email");
    } else if (mfaMethod === "sms_twilio" && !user.phone_number) {
      navigate("/login/add-details?method=sms_twilio");
    } else {
      const details = await triggerActivate({
        method: mfaMethod,
        tempToken: tempToken,
      });

      if (mfaMethod === "app") {
        navigate(`/login/mfa-qr/?qr-code=${details}`);
      }
      navigate(`/login/mfa-confirm?mfa-method=${mfaMethod}`);
    }
  };

  useEffect(() => {
    const loadUser = () => {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}account/users/currentUser/`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${tempToken}` },
          },
        )
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    };
    loadUser();
  }, [tempToken]);

  return (
    <Card className="login-card">
      <Card.Body>
        <Card.Title as="h4">Choose Verification Method</Card.Title>
        <Card.Subtitle as="p">
          For additional security NIAP requires you select a method to receive
          one time codes
        </Card.Subtitle>
        <Form className="centered-form" onSubmit={handleSubmit}>
          <Form.Group className="mb-4 text-start" controlId="method">
            <Form.Check
              type="radio"
              label="Email"
              name="method"
              value="email"
              onChange={handleChange}
              id="method1"
            />
            <p className="small">Your Email: {user.email}</p>
            <Form.Check
              type="radio"
              label="SMS"
              name="method"
              value="sms_twilio"
              onChange={handleChange}
              id="method2"
            />
            <span className="small">
              By selecting to receive MFA codes by SMS you consent to receive
              text messages from us. Message and data rates may apply. Messages
              will be sent on each login.
            </span>
            <p className="small">Your Phone Number: {user.phone_number}</p>
            <Form.Check
              type="radio"
              label="Application"
              name="method"
              value="app"
              onChange={handleChange}
              id="method3"
            />
            <p className="small">Such as Authy or Google Authenticator</p>
          </Form.Group>
          {isError && isErrorWithDetails(error) && (
            <p className="mb-4 text-danger">{error.details}</p>
          )}
          <Button variant="warning" type="submit">
            Continue
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MFAselect;
