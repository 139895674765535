import axios from "axios";
import qs from "qs";
import { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";

const NISTAssignAgentModal = ({ show, onHide, certReview, agents, update }) => {
  const [availableAgents, setAvailableAgents] = useState([]);
  const { authToken, csrfToken } = useAuth();

  const loadAvailableAgents = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/ncrt_team_members/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setAvailableAgents(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    loadAvailableAgents();
  }, []);

  const assignMember = (event, rowData) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/`,
        {
          pid: certReview?.pid?.product_id,
          crid: certReview?.crid,
          account_id: rowData.account_id.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        update();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeMember = (event, rowData) => {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}nist/agents/remove_by_values/`,
        {
          pid: certReview?.pid?.product_id,
          crid: certReview?.crid,
          account_id: rowData.account_id.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then(() => {
        update();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    { title: "First Name", field: "account_id.first_name" },
    { title: "Last Name", field: "account_id.last_name" },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 10,
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h4>
          NIST Certificate Team Reviewer(s) Details for VID{" "}
          {certReview?.pid?.v_id}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="py-4">
          <h6>Current Team:</h6>
          {agents.map((agent, idx) => (
            <p key={idx} className="mb-0 ms-3">
              {agent?.account_id?.first_name} {agent?.account_id?.last_name}
            </p>
          ))}
        </div>
        <ResponsiveMaterialTable
          title={"NCERT Team Members"}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              // Extract the necessary information from the query object
              const {
                page,
                pageSize,
                search,
                filters,
                orderBy,
                orderDirection,
              } = query;

              let newFilters = filters.map((filter) => {
                let value = "";
                if (Array.isArray(filter.value)) {
                  value = filter.value;
                } else {
                  value = filter.value.replace(/['"]+/g, "");
                }
                return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
              });
              const params = {
                offset: page * pageSize,
                limit: pageSize,
                search: search,
                filters: newFilters,
                orderBy: orderBy?.field,
                orderDirection: orderDirection,
              };
              axios
                .get(
                  `${
                    process.env.REACT_APP_DJANGO_ENDPOINT
                  }virtual-team/members/ncrt_team_members/?${qs.stringify(
                    params,
                    {
                      arrayFormat: "comma",
                    },
                  )}`,
                  {
                    withCredentials: true,
                    headers: {
                      Authorization: `Token ${authToken}`,
                    },
                  },
                )
                .then((response) => {
                  resolve({
                    data: response.data.results,
                    page: page,
                    totalCount: response.data.count,
                  });
                })
                .catch((error) => {
                  reject(error);
                });
            })
          }
          options={options}
          actions={[
            (rowData) => ({
              icon: "add",
              tooltip: "Add Agent",
              onClick: (event, rowData) => assignMember(event, rowData),
              disabled: agents.some(
                (agent) => agent?.account_id?.id === rowData?.account_id?.id,
              ),
            }),
            (rowData) => ({
              icon: "remove",
              tooltip: "Remove Agent",
              onClick: (event, rowData) => removeMember(event, rowData),
              disabled: !agents.some(
                (agent) => agent?.account_id?.id === rowData?.account_id?.id,
              ),
            }),
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" onClick={onHide} variant="success">
          Complete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NISTAssignAgentModal;
