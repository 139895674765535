import { createSlice } from "@reduxjs/toolkit";

import { AUTH_ROLES } from "api/common/types/auth.types";

import { CurrentUser } from "./user.types";

const initialState: CurrentUser = {
  id: -1,
  last_login: null,
  is_superuser: false,
  username: "",
  first_name: "",
  last_name: "",
  email: "",
  is_staff: false,
  is_active: true,
  date_joined: null,
  phone_number: "",
  fax: null,
  position: null,
  alt_phone: null,
  alt_email: null,
  notification_email: null,
  subscription_email: null,
  scheme_dir: false,
  street_address: "",
  city: "",
  state: null,
  country: null,
  zip: null,
  created_on: null,
  edited_on: null,
  on_duty_agent: false,
  daily_tally: 0,
  active_tally: null,
  session_tally: 0,
  active_date: null,
  daily_date: null,
  user_role: {
    role_id: 0,
    role_name: AUTH_ROLES.UNAUTHORIZED,
    role_type: AUTH_ROLES.UNAUTHORIZED,
    deleted: false,
    is_lead: false,
    is_senior: false,
  },
  account_group: null,
  scheme: {},
  company: {},
  creator: null,
  editor: null,
  affiliation: null,
  groups: [],
  user_permissions: [],
  full_name: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: initialState,
  },
  reducers: {
    addUser: (state, user) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = user.payload;
    },
    removeUser: (state) => {
      state.value = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
