import { type RouteObject } from "react-router-dom";

import AddDetails from "features/public/Login/AddMethodDetails/AddMethodDetails.screen";
import CheckEmail from "features/public/Login/CheckEmail/CheckEmail.screen";
import ForgotPassword from "features/public/Login/ForgotPassword/ForgotPassword.screen";
import LoginForm from "features/public/Login/LoginForm/LoginForm.screen";
import MFAConfirm from "features/public/Login/LoginMFAConfirm/LoginMfaConfirm.screen";
import MFADisplayQR from "features/public/Login/LoginMFADisplayQR/LogifMfaDisplayQr.screen";
import MFAselect from "features/public/Login/LoginMFASelect/LoginMfaSelect.screen";
import ResendCode from "features/public/Login/LoginResendCode/LoginResendCode.screen";
import LoginSuccess from "features/public/Login/LoginSuccess/LoginSuccess.screen";
import ResetPassword from "features/public/Login/ResetPassword/ResetPassword.screen";
import ResetSuccess from "features/public/Login/ResetSuccess/ResetSuccess.screen";

const loginRoutes: RouteObject[] = [
  {
    path: "/login",
    children: [
      { index: true, element: <LoginForm /> },
      {
        path: "mfa-select",
        element: <MFAselect />,
      },
      { path: "resend-code", element: <ResendCode /> },
      {
        path: "mfa-confirm",
        element: <MFAConfirm />,
      },
      {
        path: "mfa-qr",
        element: <MFADisplayQR />,
      },
      { path: "success", element: <LoginSuccess /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "check-email", element: <CheckEmail /> },
      {
        path: "add-details",
        element: <AddDetails />,
      },
      { path: "reset-password/:token?", element: <ResetPassword /> },
      { path: "reset-success", element: <ResetSuccess /> },
    ],
  },
];

export default loginRoutes;
