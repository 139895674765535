import { Modal, Container, Row, Col } from "react-bootstrap";

import approvedPPImg from "images/pp_page/approvedCCTL.png";

export default function ApprovedPPModal({ show, setShowApprovedPPModal }) {
  const handleClose = () => {
    setShowApprovedPPModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={approvedPPImg}
                      alt="approved protection profile image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Approved Protection Profile
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <p>
                  To ensure consistency in evaluations, all schemes currently
                  evaluating or considering evaluation of products compliant
                  with NIAP-approved Protection Profiles (PP) should notify
                  niap@niap-ccevs.org. The notification should include the
                  product name, vendor, evaluation start date, and NIAP-approved
                  PP/EP with which compliance is being claimed. NIAP will
                  collaborate with the scheme to address questions, provide
                  guidance, and solicit feedback for improvements to the PPs.
                </p>
              </Row>
              <Row>
                <p>
                  Effective October 1, 2009, any product accepted into
                  evaluation under the U.S. CC Scheme must claim compliance to a
                  NIAP-approved PP. The following Protection Profiles (PP) have
                  been approved for use by vendors for evaluation of products
                  under the NIAP Common Criteria Evaluation and Validation
                  Scheme (CCEVS) and the Common Criteria Recognition Arrangement
                  (CCRA).
                </p>
              </Row>
              <Row>
                <p>
                  NIAP is currently working with industry, our customers, and
                  the Common Criteria community to create Protection Profiles
                  for each technology. These Protection Profiles include
                  assurance activities with the goal of achievable, repeatable
                  and testable evaluation activities for each particular
                  technology (see PPs in Development for a status of each PP).
                </p>
              </Row>
              <Row>
                <p>
                  For those technologies where a PP does not yet exist or is not
                  in development, NIAP will work with the vendor and/or customer
                  to offer a path to evaluation. Please see our Guidelines for
                  When No PP Exists for more information.
                </p>
              </Row>
              <Row>
                <p>
                  A Transition End Date of a PP is determined by NIAP (in
                  cooperation with the applicable Technical Community) upon
                  initial publication of a NIAP-approved PP and is posted with
                  the publication of the PP. Typically, the Transition Window
                  for a PP will last six months. During the Transition Window,
                  products will be accepted into evaluation against the
                  preceding version of the PP. All relevant products submitted
                  for evaluation after the Transition End Date shall only be
                  evaluated against the new NIAP-approved PP.
                </p>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
