import parse from "html-react-parser";
import moment from "moment";
import { ReactNode, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  Breadcrumb,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";

import { useGetPublicTdQuery } from "api/public/technicalDecisions/technicalDecisions.api";
import Subheader from "components/Subheader";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import UnauthorizedView from "components/UI/UnauthorizedView";

import "./tds.css";

const TdPageWrapper = ({
  identifier,
  children,
}: {
  identifier: string;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Subheader pageName={"Technical Decisions"} />
      <Container className="p-5" fluid>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate(`/technical-decisions/`)}>
            Technical Decisions
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{identifier}</Breadcrumb.Item>
        </Breadcrumb>

        {children}
      </Container>
    </>
  );
};

export default function TechDecisionDetails() {
  const { id = "" } = useParams();

  const { hideLoadingSpinner, showLoadingSpinner } = useLoading();
  const { data: td, isLoading, isError } = useGetPublicTdQuery(id);

  const {
    identifier = "",
    title = "",
    publication_date = "",
    protection_profile = [],
    sunset_protection_profile = [],
    related_to = "",
    description = "",
    resolution = "",
    justification = "",
  } = td || {};

  useEffect(() => {
    if (isLoading) {
      showLoadingSpinner("Loading");
    } else {
      hideLoadingSpinner();
    }
  }, [hideLoadingSpinner, isLoading, showLoadingSpinner]);

  if (isError) {
    return (
      <TdPageWrapper identifier={identifier}>
        <UnauthorizedView />
      </TdPageWrapper>
    );
  }

  return (
    <TdPageWrapper identifier={identifier}>
      <Row className="mb-4">
        <Col>
          <h6 className="fw-bold">TECHNICAL DECISION</h6>
          <h3 className="fw-bold">{title}</h3>
        </Col>
      </Row>

      <Row className="gx-2">
        <Col sm={12} md={3} lg={2} className="bg-dark text-white mb-2">
          <Stack className="border-bottom pt-4 ps-4">
            <small className="pb-2">Technical Decision</small>
            <p>{identifier}</p>
          </Stack>

          <Stack className="border-bottom pt-4 ps-4">
            <small className="pb-2">Publication Date</small>
            <p>{moment.utc(publication_date).format("MM/DD/YYYY")}</p>
          </Stack>

          <Stack className="border-bottom pt-4 ps-4">
            <small className="pb-2">Protection Profiles</small>
            <ul>
              {protection_profile.map((pp) => (
                <li key={pp.pp_id}>{pp.pp_name}</li>
              ))}
              {sunset_protection_profile.map((pp) => (
                <li key={pp.pp_id}>{pp.pp_name}</li>
              ))}
            </ul>
          </Stack>

          <Stack className="border-bottom pt-4 ps-4">
            <small className="pb-2">References</small>
            <p>{related_to}</p>
          </Stack>
        </Col>

        <Col sm={12} md={9} lg={10} className="px-4">
          <Accordion className="mb-4" flush activeKey={"0"}>
            <Accordion.Item eventKey={"0"}>
              <Accordion.Header>
                <h6 className="text-primary fw-bold">ISSUE DESCRIPTION</h6>
              </Accordion.Header>

              <Accordion.Body>{parse(description)}</Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion className="my-4" flush activeKey={"0"}>
            <Accordion.Item eventKey={"0"}>
              <Accordion.Header>
                <h6 className="text-primary fw-bold">RESOLUTION</h6>
              </Accordion.Header>

              <Accordion.Body>{parse(resolution)}</Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion className="my-4" flush activeKey={"0"}>
            <Accordion.Item eventKey={"0"}>
              <Accordion.Header>
                <h6 className="text-primary fw-bold">JUSTIFICATION</h6>
              </Accordion.Header>

              <Accordion.Body>{parse(justification)}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </TdPageWrapper>
  );
}
