import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useInterval } from "hooks/useInterval";

export default function LabgramsTable({ status }) {
  const [conditionalPageSize, setConditionalPageSize] = useState(5);
  const tableRef = useRef();

  const getLocalTime = (time) => {
    return moment.utc(time).format("MM/DD/YYYY");
  };

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 600000); //10 min

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [conditionalPageSize]);

  const columns = [
    {
      title: "No.",
      field: "labgram_id",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) => {
        const paddedId = rowData.labgram_id.toString().padStart(3, "0");
        return (
          <Link to={`/labgrams/${rowData.labgram_id}`} className="text-primary">
            {paddedId}
          </Link>
        );
      },
    },
    {
      title: "Subject",
      field: "subject",
      render: (rowData) => (
        <Link to={`/labgrams/${rowData.labgram_id}`} className="text-primary">
          {rowData.subject}
        </Link>
      ),
    },
    {
      title: "Date",
      field: "labgram_date",
      cellStyle: {
        width: "30%",
        minWidth: "30%",
      },
      render: (rowData) => <>{getLocalTime(rowData.labgram_date)}</>,
      customFilterAndSearch: (input, rowData) => {
        let matcher = new RegExp(input, "i");
        if (matcher.test(getLocalTime(rowData.labgram_date))) {
          return true;
        }
      },
    },
    {
      title: "Author",
      field: "author",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
  ];

  const options = {
    sorting: true,
    pageSize: conditionalPageSize,
    emptyRowsWhenPaging: false,
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  return (
    <ResponsiveMaterialTable
      id={
        status === "active"
          ? "active-labgrams-table"
          : "archived-labgrams-table"
      }
      title={`${capitalize(status)} Labgrams`}
      columns={columns}
      options={options}
      tableRef={tableRef}
      data={(query) =>
        new Promise((resolve, reject) => {
          // Extract the necessary information from the query object
          const { page, pageSize, search, filters, orderBy, orderDirection } =
            query;

          let newFilters = filters.map((filter) => {
            let value = "";
            if (Array.isArray(filter.value)) {
              value = filter.value;
            } else {
              value = filter.value.replace(/['"]+/g, "");
            }
            return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
          });

          let updatedPageSize = pageSize;
          if (conditionalPageSize !== pageSize) {
            updatedPageSize = conditionalPageSize;
          }

          const params = {
            offset: page * updatedPageSize,
            limit: updatedPageSize,
            search: search,
            filters: newFilters,
            orderBy: orderBy?.field,
            orderDirection: orderDirection,
            archived: status === "active" ? false : true,
          };
          axios
            .get(
              `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/get_frontend_labgrams/?${qs.stringify(params, { arrayFormat: "comma" })}`,
            )
            .then((response) => {
              resolve({
                data: response.data.results,
                page: page,
                totalCount: response.data.count,
              });
              setConditionalPageSize(response.data?.count);
            })
            .catch((error) => {
              reject(error);
            });
        })
      }
    />
  );
}
