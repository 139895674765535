import axios from "axios";
import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import ReactSelect from "components/ReactSelect";
import { useAuth } from "hooks/useAuth";

const OrganizationForm = ({ show, org, handleClose, setAlert }) => {
  const [editedOrg, setEditedOrg] = useState({});
  const [states, setStates] = useState([]);
  const [stateValid, setStateValid] = useState(true);
  const [countries, setCountries] = useState([]);
  const [countryValid, setCountryValid] = useState(true);
  const [errors, setErrors] = useState({});
  const [alertShow, setAlertShow] = useState(false);
  const { authToken, csrfToken } = useAuth();

  const handleChange = (e) => {
    if (e.target.name === "approved") {
      setEditedOrg({ ...editedOrg, [e.target.name]: e.target.checked });
    } else if (e.target.name === "logo_file") {
      setEditedOrg({ ...editedOrg, [e.target.name]: e.target.files[0] });
    } else {
      if (e.target.name === "primary_phone") {
        setErrors({
          ...errors,
          [e.target.name]:
            !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
              e.target.value,
            ),
        });
      }
      setEditedOrg({ ...editedOrg, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}address/states/get_all_states/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const states = response.data?.map((data) => {
          return { value: data.us_state_abbrv, label: data.us_state_name };
        });
        setStates(states);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}address/country/get_all_countries/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const countries = response.data?.map((data) => {
          return { value: data.code2_iso, label: data.name };
        });
        setCountries(countries);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setEditedOrg({ ...org });
  }, [org]);

  useEffect(() => {
    setStateValid(true);
    setCountryValid(true);
  }, [show]);

  const testReactSelects = () => {
    if (
      editedOrg.primary_country !== "" &&
      editedOrg.primary_country &&
      editedOrg.primary_state !== "" &&
      editedOrg.primary_state
    ) {
      return true;
    } else {
      if (editedOrg.primary_country === "" || !editedOrg.primary_country) {
        setCountryValid(false);
      }
      if (editedOrg.primary_state === "" || !editedOrg.primary_state) {
        setStateValid(false);
      }
      return false;
    }
  };

  const checkPhone = () => {
    if (
      editedOrg?.primary_phone &&
      !/^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        editedOrg?.primary_phone,
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    testReactSelects();
    if (
      !form.checkValidity() ||
      testReactSelects() === false ||
      !checkPhone()
    ) {
      e.stopPropagation();
    } else {
      const submittedData = new FormData();
      for (let key in editedOrg) {
        if (editedOrg[key] === null || editedOrg[key] === "null") {
          submittedData.append(key, "");
        } else {
          submittedData.append(key, editedOrg[key]);
        }
      }

      if (editedOrg.org_id) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${editedOrg.org_id}/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            handleClose();
            setAlert({
              message: "Organization successfully updated!",
              type: "success",
            });
          })
          .catch((error) => {
            console.log("Unable to update organization: " + error);
            setAlert({
              message: "Error updating organization.",
              type: "danger",
            });
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/`,
            submittedData,
            {
              withCredentials: true,
              headers: {
                Authorization: `Token ${authToken}`,
                "X-CSRFToken": csrfToken,
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => {
            if (response.data.already_exists) {
              setAlertShow(true);
            } else {
              setAlert({
                message: "Organization successfully created!",
                type: "success",
              });
              setAlertShow(false);
              handleClose();
            }
          })
          .catch((error) => {
            console.log("Unable to add organization: " + error);
            setAlert({
              message: "Error creating organization.",
              type: "danger",
            });
          });
      }
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{org.name ? "EDIT" : "CREATE"} Organization</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group controlId="logo">
              <Form.Label>
                <h5 className="fw-bold">
                  {org.logo ? "Edit" : "Add"} Company Logo
                </h5>
              </Form.Label>
              <Row>
                <Col
                  sm={12}
                  md={6}
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "150px" }}
                >
                  {org.logo ? (
                    <img
                      src={org.logo}
                      alt={org?.name + " logo"}
                      style={{ maxWidth: "50%", maxHeight: "50%" }}
                    />
                  ) : (
                    <div>No Logo Set</div>
                  )}
                </Col>
                <Col
                  className="d-flex justify-content-center align-items-center"
                  sm={12}
                  md={6}
                >
                  <Form.Control
                    type="file"
                    name="logo_file"
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row className="mt-3">
            <h5 className="fw-bold mb-4">Organization Information</h5>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Organization Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={org.name}
                  onChange={handleChange}
                  placeholder="Name"
                  autoFocus
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="org_type">
                <Form.Label>Organization Type*</Form.Label>
                <Form.Select
                  name="org_type"
                  defaultValue={org.org_type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="Lab">Lab</option>
                  <option value="Vendor">Vendor</option>
                  <option value="NIAP">NIAP</option>
                  <option value="Validator">Validator</option>
                </Form.Select>
              </Form.Group>
            </Col>
            {editedOrg?.org_type === "Lab" && (
              <Col sm={2} className="m-auto">
                <Form.Group controlId="approved">
                  <Form.Check
                    type="checkbox"
                    label="Approved"
                    name="approved"
                    defaultChecked={editedOrg?.cctl_status === "Certified"}
                    value={true}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="poc">
                <Form.Label>POC*</Form.Label>
                <Form.Control
                  type="text"
                  name="poc"
                  defaultValue={org.poc}
                  onChange={handleChange}
                  placeholder="Point of Contact"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="poc_email">
                <Form.Label>POC Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="poc_email"
                  defaultValue={org.poc_email}
                  onChange={handleChange}
                  placeholder="POC Email"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="website">
                <Form.Label>Organization Website*</Form.Label>
                <Form.Control
                  type="url"
                  name="website"
                  defaultValue={org.website}
                  onChange={handleChange}
                  placeholder="https://example.com"
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="fax">
                <Form.Label>Primary Fax</Form.Label>
                <Form.Control
                  type="tel"
                  name="primary_fax"
                  defaultValue={org.primary_fax}
                  onChange={handleChange}
                  placeholder="111-111-1111"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Primary Phone*</Form.Label>
                <Form.Control
                  type="tel"
                  name="primary_phone"
                  defaultValue={org.primary_phone}
                  onChange={handleChange}
                  placeholder="111-111-1111"
                  isInvalid={errors.primary_phone}
                  required
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="ext">
                <Form.Label>Ext.</Form.Label>
                <Form.Control
                  type="text"
                  name="ext_phone"
                  defaultValue={org.ext_phone}
                  onChange={handleChange}
                  placeholder="Ext. 1111"
                  isInvalid={errors.ext_phone}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <h5 className="fw-bold mb-4">Address Information</h5>
            <Col>
              <Form.Group className="mb-3" controlId="addressLine1">
                <Form.Label>Primary Address Line 1*</Form.Label>
                <Form.Control
                  type="text"
                  name="primary_address_1"
                  defaultValue={org.primary_address_1}
                  onChange={handleChange}
                  placeholder="Address Line 1"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="city">
                <Form.Label>Primary City*</Form.Label>
                <Form.Control
                  type="text"
                  name="primary_city"
                  defaultValue={org.primary_city}
                  onChange={handleChange}
                  placeholder="City"
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="country">
                <Form.Label>Primary Address Country*</Form.Label>
                <ReactSelect
                  id={"country"}
                  options={countries}
                  newObj={editedOrg}
                  handleChange={handleChange}
                  name="primary_country"
                  isValid={countryValid}
                  defaultValue={countries?.find(
                    (country) => country?.value === editedOrg?.primary_country,
                  )}
                  editComponent={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="addressLine2">
                <Form.Label>Primary Address Line 2</Form.Label>
                <Form.Control
                  type="text"
                  name="primary_address_2"
                  defaultValue={org.primary_address_2}
                  onChange={handleChange}
                  placeholder="Address Line 2"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="state">
                <Form.Label>Primary Address State*</Form.Label>
                <ReactSelect
                  id={"state"}
                  options={states}
                  newObj={editedOrg}
                  handleChange={handleChange}
                  name="primary_state"
                  isValid={stateValid}
                  defaultValue={states?.find(
                    (state) => state?.value === editedOrg?.primary_state,
                  )}
                  editComponent={true}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="zipCode">
                <Form.Label>Primary Address Postal*</Form.Label>
                <Form.Control
                  type="text"
                  name="primary_zip"
                  defaultValue={org.primary_zip}
                  onChange={handleChange}
                  placeholder="Postal"
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Alert show={alertShow} dismissible variant="danger">
            That Organization Already Exists
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="warning" type="submit">
            {editedOrg.org_id ? "Update" : "Submit"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default OrganizationForm;
