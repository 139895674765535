import axios from "axios";
import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";
import { getCurrentDate } from "utils/timeUtils";

export default function EarNoteModal({
  show,
  currentNote,
  fetchNotes,
  review,
  handleClose,
}) {
  const [note, setNote] = useState(currentNote);
  const { authToken, csrfToken, currentUser } = useAuth();

  const handleChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const sendNote = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const update = {
        note: note.note,
        note_type: "ear-review",
        note_type_id: review.earid,
        submitted_by: note.isEdit ? note.submitted_by.id : currentUser.id,
        submitted_on: note.isEdit ? note.entrydate : getCurrentDate(),
      };
      if (note.isEdit) {
        axios
          .put(
            `${process.env.REACT_APP_DJANGO_ENDPOINT}note/${note.note_id}/`,
            update,
            {
              withCredentials: true,
              headers: {
                "X-CSRFToken": csrfToken,
                Authorization: `Token ${authToken}`,
              },
            },
          )
          .then(() => fetchNotes())
          .catch((error) => console.log(error));
      } else {
        axios
          .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}note/`, update, {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          })
          .then(() => fetchNotes())
          .catch((error) => console.log(error));
      }
      handleClose();
    }
  };

  useEffect(() => {
    setNote(currentNote);
  }, [currentNote]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form onSubmit={sendNote}>
        <Modal.Header closeButton>
          <Modal.Title>
            {note?.isEdit ? "EDIT" : "+ADD"} NOTE for EAR Review {review?.earid}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>VID {review?.pid?.v_id}</h4>
          <Form.Group className="mt-2" controlId="note">
            <Form.Label className="fw-bold">Note*</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="note"
              onChange={handleChange}
              placeholder="Note"
              defaultValue={note?.note}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Send
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
