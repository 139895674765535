import { Container } from "react-bootstrap";
import { Hits } from "react-instantsearch-dom";
// TODO: upgrade this package (deprecated)
// package has moved to react-instantsearch

import Subheader from "components/Subheader";
import { useAuth } from "hooks/useAuth";

import "./search.css";

const Hit = ({ hit }) => {
  const { authToken, permissions } = useAuth();
  console.log("hit", hit);
  if (hit.public) {
    return (
      <div>
        <a href={hit.link} className="h5 text-bolder">
          {hit.page}
        </a>
        <p className="m-1">{hit.blurb}</p>
      </div>
    );
  } else if (authToken && permissions.role_type !== "External") {
    return (
      <div>
        <a href={hit.link} className="h5 text-bolder">
          {hit.page}
        </a>
        <p className="m-1">{hit.blurb}</p>
      </div>
    );
  }

  return <></>;
};

const Search = () => {
  return (
    <>
      <Subheader pageName={"Search"} />
      <Container className="my-5">
        <Hits hitComponent={Hit} />
      </Container>
    </>
  );
};

export default Search;
