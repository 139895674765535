import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function AddVirtualTeamModal({
  show,
  handleClose,
  refetch,
  editMode,
  teamData,
}) {
  const [newTeam, setNewTeam] = useState({});
  const [validated, setValidated] = useState(false);
  const { authToken, csrfToken, currentUser } = useAuth();

  useEffect(() => {
    if (editMode) {
      setNewTeam(teamData);
    }
  }, [editMode, teamData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (editMode) {
      axios
        .put(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/${teamData.vtid}/`,
          {
            ...newTeam,
            moddate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            creator: currentUser.id,
            editor: currentUser.id,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          refetch();
          handleClose();
        })
        .catch((error) =>
          console.log("Unable to update virtual teams: " + error),
        );
    } else {
      axios
        .post(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/`,
          {
            ...newTeam,
            seq: 1,
            entrydate: new Date(
              new Date().setMinutes(
                new Date().getMinutes() - new Date().getTimezoneOffset(),
              ),
            ),
            creator: currentUser.id,
            editor: currentUser.id,
          },
          {
            withCredentials: true,
            headers: {
              "X-CSRFToken": csrfToken,
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then(() => {
          refetch();
          handleClose();
        })
        .catch((error) =>
          console.log("Unable to update virtual teams: " + error),
        );
    }

    setValidated(true);
  };

  const handleChange = (e) => {
    const value =
      e.target.name === "active" ? e.target.value === "true" : e.target.value;
    setNewTeam({ ...newTeam, [e.target.name]: value });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? "+ EDIT VIRTUAL TEAM" : "ADD VIRTUAL TEAM"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="virtualTeamName">
            <Form.Label>Virtual Team*</Form.Label>
            <Form.Control
              type="text"
              name="team"
              onChange={handleChange}
              placeholder="Enter Team Name"
              defaultValue={editMode ? teamData.team : ""}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Team Name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="virtualTeamAbbr">
            <Form.Label>Abbreviation*</Form.Label>
            <Form.Control
              required
              type="text"
              name="abbrev"
              onChange={handleChange}
              placeholder="Enter Abbreviation of Team Name"
              defaultValue={editMode ? teamData.abbrev : ""}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid Team Name Abbreviation.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="team_type">
            <Form.Label>Virtual Team Type</Form.Label>
            <Form.Select
              name="team_type"
              onChange={handleChange}
              defaultValue={editMode ? teamData.team_type : ""}
            >
              <option value="">Team Type</option>
              <option value="ERT Team">ERT Team</option>
              <option value="TRRT Team">TRRT Team</option>
              <option value="Response Team">Response Team</option>
              <option value="NCRT Team">NCRT Team</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="virtualTeamStatus">
            <Form.Label>Status*</Form.Label>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Form.Check
                inline
                required
                label="Active"
                name="active"
                type="radio"
                id="active"
                value={true}
                checked={newTeam.active === true}
                onChange={handleChange}
              ></Form.Check>
              <Form.Check
                inline
                required
                label="Inactive"
                name="active"
                type="radio"
                id="inactive"
                value={false}
                checked={newTeam.active === false}
                onChange={handleChange}
              ></Form.Check>
            </div>
            <Form.Control.Feedback type="invalid">
              Please select either Active or Inactive.
            </Form.Control.Feedback>
          </Form.Group>
          <Button type="submit">{editMode ? "Edit" : "Add"}</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
