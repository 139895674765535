import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";

import { useAssociateProductMutation } from "api/memberPortal/project/protectionProfiles/protectionProfiles.api";
import { useAuth } from "hooks/useAuth";

export default function AssociatedEvaluations({
  show,
  handleShowEval,
  pp_id,
  associatedEvaluations,
}) {
  const [projPps, setProjPps] = useState([]);
  const [associatedProjs, setAssociatedProjs] = useState({
    associated_products: [],
  });
  const { authToken, csrfToken, currentUser } = useAuth();

  const [triggerAssociate, results] = useAssociateProductMutation();

  const handleChange = (e) => {
    const selectedProducts = { associated_products: [] };
    e?.map((product) =>
      selectedProducts.associated_products.push({
        product: product.value,
        pp: pp_id,
        creator: currentUser.id,
        editor: currentUser.id,
      }),
    );
    setAssociatedProjs(selectedProducts);
  };

  const handleAddEvaluations = () => {
    triggerAssociate({ ...associatedProjs });
    handleShowEval();
  };

  const loadProjectProduct = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/get_all_products`,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        const currentAssociatedProjects = associatedEvaluations.map(
          (projpp) => projpp?.product__product_id,
        ); // product_ids of associations
        const projectOptions = response.data.filter(
          (proj) => !currentAssociatedProjects.includes(proj.product_id),
        ); // Only add projects not already associated
        setProjPps(projectOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [show]);

  useEffect(() => {
    loadProjectProduct();
  }, [loadProjectProduct]);

  const productOptions = projPps.map((projpp) => ({
    value: projpp.product_id,
    label: projpp.product_name,
  }));

  return (
    <Modal size="lg" show={show} onHide={handleShowEval}>
      <Modal.Header closeButton>
        <Modal.Title>+ Associate a Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="product_name">
            <Select isMulti onChange={handleChange} options={productOptions} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleShowEval}>
          Cancel
        </Button>
        <Button variant="warning" onClick={handleAddEvaluations}>
          Enter Selected Evaluations
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
