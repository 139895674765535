import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useAuth } from "hooks/useAuth";

export default function NewTDModal({ show, handleClose }) {
  const { authToken, csrfToken, currentUser } = useAuth();
  const navigate = useNavigate();

  const handleCreateTD = () => {
    let data = {
      created_on: new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ),
      created_by: currentUser.id,
    };
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}technical-decision/`,
        data,
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        navigate(
          `/community/technical-decisions/details/${response?.data?.identifier}`,
        );
      })
      .catch((error) => console.log(error.response.data));
  };

  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Create New Technical Decision?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" className="m-1" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" className="m-1" onClick={handleCreateTD}>
            Create Technical Decision
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
