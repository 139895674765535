import axios from "axios";
import { useCallback, useEffect } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function EvaluationTeamFormSection({
  product,
  handleChangeForm,
  setEvalTeam,
  reload,
  disableButton,
}) {
  const { authToken, permissions } = useAuth();

  const loadProductFromId = useCallback(() => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/personnel/all_product_evaluators/?product=${product.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => setEvalTeam(response.data))
        .catch((error) => console.log(error));
    }
  }, [product?.product_id]);

  useEffect(() => {
    loadProductFromId();
  }, [reload]);

  return (
    <>
      {permissions?.role_permissions?.find(
        (permission) => permission.area === "Project Members",
      )?.update &&
        permissions?.role_permissions?.find(
          (permission) => permission.area === "Evaluation Team",
        )?.create && (
          <Row className="mt-4 mb-3">
            <p className="fw-bold form-title">EVALUATION TEAM</p>
            <p>Add Members to Evaluation Team?</p>
            <Col>
              <Button
                variant="primary"
                onClick={(e) => handleChangeForm(e, "eval")}
                disabled={disableButton}
              >
                Click to add Evaluation Team Members
              </Button>
            </Col>
            {disableButton && (
              <div className="text-danger">
                Please enter a product name and vendor before adding team
                members
              </div>
            )}
            <Form.Text className="fw-bold">
              (Note: Clicking this button will save this form as a Draft)
            </Form.Text>
            <Form.Text className="text-muted fst-italic">
              Lead Evaluator Assignment Required to Submit Form
            </Form.Text>
          </Row>
        )}
    </>
  );
}
