import axios from "axios";
import { useState, useEffect, useRef, useCallback } from "react";
import { Alert, Container } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import Filters from "./Filters";
import OrganizationForm from "./OrganizationForm";
import OrganizationTable from "./OrganizationTable";

export default function OrganizationManagerView() {
  const [show, setShow] = useState(false);
  const [currentOrg, setCurrentOrg] = useState({});
  const [isArchivedView, setIsArchivedView] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const tableRef = useRef();
  const { authToken, csrfToken, permissions } = useAuth();

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const fetchOrgs = useCallback(() => {
    tableRef.current.onQueryChange();
  }, []);

  useEffect(() => {
    fetchOrgs();
  }, [isArchivedView]);

  const handleArchive = async (e, rowData, archived) => {
    await axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}org/organization/${rowData.org_id}/`,
        {
          archived: archived,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then((response) => {
        tableRef.current.onQueryChange();
        setAlert({
          message: `Organization successfully ${archived ? "Archived" : "Activated"}!`,
          type: "success",
        });
      })
      .catch((error) => {
        console.log("Unable to update organization: " + error);
        setAlert({ message: "Error archiving organization.", type: "danger" });
      });
  };

  const handleShowModal = (e, rowData) => {
    setCurrentOrg({ ...rowData });
    setShow(true);
  };

  return (
    <Container fluid>
      {alert?.message !== "" && (
        <Alert
          variant={alert.type}
          onClose={() => setAlert({ message: "", type: "" })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Filters
        isArchivedView={isArchivedView}
        setIsArchivedView={setIsArchivedView}
        handleShowModal={handleShowModal}
        role={permissions}
      />
      <OrganizationTable
        handleShowModal={handleShowModal}
        handleArchive={handleArchive}
        role={permissions}
        isArchivedView={isArchivedView}
        tableRef={tableRef}
      />
      <OrganizationForm
        show={show}
        org={currentOrg}
        handleClose={() => {
          setShow(false);
          setCurrentOrg({});
          fetchOrgs();
        }}
        setAlert={setAlert}
      />
    </Container>
  );
}
