import {
  ACCESS_LEVELS,
  AccessLevel,
  PermissionArea,
  RolePermission,
} from "api/common/types/auth.types";

interface PermissionCheckParams {
  permissions: { role_permissions: RolePermission[] };
  area: PermissionArea;
  access: AccessLevel;
}

export const checkPermissions = ({
  permissions: { role_permissions },
  area,
  access = ACCESS_LEVELS.READ,
}: PermissionCheckParams): boolean => {
  return role_permissions.some((p) => {
    return p.area === area && p[access as keyof RolePermission];
  });
};
