import fileDownload from "js-file-download";

import { baseApi } from "api/BaseApi";

import { UserUploadedFile, FilesPayload } from "./files.types";

const FILE_BASE_URL = "file";

const filesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getFile: build.mutation<null, string | number>({
      query: (fileId) => ({
        url: `${FILE_BASE_URL}/get_file/`,
        method: "GET",
        params: {
          file_id: fileId,
        },
        cache: "no-cache",

        responseHandler: async (response) => {
          const fileName =
            response.headers
              .get("Content-Disposition") // 'attachment; filename="filename.pdf"'
              ?.split("filename=")[1] // ['attachment; ', '"filename.pdf"']
              .replaceAll('"', "") ?? "Downloaded File"; // 'filename.pdf' or default filename

          return response.blob().then((blob) => ({
            blob,
            fileName,
          }));
        },
      }),

      transformErrorResponse: (e) => {
        console.error(e);
        return { error: "Error Downloading File" };
      },

      transformResponse: ({
        blob,
        fileName,
      }: {
        blob: Blob;
        fileName: string;
      }) => {
        fileDownload(blob, fileName);
        return null;
      },
    }),

    getFilesByTypeAndId: build.query<UserUploadedFile[], FilesPayload>({
      query: (params) => ({
        url: `${FILE_BASE_URL}/get_all_files_by_type_and_type_id/`,
        params,
      }),
      providesTags: ["FILES"],
    }),

    getFilesByTypeAndSourceAndId: build.query<UserUploadedFile[], FilesPayload>(
      {
        query: (params) => ({
          url: `${FILE_BASE_URL}/get_files_by_type_and_file_source_type_and_type_id/`,
          params,
        }),
      },
    ),

    uploadFile: build.mutation<UserUploadedFile, FormData>({
      query: (body) => ({
        url: `${FILE_BASE_URL}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FILES"],
    }),

    removeFile: build.mutation<UserUploadedFile, string | number>({
      query: (id) => ({
        url: `${FILE_BASE_URL}/${id}/`,
        method: "PUT",
        body: { active: false },
      }),
      invalidatesTags: ["FILES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetFileMutation,
  useGetFilesByTypeAndIdQuery,
  useGetFilesByTypeAndSourceAndIdQuery,
  useRemoveFileMutation,
  useUploadFileMutation,
} = filesApi;
