import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import FAQCategoriesTable from "./FAQCategoriesTable";
import FAQTable from "./FAQTable";

export default function FAQManager() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<FAQTable />} />
      <Route path="categories" element={<FAQCategoriesTable />} />
    </Routes>
  );
}
