import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import { useCallback } from "react";

const CustomGridToolbarResetButton = () => {
  const apiRef = useGridApiContext();

  const resetFilters = useCallback(() => {
    apiRef.current.setFilterModel({ items: [], quickFilterValues: [] });
  }, [apiRef]);

  return (
    <Tooltip title="Clear Filters">
      <IconButton size="small" onClick={resetFilters}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};
export default CustomGridToolbarResetButton;
