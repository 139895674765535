import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import LabgramForm from "./LabgramForm";

export default function ExternalLinksManager() {
  const [currentLabgram, setCurrentLabgram] = useState({});
  const [links, setLinks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const tableRef = useRef();
  const [isEditingLabgram, setIsEditingLabgram] = useState(false);
  const { authToken, csrfToken, currentUser } = useAuth();

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [refresh]);

  const refreshLabgrams = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const fetchGroupedLabgrams = () => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/get_grouped_labgrams/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => setLinks(response.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchGroupedLabgrams();
  }, []);

  useInterval(() => {
    refreshLabgrams();
    fetchGroupedLabgrams();
  }, 600000); //10 min

  const getLocalTime = (time) => {
    if (!time) {
      return "Not Modified";
    }
    const newTime = new Date(time);
    return newTime.toLocaleDateString("en-US", { timeZone: "UTC" });
  };

  const handleShowModal = (e, rowData) => {
    setCurrentLabgram({ ...rowData });
    setShowModal(true);
  };

  const handleDelete = (rowData) => {
    let currentTime = new Date(
      new Date().setMinutes(
        new Date().getMinutes() - new Date().getTimezoneOffset(),
      ),
    );
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/${rowData.labgram_id}/`,
        {
          creator: rowData.creator?.id,
          editor: currentUser.id,
          archived: true,
          moddate: currentTime,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        setRefresh(!refresh);
        refreshLabgrams();
      })
      .catch((error) => console.log("Unable to archive labgram: " + error));
  };

  const handleCloseModal = () => {
    setRefresh(!refresh);
    refreshLabgrams();
    fetchGroupedLabgrams();
    setCurrentLabgram({});
    setShowModal(false);
  };

  const columns = [
    { title: "No.", field: "labgram_id" },
    {
      title: "Subject",
      field: "subject",
      render: (rowData) => (
        <Link to={`/community/labgrams-manager/details/${rowData?.labgram_id}`}>
          {rowData.subject}
        </Link>
      ),
    },
    {
      title: "Date",
      field: "labgram_date",
      type: "date",
      render: (rowData) => <>{getLocalTime(rowData.labgram_date)}</>,
      customFilterAndSearch: (input, rowData) => {
        let matcher = new RegExp(input, "i");
        if (matcher.test(getLocalTime(rowData.labgram_date))) {
          return true;
        }
      },
    },
    { title: "Author", field: "author" },
    {
      title: "Archived",
      field: "archived",
      defaultFilter: ["false"],
      lookup: { true: "True", false: "False" },
      render: (rowData) => (rowData.archived ? "Yes" : "No"),
    },
    {
      title: "Files",
      render: (rowData) => links[rowData.labgram_id]?.length ?? 0,
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            key="bottom"
            overlay={
              <Popover>
                <Popover.Body>
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link mr-auto"
                    onClick={(e) => {
                      setIsEditingLabgram(true);
                      handleShowModal(e, rowData);
                    }}
                  >
                    <EditIcon color="action" /> Edit
                  </Button>
                  <Button
                    variant="link"
                    className="m-1 p-0 btn-link mr-auto"
                    onClick={() => handleDelete(rowData)}
                  >
                    <>
                      <DeleteIcon color="action" /> Delete
                    </>
                  </Button>
                  <Link
                    to={`/community/labgrams-manager/details/${rowData?.labgram_id}`}
                    className="text-decoration-none"
                  >
                    <Button variant="link" className="m-1 p-0 btn-link">
                      <VisibilityIcon color="action" /> View
                    </Button>
                  </Link>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="link">
              <MoreVertIcon className="float-end" />
              <span className="visually-hidden">Actions</span>
            </Button>
          </OverlayTrigger>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: true,
    columnsButton: true,
    pageSize: 20,
  };

  useEffect(() => {
    document.title = "NIAP - Labgrams Manager";
  }, []);

  return (
    <Container fluid className="pt-5">
      <Row className="justify-content-end">
        <Col xs={12} sm={2} className="mb-3">
          <Button
            variant="primary"
            className="me-3 rounded-0 w-100"
            onClick={(e) => {
              setIsEditingLabgram(false);
              handleShowModal(e, {});
            }}
          >
            + New Labgram
          </Button>
        </Col>
      </Row>
      <ResponsiveMaterialTable
        title={"Labgrams"}
        columns={columns}
        options={options}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else if (["labgram_date"]?.includes(filter?.column?.field)) {
                const dateObj = new Date(filter?.value);
                const isoStr = dateObj.toISOString();
                const formattedStr = isoStr.split("T")[0];
                value = formattedStr;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}lab/labgrams/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        className="my-3"
      />
      <LabgramForm
        show={showModal}
        handleCloseModal={handleCloseModal}
        labgram={currentLabgram}
        isEditingLabgram={isEditingLabgram}
      />
    </Container>
  );
}
