import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { removeRole } from "app/role.slice";
import { removeUser } from "app/user.slice";

const Logout = () => {
  const [cookies, _setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();

  useEffect(() => {
    removeCookie("auth_token");
    dispatch(removeRole());
    dispatch(removeUser());
  }, []);

  return !cookies["auth_token"] && <Navigate replace to="/" />;
};

export default Logout;
