import { MTableBodyRow, MTableCell } from "@material-table/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import { useState, useCallback, useEffect } from "react";
import { Popover, OverlayTrigger, ListGroup, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";
import { calculateActiveDaysInProgress } from "utils/timeUtils";

import StageTracker from "../StageTracker/StageTracker";

import ManageEvalTeamModal from "./ManageEvalTeamModal";

export default function ProductsInEvalTable({ tableRef, progressPoints }) {
  const [showManageEvalTeamModal, setShowManageEvalTeamModal] = useState(false);
  const [currentProductInEval, setCurrentProductInEval] = useState({});
  const [ecrs, setEcrs] = useState([]);
  const [projpps, setProjpps] = useState([]);
  const { authToken, permissions } = useAuth();

  const fetchEcrs = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ecr/get_grouped_ecrs/`, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then((response) => setEcrs(response.data))
      .catch((error) => console.log("Unable to get ecrs: " + error));
  }, []);

  const fetchPPs = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_grouped_pp/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        setProjpps(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchEcrs();
  }, []);

  useEffect(() => {
    fetchPPs();
  }, []);

  useInterval(() => {
    fetchEcrs();
    fetchPPs();
  }, 300000); //5 min

  const handleShowManageTeamModal = (rowData) => {
    setCurrentProductInEval(rowData);
    setShowManageEvalTeamModal(true);
  };

  const columns = [
    {
      title: "VID No.",
      field: "v_id",
      type: "numeric",
      render: (rowData) => (
        <Link
          to={`products/details/${rowData.product_id}`}
          className="text-decoration-underline"
          data-cy={`My Product Management List-vid_select-${rowData?.product_id}`}
        >
          {rowData?.v_id}
        </Link>
      ),
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
    {
      title: "Vendor",
      field: "vendor_id.name",
      cellStyle: {
        width: "15%",
        minWidth: "15%",
      },
    },
    {
      title: "Product",
      field: "product_name",
      width: "14.2%",
      cellStyle: {
        width: "20%",
        minWidth: "20%",
      },
    },
    {
      title: "PP Claim",
      sorting: false,
      render: (rowData) => (
        <ListGroup>
          {projpps &&
            projpps[rowData?.product_id]?.map((projpp, idx) => (
              <OverlayTrigger
                key={idx}
                placement="top"
                overlay={<Tooltip>{projpp.name}</Tooltip>}
              >
                <Link
                  to={`/community/protectionprofiles/details/${projpp.id}`}
                  className="text-decoration-underline"
                >
                  <ListGroup.Item disabled>
                    {projpp.pp_short_name}
                  </ListGroup.Item>
                </Link>
              </OverlayTrigger>
            ))}
        </ListGroup>
      ),
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return (
          <div style={{ width: "max-content" }}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              key="bottom"
              overlay={
                <Popover
                  style={{
                    minWidth: "200px",
                    maxWidth: "600px",
                    width: "max-content",
                  }}
                >
                  <Popover.Body>
                    <StageTracker rowData={rowData} />
                  </Popover.Body>
                </Popover>
              }
            >
              <Button
                variant="link"
                className="text-bright-navy small-font"
                style={{
                  width: "max-content",
                  backgroundColor:
                    rowData.status === "Pre-Proposal"
                      ? "#FFF9C4"
                      : "transparent",
                }}
              >
                {rowData.status}
              </Button>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      title: "Days in Progress",
      field: "days_in_progress",
      render: (rowData) => {
        const daysInProgress = calculateActiveDaysInProgress(rowData);
        const displayText = daysInProgress > 0 ? daysInProgress : "Pending";

        return <p>{displayText}</p>;
      },
    },
    {
      title: "Current Progress Point",
      field: "current_progress_point",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) =>
        progressPoints[rowData?.product_id] ? (
          progressPoints && progressPoints[rowData?.product_id][0]
        ) : (
          <></>
        ),
    },
    {
      title: "Kickoff Date",
      field: "kicked_off_date",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) =>
        rowData?.kicked_off_date
          ? moment.utc(rowData?.kicked_off_date).format("MM/DD/YYYY")
          : "No Date",
    },
    {
      title: "ECR Comments",
      field: "ecr_comments",
      type: "numeric",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
      render: (rowData) =>
        ecrs[rowData.product_id] ? ecrs[rowData.product_id].length : 0,
    },
    {
      title: "Assurance Maintenance Date",
      type: "date",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
    {
      title: "Estimated Date",
      type: "date",
      cellStyle: {
        width: "10%",
        minWidth: "10%",
      },
    },
    {
      title: "Actions",
      field: "actions",
      filtering: false,
      sorting: false,
      render: (rowData) => {
        return (
          <>
            {permissions?.role_permissions?.find(
              (permission) => permission.area === "Project Members",
            )?.update &&
            permissions?.role_permissions?.find(
              (permission) => permission.area === "Evaluation Team",
            )?.create ? (
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                key="bottom"
                overlay={
                  <Popover>
                    <Popover.Body>
                      <div className="d-flex-column text-center">
                        <Link
                          to={`products/details/${rowData.product_id}`}
                          className="text-decoration-none "
                        >
                          <Button variant="link" className="m-1 p-0 btn-link">
                            View Product Details
                          </Button>
                        </Link>
                        <Button
                          variant="link"
                          className="m-1 p-0 btn-link"
                          onClick={() => handleShowManageTeamModal(rowData)}
                        >
                          Manage & Assign Evaluation Team
                        </Button>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button variant="link">
                  <MoreVertIcon className="float-end" />
                </Button>
              </OverlayTrigger>
            ) : (
              <Link
                to={`products/details/${rowData.product_id}`}
                className="text-decoration-none"
              >
                <Button variant="link" className="m-1 p-0 btn-link">
                  View Product Details
                </Button>
              </Link>
            )}
          </>
        );
      },
    },
  ];

  const options = {
    sorting: true,
    search: true,
    padding: "dense",
    columnsButton: true,
    pageSize: 20,
  };

  return (
    <>
      <ManageEvalTeamModal
        show={showManageEvalTeamModal}
        setShow={setShowManageEvalTeamModal}
        currentProductInEval={currentProductInEval}
      />
      <ResponsiveMaterialTable
        title={"My Product Management List"}
        columns={columns}
        components={{
          Row: (props) => (
            <MTableBodyRow
              {...props}
              data-cy={`My Product Management List-row-${props.data.product_id}`}
            />
          ),
          Cell: (props) => (
            <MTableCell
              {...props}
              data-cy={`My Product Management List-col-${props?.columnDef?.title}-${props.rowData?.product_id}`}
            />
          ),
        }}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              status: [
                "Pre-Proposal",
                "Proposed",
                "Pre-Evaluation",
                "In Progress",
                "In Assurance Maintenance",
              ],
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/current_user_products/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
    </>
  );
}
