import axios from "axios";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";

import ProductForm from "components/Products/ProductManager/ProductForm";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import AlertsModule from "../AlertsModule";
import CCTLMetricsGroup from "../Metrics/CCTLMetricsGroup";
import ProductsInDraftModule from "../ProductsInDraftModule/ProductsInDraftModule";
import CCTLAssociatesVendorModal from "../ProductsModule/CCTLAssociatesVendorModal";
import CCTLProductsModule from "../ProductsModule/CCTLProductsModule";
import ManageEvalTeamModal from "../ProductsModule/ManageEvalTeamModal";

export default function CctlDashboard() {
  const [refetchMetrics, setRefetchMetrics] = useState(0);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showVendorModal, setShowVendorModal] = useState(false); //Close the Assciates Modal
  const [isSwitch, setIsSwitch] = useState(false);
  const [product, setProduct] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  const [reload, setReload] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ message: "", type: "" });
  const [showManageEvalTeamModal, setShowManageEvalTeamModal] = useState(false);
  const [amProjects, setAmProjects] = useState([]);
  const [thirtyDayAlert, setThirtyDayAlert] = useState([]);
  const [updateTables, setUpdateTables] = useState(false);
  const { authToken } = useAuth();

  const handleShowVendorModal = () => {
    setShowVendorModal(true);
  };

  const handleCloseVendorModal = () => {
    setShowVendorModal(false);
  };

  const handleOpenEvalTeamModal = () => {
    setShowManageEvalTeamModal(true);
  };

  const handleOpenDraft = () => {
    setIsDraft(true);
    setShowProductModal(true);
  };

  const handleCloseSwitch = () => {
    setShowProductModal(false);
    fetchProductMetrics();
  };

  const handleShowProductModal = () => {
    setIsDraft(false);
    setShowProductModal(true);
  };

  const refetch = useCallback(() => {
    fetchProductMetrics();
    fetchAMProjects();
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  useInterval(() => {
    refetch();
  }, 300000); //5 min

  /** resubmission field values do not exist on product prior to a
   * put request, so we call this endpoint to get the latest product version
   * with these fields, and set the product prop so that it's available to all
   * child components calling the switchForms function
   **/
  const fetchProduct = (product_id) => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${product_id}/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setProduct(response.data);
      })
      .catch(() => {
        console.log("Error fetching product");
      });
  };

  const handleCloseProductModal = (product, message, type) => {
    setShowProductModal(false);
    setProduct({});
    if (product) {
      refetch();
      setIsDraft(false);
      setAlertInfo({ message: message, type: type });
    }
    setUpdateTables(!updateTables);
  };

  const switchForms = (product, type) => {
    fetchProduct(product?.product_id);
    if (type === "vendor") {
      switchToVendor();
    } else if (type === "eval") {
      switchToEvalTeam();
    }
  };

  const switchToVendor = () => {
    setIsSwitch(true);
    handleCloseSwitch();
    handleShowVendorModal();
  };

  const switchToEvalTeam = () => {
    setIsSwitch(true);
    handleCloseSwitch();
    handleOpenEvalTeamModal();
  };

  const switchToProduct = () => {
    setIsSwitch(false);
    handleCloseVendorModal();
    handleOpenDraft();
  };

  const fetchProductMetrics = () => {
    setRefetchMetrics(refetchMetrics + 1);
  };

  const fetchAMProjects = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/my_am_products_for_alerts/`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        setAmProjects(response.data);
        const twoYearsAgo = moment().subtract(2, "years");
        const thirtyDaysBeforeTwoYearsAgo = moment(twoYearsAgo).subtract(
          30,
          "days",
        );
        let alerts = [];
        response.data.forEach((product) => {
          if (
            moment(product?.assurance_maintenance_date).isBetween(
              thirtyDaysBeforeTwoYearsAgo,
              twoYearsAgo,
            ) &&
            product?.current_assurance_maintenance === null
          ) {
            const countdown = moment(twoYearsAgo).diff(
              moment(product?.assurance_maintenance_date),
              "days",
            );
            alerts.push(
              `Your ability to submit VID ${product?.v_id} for AM will expire in ${countdown} days`,
            );
          }
        });
        setThirtyDayAlert(alerts);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <CCTLAssociatesVendorModal
        show={showVendorModal}
        handleCloseModal={handleCloseVendorModal}
        isSwitch={isSwitch}
        switchForms={switchToProduct}
        setReloadVendors={setReload}
      />
      {/* checks for productId before rendering to
       prevent endpoints from being hit before the
       updated product is available */}
      {product?.product_id && (
        <ManageEvalTeamModal
          show={showManageEvalTeamModal}
          setShow={setShowManageEvalTeamModal}
          currentProductInEval={product}
          switchForms={switchToProduct}
          isSwitch={isSwitch}
          setReloadFormData={setReload}
        />
      )}
      <ProductForm
        show={showProductModal}
        handleCloseModal={handleCloseProductModal}
        product={product}
        switchForms={switchForms}
        draft={isDraft}
        reload={reload}
      />
      <Container fluid>
        <Row>
          <Col>
            <Row className="mb-4">
              <Col>
                <CCTLMetricsGroup
                  handleShowModal={handleShowProductModal}
                  handleShowVendorModal={handleShowVendorModal}
                />
              </Col>
            </Row>
            {alertInfo.message !== "" && (
              <Row className="m-3 mb-3">
                <Alert
                  className="mb-0"
                  variant={alertInfo.type}
                  dismissible
                  onClose={() => setAlertInfo({ message: "", type: "" })}
                  data-cy="cctl-dashboard-alert"
                >
                  {alertInfo.message}
                </Alert>
              </Row>
            )}
            {thirtyDayAlert?.length > 0 &&
              thirtyDayAlert?.map((alert, idx) => (
                <Row className="m-3 mb-3" key={idx}>
                  <Alert
                    className="mb-0"
                    variant="warning"
                    dismissible
                    onClose={() => setThirtyDayAlert([])}
                  >
                    {alert}
                  </Alert>
                </Row>
              ))}
            <Row className="mb-4">
              <Col lg={12}>
                <CCTLProductsModule
                  amProjects={amProjects}
                  refetch={refetch}
                  setAlertInfo={setAlertInfo}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={7} className="mb-4">
                <AlertsModule />
              </Col>
              <Col lg={5}>
                <ProductsInDraftModule
                  updateTables={updateTables}
                  setProduct={setProduct}
                  handleOpenDraft={handleOpenDraft}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
