import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import NISTDetails from "../../NIST/NISTDetails";
import FileTable from "../../ProjectFiles";

import AssuranceMaintenanceDetails from "./AssuranceMaintenanceDetails";
import ProductDetails from "./ProductDetails";
import ProductEventLog from "./ProductEventLog";
import ProductsTabs from "./ProductsTabs";
import JumpVid from "./ProductUtils/JumpVid";
import SaveSignature from "./SaveSignature";

export default function ProductManager() {
  const { permissions } = useAuth();

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Project",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Container className="mt-2" fluid>
      {(permissions.role_type === "NIAP" ||
        permissions.role_type === "Validator") && (
        <Row>
          <JumpVid />
        </Row>
      )}
      <Routes>
        {permissions?.role_type !== "Vendor" && (
          <Route path="/" element={<ProductsTabs />} />
        )}
        <Route path="details/:id" element={<ProductDetails />} />
        <Route path="details/:id/eventlog/:vid" element={<ProductEventLog />} />
        <Route path="details/:id/:type" element={<FileTable />} />
        <Route
          path="details/:product_id/assurancemaintenance/:id"
          element={<AssuranceMaintenanceDetails />}
        />
        <Route
          path="details/:product_id/assurancemaintenance/:id/:type"
          element={<FileTable />}
        />
        <Route path="details/:product_id/nist/:id" element={<NISTDetails />} />
        <Route
          path="save_signature/:signer/:packageType/:id"
          element={<SaveSignature />}
        />
      </Routes>
    </Container>
  );
}
