import { baseApi } from "api/BaseApi";
import { getISOCurrentDate } from "utils/timeUtils";

import { Ear, EarCreationPayload } from "./ear.types";

const EAR_BASE_URL = "ear";

const buildEarFormData = ({
  ear,
  earFile,
  currentUser,
  productId,
}: EarCreationPayload): FormData => {
  const earData = new FormData();

  if (earFile.file) {
    //if ear file, we add to submitted data
    earData.append("attachments_file", earFile.file);
    earData.append("attachments_source_type", earFile.file_source_type);
    earData.append("attachments_uploaded", earFile.uploaded_on);
    earData.append("active", "true");
    earData.append("file_label", earFile.file_label);
    earData.append("file_display_name", "Entropy Assessment Report (EAR)");
    earData.append("private", "true");
  }

  earData.append("pid", productId);
  // @ts-ignore // TODO: remove once user types merged in
  earData.append("creator", currentUser.id);
  // @ts-ignore // TODO: remove once user types merged in
  earData.append("editor", currentUser.id);
  earData.append("entrydate", getISOCurrentDate());
  earData.append("status", "Draft");
  earData.append("apriori", ear.apriori.toString());
  earData.append("reqd", ear.reqd.toString());
  earData.append("blurba", ear.blurba);
  earData.append("just", ear.just);

  return earData;
};

const earApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createEar: build.mutation<Ear, EarCreationPayload>({
      query: (body) => ({
        url: `${EAR_BASE_URL}/`,
        method: "POST",
        body: buildEarFormData(body),
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useCreateEarMutation } = earApi;
