import { Modal, Container, Row, Col } from "react-bootstrap";

import archivedPPImg from "images/pp_page/archivedPP.png";

export default function ApprovedPPModal({ show, setShowArchivedPPModal }) {
  const handleClose = () => {
    setShowArchivedPPModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body className="px-5">
        <Container fluid>
          <Row>
            <Col>
              <Row>
                <Col>
                  <div className="d-flex justify-content-center">
                    <img
                      src={archivedPPImg}
                      alt="archived protection profile image"
                      className="w-auto"
                      height="120px"
                    />
                  </div>
                  <div className="text-center">
                    <h3 className="text-uppercase fw-bold mt-3 mb-4 text-primary">
                      Archived Protection Profiles
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <p>
                  Protection Profiles (PPs) listed on this page are for
                  reference only and are not to be used as the basis for new
                  evaluations in NIAP. PPs are reviewed periodically to
                  determine if the security functional and assurance
                  requirements are still acceptable, given rapid technology
                  changes and increasing threat levels.
                </p>
              </Row>
              <Row>
                <p className="mb-0 fw-bold">Protection Profile Sunset Date:</p>
                <p>
                  PPs that no longer accurately map to existing technology
                  threat levels are assigned a sunset date upon which they are
                  archived and moved to this list.
                </p>
                <ul className="mx-4">
                  <li>
                    No new evaluations can be initiated in NIAP against a PP on
                    this list.
                  </li>
                  <li>
                    Products with evaluations initiated prior to a PP's sunset
                    date that demonstrate exact compliance to the PP may be
                    included on the NIAP Product Compliant List.
                  </li>
                  <li>
                    Vendors are encouraged to migrate to requirements of a
                    current PP as soon as the sunset date is posted, for more
                    enduring product security.
                  </li>
                  <li>
                    Assurance Maintenance may be initiated against a PP only
                    until the posted sunset date.
                  </li>
                </ul>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
