import { useGetAllProjectPpQuery } from "api/memberPortal/project/protectionProfiles/protectionProfiles.api";
import DefaultDataGrid from "components/MUI/DataGrid/DefaultDataGrid";

import { associatedEvaluationsColumns } from "./AssociatedEvaluations.columns";

const AssociatedEvaluationsTable = ({ id }: { id: number | string }) => {
  const { data: associatedEvaluations = [], isLoading } =
    useGetAllProjectPpQuery(id);

  return (
    <DefaultDataGrid
      columns={associatedEvaluationsColumns}
      rows={associatedEvaluations}
      getRowId={(row) => row.product__v_id}
      loading={isLoading}
      showToolbar={false}
      localeText={{
        noRowsLabel: "No Associated Evaluations Found ", // Custom message
      }}
    />
  );
};

export default AssociatedEvaluationsTable;
