import { baseApi } from "api/BaseApi";

import { CsComponent } from "../cscomponent.types";

const CS_COMPONENT_PROJ_BASE_URL = "cs/proj";

const csComponentProjApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCsComponentProjByPid: build.query<CsComponent[], string | number>({
      query: (productId) => ({
        url: `${CS_COMPONENT_PROJ_BASE_URL}/get_cscomponentproj_by_pid`,
        params: { pid: productId },
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetCsComponentProjByPidQuery } = csComponentProjApi;
