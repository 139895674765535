import axios from "axios";
import { useEffect, useRef } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import { Link } from "react-router-dom";

import HomepageCards from "./components/Cards/HomepageCards";
import EventsCarousel from "./components/EventsCarousel/EventsCarousel";
import HomepageHeader from "./components/HomepageHeader/HomepageHeader";
import NewsCarousel from "./components/NewsCarousel/NewsCarousel";
import StatusContainer from "./components/StatusContainer/StatusContainer";

import "./Homepage.css";

export default function Homepage() {
  const runOnce = useRef("");

  useEffect(() => {
    document.title = "NIAP - Homepage";
  }, []);

  useEffect(() => {
    if (runOnce.current !== "Has Run") {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}pcm/products/fetch_from_ccp`,
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      runOnce.current = "Has Run";
    }
  }, []);

  const responsiveCarousel = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 4 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  return (
    <>
      <div className="subheader">
        <HomepageHeader />
      </div>

      <div className="homepage-status-container">
        <StatusContainer />
      </div>

      <div className="homepage-cards">
        <HomepageCards />
      </div>

      <Container fluid className="bg-gray py-5">
        <Row className="ms-5 pb-2 justify-content-between">
          <Col xs={12} sm={12} md={5} className="d-flex">
            <h5 className="me-5">
              <strong>ANNOUNCEMENTS, NEWS & UPDATES</strong>
            </h5>
            <Link to="/announcements" className="link">
              <p>
                <u>View All News</u>
              </p>
            </Link>
          </Col>
        </Row>

        <NewsCarousel responsiveCarousel={responsiveCarousel} />

        <Stack direction="horizontal" className="mt-4 ms-5">
          <h5 className="me-5">
            <strong>EVENTS</strong>
          </h5>
          <Link to="/events" className="link">
            <p>
              <u>View All Events</u>
            </p>
          </Link>
        </Stack>

        <EventsCarousel responsiveCarousel={responsiveCarousel} />
      </Container>
    </>
  );
}
