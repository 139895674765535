import { faAnglesLeft, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Stack,
} from "react-bootstrap";
import Select from "react-select";

import search from "images/magnify.png";

import "components/Products/Products.css";

export default function Filter({
  show,
  handleShowFilter,
  handleChangeFilters,
  clearFilters,
  filters,
  filterOptions,
  statusOptions,
}) {
  const getVariant = (status) => {
    if (filters.status === "Publishing" && status === "Approved") {
      return "primary";
    } else if (
      filters.status?.includes("Initiation") &&
      status === "In Development"
    ) {
      return "primary";
    } else if (filters.archived === "true" && status === "Archived") {
      return "primary";
    } else {
      return "light";
    }
  };

  const statusColors = {
    Approved: "var(--bs-green)",
    Archived: "var(--bs-orange)",
    "In Development": "var(--bs-yellow)",
  };

  if (!show) return null;

  return (
    <Container className="my-5 mx-1">
      <Row>
        <Col>
          <Button
            variant="light"
            className="rounded-pill sidebar-btn hide mb-3"
            onClick={handleShowFilter}
          >
            <FontAwesomeIcon className="me-3" icon={faAnglesLeft} />
            Hide Filter
            <FontAwesomeIcon className="ms-3" icon={faAnglesLeft} />
          </Button>
        </Col>
        <small className="ms-2 mb-0">Search</small>
        <InputGroup className="mb-5">
          <Form.Label htmlFor="keyword" className="visually-hidden">
            Keyword Search
          </Form.Label>
          <Form.Control
            id="keyword"
            type="text"
            name="keywordSearch"
            placeholder="Keyword Search"
            onChange={(event) =>
              handleChangeFilters("keyword_search", event.target.value)
            }
          />
          <InputGroup.Text className="bg-dark">
            <img src={search} alt="Search" />
          </InputGroup.Text>
        </InputGroup>
        {filterOptions.length > 0 &&
          filterOptions?.map((filter, key) => {
            return (
              <Select
                className="mb-2"
                onChange={(e) => handleChangeFilters(filter?.name, e?.value)}
                key={key}
                name={filter.name}
                aria-label={filter?.name}
                placeholder={`Select ${filter?.label}...`}
                value={filter.options.find(
                  (f) => f.value === filters[filter?.name],
                )}
                options={filter.options}
                isClearable
              />
            );
          })}
        <small className="ms-2 mb-0 mt-2">Status</small>
        <Stack className="d-flex flex-wrap">
          {statusOptions?.map((status, key) => (
            <Button
              key={key}
              className="m-2 text-uppercase"
              size="sm"
              variant={getVariant(status.display)}
              name={status.name}
              id={status.display}
              value={status.value}
              onClick={() => handleChangeFilters(status.name, status.value)}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className="me-1"
                color={statusColors[status.display]}
                size="xs"
              />
              {status.display === "Active" ? "Approved" : status.display}
            </Button>
          ))}
        </Stack>
        <Form.Group className="mb-5 mt-2 text-center">
          <Button
            variant="dark"
            className="sidebar-btn mb-2 text"
            onClick={clearFilters}
          >
            Clear
          </Button>
        </Form.Group>
      </Row>
    </Container>
  );
}
