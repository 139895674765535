import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import ExternalLinksTable from "./ExternalLinkTable";

export default function ExternalLinksManager() {
  const { permissions } = useAuth();

  if (permissions?.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<ExternalLinksTable />} />
    </Routes>
  );
}
