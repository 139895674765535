import { useDispatch } from "react-redux";

import { disableLoadingSpinner, enableLoadingSpinner } from "app/loading.slice";
import { AppDispatch } from "app/store";

export const useLoading = () => {
  const dispatch = useDispatch<AppDispatch>();

  const showLoadingSpinner = (newText: string) => {
    dispatch(enableLoadingSpinner(newText));
  };

  const hideLoadingSpinner = () => {
    dispatch(disableLoadingSpinner());
  };

  return { showLoadingSpinner, hideLoadingSpinner };
};
