import { baseApi } from "api/BaseApi";

import { AppSettings } from "./appSettings.types";

const appApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    checkMaintenanceBanner: build.query<boolean, void>({
      query: () => "settings/settings/get_status_maintenance_banner/",
      transformResponse: (response: AppSettings) => response.maintenance_banner,
      providesTags: ["MAINTENANCE_BANNER"],
    }),

    updateMaintenanceBanner: build.mutation({
      query: (body) => ({
        url: "settings/settings/set_maintenance_banner/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["MAINTENANCE_BANNER"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCheckMaintenanceBannerQuery,
  useUpdateMaintenanceBannerMutation,
} = appApi;
