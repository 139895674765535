import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Tooltip } from "@mui/material";

const CustomGridToolbarRefetchButton = ({
  refetch,
}: {
  refetch?: () => void;
}) => {
  if (!refetch) return;

  return (
    <Tooltip title="Refresh Data">
      <IconButton onClick={refetch}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};
export default CustomGridToolbarRefetchButton;
