import axios from "axios";
import moment from "moment";
import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

const VendorCheckOutModal = ({ show, handleClose, product, setAlertInfo }) => {
  const [postingData, setPostingData] = useState({});
  const [validated, setValidated] = useState(false);
  const [validation_report, setValidationReport] = useState({});
  const [final_draft_validation_report, setFinalDraftValidationReport] =
    useState({});
  const [assurance_activity_report, setAssuranceActivityReport] = useState({});
  const [certificate, setCertificate] = useState({});
  const { authToken, csrfToken } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const pullFiles = () => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_all_files_by_type_and_type_id/?file_type=product&file_type_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          // This will return an object with keys of file_display_name
          // and values of an array of all files with that file_display_name
          const files = Object.groupBy(
            response.data,
            (file) => file.file_display_name,
          );

          const getLatestFile = (fileDisplayName) => {
            return (
              // In this function we access the array corresponding to the file_display_name
              // and reduce it down to a single value of the most recently uploaded file
              files[fileDisplayName]?.reduce((mostRecentFile, fileToCheck) => {
                // if we don't have an uploaded_on date to compare to we are comparing to the epoch
                return moment(fileToCheck.uploaded_on).isAfter(
                  moment(mostRecentFile.uploaded_on || 0),
                )
                  ? fileToCheck
                  : mostRecentFile;
                // if we don't have a valid fileDisplayName we return an empty object
              }, {}) || {}
            );
          };

          setValidationReport(getLatestFile("Validation Report"));
          setFinalDraftValidationReport(
            getLatestFile("Final Draft Validation Report"),
          );
          setAssuranceActivityReport(
            getLatestFile("Assurance Activity Report (AAR)"),
          );
        })
        .catch(() => {
          console.log("Error getting files");
        });
    }
  };

  const getCertificate = () => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_certificate/?file_type=product&file_type_id=${product?.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setCertificate(response.data);
        })
        .catch((error) => console.log(error));
    }
  };

  const getCheckoutData = () => {
    if (product?.product_id) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/checkout-data/for_product/?pid=${product.product_id}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setPostingData({ ...postingData, ...response.data });
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    getCheckoutData();
    getCertificate();
    pullFiles();
  }, [product.product_id, show]);

  useEffect(() => {
    setPostingData({
      ...postingData,
      signer: "Vendor",
      product_id: product?.product_id,
      post_certificate: "false",
      post_to_iccc: "false",
      post_to_ccuf: "false",
    });
  }, [product]);

  const handleChange = (e) => {
    if (e.target.name === "post_certificate" && e.target.value === "false") {
      setPostingData({
        ...postingData,
        [e.target.name]: e.target.value,
        post_to_iccc: "false",
        post_to_ccuf: "false",
      });
    } else {
      setPostingData({ ...postingData, [e.target.name]: e.target.value });
    }
  };

  const signProduct = () => {
    showLoadingSpinner("Redirecting");

    handleClose();
    axios
      .post(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/sign_checkout/`,
        {
          ...postingData,
          product_id: product.product_id,
          signer: "Vendor",
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        window.location.href = response.data.redirect_url;
      })
      .catch((error) => {
        hideLoadingSpinner();
        setAlertInfo({
          type: "danger",
          message:
            "An error occurred during signing process. Please try again.",
        });
        console.log(error);
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity()) {
      signProduct();
    } else {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Release of Information</Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id="submit-contact-us-form"
      >
        <Modal.Body>
          <h1 className="text-capitalize text-primary">Check Out Package</h1>
          <div className="text-center">
            <h3>Vendor Approval for Release of Information</h3>
          </div>
          <p className="fst-italic">
            I hereby grant to the National Information Assurance Partnership
            Common Criteria Evaluation and Validation Scheme, hereinafter
            referred to as the NIAP CCEVS, permission to disseminate, through
            use of the NIAP CCEVS web site, the Product Compliant List Entry
            (PCL Entry), Security Target (ST), Validation Report (VR), Assurance
            Activity Report (AAR) and CC Certificate pertaining to the product
            identified below.
          </p>
          <h5 className="text-capitalize">PRODUCT INFORMATION</h5>
          <p>Product Name: {product?.product_name}</p>

          <h5 className="text-capitalize">
            PERTINENT DOCUMENT IDENTIFICATION/ VALIDATION RESULTS
          </h5>

          <p>
            <Form.Group controlId="st_data_1">
              <Form.Label inline={true}>ST Name*: </Form.Label>{" "}
              <Form.Control
                className="d-inline-block w-50"
                type="text"
                name="st_name"
                defaultValue={postingData.st_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="st_data_2">
              <Form.Label inline={true}>ST Date*: </Form.Label>{" "}
              <Form.Control
                className="d-inline-block w-50"
                type="date"
                name="st_date"
                defaultValue={postingData.st_date}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="vr_data_1">
              <Form.Label inline={true}>VR - Report Number: </Form.Label>{" "}
              {validation_report.file_label ? (
                <Form.Text className="d-inline-block w-75">{`CCEVS-VR-VID${product?.v_id}-${new Date(validation_report?.uploaded_on).getFullYear()}`}</Form.Text>
              ) : final_draft_validation_report?.file_label ? (
                <Form.Text className="d-inline-block w-75">{`CCEVS-VR-VID${product?.v_id}-${new Date(final_draft_validation_report?.uploaded_on).getFullYear()}`}</Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-75"
                  type="text"
                  name="vr_number"
                  defaultValue={postingData.vr_number}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="vr_data_2">
              <Form.Label inline={true}>VR - Final Draft Version: </Form.Label>{" "}
              {validation_report?.file_name ? (
                <Form.Text className="d-inline-block w-75">
                  {validation_report?.file_name}
                </Form.Text>
              ) : final_draft_validation_report?.file_name ? (
                <Form.Text className="d-inline-block w-75">
                  {final_draft_validation_report?.file_name}
                </Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-75"
                  type="date"
                  name="vr_name"
                  defaultValue={postingData.vr_name}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="aar_data_1">
              <Form.Label inline={true}>AAR Name*: </Form.Label>{" "}
              <Form.Control
                required
                className="d-inline-block w-75"
                type="text"
                name="aar_name"
                defaultValue={postingData.aar_name}
                onChange={handleChange}
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="aar_data_2">
              <Form.Label inline={true}>AAR Date*: </Form.Label>{" "}
              <Form.Control
                required
                className="d-inline-block w-75"
                type="date"
                name="aar_date"
                defaultValue={postingData.aar_date}
                onChange={handleChange}
              />
            </Form.Group>
          </p>

          <p>
            <Form.Group controlId="cert_data">
              <Form.Label inline={true}>
                CC Certificate (File Name):{" "}
              </Form.Label>{" "}
              {certificate?.certificate?.file_name ? (
                <Form.Text inline={true}>
                  {certificate?.certificate?.file_name}
                </Form.Text>
              ) : (
                <Form.Control
                  className="d-inline-block w-75"
                  type="text"
                  name="cert_name"
                  defaultValue={postingData.cert_name}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
          </p>

          <p>
            NIAP must adhere to the conditions outlined in the Common Criteria
            Recognition Arrangement (CCRA) which requires valid Common Criteria
            Certificates and their associated Certification/validation Reports
            to published and made available to the public. Approval to release
            by both the CCTL and the Vendor of the evaluation is necessary prior
            to NIAP/CCEVS web posting.
          </p>

          <p>
            The NIAP CCEVS maintains a Product Compliant List for evaluated
            products and a NIAP-approved PP list for PPs. The information and
            documentation will be made publicly available and can be found on
            the NIAP CCEVS web site.
          </p>

          <p className="fw-bold fst-italic">
            The Digital Signature on this form confirms that the PCL Entry, ST,
            VR, AAR and Certificate have been reviewed and determined that the
            documents are free of proprietary information, contain no technical
            inaccuracies, and are authorized for public release through
            employment of the NIAP CCEVS web site.
          </p>

          <h5 className="text-capitalize">CCTL APPROVAL</h5>
          <div className="row">
            <div className="col-sm-6">
              <p>CCTL: {product?.assigned_lab?.name}</p>
              <p>CCTL POC: {product?.assigned_lab?.poc}</p>
              <p>Title of POC: {product?.sponsor_title}</p>
            </div>
            <div className="col-sm-6">
              <p>Phone: {product?.assigned_lab?.primary_phone}</p>
              <p>Email: {product?.assigned_lab?.poc_email}</p>
            </div>
          </div>

          <h5 className="text-capitalize">VENDOR INFORMATION</h5>
          <div className="row">
            <div className="col-sm-6">
              <p>Vendor: {product?.vendor_id?.name}</p>
              <p>Vendor POC: {product?.vendor_id?.poc}</p>
              <p>Title of Vendor POC: {product?.vendor_id?.poc_title}</p>
              <p>Email: {product?.vendor_id?.poc_email}</p>
            </div>
            <div className="col-sm-6">
              <p>
                Full Mailing Address: {product?.vendor_id?.primary_address_1}{" "}
                {product?.vendor_id?.primary_address_2}
                <br />
                {product?.vendor_id?.primary_city},{" "}
                {product?.vendor_id?.primary_state}{" "}
                {product?.vendor_id?.primary_zip}
                <br />
                {product?.vendor_id?.primary_country}
              </p>
              <p>Phone: {product?.vendor_id?.primary_phone}</p>
            </div>
          </div>

          <h5 className="text-capitalize">CERTIFICATE AWARD*</h5>

          <p>
            Would you like the certificate presented publicly? If yes, please
            select a venue below. If no, the signed certificate will be emailed
            to the POC Listed above.
          </p>

          <div className="row my-2">
            <div className="col-5 d-flex">
              <Form.Check
                required
                id="post-yes"
                label="Yes"
                value={true}
                name="post_certificate"
                type="radio"
                onChange={handleChange}
              />
            </div>
            <div className="col-5 d-flex">
              <Form.Check
                required
                id="post-no"
                label="No"
                value={false}
                name="post_certificate"
                type="radio"
                onChange={handleChange}
              />
            </div>
          </div>

          <p>
            <span className="fw-bold">Note:</span>
            If yes, NIAP cannot agree meeting/conference coordinators will agree
            to host a CC Ceremony. For registration requirements including
            presentation ceremony details and submission deadlines, please visit
            <a href="https://iccconference.org" target="__blank">
              {" "}
              https://iccconference.org
            </a>
          </p>

          <div className="d-flex my-2">
            <Form.Check
              id="post-iccc"
              label="International Common Criteria Conference (ICCC) - September timeframe, rotates Internationally"
              value={true}
              name="post_to_iccc"
              type="checkbox"
              onChange={handleChange}
              disabled={
                !postingData.post_certificate ||
                postingData.post_certificate === "false"
              }
              checked={postingData.post_to_iccc === "true"}
            />
          </div>

          <div className="d-flex my-2">
            <Form.Check
              id="post-ccuf"
              label="Common Criteria User's Forum Meeting - April a& September timeframe, rotates Internationally."
              value={true}
              name="post_to_ccuf"
              type="checkbox"
              onChange={handleChange}
              disabled={
                !postingData.post_certificate ||
                postingData.post_certificate === "false"
              }
              checked={postingData.post_to_ccuf === "true"}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Click to Sign Digitally</Button>
          <Button
            variant="light"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default VendorCheckOutModal;
