import axios from "axios";
import FileDownload from "js-file-download";
import Button from "react-bootstrap/Button";

import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";

const PfaNameCell = ({ file }) => {
  const { authToken } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");

    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        hideLoadingSpinner();
        console.log("Error");
      });
  };

  return (
    <Button
      style={{ overflowWrap: "break-word", textAlign: "left" }}
      variant="link"
      onClick={() => downloadFile(file)}
    >
      {file.file_new_name || file.file_name}
    </Button>
  );
};

export default PfaNameCell;
