import parse from "html-react-parser";
import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Stack, Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { useGetFilesByTypeAndIdQuery } from "api/memberPortal/files/files.api";
import {
  useGetAllProjectPpQuery,
  useGetProtectionProfileQuery,
} from "api/memberPortal/project/protectionProfiles/protectionProfiles.api";
import { useGetAllArchivedOrRelatedTdsByIdQuery } from "api/memberPortal/technicalDecisions/technicalDecisions.api";
import FileUploadModal from "components/Products/FileUpload";
import { useAuth } from "hooks/useAuth";

import AssociatedEvaluations from "../AssociatedEvaluations";
import Notes from "../Notes";
import PPForm from "../PPForm";
import SideInfo from "../SideInfo";

import AssociatedEvaluationsTable from "./AssociatedEvaluationTable/AssociatedEvaluations.table";

const PPDetails = () => {
  const { id = 0 } = useParams();

  const [showPpForm, setShowPpForm] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [showAssociatedEvaluations, setShowAssociatedEvaluations] =
    useState(false);

  const { permissions } = useAuth();

  const handleShowPpForm = () => setShowPpForm(true);
  const handleClosePpForm = () => setShowPpForm(false);
  const handleShowEval = () =>
    setShowAssociatedEvaluations(!showAssociatedEvaluations);

  const { data: associatedEvaluations = [] } = useGetAllProjectPpQuery(id, {
    pollingInterval: 60_000, // refetch every 1 Minute
  });

  const { data: pp = {} } = useGetProtectionProfileQuery(id, {
    pollingInterval: 60_000, // refetch every 1 minute
  });

  const { data: tdData = { active: [], archived: [] } } =
    useGetAllArchivedOrRelatedTdsByIdQuery(id, {
      pollingInterval: 180_000, // refetch every 3 minutes
    });
  const { archived: archivedTds, active: tds } = tdData;

  const { data: files = [] } = useGetFilesByTypeAndIdQuery(
    {
      file_type: "protection-profile",
      file_type_id: id,
    },
    {
      pollingInterval: 180_000, // 3 min
    },
  );
  // refetch every 3 minutes and on initial load and on file updates

  const updateFiles = () => {
    setShowFileModal(false);
  };

  useEffect(() => {
    document.title = "NIAP Community - Protection Profiles Details";
  }, []);

  const canViewProtectionProfiles = permissions.role_permissions.find(
    (permission) => permission.area === "Protection Profile",
  )?.read;

  if (!canViewProtectionProfiles) return null;

  const canUpdateProtectionProfiles = permissions.role_permissions.find(
    (permission) => permission.area === "Protection Profile",
  )?.update;

  return (
    <Container className="p-5" fluid>
      <FileUploadModal
        show={showFileModal}
        updateFiles={updateFiles}
        type="protection-profile"
        id={id}
      />
      <Row>
        <Col md={12} lg={9}>
          <Row>
            <Col xs={12} md={4} className="d-flex mb-3">
              <h3 className="fw-bold">PROTECTION PROFILE DETAILS: </h3>
            </Col>

            {/* Wrap buttons in a single `Col` for md to larger screens */}
            <Col xs={12} md={8} className="d-md-flex justify-content-md-end">
              {/* Each button takes full row on mobile, align to end on md */}
              {canUpdateProtectionProfiles && (
                <>
                  <div className="mb-3 mb-md-0 ms-md-2">
                    <Button
                      variant="warning"
                      className="w-100 rounded-pill"
                      onClick={handleShowPpForm}
                    >
                      Edit Protection Profile
                    </Button>
                  </div>

                  <div className="ms-md-2">
                    <Button
                      variant="warning"
                      className="w-100 rounded-pill"
                      onClick={() => setShowFileModal(true)}
                    >
                      Upload Files
                    </Button>
                  </div>
                </>
              )}
            </Col>

            <Col sm={6} className="mt-3">
              <h4>Short Name</h4>
              <h4 className="fw-bold text-primary">{pp?.pp_short_name}</h4>
            </Col>

            <Col sm={6} className="mt-3">
              <h4 className="fw-bold">{pp?.pp_name}</h4>
            </Col>

            <Col sm={12} className="mt-3">
              <Row className="border-dark border-bottom border-top">
                <Col sm={2} className="border-sm-top-bottom border-md-left">
                  <Stack>
                    <h5 className="mt-2 mb-2">Status</h5>
                    <h5 className="text-secondary">{pp?.status}</h5>
                  </Stack>
                </Col>

                <Col sm={2} className="border-sm-top-bottom border-md-left">
                  <Stack>
                    <h5 className="mt-2 mb-2">Archived</h5>
                    <h5 className="text-secondary">
                      {pp?.archived ? "Yes" : "No"}
                    </h5>
                  </Stack>
                </Col>

                <Col sm={2} className="border-sm-top-bottom border-md-left">
                  <Stack>
                    <h5 className="mt-2 mb-2">CC Version</h5>
                    <h5 className="text-secondary">{pp?.cc_version}</h5>
                  </Stack>
                </Col>

                <Col sm={2} className="border-sm-top-bottom border-md-left">
                  <Stack>
                    <h5 className="mt-2 mb-2">Tech Type</h5>
                    <h5 className="text-secondary">{pp?.tech_type}</h5>
                  </Stack>
                </Col>

                <Col sm={2} className="border-sm-top-bottom border-md-left">
                  <Stack>
                    <h5 className="mt-2 mb-2">CSfC-Eligible</h5>
                    <h5 className="text-secondary">
                      {pp?.csfceligible ? "Yes" : "No"}
                    </h5>
                  </Stack>
                </Col>

                <Col sm={2}>
                  <Stack>
                    <h5 className="mt-2 mb-2">DoD Annex Reference(s)</h5>
                    <h5 className="text-secondary">
                      {pp?.dod_compliant ? "Yes" : "No"}
                    </h5>
                  </Stack>
                </Col>
              </Row>
            </Col>

            <Col sm={12} className="mt-3">
              <Accordion defaultActiveKey="0" className="mb-4 border-0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      PROTECTION PROFILE OVERVIEW
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {pp?.pp_overview && parse(pp?.pp_overview)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      SECURITY EVALUATION SUMMARY
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {pp?.security_evaluation_summary &&
                      parse(pp?.security_evaluation_summary)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      ENVIRONMENTAL STRENGTHS
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {pp?.environmental_strengths &&
                      parse(pp?.environmental_strengths)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">ASSURANCE MAINTENANCE</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {pp?.assurance_maintenance &&
                      parse(pp?.assurance_maintenance)}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      RELATED TECHNICAL DECISIONS
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {tds.map((td) => (
                      <div key={td.identifier}>
                        <Link
                          to={{
                            pathname: `/technical-decisions/${td?.identifier}`,
                          }}
                          key={td?.td_id}
                        >
                          <h6 className="mb-3 ms-3">
                            <strong>
                              {td?.identifier} - {td?.title}
                            </strong>
                            &nbsp;
                            {td?.status === "Archived" && (
                              <small>(Archived)</small>
                            )}
                          </h6>
                        </Link>

                        {td.related_to && (
                          <p className="mb-3 ms-5">
                            Reference: {td.related_to}
                          </p>
                        )}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      ARCHIVED TECHNICAL DECISIONS
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {archivedTds.map((td) => (
                      <div key={td.identifier}>
                        <Link
                          to={{
                            pathname: `/technical-decisions/${td?.identifier}`,
                          }}
                          key={td?.td_id}
                        >
                          <h6 className="mb-3 ms-3">
                            <strong>
                              {td.identifier} - {td.title}
                            </strong>
                            &nbsp;
                            {td.status === "Archived" && (
                              <small>(Archived)</small>
                            )}
                          </h6>
                        </Link>

                        {td.related_to && (
                          <p className="mb-3 ms-5">
                            Reference: {td.related_to}
                          </p>
                        )}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey="0" className="my-4" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      ASSOCIATED EVALUATIONS
                    </h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    {permissions?.role_type === "NIAP" && (
                      <Button
                        variant="secondary rounded-pill mb-4"
                        onClick={handleShowEval}
                      >
                        Associate a Product
                      </Button>
                    )}
                    <AssociatedEvaluationsTable id={id} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Notes item={pp} note_type="protection_profile" id={id} />
            </Col>
          </Row>
        </Col>

        <Col md={12} lg={3}>
          <SideInfo pp={pp} files={files} />
        </Col>
      </Row>

      <PPForm show={showPpForm} handleCloseModal={handleClosePpForm} pp={pp} />

      <AssociatedEvaluations
        show={showAssociatedEvaluations}
        handleShowEval={handleShowEval}
        pp_id={pp.pp_id}
        associatedEvaluations={associatedEvaluations}
      />
    </Container>
  );
};

export default PPDetails;
