import { Route, Routes } from "react-router-dom";

import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";

import AccountManagerView from "./AccountManager";

export default function AccountManager() {
  const { permissions } = useAuth();

  if (
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Accounts",
    )?.read &&
    !permissions?.role_permissions?.find(
      (permission) => permission.area === "Organization Members",
    )?.read
  ) {
    return <UnauthorizedView />;
  }

  return (
    <Routes>
      <Route path="/" element={<AccountManagerView />} />
    </Routes>
  );
}
