import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import parse from "html-react-parser";
import FileDownload from "js-file-download";
import qs from "qs";
import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";

import DeleteModal from "components/UI/DeleteModal";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import RelabelObject from "../RelabelObject";
import RenameObject from "../RenameObject";
import ReplaceObject from "../ReplaceObject";

const Doc3DTable = ({ title, docType, tableType, sideFilters }) => {
  const [currentObject, setCurrentObject] = useState({});
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openRelabelModal, setOpenRelabelModal] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const tableRef = useRef();
  const { authToken, csrfToken, currentUser } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const handleOpenRenameModal = (object) => {
    setCurrentObject(object);
    setOpenRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setCurrentObject({});
    setOpenRenameModal(false);
  };

  const handleOpenRelabelModal = (object) => {
    setCurrentObject(object);
    setOpenRelabelModal(true);
  };

  const handleCloseRelabelModal = () => {
    setCurrentObject({});
    setOpenRelabelModal(false);
  };

  const handleOpenReplaceModal = (object) => {
    setCurrentObject(object);
    setOpenReplaceModal(true);
  };

  const handleCloseReplaceModal = () => {
    setCurrentObject({});
    setOpenReplaceModal(false);
  };

  const handleOpenDeleteModal = (object) => {
    setCurrentObject(object);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setCurrentObject({});
    setShowDeleteModal(false);
  };

  useEffect(() => {
    tableRef.current.onQueryChange({ page: 0 });
  }, [sideFilters, tableType, docType]);

  useInterval(() => {
    tableRef.current.onQueryChange();
  }, 300000); //5 min

  const reloadDocuments = () => {
    tableRef.current.onQueryChange();
  };

  const downloadFile = (file) => {
    showLoadingSpinner("Downloading");
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_file/?file_id=${file.file_id}`,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
          },
          responseType: "blob",
        },
      )
      .then((response) => {
        hideLoadingSpinner();
        FileDownload(
          response.data,
          file.file_new_name ? file.file_new_name : file.file_name,
        );
      })
      .catch((error) => {
        console.log("Error");
      });
  };

  const deleteFile = () => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${currentObject?.file_id}/`,
        {
          active: JSON.stringify(false),
          removed_on: new Date(
            new Date().setMinutes(
              new Date().getMinutes() - new Date().getTimezoneOffset(),
            ),
          ),
          removed_by: currentUser.id,
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
        handleCloseDeleteModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const retractFile = (rowData) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
          published: JSON.stringify(false),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const publishFile = (rowData) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
          published: JSON.stringify(true),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disapproveFile = (rowData) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
          approved: JSON.stringify(false),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const approveFile = (rowData) => {
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${rowData?.file_id}/`,
        {
          approved: JSON.stringify(true),
        },
        {
          withCredentials: true,
          headers: {
            "X-CSRFToken": csrfToken,
            Authorization: `Token ${authToken}`,
          },
        },
      )
      .then((response) => {
        reloadDocuments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDisposition = (rowData) => {
    if (rowData.private) return "<p>Not Approved for Publication<p>";
    else {
      let output = "<p>";
      if (rowData.approved) output += "Approved<br />";
      if (!rowData.approved) output += "Not Approved<br />";
      if (rowData.published) output += "Published";
      if (!rowData.published) output += "Not Published";
      output += "</p>";
      return output;
    }
  };

  const publishRetract = (rowData) => {
    if (rowData.private || !rowData.approved)
      return <Button variant="link">_______</Button>;
    else {
      if (rowData.published) {
        return (
          <Button
            variant="link"
            onClick={() => {
              retractFile(rowData);
            }}
          >
            Retract
          </Button>
        );
      } else {
        return (
          <Button
            variant="link"
            onClick={() => {
              publishFile(rowData);
            }}
          >
            Publish
          </Button>
        );
      }
    }
  };

  const approveDisapprove = (rowData) => {
    if (rowData.private || rowData.published)
      return <Button variant="link">_______</Button>;
    else {
      if (rowData.approved) {
        return (
          <Button
            variant="link"
            onClick={() => {
              disapproveFile(rowData);
            }}
          >
            Disapprove
          </Button>
        );
      } else {
        return (
          <Button
            variant="link"
            onClick={() => {
              approveFile(rowData);
            }}
          >
            Approve
          </Button>
        );
      }
    }
  };

  console.log(docType);

  const columns = [
    {
      title: "File Name",
      field: "file_name",
      render: (rowData) =>
        rowData?.file_new_name ? rowData?.file_new_name : rowData?.file_name,
    },
    {
      title: "Current State",
      field: "published",
      render: (rowData) => parse(getDisposition(rowData)),
    },
    {
      title: "Relavent Item",
      render: (rowData) =>
        rowData?.file_type === "product" ? (
          <Link to={`/community/products/details/${rowData?.file_type_id}`}>
            {(rowData?.file_type === "ccp"
              ? "international evaluation"
              : rowData.file_type) +
              " " +
              rowData?.file_type_id}
          </Link>
        ) : (
          (rowData?.file_type === "ccp"
            ? "international evaluation"
            : rowData.file_type) +
          " " +
          rowData?.file_type_id
        ),
    },
    {
      title: "Actions",
      render: (rowData) => (
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          key="bottom"
          overlay={
            <Popover>
              <Popover.Body>
                <Row>
                  <Col xs={4}>
                    {rowData.file_display_name ===
                      "Protection Profile (HTML)" &&
                      rowData.file_name.includes(".htm") && (
                        <Button
                          variant="link"
                          href={`/static_html${rowData.file_location}${rowData.file_name}`}
                          target="_blank"
                        >
                          Retrieve
                        </Button>
                      )}
                    {rowData.file_display_name ===
                      "Protection Profile (HTML)" &&
                      !rowData.file_name.includes(".htm") && (
                        <Button
                          variant="link"
                          href={`/static_html${rowData.file_location}${rowData.file_name}/index.html`}
                          target="_blank"
                        >
                          Retrieve
                        </Button>
                      )}
                    {/* question for this one below, does this one have the potential to pull from somewhere other than protection-profile? */}
                    {rowData.file_display_name !==
                      "Protection Profile (HTML)" &&
                      rowData.isFolder && (
                        <Button
                          variant="link"
                          href={`/static_html${rowData.file_location}${rowData.file_name}/index.html`}
                          target="_blank"
                        >
                          Retrieve
                        </Button>
                      )}
                    {rowData.file_display_name !==
                      "Protection Profile (HTML)" &&
                      !rowData.isFolder && (
                        <Button
                          variant="link"
                          onClick={() => downloadFile(rowData)}
                        >
                          Retrieve
                        </Button>
                      )}
                  </Col>
                  <Col xs={4}>{approveDisapprove(rowData)}</Col>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      onClick={() => handleOpenReplaceModal(rowData)}
                    >
                      Replace
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      onClick={() => navigate(`details/${rowData.file_id}`)}
                    >
                      Details
                    </Button>
                  </Col>
                  <Col xs={4}>{publishRetract(rowData)}</Col>
                  <Col xs={4}>
                    <Button variant="link">....</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      onClick={() => handleOpenRelabelModal(rowData)}
                    >
                      Relabel
                    </Button>
                  </Col>
                  <Col xs={4}>
                    <Button
                      variant="link"
                      onClick={() => handleOpenRenameModal(rowData)}
                    >
                      Rename
                    </Button>
                  </Col>
                  <Col xs={4}>
                    <Button variant="link">....</Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Button variant="link">....</Button>
                  </Col>
                  <Col xs={4}>
                    <Button variant="link">....</Button>
                  </Col>
                  <Col xs={4}>
                    <Button
                      onClick={() => handleOpenDeleteModal(rowData)}
                      variant="link"
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          }
        >
          <Button variant="link">
            <MoreVertIcon className="float-end" />
            <span className="visually-hidden">Action</span>
          </Button>
        </OverlayTrigger>
      ),
    },
  ];

  const options = {
    columnsButton: true,
    headerStyle: {
      fontWeight: "bold",
    },
    pageSize: 20,
  };

  return (
    <div>
      <ResponsiveMaterialTable
        title={title}
        columns={columns}
        tableRef={tableRef}
        data={(query) =>
          new Promise((resolve, reject) => {
            // Extract the necessary information from the query object
            const { page, pageSize, search, filters, orderBy, orderDirection } =
              query;

            let newFilters = filters.map((filter) => {
              let value = "";
              if (Array.isArray(filter.value)) {
                value = filter.value;
              } else {
                value = filter.value.replace(/['"]+/g, "");
              }
              return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
            });
            const params = {
              ...sideFilters,
              offset: page * pageSize,
              limit: pageSize,
              search: search,
              filters: newFilters,
              orderBy: orderBy?.field,
              orderDirection: orderDirection,
              file_type: tableType?.toLowerCase(),
              file_display_name: docType,
            };
            axios
              .get(
                `${process.env.REACT_APP_DJANGO_ENDPOINT}file/get_files_by_type_and_display_name/?${qs.stringify(params, { arrayFormat: "comma" })}`,
                {
                  withCredentials: true,
                  headers: {
                    Authorization: `Token ${authToken}`,
                  },
                },
              )
              .then((response) => {
                resolve({
                  data: response.data.results,
                  page: page,
                  totalCount: response.data.count,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
        }
        options={options}
      />
      <DeleteModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        type={"File"}
        deleteFunction={deleteFile}
      />
      <RenameObject
        show={openRenameModal}
        handleClose={handleCloseRenameModal}
        reloadDocuments={reloadDocuments}
        object={currentObject}
      />
      <RelabelObject
        show={openRelabelModal}
        handleClose={handleCloseRelabelModal}
        reloadDocuments={reloadDocuments}
        object={currentObject}
      />
      <ReplaceObject
        show={openReplaceModal}
        handleClose={handleCloseReplaceModal}
        reloadDocuments={reloadDocuments}
        object={currentObject}
      />
    </div>
  );
};

export default Doc3DTable;
